import { put, takeLatest, all, call, select } from 'redux-saga/effects'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { toast } from 'react-hot-toast'
import errorResponseReturn from '@utils/errorResponseReturn'
import { loadingTaskArrAction, loadingTaskArrFailure, loadingTaskArrSuccess } from './taskArrStateSlice'
import { createMatchSelector } from 'connected-react-router'
import { PayloadAction } from '@reduxjs/toolkit'

function* loadingTaskArrSaga({ payload: { page, limit } }: PayloadAction<{ page?: number; limit?: number }>): Generator {
  try {
    const matchSelector = createMatchSelector({ path: ['/project/:projectId/donations'] })
    let queryParams: string = ''
    if (page && limit) queryParams = `page=${page}&limit=${limit}`
    const match: any = yield select(matchSelector)
    const { data }: any = yield call(apiConnectAxios, `/tasks/project/${match?.params?.projectId}?${queryParams}`)
    yield put(loadingTaskArrSuccess({ taskArr: data?.taskArr, taskCount: data?.taskCount }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingTaskArrFailure(errorResponseReturn(error)))
  }
}

export default function* taskArrRootSaga() {
  yield all([takeLatest(loadingTaskArrAction.type, loadingTaskArrSaga)])
}
