import { put, takeLatest, all, call, select, cancel } from 'redux-saga/effects'
import { toast } from 'react-hot-toast'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import {
  loadingDocumentRequestArrAction,
  loadingDocumentRequestArrFailure,
  loadingDocumentRequestArrSuccess,
  loadingMessageRequestArrAction,
  loadingMessageRequestArrFailure,
  loadingMessageRequestArrSuccess,
  loadingRequestArrAction,
  loadingRequestArrFailure,
  loadingRequestArrSuccess,
} from '@state/requestArr/requestArrStateSlice'
import { delay } from 'redux-saga/effects'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'

function* loadingRequestArrSaga(): Generator {
  try {
    const { requestName }: any = yield select(state => state.router.location.query)
    let queryParams = ''
    if (requestName) queryParams = `&name=${requestName}`
    const { data }: any = yield call(apiConnectAxios, `/requests?${queryParams}&`)
    yield put(
      loadingRequestArrSuccess({
        clientRequestArr: data?.clientRequestArr,
        performerRequestArr: data?.performerRequestArr,
        unreadMessagesCount: data?.unreadMessagesCount || 0,
      }),
    )
    // @ts-ignore
    const { profile }: ProfileStateInterface = yield select(state => state.profileState)
    while (profile) {
      yield delay(30000)
      // @ts-ignore
      const { profile }: ProfileStateInterface = yield select(state => state.profileState)
      if (!profile) yield cancel()
      const { data }: any = yield call(apiConnectAxios, `/requests?${queryParams}&`)
      yield put(
        loadingRequestArrSuccess({
          clientRequestArr: data?.clientRequestArr,
          performerRequestArr: data?.performerRequestArr,
          unreadMessagesCount: data?.unreadMessagesCount || 0,
        }),
      )
    }
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingRequestArrFailure(errorResponseReturn(error)))
  }
}

function* loadingMessageRequestArrSaga(): Generator {
  try {
    const { chatRequestId }: any = yield select(state => state.router.location.query)
    const { data }: any = yield call(apiConnectAxios, `/request-messages/request/${chatRequestId}`)
    yield put(loadingMessageRequestArrSuccess({ requestMessageArr: data?.messageArr, requestChat: data?.request }))
    yield call(loadingDocumentRequestArrSaga)
    yield call(loadingRequestArrSaga)
    while (chatRequestId) {
      yield delay(10000)
      const { chatRequestId }: any = yield select(state => state.router.location.query)
      if (!chatRequestId) yield cancel()
      const { data }: any = yield call(apiConnectAxios, `/request-messages/request/${chatRequestId}`)
      yield put(loadingMessageRequestArrSuccess({ requestMessageArr: data?.messageArr, requestChat: data?.request }))
    }
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingMessageRequestArrFailure(errorResponseReturn(error)))
  }
}

function* loadingDocumentRequestArrSaga(): Generator {
  try {
    const { chatRequestId }: any = yield select(state => state.router.location.query)
    const { data }: any = yield call(apiConnectAxios, `/request-documents/request/${chatRequestId}`)
    yield put(loadingDocumentRequestArrSuccess({ documentRequest: data?.nda || null, contractRequest: data?.contract || null }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingDocumentRequestArrFailure(errorResponseReturn(error)))
  }
}

export default function* requestArrRootSaga() {
  yield all([
    takeLatest(loadingRequestArrAction.type, loadingRequestArrSaga),
    takeLatest(loadingMessageRequestArrAction.type, loadingMessageRequestArrSaga),
    takeLatest(loadingDocumentRequestArrAction.type, loadingDocumentRequestArrSaga),
  ])
}
