enum SearchTypes {
  Community = 'Community',
  Project = 'Project',
  Task = 'Task',
}

enum SearchLanguage {
  All = 'All',
  English = 'English',
  Ukranian = 'Ukranian',
  Italian = 'Italian',
  Hebrew = 'Hebrew',
}

enum DateTypes {
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export { SearchTypes, DateTypes, SearchLanguage }
