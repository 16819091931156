import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TaskInterface, TaskStatusInternalEnum } from '@state/task/taskStateSlice'
import { MemberInterface } from '@state/profile/profileStateSlice'
import { ProjectInterface } from '@state/project/projectStateSlice'
import { AreaRequestEnum } from '@state/requestArr/requestArrStateSlice'

export interface ExecutorTaskInterface extends TaskInterface {
  executor: MemberInterface
  area: AreaRequestEnum
  statusInternal: TaskStatusInternalEnum
  requestId: number
}

export interface ExecutorProjectInterface extends ProjectInterface {
  executor: MemberInterface
  area: AreaRequestEnum
  statusInternal: TaskStatusInternalEnum
  requestId: number
}

export interface ExecutorTaskArrStateInterface {
  isExecutorTaskArrLoading: boolean
  isExecutorTaskArrFirstLoading: boolean
  executorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
  executorTaskCount: number

  isTaskExecutorArrLoading: boolean
  isTaskExecutorArrFirstLoading: boolean
  taskExecutorArr: MemberInterface[]
  taskExecutorCount: number
  taskExecutorPage: number

  isStatusTaskArrLoading: boolean
  isStatusTaskArrFirstLoading: boolean
  todoExecutorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
  forDevelopExecutorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
  inProgressExecutorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
  testingExecutorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
  doneExecutorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
  statusTaskCount: number
}

const initialExecutorTaskArrState: ExecutorTaskArrStateInterface = {
  isExecutorTaskArrLoading: true,
  isExecutorTaskArrFirstLoading: false,
  executorTaskArr: [],
  executorTaskCount: 0,

  isTaskExecutorArrLoading: true,
  isTaskExecutorArrFirstLoading: false,
  taskExecutorArr: [],
  taskExecutorCount: 0,
  taskExecutorPage: 0,

  isStatusTaskArrLoading: true,
  isStatusTaskArrFirstLoading: false,
  todoExecutorTaskArr: [],
  forDevelopExecutorTaskArr: [],
  inProgressExecutorTaskArr: [],
  testingExecutorTaskArr: [],
  doneExecutorTaskArr: [],
  statusTaskCount: 0,
}

const executorTaskArrState = createSlice({
  name: 'executorTaskArrState',
  initialState: initialExecutorTaskArrState,
  reducers: {
    loadingExecutorTaskArrAction(state, {}: PayloadAction<{ minDate: Date; maxDate: Date }>) {
      state.isExecutorTaskArrLoading = true
    },
    loadingExecutorTaskArrFailure(state) {
      state.isExecutorTaskArrLoading = false
      state.isExecutorTaskArrFirstLoading = true
    },
    loadingExecutorTaskArrSuccess(
      state,
      {
        payload: { executorTaskArr, executorTaskCount },
      }: PayloadAction<{ executorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]; executorTaskCount: number }>,
    ) {
      state.executorTaskArr = executorTaskArr
      state.executorTaskCount = executorTaskCount
      state.isExecutorTaskArrLoading = false
      state.isExecutorTaskArrFirstLoading = true
    },
    loadingUsersExecutorTaskArrAction(state, {}: PayloadAction<{ page: number; limit: number }>) {
      state.isTaskExecutorArrLoading = true
    },
    loadingUsersExecutorTaskArrFailure(state) {
      state.isTaskExecutorArrLoading = false
      state.isTaskExecutorArrFirstLoading = true
    },
    loadingUsersExecutorTaskArrSuccess(
      state,
      {
        payload: { taskExecutorArr, taskExecutorCount, taskExecutorPage },
      }: PayloadAction<{ taskExecutorArr: MemberInterface[]; taskExecutorCount: number; taskExecutorPage: number }>,
    ) {
      state.taskExecutorArr = taskExecutorArr
      state.taskExecutorCount = taskExecutorCount
      state.taskExecutorPage = taskExecutorPage
      state.isTaskExecutorArrLoading = false
      state.isTaskExecutorArrFirstLoading = true
    },
    loadingStatusExecutorTaskArrAction(state) {
      state.isStatusTaskArrLoading = true
    },
    loadingStatusExecutorTaskArrFailure(state) {
      state.isStatusTaskArrLoading = false
      state.isStatusTaskArrFirstLoading = true
    },
    loadingStatusExecutorTaskArrSuccess(
      state,
      {
        payload: {
          todoExecutorTaskArr,
          forDevelopExecutorTaskArr,
          inProgressExecutorTaskArr,
          testingExecutorTaskArr,
          doneExecutorTaskArr,
          statusTaskCount,
        },
      }: PayloadAction<{
        todoExecutorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
        forDevelopExecutorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
        inProgressExecutorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
        testingExecutorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
        doneExecutorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
        statusTaskCount: number
      }>,
    ) {
      state.todoExecutorTaskArr = todoExecutorTaskArr
      state.forDevelopExecutorTaskArr = forDevelopExecutorTaskArr
      state.inProgressExecutorTaskArr = inProgressExecutorTaskArr
      state.testingExecutorTaskArr = testingExecutorTaskArr
      state.doneExecutorTaskArr = doneExecutorTaskArr
      state.statusTaskCount = statusTaskCount
      state.isStatusTaskArrLoading = false
      state.isStatusTaskArrFirstLoading = true
    },
    changeStatusExecutorTaskArrAction(state, {}: PayloadAction<{ requestId: number; statusInternal: TaskStatusInternalEnum | any }>) {
      state.isStatusTaskArrLoading = true
    },
    changeStatusExecutorTaskArrFailure(state) {
      state.isStatusTaskArrLoading = false
      state.isStatusTaskArrFirstLoading = true
    },
  },
})

export const {
  loadingExecutorTaskArrAction,
  loadingExecutorTaskArrFailure,
  loadingExecutorTaskArrSuccess,
  loadingUsersExecutorTaskArrAction,
  loadingUsersExecutorTaskArrFailure,
  loadingUsersExecutorTaskArrSuccess,
  loadingStatusExecutorTaskArrAction,
  loadingStatusExecutorTaskArrFailure,
  loadingStatusExecutorTaskArrSuccess,
  changeStatusExecutorTaskArrAction,
  changeStatusExecutorTaskArrFailure,
} = executorTaskArrState.actions

export default executorTaskArrState.reducer
