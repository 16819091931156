import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MemberInterface } from '@state/profile/profileStateSlice'

export enum DirectionTransactionEnum {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing',
}

export enum StatusTransactionEnum {
  Error = 'Error',
  Success = 'Success',
  Aborted = 'Aborted',
  Hold = 'Hold',
}

export enum MethodTransactionEnum {
  Crypto = 'Crypto',
  Card = 'Card',
  AppStore = 'AppStore',
  GooglePlay = 'GooglePlay',
  Internal = 'Internal',
}

export enum PaymentTransactionEnum {
  TopUp = 'Top Up',
  Donation = 'Donation',
  Work = 'Work',
  WorkBonus = 'WorkBonus',
  Platform = 'Platform',
  PlatformBonus = 'PlatformBonus',
}

export interface TransactionInterface {
  id: number
  memberId: number
  donationMemberId?: number
  donationMember?: MemberInterface
  payment: PaymentTransactionEnum
  direction: DirectionTransactionEnum
  status: StatusTransactionEnum
  method: MethodTransactionEnum
  amount: number // decimal
  createdAt: Date
}

export interface TransactionStateInterface {
  isTransactionLoading: boolean
  isTransactionFirstLoading: boolean
  transaction: TransactionInterface | null
}

const initialTransactionState: TransactionStateInterface = {
  isTransactionLoading: true,
  isTransactionFirstLoading: false,
  transaction: null,
}

const transactionState = createSlice({
  name: 'transactionState',
  initialState: initialTransactionState,
  reducers: {
    loadingTransactionAction(state) {
      state.isTransactionLoading = true
    },
    loadingTransactionFailure(state) {
      state.isTransactionLoading = false
      state.isTransactionFirstLoading = true
    },
    loadingTransactionSuccess(state, { payload: { transaction } }: PayloadAction<{ transaction: TransactionInterface }>) {
      state.transaction = transaction
      state.isTransactionLoading = false
      state.isTransactionFirstLoading = true
    },
  },
})

export const { loadingTransactionAction, loadingTransactionFailure, loadingTransactionSuccess } = transactionState.actions

export default transactionState.reducer
