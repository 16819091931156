import { Box, Typography } from '@mui/material'
import { Subtitle } from '../Text/Subtitle/Subtitle'

export const SectionAbout: React.FC = () => {
  return (
    <Box sx={{ mb: '100px' }}>
      <Typography component='div' sx={{ textAlign: 'center', mb: '60px' }}>
        <Subtitle subtitle='Skill Rade' />
        <Typography component='h2' variant='h2' sx={{}}>
          About us
        </Typography>
      </Typography>
      <Typography component='div' sx={{ display: 'flex', fontWeight: 400, lineHeight: '150%' }}>
        <Typography component='div' sx={{ padding: '0px 10px 20px' }}>
          <Typography variant='body2' sx={{ mb: '16px' }}>
            Welcome to SkillRade, the premier platform for collaborative software development and web services. At SkillRade, we believe in the power
            of community and the strength of shared expertise. Our mission is to bring together talented individuals from all over the world to
            create, innovate, and succeed together.
          </Typography>
          <Typography variant='body2'>
            Our vision is to create a world where anyone can build their dream project without financial barriers. We aim to provide a space where
            digital professionals can collaborate seamlessly, earning and spending SkillCoins™ to bring their ideas to life.
          </Typography>
        </Typography>
        <Typography component='div' sx={{ padding: '0px 15px 20px' }}>
          <Typography variant='body2' sx={{ mb: '16px' }}>
            Our mission is to empower every member of our community to leverage their skills and talents. By providing a platform where users can earn
            SkillCoins™ for their contributions and spend them on services they need, we foster a collaborative environment where everyone's skills
            are valued and utilized.
          </Typography>
          <Typography variant='body2'>
            Become a part of SkillRade today and experience the power of collaborative creation. Whether you're looking to contribute your skills or
            find the perfect team to bring your project to life, SkillRade is the place for you. Together, we can achieve greatness.
          </Typography>
        </Typography>
      </Typography>
    </Box>
  )
}
