import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { toast } from 'react-hot-toast'
import { Button } from '@mui/material'
import { Document, Page } from 'react-pdf'
import StartsUserBox from '@components/userComponents/StartsUserBox/StartsUserBox'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { downloadFileHandler } from '@utils/downloadFileHandler'
import { DocumentArrStateInterface, DocumentRequestInterface, loadingDocumentArrAction } from '@state/documentArr/documentArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { ReactComponent as DownloadIcon } from '@icons/downloadIcon.svg'
import { ReactComponent as CircleCloseIcon } from '@icons/circleCloseIcon.svg'
import styles from './DocumentIndividualTbody.module.scss'

const DocumentIndividualTbody: FC = () => {
  const { profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const { documentArr }: DocumentArrStateInterface = useSelector((state: AppStateInterface) => state.documentArrState)
  const dispatch = useDispatch()

  const deleteDocument = (documentId: number) => () => {
    apiConnectAxios(`/requests/client/${documentId}`, 'delete')
      .then(() => {
        dispatch(loadingDocumentArrAction())
      })
      .catch(error => {
        toast.error(errorResponseReturn(error))
      })
  }

  return (
    <tbody>
      {!!documentArr?.length &&
        documentArr?.map((document: DocumentRequestInterface) => (
          <tr key={document?.id}>
            <td className={styles.td}>
              <div className={styles.usersWrapper}>
                <StartsUserBox
                  memberId={profile?.memberId == document?.clientId ? document?.performerId : document?.clientId}
                  avatar={profile?.memberId == document?.clientId ? document?.performer?.avatar : document?.client?.avatar}
                  firstName={profile?.memberId == document?.clientId ? document?.performer?.firstName : document?.client?.firstName}
                  lastName={profile?.memberId == document?.clientId ? document?.performer?.lastName : document?.client?.lastName}
                  isBackground
                />
              </div>
            </td>
            <td className={styles.td}>
              <div className={styles.tdDiv}>{dayjs(document?.createdAt).format('YYYY-DD-MM')}</div>
            </td>
            <td className={styles.td}>
              <div className={styles.tdDiv}>{document?.type}</div>
            </td>
            <td className={styles.td}>
              <div className={styles.tdDiv}>{document?.area}</div>
            </td>
            <td className={styles.td}>
              <div className={styles.tdDiv}>
                {document?.stage === 'OnCreatorSign' && 'Signed author'}
                {document?.stage === 'OnExecutorSign' && 'Executor signed'}
                {document?.stage === 'BothSigned' && 'Both signed'}
              </div>
            </td>
            <td className={styles.td}>
              <div className={styles.documentWrapper}>
                <Button
                  className={styles.fileButton}
                  type='button'
                  onClick={downloadFileHandler(document?.lastFileSource || document?.firstFileSource, document?.name)}
                >
                  <Document file={document?.lastFileSource || document?.firstFileSource}>
                    <Page pageNumber={1} />
                  </Document>
                  <div className={styles.fileBg}>
                    <DownloadIcon className={styles.downloadIcon} />
                  </div>
                </Button>
                <div className={styles.nameWrapper}>
                  <div className={styles.name}>{`${document?.name}.pdf`}</div>
                  <div className={styles.documentSize}>{document?.size}</div>
                </div>
              </div>
            </td>
            <td className={styles.td}>
              {profile?.memberId == document?.clientId && false && (
                <div className={styles.buttonWrapper}>
                  <button type='button' onClick={deleteDocument(document?.id)}>
                    <CircleCloseIcon className={styles.closeIcon} />
                  </button>
                </div>
              )}
            </td>
          </tr>
        ))}
    </tbody>
  )
}

export default DocumentIndividualTbody
