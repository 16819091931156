import React, { FC, ReactNode, useId, useState } from 'react'
import Popper from '@mui/material/Popper'
import { ClickAwayListener, IconButton, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import styles from './HorizonDotsPopover.module.scss'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
export interface PopoverInterface {
  title: string
  iconComponent?: ReactNode
  clickFunction: () => void
}

interface Props {
  popoverArr: PopoverInterface[]
}
const HorizonDotsPopover: FC<Props> = ({ popoverArr }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const closeHandler = () => {
    setAnchorEl(null)
  }
  const openHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isOpen) setAnchorEl(null)
    else setAnchorEl(event.currentTarget)
  }
  const clickHandler = (clickFunction: () => void) => () => {
    setAnchorEl(null)
    clickFunction()
  }
  const isOpen = Boolean(anchorEl)
  const id: string = useId()
  const [arrowRef, setArrowRef] = useState(null)
  return (
    <>
      <ClickAwayListener onClickAway={closeHandler}>
        <div className={styles.buttonWrapper}>
          <IconButton aria-describedby={id} type='button' onClick={openHandler}>
            <MoreHorizIcon className={styles.dots} />
          </IconButton>
          <Popper
            id={id}
            open={isOpen}
            anchorEl={anchorEl}
            className={styles.popover}
            placement='bottom-end'
            modifiers={[{ name: 'arrow', enabled: true, options: { element: arrowRef } }]}
          >
            <Box component='span' ref={setArrowRef} className={styles.arrow} />
            <div className={styles.container}>
              {popoverArr.map(({ title, iconComponent, clickFunction }: PopoverInterface) => (
                <MenuItem className={styles.item} onClick={clickHandler(clickFunction)} key={title}>
                  {iconComponent && iconComponent}
                  <span className={styles.text}>{title}</span>
                </MenuItem>
              ))}
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  )
}

export default HorizonDotsPopover
