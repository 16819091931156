import { put, takeLatest, all, call } from 'redux-saga/effects'
import {
  loadingProfileAction,
  loadingProfileFailure,
  loadingProfileSuccess,
  updateProfileAction,
  updateProfileFailure,
  ProfileInterface,
} from '@state/profile/profileStateSlice'
import { apiConnectAxios } from '../../utils/apiConnectAxios'
import { PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-hot-toast'
import { ProfileFormValuesInterface } from '@pages/ProfileSettingsPage/ProfileSettingsForm/ProfileSettingsForm'
import { FormikErrors } from 'formik/dist/types'
import errorResponseReturn from '@utils/errorResponseReturn'

function* loadingProfileSaga(): Generator {
  try {
    const { data }: any = yield call(apiConnectAxios, '/members')
    yield put(loadingProfileSuccess({ profile: { ...data, memberId: data?.memberId } }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingProfileFailure(errorResponseReturn(error)))
  }
}

function* updateProfileSaga({
  payload: { profile, setErrors },
}: PayloadAction<{ profile: ProfileInterface; setErrors: (errors: FormikErrors<ProfileFormValuesInterface>) => void }>): Generator {
  try {
    yield call(apiConnectAxios, `/profiles/${profile?.id}`, 'patch', profile, true)
    const { data }: any = yield call(apiConnectAxios, '/members')
    yield put(loadingProfileSuccess({ profile: { ...data, memberId: data?.memberId } }))
    toast.success('User Updated')
  } catch (error: any) {
    setErrors(error?.response?.data?.errors || {})
    toast.error(errorResponseReturn(error))
    yield put(updateProfileFailure(errorResponseReturn(error)))
  }
}

export default function* profileRootSaga() {
  yield all([takeLatest(loadingProfileAction.type, loadingProfileSaga), takeLatest(updateProfileAction.type, updateProfileSaga)])
}
