import { all } from 'redux-saga/effects'
import profileRootSaga from '@state/profile/profileSaga'
import followerRootSaga from '@state/follower/followerSaga'
import userRootSaga from '@state/user/userSaga'
import newsRootSaga from '@state/news/newsSaga'
import newsArrRootSaga from '@state/newsArr/newsArrSaga'
import projectRootSaga from '@state/project/projectSaga'
import projectArrRootSaga from '@state/projectArr/projectArrSaga'
import documentTemplateRootSaga from '@state/documentTemplate/documentTemplateSaga'
import documentTemplateArrRootSaga from '@state/documentTemplateArr/documentTemplateArrSaga'
import documentArrRootSaga from '@state/documentArr/documentArrSaga'
import taskRootSaga from '@state/task/taskSaga'
import taskArrRootSaga from '@state/taskArr/taskArrSaga'
import executorTaskArrRootSaga from '@state/executorTaskArr/executorTaskArrSaga'
import requestArrRootSaga from '@state/requestArr/requestArrSaga'
import contractArrRootSaga from '@state/contractArr/contractArrSaga'
import transactionRootSaga from '@state/transaction/transactionSaga'
import transactionArrRootSaga from '@state/transactionArr/transactionArrSaga'

export default function* rootSaga() {
  yield all([
    profileRootSaga(),
    followerRootSaga(),
    userRootSaga(),
    newsRootSaga(),
    newsArrRootSaga(),
    projectRootSaga(),
    projectArrRootSaga(),
    documentTemplateRootSaga(),
    documentTemplateArrRootSaga(),
    documentArrRootSaga(),
    taskRootSaga(),
    taskArrRootSaga(),
    executorTaskArrRootSaga(),
    requestArrRootSaga(),
    contractArrRootSaga(),
    transactionRootSaga(),
    transactionArrRootSaga(),
  ])
}
