import { Link } from '@mui/material'

//* Basic IconLink theme settings *//

interface IconLinkProps {
  imageSrc?: string
  link?: string
  imagePosition?: string
}

export const IconLink: React.FC<IconLinkProps> = ({ imageSrc, link, imagePosition }) => {
  return (
    <Link
      href={link}
      sx={{
        marginRight: '5px',
        minWidth: '40px',
        minHeight: '40px',
        background: `url(${imageSrc}) no-repeat`,
        backgroundPosition: imagePosition,
        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      }}
    ></Link>
  )
}
