import React, { FC, memo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { downloadFileHandler } from '@utils/downloadFileHandler'
import { toast } from 'react-hot-toast'
import { Document, Page } from 'react-pdf'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { openHook } from '@hooks/openHook'
import errorResponseReturn from '@utils/errorResponseReturn'
import {
  loadingMessageRequestArrAction,
  loadingRequestArrAction,
  RequestArrStateInterface,
  StatusRequestEnum,
} from '@state/requestArr/requestArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { DocumentRequestInterface, TypeDocumentEnum } from '@state/documentArr/documentArrStateSlice'
import { ReactComponent as DownloadIcon } from '@icons/downloadIcon.svg'
import styles from '../AddDocumentRequestsClientForm/AddDocumentRequestsClientForm.module.scss'

interface Props {
  user: 'client' | 'performer'
  documentType: TypeDocumentEnum
  document: DocumentRequestInterface
  buttonTitle: string
}

const DocumentStatusRequestsModal: FC<Props> = ({ user, documentType, document, buttonTitle }: Props) => {
  const { requestChat }: RequestArrStateInterface = useSelector((state: AppStateInterface) => state.requestArrState)
  const dispatch = useDispatch()
  const { isOpen, setOpen, openHandler, closeHandler } = openHook()
  const { url } = useRouteMatch()
  const isProjectPage: boolean = url?.includes('project')
  let title
  if (user === 'client') {
    if (!document?.lastFileSource) {
      title = `Sended ${documentType}, Waiting for performer sign`
    } else if (document?.lastFileSource) {
      title = `Performer sent a signed ${documentType}`
    }
  } else {
    if (!document?.lastFileSource) {
      title = `This ${isProjectPage ? 'Project' : 'Task'} requires signing of ${documentType}`
    } else if (document?.lastFileSource) {
      title = `Sended ${documentType}, Waiting for client sign`
    }
  }

  const changeStatusRequestHandler = () => {
    apiConnectAxios(`/requests/client/status/${requestChat?.id}`, 'patch', { status: StatusRequestEnum.Canceled })
      .then(() => {
        dispatch(loadingRequestArrAction())
        dispatch(loadingMessageRequestArrAction())
        setOpen(false)
      })
      .catch(error => {
        toast.error(errorResponseReturn(error))
      })
  }

  const signDocumentHandler = () => {
    apiConnectAxios(`/request-documents/client/sign`, 'patch', { requestId: requestChat?.id, type: documentType })
      .then(() => {
        dispatch(loadingRequestArrAction())
        dispatch(loadingMessageRequestArrAction())
        setOpen(false)
      })
      .catch(error => {
        toast.error(errorResponseReturn(error))
      })
  }
  return (
    <>
      <Button className={styles.NDAButton} type='button' onClick={openHandler}>
        {buttonTitle}
      </Button>
      <Dialog className={styles.modalWrapper} open={isOpen} onClose={closeHandler} scroll='body'>
        <div className={styles.form}>
          <h4 className={styles.title}>{title}</h4>
          <div className={styles.fileWrapper}>
            <Button className={styles.fileButton} type='button' onClick={downloadFileHandler(document?.firstFileSource, document?.type)}>
              <Document file={document?.firstFileSource}>
                <Page pageNumber={1} />
              </Document>
              <div className={styles.fileBg}>
                <DownloadIcon className={styles.downloadIcon} />
              </div>
            </Button>
            {document?.lastFileSource && (
              <Button className={styles.fileButton} type='button' onClick={downloadFileHandler(document?.lastFileSource, document?.type)}>
                <Document file={document?.lastFileSource}>
                  <Page pageNumber={1} />
                </Document>
                <div className={styles.fileBg}>
                  <DownloadIcon className={styles.downloadIcon} />
                </div>
              </Button>
            )}
          </div>
          {document?.lastFileSource && user === 'client' && (
            <div className={styles?.buttonWrapper}>
              <Button className={styles.acceptButton} type='button' onClick={signDocumentHandler}>
                ACCEPT
              </Button>
              <Button className={styles.revokeButton} type='button' onClick={changeStatusRequestHandler}>
                REVOKE
              </Button>
            </div>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default memo(DocumentStatusRequestsModal)
