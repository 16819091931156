import { put, takeLatest, all, select, call } from 'redux-saga/effects'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { toast } from 'react-hot-toast'
import errorResponseReturn from '@utils/errorResponseReturn'
import { loadingTransactionAction, loadingTransactionFailure, loadingTransactionSuccess } from '@state/transaction/transactionStateSlice'
import { push } from 'connected-react-router'

function* loadingTransactionSaga(): Generator {
  try {
    const { transactionId }: any = yield select(state => state.router.location.query)
    if (transactionId) {
      const { data }: any = yield call(apiConnectAxios, `/transactions/${transactionId}`)
      yield put(loadingTransactionSuccess({ transaction: data }))
    } else {
      yield put(loadingTransactionFailure())
    }
  } catch (error: any) {
    yield put(push('/transactions'))
    toast.error(errorResponseReturn(error))
    yield put(loadingTransactionFailure(errorResponseReturn(error)))
  }
}

export default function* transactionRootSaga() {
  yield all([takeLatest(loadingTransactionAction.type, loadingTransactionSaga)])
}
