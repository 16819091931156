import { FC, Fragment, memo } from 'react'
import StarIcon from '@mui/icons-material/Star'
import StarHalfIcon from '@mui/icons-material/StarHalf'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import styles from './StartsUser.module.scss'
interface StartIconArrInterface {
  id: number
}
interface Props {
  rating: number
  reviews: number
  isBig?: boolean
}
const startIconArr: StartIconArrInterface[] = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]
const StartsUser: FC<Props> = ({ rating, reviews, isBig }: Props) => {
  return (
    <div className='align-items_end-g'>
      <div className={`${styles.starsWrapper} ${(isBig && styles.starsWrapper_big) || ''}`}>
        {startIconArr.map(({ id }: StartIconArrInterface) => (
          <Fragment key={id}>
            {rating >= id && <StarIcon sx={{ fontSize: 12 }} />}
            {rating < id && rating + 1 > id && <StarHalfIcon sx={{ fontSize: 12 }} />}
            {rating + 1 <= id && <StarBorderIcon sx={{ fontSize: 12 }} />}
          </Fragment>
        ))}
      </div>
      {!isBig && <div className={styles.reviews}>{reviews} reviews</div>}
    </div>
  )
}

export default memo(StartsUser)
