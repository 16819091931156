import React, { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryHook } from '@hooks/useQueryHook'
import { Button, Select, SelectChangeEvent } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { openHook } from '@hooks/openHook'
import AddIcon from '@mui/icons-material/Add'
import { TaskStatusInternalEnum } from '@state/task/taskStateSlice'
import { OptionArrInterface, OptionIconArrInterface } from '@interfaces/interfaces'
import styles from './TasksSortSelect.module.scss'

const optionArr: OptionArrInterface[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: TaskStatusInternalEnum.ToDo,
    value: TaskStatusInternalEnum.ToDo,
  },
  {
    label: TaskStatusInternalEnum.ForDevelop,
    value: TaskStatusInternalEnum.ForDevelop,
  },
  {
    label: TaskStatusInternalEnum.InProgress,
    value: TaskStatusInternalEnum.InProgress,
  },
  {
    label: TaskStatusInternalEnum.Testing,
    value: TaskStatusInternalEnum.Testing,
  },
  {
    label: TaskStatusInternalEnum.Done,
    value: TaskStatusInternalEnum.Done,
  },
]

const TasksSortSelect: FC = () => {
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()

  const query: URLSearchParams = useQueryHook()
  const { isOpen, openHandler, closeHandler } = openHook()
  const changeValueHandler = (event: SelectChangeEvent) => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('sort', event.target.value)
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  const history = useHistory<History>()
  const onClickHandler = (): void => {
    history.push('/create-task')
  }
  return (
    <>
      <div className={styles.title} onClick={openHandler}>
        Sort By:
      </div>
      <Select
        className={styles.select}
        open={isOpen}
        defaultValue={query.get('sort') || 'All'}
        value={query.get('sort') || 'All'}
        onChange={changeValueHandler}
        onClose={closeHandler}
        onOpen={openHandler}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{ MenuListProps: { style: { background: 'rgba(30, 14, 63)' } } }}
      >
        {optionArr?.map(({ label, value }: OptionIconArrInterface) => (
          <MenuItem className='flex-g' key={value} value={String(value)}>
            <div className={styles.itemLabel}>{label}</div>
          </MenuItem>
        ))}
      </Select>
      <Button className={styles.addButton} type='button' onClick={onClickHandler}>
        <AddIcon className={styles.addIcon} />
      </Button>
    </>
  )
}

export default memo(TasksSortSelect)
