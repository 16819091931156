import React, { FC, Fragment, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import RequestProjectTasksPopover from './RequestProjectTasksPopover/RequestProjectTasksPopover'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { loadingTaskArrAction, TaskArrStateInterface } from '@state/taskArr/taskArrStateSlice'
import { ProjectParamsInterface } from '@state/project/projectStateSlice'
import { TaskInterface } from '@state/task/taskStateSlice'
import styles from './RequestProjectTasksAside.module.scss'

const RequestProjectTasksAside: FC = () => {
  const { taskArr, taskCount, isTaskArrLoading, isTaskArrFirstLoading }: TaskArrStateInterface = useSelector(
    (state: AppStateInterface) => state.taskArrState,
  )
  const dispatch = useDispatch()
  const { projectId } = useParams<ProjectParamsInterface>()
  useEffect(() => {
    dispatch(loadingTaskArrAction({ page: 1, limit: 3 }))
  }, [projectId, dispatch])
  if (!isTaskArrFirstLoading || !taskArr?.length) return null
  return (
    <div className={styles.wrapper}>
      <LoadingBlockSpin isLoading={isTaskArrLoading} />
      <h3 className={styles.title}>join our Tasks</h3>
      {!!taskArr?.length &&
        taskArr?.map((task: TaskInterface, index: number) => (
          <Fragment key={task?.id}>
            {index < 3 && (
              <div className={styles.linkWrapper}>
                <div className={styles.linkBorder} />
                <Link className={styles.link} to={`/task-request/${task?.id}`}>
                  {task?.name}
                </Link>
              </div>
            )}
          </Fragment>
        ))}
      {taskCount > 3 && <RequestProjectTasksPopover />}
    </div>
  )
}

export default RequestProjectTasksAside
