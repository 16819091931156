import axios, { AxiosPromise } from 'axios'
import { apiDoMain } from './variables'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const apiConnectAxios = (
  url: string,
  method: 'get' | 'post' | 'patch' | 'put' | 'delete' = 'get',
  data?: { [key: string]: any },
  multipart?: boolean,
): AxiosPromise => {
  const headers = multipart
    ? { 'Content-Type': 'multipart/form-data', 'access-token': cookies.get('access-token') }
    : { 'access-token': cookies.get('access-token') }
  return axios({
    method: method,
    url: `${apiDoMain}${url}`,
    data,
    headers,
  })
}
