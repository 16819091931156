import { Button } from '@mui/material'
import { CSSProperties, FC, MouseEvent } from 'react'
import styles from './PinkButton.module.scss'

interface Props {
  title: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  sx?: CSSProperties
}

export const PinkButton: FC<Props> = ({ title, type = 'button', onClick, sx }: Props) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(e)
  }

  return (
    <Button className={styles.button} sx={sx} type={type} onClick={handleClick}>
      <span className='z-index-5-g'>{title}</span>
    </Button>
  )
}

export default PinkButton
