import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQueryHook } from '@hooks/useQueryHook'
import SearchIcon from '@mui/icons-material/Search'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SelectArr from '@components/selects/SelectArr/SelectArr'
import { IconButton } from '@mui/material'
import { OptionIconArrInterface } from '@interfaces/interfaces'
import { loadingContractArrAction } from '@state/contractArr/contractArrStateSlice'
import styles from './OffersTableThead.module.scss'

const statusOptionArr: OptionIconArrInterface[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Approve',
    value: 'Approve',
  },
  {
    label: 'Revoke',
    value: 'Revoke',
  },
]

const OffersTableThead: FC = () => {
  const [searchContract, setSearchContract] = useState<string>('')
  const dispatch = useDispatch()
  const query: URLSearchParams = useQueryHook()
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()
  useEffect(() => {
    dispatch(loadingContractArrAction())
  }, [query.get('searchOffer'), query.get('date'), query.get('status'), dispatch])
  const changeQueryHandler = (queryName: string) => (queryValue: string) => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set(queryName, queryValue)
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }

  const changeSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchContract(event?.target?.value)
  }

  const changeFilterHandler = () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('date', query.get('date') === 'up' ? 'down' : 'up')
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  const clickSearchUserHandler = () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('searchOffer', searchContract)
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }

  return (
    <thead>
      <tr>
        <th>
          <div className={styles.searchWrapper}>
            <input
              className={styles.search}
              name='search'
              type='search'
              value={searchContract}
              placeholder='Search name offer'
              onChange={changeSearchHandler}
            />
            <IconButton className={styles.searchButton} type='button' onClick={clickSearchUserHandler}>
              <SearchIcon className={styles.searchIcon} />
            </IconButton>
          </div>
        </th>
        <th>
          <div className={styles.thDiv}>
            <SelectArr label='Status' value={query.get('status') || 'All'} setValue={changeQueryHandler('status')} optionArr={statusOptionArr} />
          </div>
        </th>
        <th>
          <div className={styles.thDiv}>
            <button className={styles.thButton} onClick={changeFilterHandler} type='button'>
              Date
              <ArrowDownwardIcon className={`${styles.arrow} ${(query.get('date') === 'up' && styles.arrow_up) || ''}`} />
            </button>
          </div>
        </th>
        <th>
          <div className={styles.thDiv}>Document</div>
        </th>
        <th>
          <div className={styles.thDiv}>Budget</div>
        </th>
      </tr>
    </thead>
  )
}

export default memo(OffersTableThead)
