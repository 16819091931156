import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { MemberInterface } from '@state/profile/profileStateSlice'
import styles from './ProfileFollowersSideBar.module.scss'

interface Props {
  followerUrl: string
  followersArr: MemberInterface[] | []
  title: 'Followers' | 'Following'
  followerCount: number
}

const ProfileFollowersSideBar: FC<Props> = ({ title, followersArr, followerUrl, followerCount }: Props) => {
  const { pathname } = useLocation()
  return (
    <div className={styles.wrapper}>
      <div className='space-between-g'>
        {pathname.includes(title.toLowerCase()) ? (
          <h4 className={styles.title}>{title}</h4>
        ) : (
          <Link to={followerUrl}>
            <h4 className={styles.title}>{title}</h4>
          </Link>
        )}
        <span className={styles.quantity}>{`[ ${followerCount} ]`}</span>
      </div>
      <div className={styles.avatarWrapper}>
        {!!followersArr?.length &&
          followersArr?.map(({ memberId, avatar }: MemberInterface) => (
            <Link to={`/user/${memberId}`} key={memberId}>
              <img className={styles.avatar} src={avatar || ''} alt='' />
            </Link>
          ))}
      </div>
      {pathname.includes(title.toLowerCase()) ? (
        <Button className={`${styles.button} ${styles.button_disabled}`} endIcon={<ArrowForwardIcon />} disabled>
          SEE ALL
        </Button>
      ) : (
        <Link to={followerUrl}>
          <Button className={styles.button} endIcon={<ArrowForwardIcon />}>
            SEE ALL
          </Button>
        </Link>
      )}
    </div>
  )
}

export default ProfileFollowersSideBar
