import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { downloadFileHandler } from '@utils/downloadFileHandler'
import { toast } from 'react-hot-toast'
import dayjs from 'dayjs'
import { Document, Page } from 'react-pdf'
import { Button } from '@mui/material'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import BluePopover, { PopoverInterface } from '@components/popovers/BluePopover/BluePopover'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PagePagination from '@components/PagePagination/PagePagination'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import EmptyText from '@components/Text/EmptyText/EmptyText'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { useQueryHook } from '@hooks/useQueryHook'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { DocumentTemplateArrStateInterface, loadingDocumentTemplateArrAction } from '@state/documentTemplateArr/documentTemplateArrStateSlice'
import { AreaDocumentEnum } from '@state/documentArr/documentArrStateSlice'
import {
  changeModalDocumentTemplateSuccess,
  DocumentTemplateInterface,
  DocumentTemplateStateInterface,
  loadingProjectDocumentTemplateAction,
  loadingTaskDocumentTemplateAction,
} from '@state/documentTemplate/documentTemplateStateSlice'
import { ReactComponent as EditSvgIcon } from '@icons/editIcon.svg'
import { ReactComponent as DeleteSvgIcon } from '@icons/deleteIcon.svg'
import { ReactComponent as CheckMarkSvgIcon } from '@icons/checkMarkIcon.svg'
import { ReactComponent as DownloadSvgIcon } from '@icons/downloadIcon.svg'
import styles from './DocumentTemplatePage.module.scss'

const DocumentTemplatePage: FC = () => {
  const {
    isDocumentTemplateArrLoading,
    isDocumentTemplateArrFirstLoading,
    documentTemplateTaskArr,
    documentTemplateTaskCount,
    documentTemplateProjectArr,
    documentTemplateProjectCount,
  }: DocumentTemplateArrStateInterface = useSelector((state: AppStateInterface) => state.documentTemplateArrState)
  const {
    documentTemplateTask,
    isDocumentTemplateTaskLoading,
    documentTemplateProject,
    isDocumentTemplateProjectLoading,
  }: DocumentTemplateStateInterface = useSelector((state: AppStateInterface) => state.documentTemplateState)
  const dispatch = useDispatch()
  const query: URLSearchParams = useQueryHook()
  useEffect(() => {
    dispatch(loadingDocumentTemplateArrAction())
  }, [query.get('documentTaskPage'), query.get('documentProjectPage'), dispatch])

  const deleteDocumentTemplate = (id?: number) => {
    apiConnectAxios(`/document-templates/${id}`, 'delete')
      .then(() => {
        toast.success('Document Template deleted')
        dispatch(loadingDocumentTemplateArrAction())
      })
      .catch(error => {
        toast.error(errorResponseReturn(error))
      })
  }

  const editDocumentTemplate = (id?: number, documentTemplate?: DocumentTemplateInterface | any) => {
    if (id) dispatch(changeModalDocumentTemplateSuccess({ isShowDocumentTemplateModal: true, documentTemplate }))
  }

  const changeDefaultDocumentTemplate = (documentTemplateId?: number, documentTemplate?: DocumentTemplateInterface | any) => {
    let url
    if (documentTemplate?.area === AreaDocumentEnum.Project) {
      url = '/document-default-project'
    } else if (documentTemplate?.area === AreaDocumentEnum.Task) {
      url = '/document-default-task'
    }
    apiConnectAxios(`${url}/${documentTemplateId}`, 'patch')
      .then((data: any) => {
        if (documentTemplate?.area === AreaDocumentEnum.Project) {
          toast.success(`Document Template Project Default ${data?.data?.affected ? 'deleted' : 'selected'}`)
          dispatch(loadingProjectDocumentTemplateAction())
        } else if (documentTemplate?.area === AreaDocumentEnum.Task) {
          toast.success(`Document Template Task Default ${data?.data?.affected ? 'deleted' : 'selected'}`)
          dispatch(loadingTaskDocumentTemplateAction())
        }
      })
      .catch(error => {
        toast.error(errorResponseReturn(error))
      })
  }

  const popoverArr: PopoverInterface[] = [
    {
      title: 'Edit',
      iconComponent: <EditSvgIcon />,
      clickFunction: editDocumentTemplate,
    },
    {
      title: 'Set as default',
      title2: 'Delete as default',
      iconComponent: <CheckMarkSvgIcon />,
      clickFunction: changeDefaultDocumentTemplate,
    },
    {
      title: 'Delete',
      iconComponent: <DeleteSvgIcon />,
      clickFunction: deleteDocumentTemplate,
    },
  ]
  const buttonRender = (id: string, openHandler: (event: React.MouseEvent<HTMLButtonElement>) => void) => (
    <button className={styles.dotsButton} onClick={openHandler} aria-describedby={id} type='button'>
      <MoreVertIcon className={styles.dots} />
    </button>
  )
  return (
    <>
      <HelmetTitle title='Document Templates' />
      <div className={styles.addButtonWrapper}>
        <h2 className={styles.title}>Task NDA Drafts</h2>
      </div>
      <div className={styles.wrapper}>
        {!!documentTemplateTaskArr?.length &&
          documentTemplateTaskArr?.map((documentTemplate: DocumentTemplateInterface) => (
            <div className={styles.documentWrapper} key={documentTemplate?.id}>
              {documentTemplate?.id === documentTemplateTask?.documentTemplateId && <div className={styles.circle} />}
              <div className={styles.documentImage}>
                <Button className={styles.fileButton} type='button' onClick={downloadFileHandler(documentTemplate?.fileSource, 'NDA')}>
                  <Document file={documentTemplate?.fileSource}>
                    <Page pageNumber={1} />
                  </Document>
                  <div className={styles.fileBg}>
                    <DownloadSvgIcon className={styles.downloadIcon} />
                  </div>
                </Button>
              </div>
              <div className={styles.description}>
                <div className={styles.nameWrapper}>
                  <h4 className={styles.name}>{documentTemplate?.name?.slice(0, 14)}</h4>
                  <span className={styles.min}>
                    {`${documentTemplate?.createdAt ? `${dayjs(documentTemplate?.createdAt).format('MMM D, YYYY')}` : '\u00A0'}`}
                  </span>
                </div>
                <BluePopover
                  buttonRender={buttonRender}
                  popoverArr={popoverArr}
                  id={documentTemplate?.id}
                  argument={documentTemplate}
                  title2Index={documentTemplate?.id === documentTemplateTask?.documentTemplateId ? 1 : null}
                />
              </div>
            </div>
          ))}
        {isDocumentTemplateArrFirstLoading && !documentTemplateTaskCount && <EmptyText text='document' />}
        {documentTemplateTaskCount > 6 && (
          <div className='width_max-g'>
            <PagePagination pageCount={Math.ceil(documentTemplateTaskCount / 6)} pageName='documentTaskPage' />
          </div>
        )}
        <LoadingBlockSpin isLoading={isDocumentTemplateArrLoading || isDocumentTemplateTaskLoading} />
      </div>
      <h2 className={styles.title}>Project NDA Drafts</h2>
      <div className={styles.wrapper}>
        {!!documentTemplateProjectArr?.length &&
          documentTemplateProjectArr?.map((documentTemplate: DocumentTemplateInterface) => (
            <div className={styles.documentWrapper} key={documentTemplate?.id}>
              {documentTemplate?.id === documentTemplateProject?.documentTemplateId && <div className={styles.circle} />}
              <div className={styles.documentImage}>
                <Button className={styles.fileButton} type='button' onClick={downloadFileHandler(documentTemplate?.fileSource, 'NDA')}>
                  <Document file={documentTemplate?.fileSource}>
                    <Page pageNumber={1} />
                  </Document>
                  <div className={styles.fileBg}>
                    <DownloadSvgIcon className={styles.downloadIcon} />
                  </div>
                </Button>
              </div>
              <div className={styles.description}>
                <div className={styles.nameWrapper}>
                  <h4 className={styles.name}>{documentTemplate?.name?.slice(0, 14)}</h4>
                  <span className={styles.min}>
                    {`${documentTemplate?.createdAt ? `${dayjs(documentTemplate?.createdAt).format('MMM D, YYYY')}` : '\u00A0'}`}
                  </span>
                </div>
                <BluePopover
                  buttonRender={buttonRender}
                  popoverArr={popoverArr}
                  id={documentTemplate?.id}
                  argument={documentTemplate}
                  title2Index={documentTemplate?.id === documentTemplateProject?.documentTemplateId ? 1 : null}
                />
              </div>
            </div>
          ))}
        {isDocumentTemplateArrFirstLoading && !documentTemplateProjectCount && <EmptyText text='document' />}
        {documentTemplateProjectCount > 6 && (
          <div className='width_max-g'>
            <PagePagination pageCount={Math.ceil(documentTemplateProjectCount / 6)} pageName='documentProjectPage' />
          </div>
        )}
        <LoadingBlockSpin isLoading={isDocumentTemplateArrLoading || isDocumentTemplateProjectLoading} />
      </div>
    </>
  )
}

export default DocumentTemplatePage
