import { Container } from '@mui/system'
import React from 'react'
import { SectionAbout } from '../components/landing/section-about'
import { SectionHowTo } from '../components/landing/section-how-to'
import { SectionIntro } from '../components/landing/section-intro'
import { SectionMembership } from '../components/landing/section-membership'
import { SectionPromo } from '../components/landing/section-promo'
import HelmetTitle from '../components/titles/HelmetTitle/HelmetTitle'

export const Home: React.FC = () => {
  return (
    <Container disableGutters={true}>
      <HelmetTitle title='SkillRade' />
      <SectionIntro />
      <SectionPromo />
      <SectionHowTo />
      <SectionMembership />
      <SectionAbout />
    </Container>
  )
}
