import { put, takeLatest, all, call, select } from 'redux-saga/effects'
import { toast } from 'react-hot-toast'
import { createMatchSelector } from 'connected-react-router'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { loadingNewsAction, loadingNewsFailure, loadingNewsSuccess, NewsInterface } from '@state/news/newsStateSlice'
import { MatchPathInterface } from '@interfaces/interfaces'
import { NewsArrStateInterface } from '@state/newsArr/newsArrStateSlice'
import errorResponseReturn from '@utils/errorResponseReturn'

function* loadingNewsSaga(): Generator {
  try {
    const matchSelector = createMatchSelector({ path: ['/edit-project-news/:id'] })
    // @ts-ignore
    const match: MatchPathInterface = yield select(matchSelector)
    // @ts-ignore
    const { newsArr }: NewsArrStateInterface = yield select(state => state.newsArrState)
    // @ts-ignore
    let newsFound: NewsInterface = newsArr?.find((item: NewsInterface): boolean => item?.id == match?.params?.id)
    if (!newsFound) {
      const { data }: any = yield call(apiConnectAxios, `/project-news/${match?.params?.id}`)
      newsFound = data
    }
    yield put(loadingNewsSuccess({ news: newsFound }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingNewsFailure(errorResponseReturn(error)))
  }
}

export default function* newsRootSaga() {
  yield all([takeLatest(loadingNewsAction.type, loadingNewsSaga)])
}
