import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQueryHook } from '@hooks/useQueryHook'
import SearchIcon from '@mui/icons-material/Search'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SelectArr from '@components/selects/SelectArr/SelectArr'
import { IconButton } from '@mui/material'
import { OptionIconArrInterface } from '@interfaces/interfaces'
import { loadingDocumentArrAction } from '@state/documentArr/documentArrStateSlice'
import styles from './DocumentIndividualThead.module.scss'

const typeOptionArr: OptionIconArrInterface[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'NDA',
    value: 'NDA',
  },
  {
    label: 'Contract',
    value: 'Contract',
  },
]

const areaOptionArr: OptionIconArrInterface[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Project',
    value: 'Project',
  },
  {
    label: 'Task',
    value: 'Task',
  },
]

const stageOptionArr: OptionIconArrInterface[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Signed author',
    value: 'OnCreatorSign',
  },
  {
    label: 'Executor signed',
    value: 'OnExecutorSign',
  },
  {
    label: 'Both signed',
    value: 'BothSigned',
  },
]

const DocumentIndividualThead: FC = () => {
  const [searchUser, setSearchUser] = useState<string>('')
  const dispatch = useDispatch()
  const query: URLSearchParams = useQueryHook()
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()
  useEffect(() => {
    dispatch(loadingDocumentArrAction())
  }, [query.get('searchUser'), query.get('page'), query.get('date'), query.get('stage'), query.get('type'), query.get('area'), dispatch])
  const changeQueryHandler = (queryName: string) => (queryValue: string) => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set(queryName, queryValue)
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }

  const changeSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUser(event?.target?.value)
  }

  const changeFilterHandler = () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('date', query.get('date') === 'up' ? 'down' : 'up')
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  const clickSearchUserHandler = () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('searchUser', searchUser)
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  return (
    <thead>
      <tr>
        <th>
          <div className={styles.searchWrapper}>
            <input
              className={styles.search}
              name='search'
              type='search'
              value={searchUser}
              placeholder='Search name document'
              onChange={changeSearchHandler}
            />
            <IconButton className={styles.searchButton} type='button' onClick={clickSearchUserHandler}>
              <SearchIcon className={styles.searchIcon} />
            </IconButton>
          </div>
        </th>
        <th>
          <div className={styles.thDiv}>
            <button className={styles.thButton} onClick={changeFilterHandler} type='button'>
              Date
              <ArrowDownwardIcon className={`${styles.arrow} ${(query.get('date') === 'up' && styles.arrow_up) || ''}`} />
            </button>
          </div>
        </th>
        <th>
          <div className={styles.thDiv}>
            <SelectArr label='type' value={query.get('type') || 'All'} setValue={changeQueryHandler('type')} optionArr={typeOptionArr} />
          </div>
        </th>
        <th>
          <div className={styles.thDiv}>
            <SelectArr label='area' value={query.get('area') || 'All'} setValue={changeQueryHandler('area')} optionArr={areaOptionArr} />
          </div>
        </th>
        <th>
          <div className={styles.thDiv}>
            <SelectArr label='stage' value={query.get('stage') || 'All'} setValue={changeQueryHandler('stage')} optionArr={stageOptionArr} />
          </div>
        </th>
        <th>
          <div className={styles.thDiv}>Document</div>
        </th>
      </tr>
    </thead>
  )
}

export default memo(DocumentIndividualThead)
