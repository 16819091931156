import React, { FC } from 'react'
import { Field } from 'formik'
import FormField from '@fields/FormField/FormField'
import CheckboxField from '@fields/CheckboxField/CheckboxField'
import styles from './BudgetTaskField.module.scss'

const BudgetTaskField: FC = () => (
  <>
    <Field name='skillCoins' label='Budget' placeholder='Skillcoins' component={FormField} />
    <div className={styles.container}>
      <Field name='isStrict' label='Flexible Payment' component={CheckboxField} />
      <div className={styles.payWrapper}>
        <Field name='canPayByCoins' label='Can Pay by SkillCoins' placeholder='SkillCoins' isTransparentBackground component={FormField} />
        <Field name='canPayByTime' label='Can Pay By Own Time' placeholder='SkillCoins' isTransparentBackground component={FormField} />
      </div>
      <Field name='skillCoinsTotal' label='Total Coins' component={FormField} />
    </div>
  </>
)

export default BudgetTaskField
