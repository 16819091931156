import { FC, memo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Tab, Tabs } from '@mui/material'
import styles from './NavigationTabs.module.scss'

interface TabInterface {
  path: string
  label: string
}

const NavigationTabs: FC = () => {
  const { url } = useRouteMatch()
  const history = useHistory<History>()

  const tabs: TabInterface[] = [
    { path: '/requests', label: 'Requests' },
    { path: '/tasks', label: 'Tasks' },
    { path: '/projects', label: 'Projects' },
    { path: '/offers', label: 'Offers' },
    { path: '/settings', label: 'Settings' },
  ]

  const changeTabHandler = (path: string) => () => {
    history.push(path)
  }
  return (
    <Tabs className={styles.wrapper} value={tabs.find(({ path }: TabInterface): boolean => path === url)?.path || false} centered={true}>
      {tabs.map(({ path, label }: TabInterface) => (
        <Tab className={styles.item} value={path} key={path} onClick={changeTabHandler(path)} label={label} />
      ))}
    </Tabs>
  )
}

export default memo(NavigationTabs)
