import { useTheme } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import FaqPage from '../components/screens/FAQ/FaqPage'
import HelmetTitle from '../components/titles/HelmetTitle/HelmetTitle'
export const FAQ: React.FC = () => {
  const theme = useTheme()

  return (
    <>
      <HelmetTitle title='FAQ' />
      <Container
        disableGutters={true}
        sx={{
          display: 'flex',
          [theme.breakpoints.down(768)]: {
            display: 'block',
          },
        }}
      >
        <FaqPage />
      </Container>
    </>
  )
}
