import React, { FC, Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Document, Page } from 'react-pdf'
import RequestsSystemMessage from './RequestsSystemMessage/RequestsSystemMessage'
import { useQueryHook } from '@hooks/useQueryHook'
import CheckIcon from '@mui/icons-material/Check'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RequestsChatAvatar from './RequestsChatAvatar/RequestsChatAvatar'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {
  FileByMessageRequestTypeEnum,
  loadingMessageRequestArrAction,
  MessageRequestInterface,
  RequestArrStateInterface,
} from '@state/requestArr/requestArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import styles from './RequestsChat.module.scss'

const RequestsChat: FC = () => {
  const { isProfileLoading, isProfileFirstLoading, profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const { isMessageArrLoading, requestMessageArr, requestChat }: RequestArrStateInterface = useSelector(
    (state: AppStateInterface) => state.requestArrState,
  )
  const dispatch = useDispatch()
  const query: URLSearchParams = useQueryHook()
  useEffect(() => {
    if (!isProfileLoading && isProfileFirstLoading && profile && query.get('chatRequestId')) {
      dispatch(loadingMessageRequestArrAction())
    }
  }, [isProfileLoading, isProfileFirstLoading, profile, query.get('chatRequestId'), dispatch])
  return (
    <>
      <LoadingBlockSpin isLoading={isMessageArrLoading} />
      <div className={styles.wrapper}>
        <div className={styles.requestWrapper}>
          <hr className={styles.line} />
          <div className={styles.requestContainer}>
            <div className={styles.request}>
              {`${requestChat?.area}: ${(requestChat?.name && requestChat?.name?.slice(0, 34)) || ''}${(requestChat?.name && requestChat?.name?.length > 45 && '...') || ''}`}
              <button className={styles.dotsButton} type='button'>
                <MoreVertIcon className={styles.dotsIcon} />
              </button>
            </div>
            <div className={styles.downLine} />
            <div className={styles.request}>
              {`Coins: ${requestChat?.skillCoinsTotal || 0}/ Self Time: ${requestChat?.ownHours || 0} H`}
              {(requestChat?.status === 'Approved' || requestChat?.status === 'Canceled') && (
                <div className={styles.status}>
                  {requestChat?.status === 'Approved' && <CheckIcon className={styles.checkIcon} />}
                  {requestChat?.status === 'Canceled' && <PriorityHighIcon className={styles.priorityIcon} />}
                </div>
              )}
              {requestChat?.status === 'Created' && <AccessTimeIcon className={styles.timeIcon} />}
            </div>
          </div>
          <hr className={styles.line} />
        </div>
        <div className={styles.chat}>
          {requestMessageArr &&
            !!requestMessageArr.length &&
            requestMessageArr?.map((message: MessageRequestInterface) => (
              <Fragment key={message?.id}>
                {message?.file && !message?.systemStage && (
                  <div className={styles.documentWrapper}>
                    <div className={`${styles.document} ${message?.sendByMemberId === profile?.memberId && styles.documentMy}`}>
                      <a className={styles.fileLink} rel='noopener noreferrer' href={message?.file?.fileSource} target='_blank'>
                        {message?.file.fileType === FileByMessageRequestTypeEnum.PDF && (
                          <Document file={message?.file?.fileSource}>
                            <Page pageNumber={1} />
                          </Document>
                        )}
                        {(message?.file?.fileType === FileByMessageRequestTypeEnum.JPG ||
                          message?.file?.fileType === FileByMessageRequestTypeEnum.JPEG ||
                          message?.file?.fileType === FileByMessageRequestTypeEnum.PNG) && (
                          <img className={styles.img} src={message?.file?.fileSource} alt='' />
                        )}
                      </a>
                    </div>
                    <RequestsChatAvatar message={message} />
                  </div>
                )}
                <div className={styles.messageWrapper}>
                  <RequestsChatAvatar message={message} />
                  {message?.systemStage || message?.systemType || message?.systemStatus ? (
                    <RequestsSystemMessage message={message} />
                  ) : (
                    <div className={`${(message?.sendByMemberId === profile?.memberId && styles.myMessage) || styles.message}`}>{message?.text}</div>
                  )}
                </div>
              </Fragment>
            ))}
        </div>
      </div>
    </>
  )
}

export default RequestsChat
