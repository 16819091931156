import React, { FC, memo } from 'react'
import { Route, Switch } from 'react-router-dom'
import ProfileSideBar from './ProfileSideBar/ProfileSideBar'
import ProfileSettingsForm from './ProfileSettingsForm/ProfileSettingsForm'
import ProfileFollowers from './ProfileFollowers/ProfileFollowers'
import ProfileUser from './ProfileUser/ProfileUser'

const ProfileSettingsPage: FC = () => (
  <div className='align-items_start-g width_max-g'>
    <ProfileSideBar />
    <Switch>
      <Route exact path='/settings' component={ProfileSettingsForm} />
      <Route exact path='/user/:id' component={ProfileUser} />
      <Route exact path={['/following', '/following/:id', '/followers', '/followers/:id']} component={ProfileFollowers} />
    </Switch>
  </div>
)

export default memo(ProfileSettingsPage)
