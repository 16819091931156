import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import PagePagination from '@components/PagePagination/PagePagination'
import EmptyText from '@components/Text/EmptyText/EmptyText'
import LoadingTransparentSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import DocumentIndividualThead from './DocumentIndividualThead/DocumentIndividualThead'
import DocumentIndividualTbody from './DocumentIndividualTbody/DocumentIndividualTbody'
import { DocumentArrStateInterface } from '@state/documentArr/documentArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import styles from './DocumentIndividualPage.module.scss'

const DocumentIndividualPage: FC = () => {
  const { documentCount, isDocumentArrLoading, isDocumentArrFirstLoading }: DocumentArrStateInterface = useSelector(
    (state: AppStateInterface) => state.documentArrState,
  )
  return (
    <>
      <HelmetTitle title='Document Individual' />
      <div className={styles.wrapper}>
        <LoadingTransparentSpin isLoading={isDocumentArrLoading} />
        <table className={styles.table}>
          <DocumentIndividualThead />
          <DocumentIndividualTbody />
        </table>
        {isDocumentArrFirstLoading && !documentCount && <EmptyText text='document' />}
        {documentCount > 10 && <PagePagination pageCount={Math.ceil(documentCount / 10)} />}
      </div>
    </>
  )
}

export default DocumentIndividualPage
