import React, { FC } from 'react'
import { FieldInputProps, FormikProps } from 'formik'
import { TextareaAutosize } from '@mui/base'
import styles from './RequestsMessageField.module.scss'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  placeholder: string
  isDisabled?: boolean
}

const RequestsMessageField: FC<Props> = ({ field: { name, onChange, value, onBlur }, form: { touched, errors }, placeholder }: Props) => (
  <div className={styles.wrapper}>
    <TextareaAutosize
      className={styles.textarea}
      maxRows={11}
      onChange={onChange}
      name={name}
      value={value}
      onBlur={onBlur}
      placeholder={placeholder}
    />
    <div className={styles.error}>{`${(touched?.[name] && errors?.[name]) || errors?.image || `\u00A0`}`}</div>
  </div>
)
export default RequestsMessageField
