import React, { ChangeEvent, FC, MouseEvent, useEffect, useId, useState } from 'react'
import { useQueryHook } from '@hooks/useQueryHook'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { ClickAwayListener, IconButton, MenuItem } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import Popper from '@mui/material/Popper'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import SeeMoreSmallButton from '@buttons/SeeMoreSmallButton/SeeMoreSmallButton'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { loadingRequestArrAction, RequestInterface } from '@state/requestArr/requestArrStateSlice'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ReactComponent as CircleCloseIcon } from '@icons/circleCloseIcon.svg'
import styles from './SearchRequestsInput.module.scss'

const SearchRequestsInput: FC = () => {
  const [searchRequestValue, setRequestValue] = useState<string>('')
  const [requestArr, setRequestArr] = useState<RequestInterface[]>([])
  const [requestCount, setRequestCount] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(false)
  const { isProfileLoading, isProfileFirstLoading, profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const query: URLSearchParams = useQueryHook()
  const dispatch = useDispatch()
  useEffect(() => {
    if (!isProfileLoading && isProfileFirstLoading && profile) {
      dispatch(loadingRequestArrAction())
    }
  }, [isProfileLoading, isProfileFirstLoading, profile, query.get('requestName'), dispatch])

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement> | any) => {
    setRequestValue(event?.target?.value)
    setAnchorEl(event?.currentTarget)
    setIsLoading(true)
    apiConnectAxios(`/requests/search?name=${event?.target?.value}&page=1&limit=6`, 'get')
      .then((data: any): void => {
        setIsLoading(false)
        setIsFirstLoading(true)
        setRequestArr(data?.data?.requestArr)
        setRequestCount(data?.data?.requestCount)
        setPage(1)
      })
      .catch(error => {
        setIsLoading(false)
        toast.error(errorResponseReturn(error))
      })
  }

  const onClickLoadHandler = (): void => {
    setIsLoading(true)
    console.log(`name=${searchRequestValue}`)
    apiConnectAxios(`/requests/search?name=${searchRequestValue}&page=${page + 1}&limit=6`, 'get')
      .then((data: any) => {
        setIsLoading(false)
        setIsFirstLoading(true)
        console.log(222, data?.data)
        if (data?.data?.requestArr?.length) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          setRequestArr([...requestArr, ...data?.data?.requestArr])
        }
        setRequestCount(data?.data?.requestCount)
        setPage(page + 1)
      })
      .catch(error => {
        setIsLoading(false)
        toast.error(errorResponseReturn(error))
      })
  }

  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()
  const onClickItemHandler = (request: RequestInterface) => () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('chatRequestId', '')
    searchParams.set('requestName', request?.name)
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
    setAnchorEl(null)
  }

  const onClickCloseFilterHandler = () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('requestName', '')
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }

  const closeHandler = () => {
    setAnchorEl(null)
  }
  const isOpen = Boolean(anchorEl)
  const popperId: string = useId()

  return (
    <ClickAwayListener onClickAway={closeHandler}>
      <div className={styles.searchWrapper}>
        <input
          disabled={Boolean(query.get('requestName'))}
          aria-describedby={popperId}
          className={styles.searchInput}
          value={query.get('requestName') || searchRequestValue}
          name='search'
          type='search'
          placeholder='Search task or project Request'
          onChange={onChangeHandler}
          onClick={onChangeHandler}
        />
        {!query.get('requestName') && <SearchIcon className={styles.searchIcon} />}
        {query.get('requestName') && (
          <IconButton type='button' className={styles.closeButton} onClick={onClickCloseFilterHandler}>
            <CircleCloseIcon className={styles.closeIcon} />
          </IconButton>
        )}
        <Popper id={popperId} open={isOpen} anchorEl={anchorEl} className={styles.popper} placement='bottom' style={{ width: anchorEl?.clientWidth }}>
          {!!requestArr?.length &&
            requestArr?.map((request: RequestInterface) => (
              <MenuItem className={styles.item} onClick={onClickItemHandler(request)} key={request?.name}>
                <span className={styles.text}>{`${request?.area}: ${request?.name}`}</span>
              </MenuItem>
            ))}
          {isFirstLoading && !requestArr?.length && <span className={styles.text}>Not found request</span>}
          <LoadingBlockSpin isLoading={isLoading} />
          {requestCount > page * 6 && <SeeMoreSmallButton onClick={onClickLoadHandler} />}
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export default SearchRequestsInput
