import { put, takeLatest, all, call, select, fork } from 'redux-saga/effects'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { createMatchSelector } from 'connected-react-router'
import { toast } from 'react-hot-toast'
import {
  createProfileFollowerAction,
  createProfileFollowerFailure,
  createProfileFollowerSuccess,
  unsubscribeProfileFollowerAction,
  unsubscribeProfileFollowerFailure,
  loadingProfileFollowerAction,
  loadingProfileFollowerFailure,
  loadingProfileFollowerSuccess,
  loadingUserFollowerAction,
  loadingUserFollowerFailure,
  loadingUserFollowerSuccess,
} from '@state/follower/followerStateSlice'
import { PayloadAction } from '@reduxjs/toolkit'
import { MatchPathInterface } from '@interfaces/interfaces'
import errorResponseReturn from '@utils/errorResponseReturn'

function* loadingProfileFollowerSaga(): Generator {
  try {
    const { page }: any = yield select(state => state.router.location.query)
    const { data }: any = yield call(apiConnectAxios, `/followers?page=${page || 1}`)
    yield put(
      loadingProfileFollowerSuccess({
        profileFollowerArr: data?.followerMemberArr,
        profileFollowerCount: data?.followerCount,
        profileFollowingArr: data.followingMemberArr,
        profileFollowingCount: data?.followingCount,
        profileFollowingIdArr: data?.followingIdArr,
      }),
    )
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingProfileFollowerFailure(errorResponseReturn(error)))
  }
}

function* createProfileFollowerSaga({ payload: { memberId } }: PayloadAction<{ memberId: number }>): Generator {
  try {
    yield call(apiConnectAxios, '/followers', 'post', { followingMemberId: memberId })
    const matchSelector = createMatchSelector({ path: ['/user/:id', '/followers/:id', '/following/:id'] })
    // @ts-ignore
    const match: MatchPathInterface = yield select(matchSelector)
    toast.success('Follower Created')
    yield call(loadingProfileFollowerSaga)
    if (match?.params?.id) yield fork(loadingUserFollowerSaga)
    yield put(createProfileFollowerSuccess())
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(createProfileFollowerFailure(errorResponseReturn(error)))
  }
}

function* unsubscribeProfileFollowerSaga({ payload: { memberId } }: PayloadAction<{ memberId: number }>): Generator {
  try {
    yield call(apiConnectAxios, `/followers/${memberId}`, 'delete')
    yield fork(loadingProfileFollowerSaga)
    const matchSelector = createMatchSelector({ path: ['/user/:id', '/followers/:id', '/following/:id'] })
    // @ts-ignore
    const match: MatchPathInterface = yield select(matchSelector)
    if (match?.params?.id) yield fork(loadingUserFollowerSaga)
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(unsubscribeProfileFollowerFailure(errorResponseReturn(error)))
  }
}

function* loadingUserFollowerSaga(): Generator {
  try {
    const { page }: any = yield select(state => state.router.location.query)
    const matchSelector = createMatchSelector({ path: ['/user/:id', '/followers/:id', '/following/:id'] })
    // @ts-ignore
    const match: MatchPathInterface = yield select(matchSelector)
    const { data }: any = yield call(apiConnectAxios, `/followers/${match?.params?.id}?page=${page || 1}`)
    yield put(
      loadingUserFollowerSuccess({
        userFollowerArr: data?.followerMemberArr,
        userFollowerCount: data?.followerCount,
        userFollowingArr: data.followingMemberArr,
        userFollowingCount: data?.followingCount,
      }),
    )
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingUserFollowerFailure(errorResponseReturn(error)))
  }
}

export default function* followerRootSaga() {
  yield all([
    takeLatest(loadingProfileFollowerAction.type, loadingProfileFollowerSaga),
    takeLatest(createProfileFollowerAction.type, createProfileFollowerSaga),
    takeLatest(unsubscribeProfileFollowerAction.type, unsubscribeProfileFollowerSaga),
    takeLatest(loadingUserFollowerAction.type, loadingUserFollowerSaga),
  ])
}
