import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryHook } from '@hooks/useQueryHook'
import { Button } from '@mui/material'
import BluePopover, { PopoverInterface } from '@components/popovers/BluePopover/BluePopover'
import SearchUserRankButton from './SearchUserRequestsButton/SearchUserRequestsButton'
import AddDocumentRequestsClientForm from './AddDocumentRequestsClientForm/AddDocumentRequestsClientForm'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import SignDocumentRequestsPerformerForm from './SignDocumentRequestsPerformerForm/SignDocumentRequestsPerformerForm'
import DocumentStatusRequestsModal from './DocumentStatusRequestsModal/DocumentStatusRequestsModal'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import {
  loadingMessageRequestArrAction,
  loadingRequestArrAction,
  RequestArrStateInterface,
  StatusRequestEnum,
} from '@state/requestArr/requestArrStateSlice'
import { StageDocumentEnum, TypeDocumentEnum } from '@state/documentArr/documentArrStateSlice'
import styles from './SearchUserRequests.module.scss'
import SearchRequestsInput from '@pages/RequestsPage/SearchUserRequests/SearchRequestsInput/SearchRequestsInput'

const SearchUserRequests: FC = () => {
  const { profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const { isMessageArrFirstLoading, documentRequest, contractRequest, requestChat }: RequestArrStateInterface = useSelector(
    (state: AppStateInterface) => state.requestArrState,
  )
  const dispatch = useDispatch()
  const query: URLSearchParams = useQueryHook()
  const changeStatusRequestHandler = (status: StatusRequestEnum.Approved | StatusRequestEnum.Canceled) => () => {
    apiConnectAxios(`/requests/client/status/${requestChat?.id}`, 'patch', { status })
      .then(() => {
        dispatch(loadingRequestArrAction())
        dispatch(loadingMessageRequestArrAction())
      })
      .catch(() => {})
  }
  const popoverArr: PopoverInterface[] = []
  if (requestChat?.status !== StatusRequestEnum.Created && requestChat?.status !== StatusRequestEnum.Canceled) {
    popoverArr.push({
      title: 'Revoke',
      clickFunction: changeStatusRequestHandler(StatusRequestEnum.Canceled),
    })
  } else {
    popoverArr.push({
      title: 'Accept',
      clickFunction: changeStatusRequestHandler(StatusRequestEnum.Approved),
    })
  }
  const buttonRender = (id: string, openHandler: (event: React.MouseEvent<HTMLButtonElement>) => void) => (
    <button className={styles.dotsButton} onClick={openHandler} aria-describedby={id} type='button'>
      <MoreHorizIcon className={styles.dotsIcon} />
    </button>
  )

  return (
    <div className={styles.wrapper}>
      <SearchRequestsInput />
      {query.get('relationship') !== 'performer' && (
        <>
          <SearchUserRankButton isRankButton />
          <SearchUserRankButton />
        </>
      )}
      <div className={styles.buttonsWrapper}>
        {query.get('chatRequestId') && isMessageArrFirstLoading && requestChat?.clientId === profile?.memberId && (
          <>
            {requestChat?.status === StatusRequestEnum.Created && (
              <>
                <Button className={styles.acceptButton} type='button' onClick={changeStatusRequestHandler(StatusRequestEnum.Approved)}>
                  ACCEPT
                </Button>
                <Button className={styles.revokeButton} type='button' onClick={changeStatusRequestHandler(StatusRequestEnum.Canceled)}>
                  REVOKE
                </Button>
              </>
            )}
            {requestChat?.status === StatusRequestEnum.Approved && (
              <>
                {requestChat?.isRequiredNda && !documentRequest && (
                  <div className={styles.acceptWrapper}>
                    <AddDocumentRequestsClientForm documentType={TypeDocumentEnum.NDA} />
                  </div>
                )}
                {requestChat?.isRequiredContract &&
                  !contractRequest &&
                  ((requestChat?.isRequiredNda && documentRequest?.lastFileSource && documentRequest.stage === StageDocumentEnum.BothSigned) ||
                    !requestChat?.isRequiredNda) && (
                    <div className={styles.acceptWrapper}>
                      <AddDocumentRequestsClientForm documentType={TypeDocumentEnum.Contract} />
                    </div>
                  )}
                {requestChat?.isRequiredNda &&
                  ((documentRequest?.firstFileSource && !documentRequest?.lastFileSource) ||
                    (documentRequest?.lastFileSource && documentRequest.stage === StageDocumentEnum.OnExecutorSign)) && (
                    <div className={styles.acceptWrapper}>
                      <DocumentStatusRequestsModal
                        buttonTitle={`${documentRequest?.lastFileSource ? 'ACCEPT' : 'Current status'}`}
                        user='client'
                        documentType={TypeDocumentEnum.NDA}
                        document={documentRequest}
                      />
                    </div>
                  )}
                {requestChat?.isRequiredContract &&
                  ((contractRequest?.firstFileSource && !contractRequest?.lastFileSource) ||
                    (contractRequest?.lastFileSource && contractRequest.stage === StageDocumentEnum.OnExecutorSign)) && (
                    <div className={styles.acceptWrapper}>
                      <DocumentStatusRequestsModal
                        buttonTitle={`${contractRequest?.lastFileSource ? 'ACCEPT' : 'Current status'}`}
                        user='client'
                        documentType={TypeDocumentEnum.Contract}
                        document={contractRequest}
                      />
                    </div>
                  )}
              </>
            )}
          </>
        )}
        {query.get('chatRequestId') &&
          isMessageArrFirstLoading &&
          requestChat?.clientId === profile?.memberId &&
          requestChat?.status !== StatusRequestEnum.Created && <BluePopover buttonRender={buttonRender} popoverArr={popoverArr} />}
        {query.get('chatRequestId') &&
          isMessageArrFirstLoading &&
          requestChat?.performerId === profile?.memberId &&
          requestChat?.status === StatusRequestEnum.Approved && (
            <>
              {requestChat?.isRequiredNda && documentRequest?.firstFileSource && !documentRequest?.lastFileSource && (
                <div className={styles.acceptWrapper}>
                  <SignDocumentRequestsPerformerForm documentType={TypeDocumentEnum.NDA} document={documentRequest} />
                </div>
              )}
              {requestChat?.isRequiredContract && contractRequest?.firstFileSource && !contractRequest?.lastFileSource && (
                <div className={styles.acceptWrapper}>
                  <SignDocumentRequestsPerformerForm documentType={TypeDocumentEnum.Contract} document={contractRequest} />
                </div>
              )}
              {requestChat?.isRequiredNda &&
                documentRequest?.firstFileSource &&
                documentRequest?.lastFileSource &&
                documentRequest.stage === StageDocumentEnum.OnExecutorSign && (
                  <div className={styles.acceptWrapper}>
                    <DocumentStatusRequestsModal
                      user='performer'
                      buttonTitle={`Current status`}
                      documentType={TypeDocumentEnum.NDA}
                      document={documentRequest}
                    />
                  </div>
                )}
              {requestChat?.isRequiredContract &&
                contractRequest?.firstFileSource &&
                contractRequest?.lastFileSource &&
                contractRequest.stage === StageDocumentEnum.OnExecutorSign && (
                  <div className={styles.acceptWrapper}>
                    <DocumentStatusRequestsModal
                      user='performer'
                      buttonTitle={`Current status`}
                      documentType={TypeDocumentEnum.Contract}
                      document={contractRequest}
                    />
                  </div>
                )}
            </>
          )}
      </div>
    </div>
  )
}

export default SearchUserRequests
