import React, { FC, Fragment, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import RequestsSidebarUser from './RequestsSidebarUser/RequestsSidebarUser'
import { useQueryHook } from '@hooks/useQueryHook'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { RequestArrStateInterface, RequestInterface } from '@state/requestArr/requestArrStateSlice'
import styles from './RequestsSidebarClient.module.scss'

const RequestsSidebarClient: FC = () => {
  const { performerRequestArr }: RequestArrStateInterface = useSelector((state: AppStateInterface) => state.requestArrState)
  const query: URLSearchParams = useQueryHook()
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()

  const changeHandler = (paramsName: string) => () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set(paramsName, query.get(paramsName) === 'false' ? 'true' : 'false')
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  const newPerformerRequestArr: RequestInterface[] | undefined = performerRequestArr?.filter((request: RequestInterface): boolean => {
    if (query.get('Created') == 'false' && request?.status == 'Created') return false
    if (query.get('Approved') == 'false' && request?.status == 'Approved') return false
    if (query.get('Canceled') == 'false' && request?.status == 'Canceled') return false
    if (query.get('Denied') == 'false' && request?.status == 'Denied') return false
    return true
  })
  if (query.get('relationship') === 'performer') return null
  return (
    <>
      <Accordion expanded={query.get('WillWee') != 'false'} className={styles.accordion} aria-controls='panel1bh-content'>
        <AccordionSummary
          onClick={changeHandler('WillWee')}
          className={styles.summary}
          expandIcon={<KeyboardArrowDownIcon className={styles.icon} />}
        >
          <span className={styles.accordionTitle}>will see</span>
        </AccordionSummary>
        <AccordionDetails className={styles.detailsWrapper}>
          {!!newPerformerRequestArr?.length &&
            newPerformerRequestArr?.map((request: RequestInterface) => (
              <Fragment key={request?.id}>
                {request?.performerRank === 'WillWee' && <RequestsSidebarUser relationship='performer' request={request} />}
              </Fragment>
            ))}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={query.get('Best') != 'false'} className={styles.accordion} aria-controls='panel1bh-content'>
        <AccordionSummary onClick={changeHandler('Best')} className={styles.summary} expandIcon={<KeyboardArrowDownIcon className={styles.icon} />}>
          <span className={styles.accordionTitle}>Best</span>
        </AccordionSummary>
        <AccordionDetails className={styles.detailsWrapper}>
          {!!newPerformerRequestArr?.length &&
            newPerformerRequestArr?.map((request: RequestInterface) => (
              <Fragment key={request?.id}>
                {request?.performerRank === 'Best' && <RequestsSidebarUser relationship='performer' request={request} />}
              </Fragment>
            ))}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={query.get('NotFit') != 'false'} className={styles.accordion} aria-controls='panel1bh-content'>
        <AccordionSummary onClick={changeHandler('NotFit')} className={styles.summary} expandIcon={<KeyboardArrowDownIcon className={styles.icon} />}>
          <span className={styles.accordionTitle}>Not fit</span>
        </AccordionSummary>
        <AccordionDetails className={styles.detailsWrapper}>
          {!!newPerformerRequestArr?.length &&
            newPerformerRequestArr?.map((request: RequestInterface) => (
              <Fragment key={request?.id}>
                {request?.performerRank === 'NotFit' && <RequestsSidebarUser relationship='performer' request={request} />}
              </Fragment>
            ))}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default memo(RequestsSidebarClient)
