import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import ProjectsTableTbodyColumn from './ProjectsTableTbodyColumn/ProjectsTableTbodyColumn'
import { TaskStatusInternalEnum } from '@state/task/taskStateSlice'
import { ExecutorProjectInterface, ExecutorTaskArrStateInterface, ExecutorTaskInterface } from '@state/executorTaskArr/executorTaskArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import styles from './ProjectsTableTbody.module.scss'

interface ColumnArrInterface {
  status: TaskStatusInternalEnum
  executorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
}

const ProjectsTableTbody: FC = () => {
  const {
    todoExecutorTaskArr,
    forDevelopExecutorTaskArr,
    inProgressExecutorTaskArr,
    testingExecutorTaskArr,
    doneExecutorTaskArr,
  }: ExecutorTaskArrStateInterface = useSelector((state: AppStateInterface) => state.executorTaskArrState)
  const columnArr: ColumnArrInterface[] = [
    {
      status: TaskStatusInternalEnum.ToDo,
      executorTaskArr: todoExecutorTaskArr,
    },
    {
      status: TaskStatusInternalEnum.ForDevelop,
      executorTaskArr: forDevelopExecutorTaskArr,
    },
    {
      status: TaskStatusInternalEnum.InProgress,
      executorTaskArr: inProgressExecutorTaskArr,
    },
    {
      status: TaskStatusInternalEnum.Testing,
      executorTaskArr: testingExecutorTaskArr,
    },
    {
      status: TaskStatusInternalEnum.Done,
      executorTaskArr: doneExecutorTaskArr,
    },
  ]
  return (
    <div className={styles.wrapper}>
      {columnArr?.map(({ status, executorTaskArr }: ColumnArrInterface) => (
        <ProjectsTableTbodyColumn key={status} status={status} executorTaskArr={executorTaskArr} />
      ))}
    </div>
  )
}

export default memo(ProjectsTableTbody)
