import { IconLink } from '@components/links/IconLink'
import { Box, Container, IconButton, Typography, useTheme } from '@mui/material'
import { FC, memo } from 'react'
import FaceBookIcon from '../../assets/svg/facebook.svg'
import GoogleIcon from '../../assets/svg/google.svg'
import SocialNetworkIcon from '../../assets/svg/socialNetwork.svg'
import TwitterIcon from '../../assets/svg/twitter.svg'
import Vimeo from '../../assets/svg/vimeo.svg'
import { BottomSection, FooterBoxItem, FooterItemWrapper, FooterWrapper } from './styles'
const Footer: FC = () => {
  const theme = useTheme()

  return (
    <Box sx={{ background: '#311768' }}>
      <Container disableGutters={true}>
        <FooterWrapper>
          <FooterBoxItem
            sx={{
              [theme.breakpoints.down(1024)]: {
                width: '100%',
              },
              [theme.breakpoints.up(1024)]: {},
              width: '25%',
            }}
          >
            <IconButton disableRipple>
              <Typography component='img' src='../images/footer-logo.png' />
            </IconButton>
            <FooterItemWrapper sx={{ mt: '20px' }}>
              Quis ipsum suspendisse ultrices gravida. Risus commodo viverr a maecenas accumsan lacus vel facilisis.
            </FooterItemWrapper>
          </FooterBoxItem>
          <FooterBoxItem>
            <div style={{ position: 'relative' }}>
              <Typography component='img' src='../images/icons/location-dark.png' style={{ position: 'absolute', right: '140px' }} />
            </div>
            <Typography sx={{ color: '#fff', mb: '15px', fontSize: '20px' }}>Get in Touch</Typography>
            <FooterItemWrapper>
              <Typography component='div' sx={{ width: '30px' }}></Typography>
              201 Oak Street
              <br /> Building 27
              <br /> Manchester, UK
            </FooterItemWrapper>
          </FooterBoxItem>
          <FooterBoxItem>
            <Typography sx={{ color: '#fff', mb: '15px', fontSize: '20px' }}>Contact Us</Typography>
            <FooterItemWrapper component='div' sx={{ marginBottom: '15px' }}>
              <Typography component='div' sx={{ width: '30px' }}>
                <Typography component='img' src='../images/icons/phone.png' />
              </Typography>
              +1 [123] 456 7890
            </FooterItemWrapper>
            <FooterItemWrapper>
              <Typography component='div' sx={{ width: '30px' }}>
                <Typography component='img' src='../images/icons/email.png' />
              </Typography>
              team@itguild.org
            </FooterItemWrapper>
          </FooterBoxItem>
          <FooterBoxItem>
            <FooterItemWrapper component='div' sx={{ display: 'flex', justifyContent: 'flex-end', width: '216', height: '48', flexShrink: '0' }}>
              <IconLink imageSrc={FaceBookIcon} imagePosition='0 0' />
              <IconLink imageSrc={TwitterIcon} imagePosition='-40 0' />
              <IconLink imageSrc={GoogleIcon} imagePosition='-80 0' />
              <IconLink imageSrc={SocialNetworkIcon} imagePosition='-160 0' />
              <IconLink imageSrc={Vimeo} imagePosition='-120 0' />
            </FooterItemWrapper>
          </FooterBoxItem>
        </FooterWrapper>
        <BottomSection component='div'>
          <div>Copyrights © 2022 IT GUILD ORG. All rights reserved.</div>
        </BottomSection>
      </Container>
    </Box>
  )
}

export default memo(Footer)
