import CloseIcon from '@mui/icons-material/Close'
import { Box, Typography, useTheme } from '@mui/material'
import { WrapFlexBox } from '../../Boxes/WrapFlexBox'
import { PopupsBox, PopupsButton, PopupsClose, PopupsInside } from '../styles'
import { useAuth } from '../../../layouts/AuthContext'
interface IJoinUsProps {
  isJoinUsOpen: boolean
  closeJoinUs: () => void
  closePopups: () => void
}

export const JoinUs: React.FC<IJoinUsProps> = ({ isJoinUsOpen, closeJoinUs, closePopups }) => {
  const theme = useTheme()
  const { handleGoogleAuth, handleGitHubAuth, handleAppleAuth, handleMicrosoftAuth } = useAuth()

  return (
    <div onClick={closePopups}>
      <PopupsInside className='PopupsOutside' display={isJoinUsOpen ? 'flex' : 'none'}>
        <PopupsBox>
          <PopupsClose onClick={closeJoinUs}>
            <CloseIcon fontSize='large' sx={{ '&:hover': { fill: '#fec40c' } }} />
          </PopupsClose>
          <WrapFlexBox>
            <Box>
              <Typography fontSize='30px' fontWeight='600' pb={theme.spacing(3)}>
                Welcome to Skill Rade
              </Typography>
              <Typography fontSize='24px' fontWeight='600' pb={theme.spacing(2)}>
                Join us with
              </Typography>
            </Box>
            <PopupsButton onClick={() => handleGoogleAuth()}>
              <Box width={'20%'} height={'100%'} component={'img'} src='images/svg-icons/google-logo.svg' />
              <Box width={'60%'}>google</Box>
            </PopupsButton>
            <PopupsButton onClick={() => handleGitHubAuth()}>
              <Box width={'20%'} height={'100%'} component={'img'} src='images/svg-icons/github-logo.svg' />
              <Box width={'60%'}>github</Box>
            </PopupsButton>
            <PopupsButton onClick={() => handleMicrosoftAuth()}>
              <Box width={'20%'} height={'100%'} component={'img'} src='images/svg-icons/microsoft-logo.svg' />
              <Box width={'60%'}>microsoft</Box>
            </PopupsButton>
            <PopupsButton onClick={() => handleAppleAuth()}>
              <Box width={'20%'} height={'100%'} component={'img'} src='images/svg-icons/apple-logo.svg' />
              <Box width={'60%'}>apple</Box>
            </PopupsButton>
          </WrapFlexBox>
        </PopupsBox>
      </PopupsInside>
    </div>
  )
}
