import { FC, memo, useEffect } from 'react'
import { useQueryHook } from '@hooks/useQueryHook'
import { useDispatch } from 'react-redux'
import { loadingTransactionArrAction } from '@state/transactionArr/transactionArrStateSlice'

interface Props {
  selectedMinDate: Date
  selectedMaxDate: Date
}
const TransactionsCalendarLogic: FC<Props> = ({ selectedMinDate, selectedMaxDate }: Props) => {
  const query: URLSearchParams = useQueryHook()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadingTransactionArrAction({ minDate: selectedMinDate, maxDate: selectedMaxDate }))
  }, [query.get('page'), query.get('date'), query.get('direction'), selectedMinDate, selectedMaxDate, dispatch])

  return null
}

export default memo(TransactionsCalendarLogic)
