import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import TasksUserPopoverBox from './TasksUserPopoverBox/TasksUserPopoverBox'
import TransactionsCalendar from '@pages/TransactionsPage/TransactionsCalendar/TransactionsCalendar'
import TasksSortSelect from './TasksSortSelect/TasksSortSelect'
import TasksTableThead from './TasksTableThead/TasksTableThead'
import TasksTableTbody from './TasksTableTbody/TasksTableTbody'
import EmptyText from '@components/Text/EmptyText/EmptyText'
import PagePagination from '@components/PagePagination/PagePagination'
import LoadingTransparentSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import { ExecutorTaskArrStateInterface } from '@state/executorTaskArr/executorTaskArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import styles from './TasksPage.module.scss'

const TasksPage: FC = () => {
  const { isExecutorTaskArrLoading, isExecutorTaskArrFirstLoading, executorTaskCount }: ExecutorTaskArrStateInterface = useSelector(
    (state: AppStateInterface) => state.executorTaskArrState,
  )
  return (
    <>
      <HelmetTitle title='Tasks' />
      <div className='page-container-g'>
        <div className={styles.wrapper}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>Tasks</h1>
            <TasksUserPopoverBox />
          </div>
          <div className={styles.calendarWrapper}>
            <TransactionsCalendar isTask />
            <TasksSortSelect />
          </div>
          <div className={styles.tableWrapper}>
            <LoadingTransparentSpin isLoading={isExecutorTaskArrLoading} />
            <table className='width_max-g'>
              <TasksTableThead />
              <TasksTableTbody />
            </table>
            {isExecutorTaskArrFirstLoading && !executorTaskCount && <EmptyText text='Task' />}
            {executorTaskCount > 10 && <PagePagination pageCount={Math.ceil(executorTaskCount / 10)} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default TasksPage
