export interface MatchPathInterface {
  params: {
    id: string
  }
}

export interface IdParamsInterface {
  id: string
}

export interface OptionArrInterface {
  label: string
  value: string
}
export interface OptionIconArrInterface {
  label: string
  value: string | number | null
  icon?: string | null
}

export interface TagInterface {
  id: number
  taskId: number
  name: string
  priority?: number
}

export enum FileTypeEnum {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
  PDF = 'application/pdf',
  TXT = 'text/plain',
  DOC = 'application/msword',
}
