import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Button, Tooltip } from '@mui/material'
import { FieldInputProps, FormikProps, useField } from 'formik'
import { toast } from 'react-hot-toast'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import styles from './LoadPhotoField.module.scss'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  buttonTitle: string
  title: string
  isIcon?: boolean
}

const LoadPhotoField: FC<Props> = ({
  field: { name, value },
  form: { touched, errors },
  title = 'Add photo',
  buttonTitle = 'Choose photo',
  isIcon,
}: Props) => {
  const [, , { setValue, setError, setTouched }] = useField(name)
  const maxSize = 10000000 // 10mb
  const supportedFormats: string[] = isIcon ? ['image/svg+xml'] : ['image/jpg', 'image/jpeg', 'image/png']
  const [file, setFile] = useState<null | string>(null)
  useEffect(() => {
    if (!value) {
      setFile(null)
      return
    } else if (typeof value === 'object') {
      const reader: FileReader = new FileReader()
      reader.readAsDataURL(value)
      reader.onloadend = async () => {
        // @ts-ignore
        setFile(reader.result)
      }
      reader.onerror = error => toast.error(error?.toString())
    } else {
      setFile(value)
    }
  }, [value])
  const loadingFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    const reader: FileReader = new FileReader()
    const [file]: any = e.target.files
    if (file) {
      reader.readAsDataURL(file)
      reader.onloadend = async () => {
        if (!supportedFormats.includes(file?.type)) {
          await setTouched(true)
          setError('Unsupported Format')
          return toast.error('Unsupported Format')
        } else if (file.size > maxSize) {
          await setTouched(true)
          setError('Max file size is 10mb')
          return toast.error('Max file size is 10mb')
        } else {
          await setTouched(true)
          setError('')
          setValue(file)
        }
      }
      reader.onerror = error => toast.error(error?.toString())
    }
  }
  const clickDeleteValueHandler = () => {
    setValue('')
    setError('')
  }
  return (
    <div className='column-align_start-g'>
      <h3 className={`${styles.title} label-title-g`}>{isIcon ? 'Add icon' : title}</h3>
      <div className={styles.buttonWrapper}>
        <Button className={styles.button} endIcon={<ArrowForwardIcon style={{ marginLeft: '10px', fontSize: 32, zIndex: 2 }} />} component='label'>
          <span className='z-index-5-g'>{isIcon ? 'Choice icon' : buttonTitle}</span>
          <input name={name} type='file' accept={supportedFormats.join()} onChange={loadingFileHandler} />
        </Button>
        {!file && (
          <div className={styles.descriptionWrapper}>
            <div>{`Load ${isIcon ? 'icon' : 'photo'}`}</div>
            <span>
              Max file size is 10Mb.
              {!isIcon && '\n500х500 pixels. jpg, jpeg, png'}
            </span>
          </div>
        )}
        {file && (
          <>
            <img className={styles.img} src={file} alt='' />
            <Tooltip title={`Delete ${isIcon ? 'icon' : 'photo'}`} placement='bottom'>
              <button onClick={clickDeleteValueHandler} type='button'>
                <CloseIcon className={styles.icon} />
              </button>
            </Tooltip>
          </>
        )}
      </div>
      <span className={styles.error}>{`${(touched?.[name] && errors?.[name]) || '\u00A0'}`}</span>
    </div>
  )
}

export default LoadPhotoField
