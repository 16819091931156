import React, { FC, memo } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Button } from '@mui/material'
import AvatarUser from '@components/userComponents/StartsUserBox/AvatarUser/AvatarUser'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProjectStateInterface } from '@state/project/projectStateSlice'
import { TaskStateInterface } from '@state/task/taskStateSlice'
import styles from './RequestMemberProject.module.scss'

interface Props {
  chatRequestId: null | number
}

const RequestMemberProject: FC<Props> = ({ chatRequestId }: Props) => {
  const { project }: ProjectStateInterface = useSelector((state: AppStateInterface) => state.projectState)
  const { task }: TaskStateInterface = useSelector((state: AppStateInterface) => state.taskState)
  const { url } = useRouteMatch()
  const isProjectPage: boolean = url?.includes('project')
  const pageContent = isProjectPage ? project : task
  return (
    <div className={styles.wrapper}>
      <hr className={styles.line} />
      <div className={styles.container}>
        <AvatarUser memberId={pageContent?.memberId} avatar={pageContent?.member?.avatar} sizePx={88} />
        <div className={styles.firstNameWrapper}>
          <div className={styles.firstName}>{`${pageContent?.member?.firstName || '\u00A0'} ${pageContent?.member?.lastName || '\u00A0'}`}</div>
          <div
            className={styles.register}
          >{`${(pageContent?.member?.createdAt && `Register: ${dayjs(pageContent?.member?.createdAt).format('MMM D, YYYY')}`) || '\u00A0'}`}</div>
          <div className={styles.description}>{`Hello! Thank you for viewing my ${isProjectPage ? 'project' : 'task'}. Please apply to join!`}</div>
        </div>
        {chatRequestId && (
          <Link className={styles.link} to={`/requests/?relationship=performer&chatRequestId=${chatRequestId}`}>
            <Button className={styles.button} type='button'>
              Chat
            </Button>
          </Link>
        )}
      </div>
      <hr className={styles.line} />
    </div>
  )
}

export default memo(RequestMemberProject)
