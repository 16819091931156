import { useState } from 'react'

export const openHook = (): any => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const closeHandler = () => {
    setOpen(false)
  }
  const openHandler = () => {
    setOpen(true)
  }

  return {
    isOpen,
    setOpen,
    openHandler,
    closeHandler,
  }
}
