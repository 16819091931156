import React, { FC, memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import dayjs from 'dayjs'
import { toast } from 'react-hot-toast'
import LoadingTransparentSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { TagInterface } from '@interfaces/interfaces'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { FileByTaskInterface, TaskParamsInterface, TaskStatusInternalEnum } from '@state/task/taskStateSlice'

const CreateTaskPageLogic: FC = () => {
  const { setValues, setErrors, setTouched } = useFormikContext()
  const { taskId } = useParams<TaskParamsInterface>()
  const history = useHistory<History>()
  const { isProfileFirstLoading, isProfileLoading, profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  useEffect(() => {
    if (history?.location?.pathname === '/create-task') {
      setValues({
        projectId: '',
        name: '',
        description: '',
        taskStatusInternal: TaskStatusInternalEnum.ToDo,
        label: 'Label',
        skillCoins: 0,
        isStrict: false,
        canPayByCoins: 0,
        canPayByTime: 0,
        skillCoinsTotal: 0,
        deadline: dayjs(new Date()).format('YYYY-MM-DD') || '',
        tags: '',
        isAutomaticSendingNda: false,
        isRequiredContract: false,
        files: [],
      })
      setTouched({})
      setErrors({})
    } else if (taskId && isProfileFirstLoading) {
      apiConnectAxios(`/tasks/${taskId}`, 'get')
        .then(({ data }) => {
          if (!data) {
            toast.error('This Task does not exist with this Id')
            history.push('/create-task')
            return
          } else if (profile?.memberId && data?.memberId == profile?.memberId) {
            setValues({
              projectId: data?.projectId || '',
              name: data?.name || '',
              description: data?.description || '',
              taskStatusInternal: data?.taskStatusInternal || '',
              label: data?.label || '',
              skillCoins: data?.skillCoins || '',
              isStrict: data?.isStrict || '',
              canPayByCoins: data?.canPayByCoins || '',
              canPayByTime: data?.canPayByTime || '',
              total: data?.total || '',
              deadline: dayjs(data?.deadline).format('YYYY-DD-MM') || '',
              tags: data?.tags?.map((tag: TagInterface) => tag?.name)?.join(', ') || '',
              isAutomaticSendingNda: data?.isAutomaticSendingNda || false,
              isRequiredContract: data?.isRequiredContract || false,
              files:
                data?.files?.map((file: FileByTaskInterface) => ({
                  name: file?.name,
                  fileType: file?.fileType,
                  fileSource: file?.fileSource,
                  createdBy: file?.createdBy,
                })) || [],
            })
          } else {
            toast.error('No access')
            history.push('/create-task')
          }
        })
        .catch(error => {
          toast.error(errorResponseReturn(error))
          history.push('/create-task')
        })
    }
  }, [taskId, isProfileFirstLoading, profile?.memberId, history?.location?.pathname])
  if (!taskId) return null
  return <LoadingTransparentSpin isLoading={isProfileLoading} />
}

export default memo(CreateTaskPageLogic)
