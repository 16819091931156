import React, { FC, memo, useState } from 'react'
import { ReactComponent as CopySvgIcon } from '@icons/copyIcon.svg'
import styles from './TransactionsDetailsAsideHash.module.scss'

interface Props {
  hash: string
}

const TransactionsDetailsAsideHash: FC<Props> = ({ hash }: Props) => {
  const [isCopiedHash, setIsCopiedHash] = useState(false)
  const onClickCopyHashHandler = () => {
    navigator.clipboard.writeText(hash)
    setIsCopiedHash(true)
    setTimeout(() => {
      setIsCopiedHash(false)
    }, 2000)
  }

  return (
    <div className={styles.wrapper} onClick={onClickCopyHashHandler}>
      {hash}
      {isCopiedHash ? <div className={styles.copied}>Copied</div> : <CopySvgIcon className={styles.copyIcon} />}
    </div>
  )
}

export default memo(TransactionsDetailsAsideHash)
