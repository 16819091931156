import dayjs from 'dayjs'

export const requiredValidationVar = 'Is a required field'
export const minLengthValidationVar = {
  min: 3,
  message: 'Must be at least 3 characters',
}
export const minTextareaLengthValidationVar = {
  min: 10,
  message: 'Must be at least 10 characters',
}

export const URLValidationVar = 'Must be a valid URL'

export const minNumberValueValidationVar = {
  min: 1,
  message: 'The value must be greater than 0',
}

export const minDateValueValidationVar = {
  min: new Date(),
  message: `Minimum date ${dayjs(new Date()).format('DD/MM/YYYY')}`,
}

export const maxDateValueValidationVar = {
  min: dayjs('2030-01-1').toDate(),
  message: `Maximum date ${dayjs('2030.1.1').format('DD/MM/YYYY')}`,
}
