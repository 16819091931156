import React, { FC, useEffect, useState } from 'react'
import { useField } from 'formik'
import { Tooltip } from '@mui/material'
import { toast } from 'react-hot-toast'
import { pdfjs } from 'react-pdf'
import { Document, Page } from 'react-pdf'
import CloseIcon from '@mui/icons-material/Close'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import styles from './RequestsChatSendFile.module.scss'

const RequestsChatSendFile: FC = () => {
  const [{ value }, , { setValue, setError }] = useField('file')
  const supportedImgFormats: string[] = ['image/jpg', 'image/jpeg', 'image/png']
  const [IMGFile, setIMGFile] = useState<null | string>(null)
  useEffect(() => {
    if (!value) {
      setIMGFile(null)
      return
    } else if (supportedImgFormats.includes(value?.type)) {
      const reader: FileReader = new FileReader()
      // @ts-ignore
      reader.readAsDataURL(value)
      reader.onloadend = async () => {
        // @ts-ignore
        setIMGFile(reader.result)
      }
      reader.onerror = error => toast.error(error?.toString())
    }
  }, [value])
  const clickDeleteValueHandler = () => {
    setValue('')
    setError('')
    setIMGFile(null)
  }
  return (
    <>
      {IMGFile && (
        <div className={styles.documentWrapper}>
          <img className={styles.img} src={IMGFile} alt='' />
          <Tooltip title='Delete file' placement='bottom'>
            <button className={styles.closeButton} onClick={clickDeleteValueHandler} type='button'>
              <CloseIcon className={styles.closeIcon} />
            </button>
          </Tooltip>
        </div>
      )}
      {value.type && value.type === 'application/pdf' && (
        <div className={styles.documentWrapper}>
          <div className={styles.document}>
            <Document file={value}>
              <Page pageNumber={1} />
            </Document>
          </div>
          <Tooltip title='Delete file' placement='bottom'>
            <button className={styles.closeButton} onClick={clickDeleteValueHandler} type='button'>
              <CloseIcon className={styles.closeIcon} />
            </button>
          </Tooltip>
        </div>
      )}
    </>
  )
}
export default RequestsChatSendFile
