import { FC, memo } from 'react'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
}

const HelmetTitle: FC<Props> = ({ title }: Props) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
)

export default memo(HelmetTitle)
