import { Box, Container, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Accordion } from 'react-accessible-accordion'
import { faqs, nft } from './data'
import { StyledAccordionItem, StyledAccordionItemButton, StyledAccordionItemPanel, Ul } from './styles'

const FaqPage = () => {
  const [activeTab, setActiveTab] = useState('FAQ')
  const [accordionItems, setAccordionItems] = useState(faqs)

  const renderAccordionItems = () => {
    return accordionItems.map((item, index) => (
      <StyledAccordionItem key={index} title={item.question}>
        <StyledAccordionItemButton>
          <Typography sx={{ fontWeight: 700, fontSize: 'calc(20px + 16 * ((100vw - 320px) / 1800))' }}>{item.question}</Typography>
        </StyledAccordionItemButton>
        <StyledAccordionItemPanel>
          <Typography sx={{ fontSize: 'calc(16px + 16 * ((100vw - 320px) / 1800))' }}>{item.answer}</Typography>
        </StyledAccordionItemPanel>
      </StyledAccordionItem>
    ))
  }

  const handleTabClick = (tab: React.SetStateAction<string>) => {
    setActiveTab(tab)
    if (tab === 'FAQ') {
      setAccordionItems(faqs)
    } else {
      setAccordionItems(nft)
    }
  }

  return (
    <Container disableGutters={true}>
      <Box sx={{ textAlign: 'left', margin: '60px 10px 40px' }}>
        <Typography sx={{ fontWeight: 500, color: '#ffc50c', fontSize: 'calc(24px + 16 * ((100vw - 320px) / 890))' }}>
          FREQUENTLY ASKED QUESTIONS
        </Typography>
      </Box>

      <Typography variant='h4' m='0 10px 20px'>
        Below are some common frequently asked questionshendrerit justo quisque quis.
      </Typography>
      <Typography variant='h4' m='0 10px 50px'>
        Rhoncus exeget semper semlamat lobortis velit estibulum ante.
      </Typography>

      <Box sx={{ textAlign: 'left', margin: '60px 10px 40px' }}>
        <Ul>
          <li className={activeTab === 'FAQ' ? 'active' : ''} onClick={() => handleTabClick('FAQ')}>
            <Typography
              sx={{ fontWeight: `${activeTab === 'FAQ' ? 700 : 300}`, color: '#ffc50c', fontSize: 'calc(16px + 16 * ((100vw - 320px) / 890))' }}
            >
              FAQ
            </Typography>
          </li>
          <li className={activeTab === 'NFT' ? 'active' : ''} onClick={() => handleTabClick('NFT')}>
            <Typography
              sx={{ fontWeight: `${activeTab === 'NFT' ? 700 : 300}`, color: '#ffc50c', fontSize: 'calc(16px + 16 * ((100vw - 320px) / 890))' }}
            >
              NFT
            </Typography>
          </li>
        </Ul>
        <Accordion>{renderAccordionItems()}</Accordion>
      </Box>
    </Container>
  )
}

export default FaqPage
