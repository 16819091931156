import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Popover from '@mui/material/Popover'
import Button from '@mui/material/Button'
import StartsUserBox from '@components/userComponents/StartsUserBox/StartsUserBox'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import SeeMoreButton from '@buttons/SeeMoreButton/SeeMoreButton'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { loadingMembersProjectSuccess, ProjectByMemberInterface, ProjectStateInterface } from '@state/project/projectStateSlice'
import styles from './ProjectUserPopover.module.scss'

const ProjectUserPopover: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState<boolean>(false)
  const { project }: ProjectStateInterface = useSelector((state: AppStateInterface) => state.projectState)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const closeHandler = () => {
    setAnchorEl(null)
  }
  const clickSeeMoreHandler = () => {
    setLoading(true)
    apiConnectAxios(`/projects/all-members/${projectId}`)
      .then(data => {
        dispatch(loadingMembersProjectSuccess({ projectByMembers: data?.data }))
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  const open = Boolean(anchorEl)
  return (
    <>
      {project?.projectByMembers && project.projectByMembers.projectByMemberCount > 4 && (
        <div className={styles.buttonWrapper}>
          <Button className={styles.avatarButton} variant='contained' onClick={clickHandler}>
            +{project.projectByMembers.projectByMemberCount - 4}
          </Button>
        </div>
      )}
      <Popover
        className={styles.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={closeHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={styles.wrapper}>
          <LoadingBlockSpin isLoading={isLoading} />
          {project?.projectByMembers &&
            !!project.projectByMembers.projectByMemberArr &&
            project.projectByMembers.projectByMemberArr?.map(({ member, memberId }: ProjectByMemberInterface) => (
              <Fragment key={member?.id}>
                <h4 className={styles.title}>{project?.createdBy == memberId ? 'creator' : 'participants'}</h4>
                <StartsUserBox
                  memberId={memberId}
                  avatar={member?.avatar}
                  firstName={member?.firstName}
                  lastName={member?.lastName}
                  rating={Number((Math.random() * 5).toFixed(1))}
                  reviews={Math.floor(Math.random() * 99)}
                />
                <hr className={styles.line} />
              </Fragment>
            ))}
          {project?.projectByMembers?.projectByMemberArr?.length != project?.projectByMembers?.projectByMemberCount && (
            <SeeMoreButton onClick={clickSeeMoreHandler} />
          )}
        </div>
      </Popover>
    </>
  )
}

export default ProjectUserPopover
