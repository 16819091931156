import { FC, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { ProfileInterface } from '@state/profile/profileStateSlice'
import { ProfileFormValuesInterface } from '../ProfileSettingsForm'

interface Props {
  profile: ProfileInterface
}

const ProfileSettingsFormLogic: FC<Props> = ({ profile }: Props) => {
  const { setValues } = useFormikContext()

  useEffect(() => {
    const values: ProfileFormValuesInterface = {
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || '',
      bio: profile?.bio || '',
      facebookLink: profile?.facebookLink || '',
      twitterLink: profile?.twitterLink || '',
      linkedinLink: profile?.linkedinLink || '',
      websiteLink: profile?.websiteLink || '',
      location: profile?.location || '',
      skills: profile?.skills?.join(', ') || '',
      avatar: profile?.avatar || '',
    }
    setValues(values)
  }, [profile])

  return null
}

export default ProfileSettingsFormLogic
