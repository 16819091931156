import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ReactComponent as CurrencyIcon } from '@icons/currencyIcon.svg'
import styles from './UserBalance.module.scss'

interface Props {
  isSmall?: boolean
}

const UserBalance: FC<Props> = ({ isSmall }: Props) => {
  const { profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  return (
    <div className={`${styles.coinsWrapper} ${(isSmall && styles.coinsWrapper_small) || ''}`}>
      {profile?.balance || ''}
      <CurrencyIcon className={styles.currencyIcon} />
    </div>
  )
}

export default memo(UserBalance)
