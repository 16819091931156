import { Container, Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { FlexBox } from '../../Boxes/FlexBox'
import { ColorsButton } from '@buttons/ColorsButton/ColorsButton'
import { Form } from '../../Inputs/Form'
import { FormField } from '../../Inputs/FormField'
import { MultilineFormField } from '../../Inputs/MultilineFormField'
import { ScaleText } from '../../Text/ScaleText'
import { ContactFormWrapper, ContactLink, ContactImage, ContactText, ImageBox } from './styles'
export const ContactPage: React.FC = () => {
  const theme = useTheme()
  return (
    <Container disableGutters={true}>
      <ScaleText title='Contact' scaleFunction='calc(24px + 16 * ((100vw - 320px) / 890))' color='#ffc50c' />
      <Typography
        component='p'
        sx={{
          margin: '0 auto 50px',
          lineHeight: '170% !important',
          textAlign: 'center',
          fontWeight: '500',
          fontSize: '18px',
          color: '#bfbcc5',
        }}
      >
        <span>Below are some common frequently asked questionshendrerit justo quisque quis</span>
        <br />
        rhoncus exeget semper semlamat lobortis velit estibulum ante.
      </Typography>
      <Box sx={{ margin: '60px 0 40px', alignItems: 'center', justifyContent: 'center', fontSize: '18px', display: 'flex' }}>
        <ContactLink sx={{ borderRight: '1px solid #394180' }}>
          <ContactImage src='../images/icons/phone-yellow.png' />
          +61 3 8376 6284
        </ContactLink>
        <ContactLink>
          <ContactImage src='../images/icons/email-yellow.png' />
          info@crypten.com
        </ContactLink>
      </Box>
      <FlexBox sx={{ mb: '120px' }}>
        <Form sx={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap', maxWidth: '768px' }}>
          <ContactFormWrapper>
            <FormField type='text' placeholder='Name' />
          </ContactFormWrapper>
          <ContactFormWrapper>
            <FormField type='text' placeholder='Email Address' />
          </ContactFormWrapper>
          <ContactFormWrapper>
            <FormField type='text' placeholder='Phone' />
          </ContactFormWrapper>
          <ContactFormWrapper>
            <FormField type='text' placeholder='Subject' />
          </ContactFormWrapper>
          <MultilineFormField placeholder='Message' />
          <ColorsButton type='submit' title='Send Message' buttonSize='large' backgroundColor={theme.palette.primary.main} />
        </Form>
      </FlexBox>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        <Typography
          sx={{ width: '100%', [theme.breakpoints.up(768)]: { width: '66%' } }}
          mb='20px'
          component='img'
          src='images/contact.png?v=1655211687859'
          alt=''
        />
        <Box pl='40px' sx={{ width: '100%', [theme.breakpoints.up(768)]: { width: '33%' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: '50px' }}>
            <ImageBox>
              <img src='../images/icons/location.png' alt='' />
            </ImageBox>
            <Box>
              <ContactText>456444, Russia, Chelyabinsk region,</ContactText>
              <ContactText>Chebarcul, Kashirina 50-44</ContactText>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: '50px' }}>
            <ImageBox>
              <img src='../images/icons/clock.png' alt='' />
            </ImageBox>

            <Box>
              <ContactText>Monday to Friday from 9.00 am to 17.00 EST</ContactText>
              <ContactText>Saturday from 10.00 am to 16.00 EST</ContactText>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ImageBox>
              <img src='../images/icons/comments.png' alt='' />
            </ImageBox>
            <Box>
              <ContactText>Phone: +7 900 066 70 50</ContactText>
              <ContactText>Email: sales@verzo.net</ContactText>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
