import React, { FC, Fragment, useEffect } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import RequestProjectAside from '@pages/projectPages/ProjectPage/RequestProjectPage/RequestProjectAside/RequestProjectAside'
import BackLink from '@components/links/BackLink/BackLink'
import ProjectUserPopoverBox from '@components/userComponents/ProjectUserPopoverBox/ProjectUserPopoverBox'
import RequestProjectDonations from './RequestProjectDonations/RequestProjectDonations'
import RequestProjectTasksAside from './RequestProjectTasksAside/RequestProjectTasksAside'
import { TagInterface } from '@interfaces/interfaces'
import { loadingProjectAction, ProjectParamsInterface, ProjectStateInterface } from '@state/project/projectStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { FileByTaskInterface, loadingTaskAction, TaskParamsInterface, TaskStateInterface } from '@state/task/taskStateSlice'
import styles from './RequestProjectPage.module.scss'
const RequestProjectPage: FC = () => {
  const dispatch = useDispatch()
  const { projectId } = useParams<ProjectParamsInterface>()
  const { taskId } = useParams<TaskParamsInterface>()
  const { project }: ProjectStateInterface = useSelector((state: AppStateInterface) => state.projectState)
  const { task }: TaskStateInterface = useSelector((state: AppStateInterface) => state.taskState)
  const { url } = useRouteMatch()
  const isProjectPage: boolean = url?.includes('project')
  const pageContent = isProjectPage ? project : task
  const isDonationsPage: boolean = url?.includes('donations')
  useEffect(() => {
    if (isProjectPage) dispatch(loadingProjectAction({ isMember: true, isProjectMembers: true }))
    else dispatch(loadingTaskAction({ isMember: true }))
  }, [projectId, taskId, dispatch])
  return (
    <>
      <HelmetTitle title={`${isProjectPage ? 'Project' : 'Task'} ${isDonationsPage ? 'Donations' : 'Request'}`} />
      {isProjectPage && (
        <div className={styles.linkWrapper}>
          <BackLink title='BACK TO PROJECTS' link='/projects' />
          <ProjectUserPopoverBox />
        </div>
      )}
      {!isDonationsPage && <Button className={styles.requestButton}>REQUESTS</Button>}
      <h1 className={styles.title}>{pageContent?.name || '\u00A0'}</h1>
      <div className={styles.wrapperTag}>
        {!!pageContent?.tags?.length &&
          pageContent.tags?.map(({ id, name }: TagInterface) => (
            <Fragment key={id}>
              {name && (
                <div key={id} className={styles.tag}>
                  {name}
                </div>
              )}
            </Fragment>
          ))}
      </div>
      <div className={styles.imgWrapper}>
        {isProjectPage && <img className={styles.img} src={project?.image || ''} alt='' />}
        {!isProjectPage &&
          task?.files?.map((file: FileByTaskInterface) => (
            <Fragment key={file?.id}>
              {file?.fileType === 'image/jpg' ||
                (file?.fileType === 'image/png' && <img className={styles.img} src={file?.fileSource || ''} alt='' />)}
            </Fragment>
          ))}
        {!isDonationsPage && <RequestProjectAside pageContent={pageContent} />}
        {isDonationsPage && <RequestProjectDonations />}
        {pageContent?.description && (
          <div className={`${styles.description} ${(!isProjectPage && !task?.files?.length && styles.description_order) || ''}`}>
            {pageContent?.description}
          </div>
        )}
        {isDonationsPage && isProjectPage && <RequestProjectTasksAside />}
      </div>
    </>
  )
}

export default RequestProjectPage
