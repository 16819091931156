import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const MembershipBox = styled(Box)({
  display: 'flex',
  backgroundColor: '#2e1563',
  borderRadius: '5px',
  margin: '0px 10px 20px',
  padding: '45px 50px',
})

export const HowToText = styled(Box)({
  maxWidth: '250px',
  margin: '0 auto',
  lineHeight: '150%',
  fontSize: '18px',
  fontWeight: '400',
})
