import { FC, memo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import styles from './PageTitle.module.scss'

interface Props {
  title: string
}

const PageTitle: FC<Props> = ({ title }: Props) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{title}</h1>
      <Link className={styles.link} to='/'>
        Cancel
      </Link>
    </div>
  </>
)

export default memo(PageTitle)
