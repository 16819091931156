import { Link, styled } from '@mui/material'

export const ProductsTitle = styled(Link)`
  :hover {
    color: #fec40c;
  }
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  color: #fff;
  height: 69px;
  overflow: hidden;
  font-size: calc(16px + 4 * ((100vw - 320px) / 890));
`
