import { put, takeLatest, all, select, call } from 'redux-saga/effects'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { toast } from 'react-hot-toast'
import errorResponseReturn from '@utils/errorResponseReturn'
import { loadingContractArrAction, loadingContractArrFailure, loadingContractArrSuccess } from '@state/contractArr/contractArrStateSlice'

function* loadingContractArrSaga(): Generator {
  try {
    const { searchOffer, page, status, date }: any = yield select(state => state.router.location.query)
    let queryParams: string = ''
    if (searchOffer) queryParams = `${queryParams}&searchOffer=${searchOffer}`
    if (status && status !== 'All') queryParams = `${queryParams}&status=${status}`
    queryParams = `${queryParams}&date=${date || 'down'}`
    const { data }: any = yield call(apiConnectAxios, `/contract/?page=${page || 1}&limit=10${queryParams}`)
    yield put(loadingContractArrSuccess({ contractArr: data?.contractArr, contractCount: data?.contractCount }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingContractArrFailure(errorResponseReturn(error)))
  }
}

export default function* contractArrRootSaga() {
  yield all([takeLatest(loadingContractArrAction.type, loadingContractArrSaga)])
}
