import { PayloadAction } from '@reduxjs/toolkit'
import { TaskStatusInternalEnum } from '@state/task/taskStateSlice'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { createMatchSelector } from 'connected-react-router'
import dayjs from 'dayjs'
import { toast } from 'react-hot-toast'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  changeStatusExecutorTaskArrAction,
  changeStatusExecutorTaskArrFailure,
  loadingExecutorTaskArrAction,
  loadingExecutorTaskArrFailure,
  loadingExecutorTaskArrSuccess,
  loadingStatusExecutorTaskArrAction,
  loadingStatusExecutorTaskArrFailure,
  loadingStatusExecutorTaskArrSuccess,
  loadingUsersExecutorTaskArrAction,
  loadingUsersExecutorTaskArrFailure,
  loadingUsersExecutorTaskArrSuccess,
} from './executorTaskArrStateSlice'

function* loadingExecutorTaskArrSaga({ payload: { minDate, maxDate } }: PayloadAction<{ minDate: Date; maxDate: Date }>): Generator {
  try {
    const { page, sort }: any = yield select(state => state.router.location.query)
    let queryParams: string = ''
    if (sort && sort !== 'All') queryParams = `&statusInternal=${sort}`
    const { data }: any = yield call(
      apiConnectAxios,
      `/tasks/executors/?page=${page || 1}&limit=10&minDate=${dayjs(minDate).format('YYYY-MM-DD')}&maxDate=${dayjs(maxDate).format('YYYY-MM-DD')}${queryParams}`,
    )
    yield put(loadingExecutorTaskArrSuccess({ executorTaskArr: data?.executorTaskArr, executorTaskCount: data?.executorTaskCount }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingExecutorTaskArrFailure(errorResponseReturn(error)))
  }
}

function* loadingUsersExecutorTaskArrSaga({ payload: { page, limit } }: PayloadAction<{ page: number; limit: number }>): Generator {
  try {
    const matchSelector = createMatchSelector({ path: ['/projects'] })
    const match: any = yield select(matchSelector)
    const { projectId }: any = yield select(state => state.router.location.query)
    let queryParams: string = ''
    if (projectId && projectId !== 'all') queryParams = `&projectId=${projectId}`
    if (match?.path === '/projects') {
      if (page === 1) {
        const { data }: any = yield call(apiConnectAxios, `/tasks/users-executors/?page=${page || 1}&limit=${limit || 6}${queryParams}`)
        yield put(
          loadingUsersExecutorTaskArrSuccess({
            taskExecutorArr: data?.taskExecutorArr,
            taskExecutorCount: data?.taskExecutorCount,
            taskExecutorPage: page,
          }),
        )
      } else {
        const { data }: any = yield call(apiConnectAxios, `/tasks/users-executors/?page=${page || 1}&limit=${limit || 6}${queryParams}`)
        const { taskExecutorArr }: any = yield select(state => state.executorTaskArrState)
        let newTaskExecutorArr = []
        if (taskExecutorArr?.length) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          newTaskExecutorArr = [...taskExecutorArr, ...data?.taskExecutorArr]
        } else {
          newTaskExecutorArr = data?.taskExecutorArr
        }
        yield put(
          loadingUsersExecutorTaskArrSuccess({
            taskExecutorArr: newTaskExecutorArr,
            taskExecutorCount: data?.taskExecutorCount,
            taskExecutorPage: page,
          }),
        )
      }
    } else {
      const { data }: any = yield call(apiConnectAxios, `/tasks/users-executors/?page=${page || 1}&limit=${limit || 6}`)
      const { taskExecutorArr }: any = yield select(state => state.executorTaskArrState)
      let newTaskExecutorArr = []
      if (taskExecutorArr?.length) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        newTaskExecutorArr = [...taskExecutorArr, ...data?.taskExecutorArr]
      } else {
        newTaskExecutorArr = data?.taskExecutorArr
      }
      yield put(
        loadingUsersExecutorTaskArrSuccess({
          taskExecutorArr: newTaskExecutorArr,
          taskExecutorCount: data?.taskExecutorCount,
          taskExecutorPage: page,
        }),
      )
    }
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingUsersExecutorTaskArrFailure(errorResponseReturn(error)))
  }
}

function* loadingStatusExecutorTaskArrSaga(): Generator {
  try {
    const { page, projectId, executorId, searchName }: any = yield select(state => state.router.location.query)
    let queryParams: string = ''
    if (projectId && projectId !== 'all') queryParams = `&projectId=${projectId}`
    if (executorId && executorId !== 'all') queryParams = `${queryParams}&executorId=${executorId}`
    if (searchName) queryParams = `${queryParams}&searchName=${searchName}`
    const { data }: any = yield call(apiConnectAxios, `/tasks/status-executors/?page=${page || 1}&limit=5${queryParams}`)
    yield put(
      loadingStatusExecutorTaskArrSuccess({
        todoExecutorTaskArr: data?.todoExecutorTaskArr,
        forDevelopExecutorTaskArr: data?.forDevelopExecutorTaskArr,
        inProgressExecutorTaskArr: data?.inProgressExecutorTaskArr,
        testingExecutorTaskArr: data?.testingExecutorTaskArr,
        doneExecutorTaskArr: data?.doneExecutorTaskArr,
        statusTaskCount: data?.statusTaskCount,
      }),
    )
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingStatusExecutorTaskArrFailure(errorResponseReturn(error)))
  }
}

function* changeStatusExecutorTaskArrSaga({
  payload: { requestId, statusInternal },
}: PayloadAction<{ requestId: number; statusInternal: TaskStatusInternalEnum }>): Generator {
  try {
    yield call(apiConnectAxios, `/tasks/status-internal`, 'patch', { requestId, statusInternal })
    yield call(loadingStatusExecutorTaskArrSaga)
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(changeStatusExecutorTaskArrFailure(errorResponseReturn(error)))
  }
}

export default function* executorTaskArrRootSaga() {
  yield all([
    takeLatest(loadingExecutorTaskArrAction.type, loadingExecutorTaskArrSaga),
    takeLatest(loadingUsersExecutorTaskArrAction.type, loadingUsersExecutorTaskArrSaga),
    takeLatest(loadingStatusExecutorTaskArrAction.type, loadingStatusExecutorTaskArrSaga),
    takeLatest(changeStatusExecutorTaskArrAction.type, changeStatusExecutorTaskArrSaga),
  ])
}
