import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import RequestsSidebarUser from '../RequestsSidebarClient/RequestsSidebarUser/RequestsSidebarUser'
import { useQueryHook } from '@hooks/useQueryHook'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { RequestArrStateInterface, RequestInterface } from '@state/requestArr/requestArrStateSlice'
import styles from './RequestsSidebarPerformer.module.scss'

const RequestsSidebarPerformer: FC = () => {
  const { clientRequestArr, isRequestArrFirstLoading }: RequestArrStateInterface = useSelector((state: AppStateInterface) => state.requestArrState)
  const query: URLSearchParams = useQueryHook()
  if (query.get('relationship') === 'client' || !query.get('relationship') || query.get('relationship') !== 'performer') return null
  return (
    <>
      {!!clientRequestArr?.length &&
        clientRequestArr?.map((request: RequestInterface) => <RequestsSidebarUser key={request?.id} relationship='client' request={request} />)}
      {isRequestArrFirstLoading && !clientRequestArr?.length && <div className={styles.empty}>No Requests were found that were created by you</div>}
    </>
  )
}

export default memo(RequestsSidebarPerformer)
