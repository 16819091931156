import { FC, memo } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import ProfileFollowerItem from './ProfileFollowerItem/ProfileFollowerItem'
import PagePagination from '@components/PagePagination/PagePagination'
import { MemberInterface, ProfileStateInterface } from '@state/profile/profileStateSlice'
import { FollowerStateInterface } from '@state/follower/followerStateSlice'
import { IdParamsInterface } from '@interfaces/interfaces'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { UserStateInterface } from '@state/user/userStateSlice'
import styles from './ProfileFollowers.module.scss'

const ProfileFollowers: FC = () => {
  const { id } = useParams<IdParamsInterface>()
  const {
    profileFollowerArr,
    profileFollowerCount,
    profileFollowingArr,
    profileFollowingCount,
    profileFollowingIdArr,
    userFollowerArr,
    userFollowerCount,
    userFollowingArr,
    userFollowingCount,
    isProfileFollowerFirstLoading,
  }: FollowerStateInterface = useSelector((state: AppStateInterface) => state.followerState)
  const { path } = useRouteMatch()
  const { profile, isProfileFirstLoading }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const { userProfile }: UserStateInterface = useSelector((state: AppStateInterface) => state.userState)
  const title = id ? `${path?.[1].toUpperCase()}${path.slice(2, -4)}` : `${path?.[1].toUpperCase()}${path.slice(2)}`
  let followerArr: MemberInterface[] | [] = title === 'Followers' ? profileFollowerArr : profileFollowingArr
  let followerCount: number = title === 'Followers' ? profileFollowerCount : profileFollowingCount
  if (id) followerArr = title === 'Followers' ? userFollowerArr : userFollowingArr
  if (id) followerCount = title === 'Followers' ? userFollowerCount : userFollowingCount
  return (
    <>
      <HelmetTitle title={`${id ? 'User ' : ''}${title}`} />
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          {title}
          <span>{`[ ${followerCount} ]`}</span>
        </h2>
        {followerArr?.map((follower: MemberInterface) => (
          <ProfileFollowerItem
            key={follower?.id}
            isFirstLoading={isProfileFollowerFirstLoading && isProfileFirstLoading}
            follower={follower}
            followerStatus={
              profileFollowingIdArr?.find((followId: number): boolean => followId == follower?.memberId || profile?.memberId == follower?.memberId)
                ? 'Following'
                : 'Follow'
            }
            deleteMemberId={profile?.memberId == follower?.memberId ? userProfile?.memberId : follower?.memberId}
          />
        ))}
        {followerCount > 10 && <PagePagination pageCount={Math.ceil(followerCount / 10)} />}
      </div>
    </>
  )
}
export default memo(ProfileFollowers)
