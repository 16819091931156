import { Box, Typography, useTheme } from '@mui/material'
import { MembershipBox } from './styles'

interface BlcokProps {
  imageSrc: string
  titleText: string
  mainText: string
}

export const MembershipBlock: React.FC<BlcokProps> = ({ imageSrc, titleText, mainText }) => {
  const theme = useTheme()

  return (
    <MembershipBox>
      <Typography component='div' sx={{ display: 'flex', alignItems: 'center', mr: theme.spacing(2.5) }}>
        <img src={imageSrc} alt='' />
      </Typography>
      <Box sx={{ textAlign: 'left' }}>
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: '600',
            mb: '15px',
          }}
        >
          {titleText}
        </Typography>
        <Typography variant='h4' sx={{ color: theme.palette.text.secondary }}>
          {mainText}
        </Typography>
      </Box>
    </MembershipBox>
  )
}
