import { Button, Typography } from '@mui/material'
import { darken, useTheme } from '@mui/material/styles'
import { FC, MouseEvent, ReactNode } from 'react'

interface Props {
  title: string
  hidden?: true | false
  textColor?: string
  backgroundColor?: string
  buttonSize?: 'small' | 'medium' | 'large'
  type?: 'button' | 'submit' | 'reset'
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  icon?: ReactNode
}

export const ColorsButton: FC<Props> = ({ hidden, title, textColor, backgroundColor, buttonSize, type, onClick, icon }: Props) => {
  const theme = useTheme()

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(event)
  }

  return (
    <Button
      variant='contained'
      onClick={onClickHandler}
      size={buttonSize}
      type={type}
      hidden={hidden}
      sx={{
        visibility: hidden ? 'hidden' : 'block',
        background: backgroundColor || theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: backgroundColor || theme.palette.secondary.main ? darken(backgroundColor || theme.palette.secondary.main, 0.3) : '',
        },
        '&:not(:last-child)': {
          marginRight: theme.spacing(2),
        },
      }}
    >
      <Typography
        color={textColor ?? theme.palette.primary.contrastText}
        sx={{ fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        {title}
        {icon && <span style={{ margin: '0px 8px' }}>{icon}</span>}
      </Typography>
    </Button>
  )
}
