import React, { FC, memo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useQueryHook } from '@hooks/useQueryHook'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import LoadingTransparentSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import TransactionsDetailsAsideHash from './TransactionsDetailsAsideHash/TransactionsDetailsAsideHash'
import {
  DirectionTransactionEnum,
  loadingTransactionAction,
  StatusTransactionEnum,
  TransactionStateInterface,
} from '@state/transaction/transactionStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ReactComponent as CircleGreenArrowSvgIcon } from '@icons/circleGreenArrowIcon.svg'
import { ReactComponent as CircleRedArrowSvgIcon } from '@icons/circleRedArrowIcon.svg'
import { ReactComponent as CurrencySvgIcon } from '@icons/currencyIcon.svg'
import { ReactComponent as CircleCheckMarkSvgIcon } from '@icons/circleCheckMarkIcon.svg'
import { ReactComponent as ExclamationMarkSvgIcon } from '@icons/circleRedExclamationMarkIcon.svg'
import styles from './TransactionsDetailsAside.module.scss'

const TransactionsDetailsAside: FC = () => {
  const query: URLSearchParams = useQueryHook()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadingTransactionAction())
  }, [query.get('transactionId'), dispatch])
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()
  const onClickCloseHandler = () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('transactionId', '')
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  const { transaction, isTransactionLoading }: TransactionStateInterface = useSelector((state: AppStateInterface) => state.transactionState)
  return (
    <div className={styles.wrapper}>
      <LoadingTransparentSpin isLoading={isTransactionLoading} />
      <h2 className={styles.asideTitle}>Transaction details</h2>
      <IconButton className={styles.closeButton} type='button' onClick={onClickCloseHandler}>
        <CloseIcon className={styles.closeIcon} />
      </IconButton>
      {transaction?.direction === DirectionTransactionEnum.Incoming ? (
        <CircleGreenArrowSvgIcon className={styles.arrowIcon} />
      ) : (
        <CircleRedArrowSvgIcon className={styles.arrowIcon} />
      )}
      <div className={styles.amount}>
        +{transaction?.amount}
        <CurrencySvgIcon className={styles.currencyIcon} />
      </div>
      <div className={styles.title}>Status</div>
      <div className={styles.statusWrapper}>
        {transaction?.status === StatusTransactionEnum.Success && (
          <>
            <CircleCheckMarkSvgIcon />
            <div className={styles.status_success}>{StatusTransactionEnum.Success}</div>
          </>
        )}
        {transaction?.status === StatusTransactionEnum.Hold && (
          <>
            <AccessTimeIcon className={styles.timeIcon} />
            <div className={styles.status_hold}>{StatusTransactionEnum.Hold}</div>
          </>
        )}
        {transaction?.status === StatusTransactionEnum.Error && (
          <>
            <ExclamationMarkSvgIcon />
            <div className={styles.status_error}>{StatusTransactionEnum.Error}</div>
          </>
        )}
      </div>
      <div className={styles.title}>Time</div>
      <div className={styles.text}>{dayjs(transaction?.createdAt).format('HH:mm:ss, MMM D, YYYY')}</div>
      <div className={styles.title}>Wasted time</div>
      <div className={styles.text}>{`${dayjs(transaction?.createdAt).format(`HH`)}h ${dayjs(transaction?.createdAt).format(`mm`)}m`}</div>
      {transaction?.donationMember && (
        <>
          <div className={styles.name}>Person</div>
          <Link to={`/user/${transaction?.donationMember?.memberId}`}>
            {`${transaction?.donationMember?.firstName} ${transaction?.donationMember?.lastName}`}
          </Link>
        </>
      )}
      <div className={styles.title}>Payment method</div>
      <div className={styles.text}>{transaction?.method}</div>
      <div className={styles.title}>Transaction hash</div>
      <TransactionsDetailsAsideHash hash='oxc30876ceb456dmkl45...' />
      <div className={styles.title}>From</div>
      <TransactionsDetailsAsideHash hash='oxc30876ceb456dmkl45...' />
      <div className={styles.title}>To</div>
      <TransactionsDetailsAsideHash hash='oxc30876ceb456dmkl45...' />
    </div>
  )
}

export default memo(TransactionsDetailsAside)
