import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ProjectUserPopover from './ProjectUserPopover/ProjectUserPopover'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProjectByMemberInterface, ProjectStateInterface } from '@state/project/projectStateSlice'
import styles from './ProjectUserPopoverBox.module.scss'

const ProjectUserPopoverBox: FC = () => {
  const { project }: ProjectStateInterface = useSelector((state: AppStateInterface) => state.projectState)
  let projectByMemberArr: ProjectByMemberInterface[] = []
  if (project?.projectByMembers && project.projectByMembers.projectByMemberArr?.length) {
    projectByMemberArr = project.projectByMembers.projectByMemberArr.slice(
      0,
      project.projectByMembers.projectByMemberArr?.length > 3 ? 4 : project.projectByMembers.projectByMemberArr?.length,
    )
  }
  return (
    <div className={styles.avatarWrapper}>
      {!!projectByMemberArr?.length &&
        projectByMemberArr?.map(({ member, memberId }: ProjectByMemberInterface) => (
          <div className={styles.linkWrapper} key={memberId}>
            {member?.avatar && (
              <Link className={styles.avatarLink} to={`/user/${memberId}`}>
                <img className={styles.avatar} src={member?.avatar} alt='' />
              </Link>
            )}
          </div>
        ))}
      {project?.projectByMembers && project?.projectByMembers?.projectByMemberCount > 4 && <ProjectUserPopover />}
    </div>
  )
}

export default memo(ProjectUserPopoverBox)
