import { Typography, useTheme } from '@mui/material'

interface IScaleText {
  title: string
  scaleFunction: string
  color?: string
}

export const ScaleText: React.FC<IScaleText> = ({ title, scaleFunction, color }) => {
  const theme = useTheme()

  return (
    <Typography
      variant={'h2'}
      sx={{
        textAlign: 'center',
        color: color ?? '#fff',
        textTransform: 'uppercase',
        padding: '60px 0 40px',
        letterSpacing: '3px',
        fontSize: scaleFunction,
        [theme.breakpoints.down(320)]: { fontSize: '24px' },
      }}
    >
      {title}
    </Typography>
  )
}
