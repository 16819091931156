import { Box, styled } from '@mui/material'
export const ContactFormWrapper = styled(Box)`
  display: flex;
  width: 50%;
  padding: 0 5px 10px;
  @media (max-width: 575px) {
    width: 100%;
  }
`
export const ContactLink = styled('a')`
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
  padding: 0 30px;
  height: 40px;
  display: flex;
  align-items: center;
  :hover {
    color: #ffc50c;
  }
`
export const ContactImage = styled('img')`
  margin-right: 15px;
`

export const ContactText = styled('p')`
  margin-bottom: 10px;
  font-size: 14px;
  color: #fff;
  :last-child {
    margin-bottom: 0;
  }
`
export const ImageBox = styled(Box)({
  mr: '20px',
  height: '45px',
  width: '45px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
