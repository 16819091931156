import React, { FC, memo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { openHook } from '@hooks/openHook'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { Select, SelectChangeEvent } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuItem from '@mui/material/MenuItem'
import { useQueryHook } from '@hooks/useQueryHook'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProjectInterface } from '@state/project/projectStateSlice'
import { loadingProjectArrAction, ProjectArrStateInterface } from '@state/projectArr/projectArrStateSlice'
import styles from './ProjectsSelect.module.scss'

const ProjectsSelect: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadingProjectArrAction())
  }, [dispatch])
  const { projectArr }: ProjectArrStateInterface = useSelector((state: AppStateInterface) => state.projectArrState)
  const { isOpen, openHandler, closeHandler } = openHook()
  const query: URLSearchParams = useQueryHook()
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()
  const changeValueHandler = (event: SelectChangeEvent) => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('projectId', event.target.value)
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
    searchParams.set('executorId', 'all')
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  useEffect(() => {
    if (query.get('projectId') && !Number.isInteger(Number(query.get('projectId'))) && query.get('projectId') !== 'all') {
      const searchParams: URLSearchParams = new URLSearchParams(search)
      searchParams.set('projectId', '')
      push({
        pathname: pathname,
        search: searchParams.toString(),
      })
      toast.error('projectId must be number')
    }
  }, [query.get('projectId'), dispatch])
  const projectFound: ProjectInterface | undefined = projectArr?.find(({ id }: ProjectInterface): boolean => id == Number(query.get('projectId')))
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>{projectFound?.icon && <img className={styles.icon} src={projectFound?.icon} alt='' />}</div>
      <Select
        className={styles.select}
        open={isOpen}
        value={query.get('projectId') || 'all'}
        onChange={changeValueHandler}
        onClose={closeHandler}
        onOpen={openHandler}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{ MenuListProps: { style: { background: 'rgba(30, 14, 63)' } } }}
      >
        <MenuItem className='flex-g' value='all'>
          <div className={styles.iconSelectWrapper}>All Project</div>
        </MenuItem>
        {projectArr?.map(({ id, name, icon }: ProjectInterface) => (
          <MenuItem className='flex-g' key={id} value={String(id)}>
            <div className={styles.iconSelectWrapper}>
              {icon && <img className={styles.selectIcon} src={icon} alt='' />}
              {name}
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}
export default memo(ProjectsSelect)
