import { Typography } from '@mui/material'
import { WrapFlexBox } from '../Boxes/WrapFlexBox'
import { HowToBlock } from './landing-components/HowToBlock'

export const SectionHowTo: React.FC = () => {
  return (
    <WrapFlexBox sx={{ mb: '200px', mt: '200px' }}>
      <Typography variant='h2' sx={{ mb: '60px' }}>
        HOW IT WORKS
      </Typography>
      <WrapFlexBox>
        <HowToBlock
          imageSrc='images/how/1.png?v=1655211687859'
          titleText='Collaborate and Earn'
          mainText='Are you a backend developer? Partner with frontend engineers and other specialists on various projects. For every contribution, you earn SkillCoins™ or obligations based on the type and amount of work you do.'
        />
        <HowToBlock
          imageSrc='images/how/2.png?v=1655211687859'
          titleText='Accumulate SkillCoins™'
          mainText='As you work, you build your SkillCoins™ balance. For example, you might earn 1000 SkillCoins and 500 SkillCoins as an obligation from frontend engeneer and 500 for a content creator. These numbers are just examples and can vary.'
        />
        <HowToBlock
          imageSrc='images/how/3.png?v=1655211687859'
          titleText='Invest in Your Projects'
          mainText='Use your SkillCoins™ to bring your ideas to life. Need a design? Spend the appropriate amount of SkillCoins™. Require frontend development? Use obligated SkillCoins™ as hours of work. Need content creation? Use obligated SkillCoins™!'
        />
      </WrapFlexBox>
    </WrapFlexBox>
  )
}
