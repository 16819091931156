import React, { FC, memo } from 'react'
import { TaskStatusInternalEnum } from '@state/task/taskStateSlice'
import styles from './ProjectsTableThead.module.scss'

const optionArr: TaskStatusInternalEnum[] = [
  TaskStatusInternalEnum.ToDo,
  TaskStatusInternalEnum.ForDevelop,
  TaskStatusInternalEnum.InProgress,
  TaskStatusInternalEnum.Testing,
  TaskStatusInternalEnum.Done,
]

const ProjectsTableThead: FC = () => (
  <div className={styles.wrapper}>
    {optionArr?.map((trName: TaskStatusInternalEnum) => (
      <div className={styles.container} key={trName}>
        {trName}
      </div>
    ))}
  </div>
)

export default memo(ProjectsTableThead)
