import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Avatar, Box, IconButton, MenuItem, Popover, useTheme } from '@mui/material'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProfileInterface, ProfileStateInterface } from '@state/profile/profileStateSlice'
import { FlexBox } from '../../Boxes/FlexBox'
import { CoinsCount, UserName } from '../styles'
import { Currency } from './Currency'
import avatarPng from '@images/avatar.png'
type Props = {
  activePopup: string | null
  setActivePopup: (active: any) => void
  memberProfile: ProfileInterface | null
  onLogout: () => void
  anchorRef: any
  dropdownKey: string
}

const DropdownProfileOptions: FC<Props> = ({ activePopup, setActivePopup, onLogout, anchorRef, dropdownKey }: Props) => {
  const {
    isProfileFirstLoading,
    profile: { avatar, firstName },
  }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const theme = useTheme()

  const open = activePopup === dropdownKey
  const id = open ? 'profile-dropdown' : undefined
  return (
    <Box
      display='flex'
      sx={{
        justifyContent: 'space-between',
        [theme.breakpoints.up(576)]: {
          borderLeft: '1px solid #000',
          paddingLeft: '25px',
        },
        [theme.breakpoints.up(768)]: {
          minWidth: '210px',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', [theme.breakpoints.down(768)]: { display: 'none' } }}>
        <UserName>{isProfileFirstLoading && (firstName || 'UserName')}</UserName>
        <FlexBox>
          <CoinsCount>8129</CoinsCount>
          <Currency />
        </FlexBox>
      </Box>
      <IconButton
        ref={anchorRef}
        onClick={() => {
          setActivePopup((prevPopup: string | null) => (prevPopup === dropdownKey ? null : dropdownKey))
        }}
        sx={{ backgroundColor: 'transparent' }}
      >
        <Avatar src={avatar || avatarPng} sx={{ height: 53, width: 53 }} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setActivePopup(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.dark,
          }}
        >
          <Link to='/tasks'>
            <MenuItem>My Tasks</MenuItem>
          </Link>
          <Link to='/projects'>
            <MenuItem>My Projects</MenuItem>
          </Link>
          <Link to='/settings'>
            <MenuItem>My Profile</MenuItem>
          </Link>
          <Link to='/requests'>
            <MenuItem>My Requests</MenuItem>
          </Link>
          <Link to='/transactions'>
            <MenuItem>My Transactions</MenuItem>
          </Link>
          <Link to='/documents/templates'>
            <MenuItem>My Documents</MenuItem>
          </Link>
          <Link to='/faq'>
            <MenuItem href='/faq'>FAQ</MenuItem>
          </Link>
          <MenuItem onClick={onLogout}>LogOut</MenuItem>
        </Box>
      </Popover>
    </Box>
  )
}

export default DropdownProfileOptions
