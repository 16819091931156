import React, { FC } from 'react'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import BackLink from '@components/links/BackLink/BackLink'
import ProjectNewsSelect from './ProjectNewsSelect/ProjectNewsSelect'
import ProjectNewsDescription from './ProjectNewsDescription/ProjectNewsDescription'
import ProjectNews from './ProjectNews/ProjectNews'

const ProjectNewsPage: FC = () => (
  <>
    <HelmetTitle title='Project News' />
    <div className='page-container-g'>
      <BackLink />
      <ProjectNewsSelect />
      <ProjectNewsDescription />
      <ProjectNews />
    </div>
  </>
)

export default ProjectNewsPage
