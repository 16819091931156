import React, { ChangeEvent, FC } from 'react'
import { FieldInputProps, FormikProps, useField } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-hot-toast'
import { Button, Tooltip } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import styles from '@fields/LoadPhotoField/LoadPhotoField.module.scss'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
}

const LoadFileArrField: FC<Props> = ({ field: { name, value }, form: { touched, errors } }: Props) => {
  const [, , { setValue, setError, setTouched }] = useField(name)
  const maxSize = 10000000 // 10mb
  const supportedFormats: string[] = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf', 'text/plain', 'application/msword']
  const loadingFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    const reader: FileReader = new FileReader()
    const [file]: any = e.target.files
    if (file) {
      reader.readAsDataURL(file)
      reader.onloadend = async () => {
        if (!supportedFormats.includes(file?.type)) {
          await setTouched(true)
          setError('Unsupported Format')
          return toast.error('Unsupported Format')
        } else if (file.size > maxSize) {
          await setTouched(true)
          setError('Max file size is 10mb')
          return toast.error('Max file size is 10mb')
        } else if (value?.length > 2) {
          await setTouched(true)
          setError('Maximum number of files 3')
        } else {
          await setTouched(true)
          setError('')
          setValue(value?.length ? [...value, file] : [file])
        }
      }
      reader.onerror = error => toast.error(error?.toString())
    }
  }
  const clickDeleteValueHandler = (index: number) => () => {
    if (value?.length) {
      const arr = value
      arr.splice(index, 1)
      setValue(arr)
    }
  }
  return (
    <div className='column-align_start-g'>
      <h3 className={`${styles.title} label-title-g`}>Add Files</h3>
      <div className={styles.buttonWrapper}>
        <Button className={styles.button} endIcon={<ArrowForwardIcon style={{ marginLeft: '10px', fontSize: 32, zIndex: 2 }} />} component='label'>
          <span className='z-index-5-g'>Add File</span>
          <input name={name} type='file' accept={supportedFormats.join()} onChange={loadingFileHandler} />
        </Button>
        <div className={styles.descriptionWrapper}>
          <div>Load files</div>
          <span>{`Max file size is 10Mb. \n jpg, jpeg, png, pdf, doc, txt`}</span>
        </div>
      </div>
      {!!value?.length &&
        value?.map((file: any, index: number) => (
          <div className='align-items_center-g' key={uuidv4()}>
            {file?.name}
            <Tooltip title='Delete file' placement='bottom'>
              <button onClick={clickDeleteValueHandler(index)} type='button'>
                <CloseIcon className={styles.icon} />
              </button>
            </Tooltip>
          </div>
        ))}
      <span className={styles.error}>{`${(touched?.[name] && errors?.[name]) || '\u00A0'}`}</span>
    </div>
  )
}

export default LoadFileArrField
