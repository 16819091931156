import React, { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { toast } from 'react-hot-toast'
import PageTitle from '@components/titles/PageTitle/PageTitle'
import PinkButton from '@buttons/PinkButton/PinkButton'
import FormField from '@fields/FormField/FormField'
import TextareaField from '@fields/TextareaField/TextareaField'
import CreateTaskPageLogic from './CreateTaskPageLogic/CreateTaskPageLogic'
import LoadFileArrField from '@fields/LoadFileArrField/LoadFileArrField'
import BudgetTaskField from './BudgetTaskField/BudgetTaskField'
import DateFormField from '@fields/DateFormField/DateFormField'
import SelectArrField from '@fields/SelectArrField/SelectArrField'
import CheckboxField from '@fields/CheckboxField/CheckboxField'
import SendingNDACheckboxField from '@pages/projectPages/CreateProjectPage/SendingNDACheckboxField/SendingNDACheckboxField'
import {
  maxDateValueValidationVar,
  minDateValueValidationVar,
  minLengthValidationVar,
  minTextareaLengthValidationVar,
  requiredValidationVar,
} from '@utils/fieldsValidationVariables'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { OptionIconArrInterface } from '@interfaces/interfaces'
import { ProjectInterface } from '@state/project/projectStateSlice'
import { loadingProjectArrAction, ProjectArrStateInterface } from '@state/projectArr/projectArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { TaskParamsInterface, TaskStatusInternalEnum } from '@state/task/taskStateSlice'
import styles from './CreateTaskPage.module.scss'

const validationSchema = yup.object().shape({
  name: yup.string().required(requiredValidationVar).min(minLengthValidationVar.min, minLengthValidationVar.message),
  description: yup.string().required(requiredValidationVar).min(minTextareaLengthValidationVar.min, minTextareaLengthValidationVar.message),
  taskStatusInternal: yup.string().required(requiredValidationVar).min(minLengthValidationVar.min, minLengthValidationVar.message),
  label: yup.string().required(requiredValidationVar),
  skillCoins: yup.number().required(requiredValidationVar).min(1, 'The value must not be less than 1'),
  isStrict: yup.boolean(),
  canPayByCoins: yup.number(),
  canPayByTime: yup.number(),
  skillCoinsTotal: yup.number(),
  deadline: yup
    .date()
    .min(minDateValueValidationVar.min, minDateValueValidationVar.message)
    .max(maxDateValueValidationVar.min, maxDateValueValidationVar.message)
    .required(requiredValidationVar),
  tags: yup.string(),
  isAutomaticSendingNda: yup.boolean(),
  isRequiredContract: yup.boolean(),
})

interface TaskFormValuesInterface {
  projectId: string | number
  name: string
  description: string
  taskStatusInternal: TaskStatusInternalEnum
  label: string
  skillCoins: number | string
  isStrict: boolean
  canPayByCoins: number | string
  canPayByTime: number | string
  skillCoinsTotal: number | string
  deadline: string | Date
  tags: string
  isAutomaticSendingNda: boolean
  isRequiredContract: boolean
  files: []
}

const optionTaskStatusInternalArr: OptionIconArrInterface[] = [
  {
    label: TaskStatusInternalEnum.ToDo,
    value: TaskStatusInternalEnum.ToDo,
  },
  {
    label: TaskStatusInternalEnum.ForDevelop,
    value: TaskStatusInternalEnum.ForDevelop,
  },
  {
    label: TaskStatusInternalEnum.InProgress,
    value: TaskStatusInternalEnum.InProgress,
  },
  {
    label: TaskStatusInternalEnum.Testing,
    value: TaskStatusInternalEnum.Testing,
  },
  {
    label: TaskStatusInternalEnum.Done,
    value: TaskStatusInternalEnum.Done,
  },
]

const optionLabelArr: OptionIconArrInterface[] = [{ label: 'Label', value: 'Label' }]

const CreateTaskPage: FC = () => {
  const { projectArr }: ProjectArrStateInterface = useSelector((state: AppStateInterface) => state.projectArrState)
  const history = useHistory<History>()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadingProjectArrAction())
  }, [dispatch])
  let optionProjectArr: OptionIconArrInterface[] = [
    {
      label: 'Without a project',
      value: '',
    },
  ]
  if (projectArr?.length) {
    optionProjectArr = projectArr?.map((project: ProjectInterface) => ({
      label: project?.name,
      value: project?.id,
      icon: project?.icon,
    }))
    optionProjectArr.unshift({
      label: 'Without a project',
      value: '',
    })
  }

  const { taskId } = useParams<TaskParamsInterface>()
  const initialValues: TaskFormValuesInterface = {
    projectId: '',
    name: '',
    description: '',
    taskStatusInternal: TaskStatusInternalEnum.ToDo,
    label: 'Label',
    skillCoins: 0,
    isStrict: false,
    canPayByCoins: 0,
    canPayByTime: 0,
    skillCoinsTotal: 0,
    deadline: dayjs(new Date()).format('YYYY-MM-DD'),
    tags: '',
    isAutomaticSendingNda: false,
    isRequiredContract: false,
    files: [],
  }
  const submitHandler = async (fieldValues: TaskFormValuesInterface, { setErrors }: FormikHelpers<TaskFormValuesInterface>) => {
    const newFieldValues: any = { ...fieldValues }
    console.log(11, newFieldValues?.projectId)
    if (!newFieldValues?.projectId) {
      delete newFieldValues.projectId
    }
    apiConnectAxios(
      taskId ? `/tasks/${taskId}` : '/tasks',
      taskId ? 'patch' : 'post',
      {
        ...newFieldValues,
        deadline: dayjs(fieldValues.deadline).toDate(),
        tags: fieldValues.tags?.split(', ') || [],
        files: fieldValues?.files || [],
      },
      true,
    )
      .then(() => {
        toast.success(taskId ? 'Task edited' : 'Task created')
        history.push('/tasks')
      })
      .catch(error => {
        setErrors(error?.response?.data?.errors || {})
        toast.error(errorResponseReturn(error))
      })
  }

  const title = taskId ? 'Edit Task' : 'Create Task'
  return (
    <div className='page-container-g'>
      <PageTitle title={taskId ? 'Edit Task' : 'Create Task'} />
      <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
        <Form className='direction_column-max-g' autoComplete='off'>
          <CreateTaskPageLogic />
          <Field name='projectId' label='Project' component={SelectArrField} optionArr={optionProjectArr} />
          <Field name='name' label='Name' component={FormField} />
          <Field name='description' label='Description' component={TextareaField} />
          <div className={styles.statusWrapper}>
            <Field name='taskStatusInternal' label='Status' component={SelectArrField} optionArr={optionTaskStatusInternalArr} />
            <Field name='label' label='Label' component={SelectArrField} optionArr={optionLabelArr} />
          </div>
          <BudgetTaskField />
          <Field name='deadline' label='Deadline' component={DateFormField} />
          <Field name='tags' label='Tags' placeholder='Add tags separated by commas' component={FormField} />
          <Field name='isAutomaticSendingNda' label='Enable document workflow' component={SendingNDACheckboxField} />
          <Field name='isRequiredContract' label='Contract Required' component={CheckboxField} />
          <Field name='files' component={LoadFileArrField} />
          <PinkButton title={title} type='submit' sx={{ margin: '90px auto 30px auto' }} />
        </Form>
      </Formik>
    </div>
  )
}

export default CreateTaskPage
