import React, { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryHook } from '@hooks/useQueryHook'
import { Button } from '@mui/material'
import styles from './TasksTableThead.module.scss'

const TasksTableThead: FC = () => {
  const query: URLSearchParams = useQueryHook()
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()
  const onClickDateHandler = (date: 'day' | 'week' | 'month') => (): void => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('date', date)
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }

  return (
    <thead>
      <tr>
        <th>
          <Button
            className={`${styles.button} ${((query.get('date') === 'day' || (query.get('date') != 'week' && query.get('date') != 'month')) && styles.button_active) || ''}`}
            type='button'
            onClick={onClickDateHandler('day')}
          >
            Day
          </Button>
          <Button
            className={`${styles.button} ${(query.get('date') === 'week' && styles.button_active) || ''}`}
            type='button'
            onClick={onClickDateHandler('week')}
          >
            Week
          </Button>
          <Button
            className={`${styles.button} ${(query.get('date') === 'month' && styles.button_active) || ''}`}
            type='button'
            onClick={onClickDateHandler('month')}
          >
            Month
          </Button>
        </th>
        <th>
          <div className={styles.thDiv}>Executor</div>
        </th>
        <th>
          <div className={styles.thDiv}>Status</div>
        </th>
        <th>
          <div className={styles.thDiv}>Due date</div>
        </th>
        <th>
          <div className={styles.thDiv}>Priority</div>
        </th>
      </tr>
    </thead>
  )
}

export default memo(TasksTableThead)
