import { put, takeLatest, all, call, select } from 'redux-saga/effects'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { toast } from 'react-hot-toast'
import errorResponseReturn from '@utils/errorResponseReturn'
import { loadingTaskAction, loadingTaskFailure, loadingTaskSuccess } from '@state/task/taskStateSlice'
import { PayloadAction } from '@reduxjs/toolkit'
import { createMatchSelector, push } from 'connected-react-router'

function* loadingTaskSaga({ payload: { isMember } }: PayloadAction<{ isMember?: boolean }>): Generator {
  try {
    const matchSelector = createMatchSelector({ path: ['/task/:taskId', '/task/:taskId/donations'] })
    const match: any = yield select(matchSelector)
    let queryParams: string = ''
    if (isMember) queryParams = `${queryParams}&isMember=true`
    const { data }: any = yield call(apiConnectAxios, `/tasks/${match?.params?.taskId}?${queryParams}`)
    if (!data) {
      yield put(push('/create-task'))
      toast.error('Task does not exist')
    }
    yield put(loadingTaskSuccess({ task: data }))
  } catch (error: any) {
    yield put(push('/create-task'))
    toast.error(errorResponseReturn(error))
    yield put(loadingTaskFailure(errorResponseReturn(error)))
  }
}

export default function* taskRootSaga() {
  yield all([takeLatest(loadingTaskAction.type, loadingTaskSaga)])
}
