import React, { FC } from 'react'
import AvatarUser from '@components/userComponents/StartsUserBox/AvatarUser/AvatarUser'
import { TextareaAutosize } from '@mui/base'
import { CommentByNewsInterface } from '@state/news/newsStateSlice'
import stTextarea from '../AddCommentNews/AddCommentNewsField/AddCommentNewsField.module.scss'
import styles from './CommentNews.module.scss'

interface Props {
  comment: CommentByNewsInterface
}
const CommentNews: FC<Props> = ({ comment }: Props) => (
  <div className={styles.wrapper}>
    <AvatarUser memberId={comment?.member?.memberId} avatar={comment?.member?.avatar} />
    <div className={styles.container}>
      {comment?.image && <img className={styles.img} src={comment?.image} alt='' />}
      <div className={`${stTextarea.wrapper} ${styles.textareaWrapper}`}>
        <TextareaAutosize className={`${stTextarea.textarea} ${styles.textarea}`} maxRows={5} value={comment?.text} disabled />
      </div>
    </div>
  </div>
)

export default CommentNews
