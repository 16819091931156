import React, { FC } from 'react'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import SearchUserRequests from '@pages/RequestsPage/SearchUserRequests/SearchUserRequests'
import RequestsSidebar from './RequestsSidebar/RequestsSidebar'
import RequestsChatAside from './RequestsChatAside/RequestsChatAside'
import styles from './RequestsPage.module.scss'
const RequestsPage: FC = () => (
  <>
    <HelmetTitle title='Requests' />
    <SearchUserRequests />
    <div className={styles.wrapper}>
      <RequestsSidebar />
      <RequestsChatAside />
    </div>
  </>
)

export default RequestsPage
