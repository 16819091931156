import { FC, memo, useEffect, useState } from 'react'
import { AppBar, Container, Link, Toolbar } from '@mui/material'
import { getAuth, onAuthStateChanged, User } from 'firebase/auth'
import Cookies from 'universal-cookie'
import { LoggedInSection } from './LoggedInSection'
import { LoggedOutSection } from './LoggedOutSection'
import HeaderSearchInput from '@components/Header/HeaderSearchInput/HeaderSearchInput'
import { useAuth } from 'layouts/AuthContext'
import { ProfileInterface } from '@state/profile/profileStateSlice'

const Header: FC = () => {
  const { getMemberProfile } = useAuth()
  const auth = getAuth()
  const cookie = new Cookies()
  const [profile, setProfile] = useState<ProfileInterface | null>(() => null)
  const [isLogged, setIsLogged] = useState<null | boolean>(() => {
    const logged = window.sessionStorage.getItem('logged')
    if (logged) {
      return true
    }
    return null
  })

  const updateUserIdToken = (user: User) => {
    user.getIdToken().then((token: string) => {
      cookie.set('access-token', token, { path: '/' })
      window.sessionStorage.setItem('logged', 'true')
    })
  }

  useEffect(() => {
    setProfile(getMemberProfile())
  })
  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        updateUserIdToken(user) // User is logged in set idToken to cookie
        setIsLogged(true)
        setInterval(() => {
          updateUserIdToken(user) // Refresh the idToken every 55 minutes
        }, 3360000)
      } else {
        cookie.remove('access-token')
        window.sessionStorage.removeItem('logged')
        setIsLogged(false)
      }
    })
  }, [])

  return (
    <AppBar
      position='relative'
      sx={{
        background: 'rgba(0,0,0,0)',
        borderBottom: 'solid 1px rgba(255,255,255,.1)',
      }}
    >
      <Container disableGutters={true}>
        <Toolbar disableGutters={true} sx={{ padding: '0 20px', justifyContent: 'space-between' }}>
          <Link href='/' sx={{ minHeight: '106px', display: 'flex', alignItems: 'center', mr: '40px' }}>
            <img src={'../images/logo.png'} alt='' />
          </Link>
          <HeaderSearchInput />
          {isLogged ? <LoggedInSection memberProfile={profile} /> : <LoggedOutSection />}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default memo(Header)
