import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MemberInterface } from '@state/profile/profileStateSlice'
import { RequestInterface } from '@state/requestArr/requestArrStateSlice'

export enum TypeDocumentEnum {
  NDA = 'NDA',
  Contract = 'Contract',
}

export enum AreaDocumentEnum {
  Project = 'Project',
  Task = 'Task',
}

export enum LegalDocumentEnum {
  C2B = 'C2B',
  B2B = 'B2B',
  C2C = 'C2C',
}
export enum StageDocumentEnum {
  Start = 'Start',
  OnCreatorSign = 'OnCreatorSign',
  OnExecutorSign = 'OnExecutorSign',
  BothSigned = 'BothSigned',
}

export interface DocumentRequestInterface {
  id: number
  requestId: number
  request?: RequestInterface
  clientId: number
  client: MemberInterface // who created the task or project
  performerId: number
  performer: MemberInterface // who created this request
  type: TypeDocumentEnum
  legal: LegalDocumentEnum
  stage: StageDocumentEnum
  area: AreaDocumentEnum
  name: string
  firstFileSource: string
  lastFileSource: string
  size: string
  createdAt: Date
}

export interface DocumentArrStateInterface {
  isDocumentArrLoading: boolean
  isDocumentArrFirstLoading: boolean
  documentArr: DocumentRequestInterface[]
  documentCount: number
}

const initialDocumentArrState: DocumentArrStateInterface = {
  isDocumentArrLoading: true,
  isDocumentArrFirstLoading: false,
  documentArr: [],
  documentCount: 0,
}

const documentArrState = createSlice({
  name: 'documentArrState',
  initialState: initialDocumentArrState,
  reducers: {
    loadingDocumentArrAction(state) {
      state.isDocumentArrLoading = true
    },
    loadingDocumentArrFailure(state) {
      state.isDocumentArrLoading = false
      state.isDocumentArrFirstLoading = true
    },
    loadingDocumentArrSuccess(
      state,
      { payload: { documentArr, documentCount } }: PayloadAction<{ documentArr: DocumentRequestInterface[]; documentCount: number }>,
    ) {
      state.documentArr = documentArr
      state.documentCount = documentCount
      state.isDocumentArrLoading = false
      state.isDocumentArrFirstLoading = true
    },
  },
})

export const { loadingDocumentArrAction, loadingDocumentArrFailure, loadingDocumentArrSuccess } = documentArrState.actions

export default documentArrState.reducer
