import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import AvatarUser from '@components/userComponents/StartsUserBox/AvatarUser/AvatarUser'
import UserBalance from '@components/userComponents/UserBalance/UserBalance'
import PinkButton from '@buttons/PinkButton/PinkButton'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import styles from './TransactionsBalanceAside.module.scss'

const TransactionsBalanceAside: FC = () => {
  const { profile, isProfileLoading }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Balance</h2>
      <div className={styles.avatarWrapper}>
        {!isProfileLoading && <AvatarUser memberId={profile?.memberId} avatar={profile?.avatar} sizePx={144} />}
      </div>
      <div className={styles.firstName}>{`${profile?.firstName || '\u00A0'} ${profile?.lastName || ''}`}</div>
      <div className={styles.profession}>Graphic Design &nbsp;&nbsp;|&nbsp;&nbsp; Web-Design</div>
      <UserBalance isSmall />
      <PinkButton title='Top up the balance' sx={{ margin: 'auto auto 0 auto' }} />
    </div>
  )
}

export default memo(TransactionsBalanceAside)
