import AvatarUser from '@components/userComponents/StartsUserBox/AvatarUser/AvatarUser'
import StartsUser from '@components/userComponents/StartsUserBox/StartsUser/StartsUser'
import { ProjectInterface } from '@state/project/projectStateSlice'
import { TaskInterface } from '@state/task/taskStateSlice'
import dayjs from 'dayjs'
import { FC } from 'react'
import styles from './RequestProjectAside.module.scss'
interface Props {
  pageContent: TaskInterface | ProjectInterface | null | any
}

const RequestProjectAside: FC<Props> = ({ pageContent }: Props) => (
  <div className={styles.requestWrapper}>
    <div className={styles.timecoinWrapper}>
      <div className={`${styles.timecoin} ${pageContent?.skillCoinsTotal >= 1000 && styles.timecoin_small}`}>
        {(!pageContent && '\u00A0') || (pageContent?.skillCoinsTotal && Math.trunc(pageContent?.skillCoinsTotal)) || 0}
      </div>
      <div className={styles.timecoinName}>SC</div>
    </div>
    <div className={styles.timeDescription}>Can you do this Request in 2 hours?</div>
    <div className={styles.profileWrapper}>
      <AvatarUser memberId={pageContent?.memberId} avatar={pageContent?.member?.avatar} sizePx={56} />
      <div className={styles.firstNameWrapper}>
        <div className={styles.firstName}>{`${pageContent?.member?.firstName || '\u00A0'} ${pageContent?.member?.lastName || '\u00A0'}`}</div>
        <StartsUser rating={Number((Math.random() * 5).toFixed(1))} reviews={Math.floor(Math.random() * 99)} isBig />
      </div>
    </div>
    <div
      className={styles.requestDate}
    >{`${pageContent?.member?.createdAt ? `This Request expired on ${dayjs(pageContent?.member?.createdAt).format('MMM D, YYYY')}.` : '\u00A0'}`}</div>
  </div>
)

export default RequestProjectAside
