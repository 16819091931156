import React, { FC, memo, useState } from 'react'
import { toast } from 'react-hot-toast'
import * as yup from 'yup'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { IconButton } from '@mui/material'
import TelegramIcon from '@mui/icons-material/Telegram'
import TasksTableTbodyField from './TasksTableTbodyField/TasksTableTbodyField'
import { minLengthValidationVar, requiredValidationVar } from '@utils/fieldsValidationVariables'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { ExecutorProjectInterface, ExecutorTaskInterface } from '@state/executorTaskArr/executorTaskArrStateSlice'
import { ReactComponent as EditSvgIcon } from '@icons/edit2Icon.svg'
import styles from './TasksTableTbodyForm.module.scss'

interface Props {
  executorTask: ExecutorTaskInterface | ExecutorProjectInterface
}

interface FieldValuesInterface {
  name: string
}

const validationSchema = yup.object().shape({
  name: yup.string().required(requiredValidationVar).min(minLengthValidationVar.min, minLengthValidationVar.message),
})

const TasksTableTbodyForm: FC<Props> = ({ executorTask }: Props) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const onClickEditHandler = () => {
    setIsDisabled(false)
  }
  const initialValues: FieldValuesInterface = {
    name: executorTask?.name || '',
  }
  const submitHandler = async (fieldValues: FieldValuesInterface, { setErrors, setTouched }: FormikHelpers<FieldValuesInterface>) => {
    apiConnectAxios('/tasks/name', 'patch', { name: fieldValues?.name, requestId: executorTask?.requestId }, true)
      .then(() => {
        toast.success('Name changed')
        setIsDisabled(true)
        setTouched({ name: false })
      })
      .catch(error => {
        setErrors(error?.response?.data?.errors || {})
        toast.error(errorResponseReturn(error))
      })
  }

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
      <Form className={styles.inputWrapper} autoComplete='off'>
        <Field name='name' component={TasksTableTbodyField} isDisabled={isDisabled} />
        {isDisabled && (
          <IconButton className={styles.editButton} type='button' onClick={onClickEditHandler}>
            <EditSvgIcon />
          </IconButton>
        )}
        <IconButton className={`${styles.editButton} ${(isDisabled && 'hide-g') || ''}`} type='submit'>
          <TelegramIcon className={styles.telegramIcon} />
        </IconButton>
      </Form>
    </Formik>
  )
}

export default memo(TasksTableTbodyForm)
