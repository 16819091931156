import React from 'react'
import Button from '@mui/material/Button'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import styles from './SeeMoreButton.module.scss'

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  isMarginBottom?: boolean
}
const SeeMoreButton: React.FC<Props> = ({ onClick, isMarginBottom }: Props) => (
  <div className={styles.buttonWrapper}>
    <Button className={`${styles.button} ${(isMarginBottom && styles.button_bottom) || ''}`} onClick={onClick} type='button'>
      <span>see more</span>
      <KeyboardDoubleArrowDownIcon />
    </Button>
  </div>
)

export default SeeMoreButton
