import { put, takeLatest, all, call, select } from 'redux-saga/effects'
import { toast } from 'react-hot-toast'
import { loadingProjectArrAction, loadingProjectArrFailure, loadingProjectArrSuccess } from '@state/projectArr/projectArrStateSlice'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { createMatchSelector } from 'connected-react-router'

function* loadingProjectArrSaga(): Generator {
  try {
    const matchSelector = createMatchSelector({ path: ['/projects'] })
    const match: any = yield select(matchSelector)
    let queryParams: string = ''
    if (match?.path === '/projects') {
      queryParams = '?isMemberProjects=true'
    }
    const { data }: any = yield call(apiConnectAxios, `/projects${queryParams}`)
    yield put(loadingProjectArrSuccess({ projectArr: data?.projectArr }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingProjectArrFailure(errorResponseReturn(error)))
  }
}

export default function* projectArrRootSaga() {
  yield all([takeLatest(loadingProjectArrAction.type, loadingProjectArrSaga)])
}
