import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import PagePagination from '@components/PagePagination/PagePagination'
import EmptyText from '@components/Text/EmptyText/EmptyText'
import LoadingTransparentSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import OffersTableThead from './OffersTableThead/OffersTableThead'
import OffersTableTbody from './OffersTableTbody/OffersTableTbody'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ContractArrStateInterface } from '@state/contractArr/contractArrStateSlice'
import styles from './OffersPage.module.scss'

const OffersPage: FC = () => {
  const { contractCount, isContractArrLoading, isContractArrFirstLoading }: ContractArrStateInterface = useSelector(
    (state: AppStateInterface) => state.contractArrState,
  )
  return (
    <>
      <HelmetTitle title='Offers' />
      <div className={styles.wrapper}>
        <LoadingTransparentSpin isLoading={isContractArrLoading} />
        <table className={styles.table}>
          <OffersTableThead />
          <OffersTableTbody />
        </table>
        {isContractArrFirstLoading && !contractCount && <EmptyText text='Contract' />}
        {contractCount > 10 && <PagePagination pageCount={Math.ceil(contractCount / 10)} />}
      </div>
    </>
  )
}

export default OffersPage
