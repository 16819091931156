import { FC, memo, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { loadingDocumentRequestArrAction, RequestArrStateInterface } from '@state/requestArr/requestArrStateSlice'
import {
  DocumentTemplateStateInterface,
  loadingProjectDocumentTemplateAction,
  loadingTaskDocumentTemplateAction,
} from '@state/documentTemplate/documentTemplateStateSlice'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { LegalDocumentEnum, TypeDocumentEnum } from '@state/documentArr/documentArrStateSlice'

const AddDocumentRequestsFormLogic: FC = () => {
  const { setValues, setErrors, setTouched } = useFormikContext()
  const { isProfileLoading, isProfileFirstLoading, profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const { isMessageArrLoading, isMessageArrFirstLoading, requestChat }: RequestArrStateInterface = useSelector(
    (state: AppStateInterface) => state.requestArrState,
  )
  const { isDocumentRequestLoading, isDocumentRequestFirstLoading, documentRequest }: RequestArrStateInterface = useSelector(
    (state: AppStateInterface) => state.requestArrState,
  )
  const {
    documentTemplateTask,
    isDocumentTemplateTaskLoading,
    isDocumentTemplateTaskFirstLoading,
    documentTemplateProject,
    isDocumentTemplateProjectLoading,
    isDocumentTemplateProjectFirstLoading,
  }: DocumentTemplateStateInterface = useSelector((state: AppStateInterface) => state.documentTemplateState)
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const isProjectRequestPage: boolean = url?.includes('project')
  useEffect(() => {
    dispatch(loadingDocumentRequestArrAction())
    if (isProjectRequestPage) {
      dispatch(loadingProjectDocumentTemplateAction())
    } else {
      dispatch(loadingTaskDocumentTemplateAction())
    }
  }, [isProjectRequestPage, dispatch])
  useEffect(() => {
    if (
      !isProfileLoading &&
      isProfileFirstLoading &&
      !isMessageArrLoading &&
      isMessageArrFirstLoading &&
      requestChat?.clientId === profile?.memberId &&
      !isDocumentRequestLoading &&
      isDocumentRequestFirstLoading
    ) {
      if (!isProjectRequestPage && !isDocumentTemplateTaskLoading && isDocumentTemplateTaskFirstLoading && documentTemplateTask) {
        console.log(111)
        setValues({
          text: '',
          file: documentTemplateTask?.documentTemplate?.fileSource || '',
          name: documentTemplateTask?.documentTemplate?.name || '',
          legal: documentTemplateTask?.documentTemplate?.legal || LegalDocumentEnum?.B2B,
          size: documentTemplateTask?.documentTemplate?.size || '',
          type: TypeDocumentEnum?.NDA,
        })
        setTouched({})
        setErrors({})
      } else if (isProjectRequestPage && !isDocumentTemplateProjectLoading && isDocumentTemplateProjectFirstLoading && documentTemplateProject) {
        setValues({
          text: '',
          file: documentTemplateProject?.documentTemplate?.fileSource || '',
          name: documentTemplateProject?.documentTemplate?.name || '',
          legal: documentTemplateProject?.documentTemplate?.legal || LegalDocumentEnum?.B2B,
          size: documentTemplateProject?.documentTemplate?.size || '',
          type: TypeDocumentEnum?.NDA,
        })
        setTouched({})
        setErrors({})
      }
    }
  }, [
    isProfileLoading,
    isProfileFirstLoading,
    profile,
    isMessageArrLoading,
    isMessageArrFirstLoading,
    requestChat,
    isDocumentRequestLoading,
    isDocumentRequestFirstLoading,
    isProjectRequestPage,
    isDocumentTemplateTaskLoading,
    isDocumentTemplateTaskFirstLoading,
    isDocumentTemplateProjectLoading,
    isDocumentTemplateProjectFirstLoading,
    documentRequest,
    documentTemplateTask,
    documentTemplateProject,
    setValues,
  ])

  return null
}

export default memo(AddDocumentRequestsFormLogic)
