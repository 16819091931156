import { put, takeLatest, all, select, call } from 'redux-saga/effects'
import { loadingUserAction, loadingUserFailure, loadingUserSuccess } from '@state/user/userStateSlice'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { toast } from 'react-hot-toast'
import { createMatchSelector, push } from 'connected-react-router'
import { MatchPathInterface } from '@interfaces/interfaces'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import errorResponseReturn from '@utils/errorResponseReturn'

function* loadingUserSaga(): Generator {
  try {
    const matchSelector = createMatchSelector({ path: ['/user/:id', '/followers/:id', '/following/:id'] })
    // @ts-ignore
    const match: MatchPathInterface = yield select(matchSelector)
    const { data }: any = yield call(apiConnectAxios, `/members/${match?.params?.id}`)
    // @ts-ignore
    const { profile }: ProfileStateInterface = yield select(state => state.profileState)
    if (profile?.id && profile.id == data?.id) yield put(push('/settings'))
    else yield put(loadingUserSuccess({ userProfile: data }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingUserFailure(errorResponseReturn(error)))
    yield put(push('/settings'))
  }
}

export default function* userRootSaga() {
  yield all([takeLatest(loadingUserAction.type, loadingUserSaga)])
}
