import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MemberInterface } from '@state/profile/profileStateSlice'

export interface FollowerStateInterface {
  isProfileFollowerLoading: boolean
  isProfileFollowerFirstLoading: boolean
  profileFollowerArr: MemberInterface[] | []
  profileFollowerCount: number
  profileFollowingArr: MemberInterface[] | []
  profileFollowingCount: number
  profileFollowingIdArr: number[] | []
  isUserFollowerLoading: boolean
  isUserFollowerFirstLoading: boolean
  userFollowerArr: MemberInterface[] | []
  userFollowerCount: number
  userFollowingArr: MemberInterface[] | []
  userFollowingCount: number
}

const initialFollowerState: FollowerStateInterface = {
  isProfileFollowerLoading: true,
  isProfileFollowerFirstLoading: false,
  profileFollowerArr: [],
  profileFollowerCount: 0,
  profileFollowingArr: [],
  profileFollowingCount: 0,
  profileFollowingIdArr: [],
  isUserFollowerLoading: true,
  isUserFollowerFirstLoading: false,
  userFollowerArr: [],
  userFollowerCount: 0,
  userFollowingArr: [],
  userFollowingCount: 0,
}

const followerState = createSlice({
  name: 'followerState',
  initialState: initialFollowerState,
  reducers: {
    loadingProfileFollowerAction(state) {
      state.isProfileFollowerLoading = true
    },
    loadingProfileFollowerFailure(state) {
      state.isProfileFollowerLoading = false
      state.isProfileFollowerFirstLoading = true
    },
    loadingProfileFollowerSuccess(
      state,
      {
        payload: { profileFollowerArr, profileFollowerCount, profileFollowingArr, profileFollowingCount, profileFollowingIdArr },
      }: PayloadAction<{
        profileFollowerArr: MemberInterface[] | []
        profileFollowerCount: number
        profileFollowingArr: MemberInterface[] | []
        profileFollowingCount: number
        profileFollowingIdArr: number[] | []
      }>,
    ) {
      state.profileFollowerArr = profileFollowerArr
      state.profileFollowerCount = profileFollowerCount
      state.profileFollowingArr = profileFollowingArr
      state.profileFollowingCount = profileFollowingCount
      state.profileFollowingIdArr = profileFollowingIdArr
      state.isProfileFollowerLoading = false
      state.isProfileFollowerFirstLoading = true
    },

    createProfileFollowerAction(state, {}: PayloadAction<{ memberId: number }>) {
      state.isProfileFollowerLoading = true
    },
    createProfileFollowerFailure(state) {
      state.isProfileFollowerLoading = false
      state.isProfileFollowerFirstLoading = true
    },
    createProfileFollowerSuccess(state) {
      state.isProfileFollowerLoading = false
      state.isProfileFollowerFirstLoading = true
    },

    unsubscribeProfileFollowerAction(state, {}: PayloadAction<{ memberId: number }>) {
      state.isProfileFollowerLoading = true
    },
    unsubscribeProfileFollowerFailure(state) {
      state.isProfileFollowerLoading = false
      state.isProfileFollowerFirstLoading = true
    },

    loadingUserFollowerAction(state) {
      state.isUserFollowerLoading = true
    },
    loadingUserFollowerFailure(state) {
      state.isUserFollowerLoading = false
      state.isUserFollowerFirstLoading = true
    },
    loadingUserFollowerSuccess(
      state,
      {
        payload: { userFollowerArr, userFollowerCount, userFollowingArr, userFollowingCount },
      }: PayloadAction<{
        userFollowerArr: MemberInterface[] | []
        userFollowerCount: number
        userFollowingArr: MemberInterface[] | []
        userFollowingCount: number
      }>,
    ) {
      state.userFollowerArr = userFollowerArr
      state.userFollowerCount = userFollowerCount
      state.userFollowingArr = userFollowingArr
      state.userFollowingCount = userFollowingCount
      state.isUserFollowerLoading = false
      state.isUserFollowerFirstLoading = true
    },
  },
})

export const {
  loadingProfileFollowerAction,
  loadingProfileFollowerFailure,
  loadingProfileFollowerSuccess,

  createProfileFollowerSuccess,
  createProfileFollowerFailure,
  createProfileFollowerAction,

  unsubscribeProfileFollowerAction,
  unsubscribeProfileFollowerFailure,

  loadingUserFollowerAction,
  loadingUserFollowerFailure,
  loadingUserFollowerSuccess,
} = followerState.actions

export default followerState.reducer
