import React, { FC } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import ProjectsSelect from './ProjectsSelect/ProjectsSelect'
import ProjectsSearchInput from './ProjectsSearchInput/ProjectsSearchInput'
import ProjectsExecutorSelect from './ProjectsExecutorSelect/ProjectsExecutorSelect'
import TasksUserPopoverBox from '@pages/TasksPage/TasksUserPopoverBox/TasksUserPopoverBox'
import LoadingTransparentSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import ProjectsTableThead from './ProjectsTableThead/ProjectsTableThead'
import ProjectsTableTbody from './ProjectsTableTbody/ProjectsTableTbody'
import EmptyText from '@components/Text/EmptyText/EmptyText'
import PagePagination from '@components/PagePagination/PagePagination'
import { ExecutorTaskArrStateInterface } from '@state/executorTaskArr/executorTaskArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import styles from './ProjectsPage.module.scss'

interface linkArrInterface {
  link: string
  title: string
}

const linkArr: linkArrInterface[] = [
  { link: '/projects', title: 'Projects' },
  { link: '/news', title: 'News' },
]

const ProjectsPage: FC = () => {
  const { isStatusTaskArrLoading, isExecutorTaskArrFirstLoading, executorTaskCount }: ExecutorTaskArrStateInterface = useSelector(
    (state: AppStateInterface) => state.executorTaskArrState,
  )
  const { url } = useRouteMatch()
  return (
    <>
      <HelmetTitle title={url === linkArr[0].link ? linkArr[0].title : linkArr[1].title} />
      <div className='page-container-g'>
        <div className={styles.linkWrapper}>
          {linkArr.map(({ link, title }: linkArrInterface) => (
            <Link key={link} className={`${styles.link} ${(url === link && styles.link_active) || ''}`} to={link}>
              <span className={styles.linkText}>{title}</span>
              <span className={styles.linkGradient}>{title}</span>
            </Link>
          ))}
          <ProjectsSelect />
        </div>
        <div className={styles.searchWrapper}>
          <ProjectsSearchInput />
          <ProjectsExecutorSelect />
          <TasksUserPopoverBox />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingTransparentSpin isLoading={isStatusTaskArrLoading} />
          <ProjectsTableThead />
          <ProjectsTableTbody />
          {isExecutorTaskArrFirstLoading && !executorTaskCount && <EmptyText text='Task' />}
          {executorTaskCount > 10 && <PagePagination pageCount={Math.ceil(executorTaskCount / 10)} />}
        </div>
      </div>
    </>
  )
}

export default ProjectsPage
