import { styled } from '@mui/material'

export const MultilineFormField = styled('textarea')`
  line-height: 1.5em;
  padding-left: 30px;
  width: 100%;
  background-color: #271156ab;
  border: none;
  border-radius: 5px;
  color: #ada2c3;
  font-size: 16px;
  height: 140px;
  padding-top: 15px;
  resize: none;
`
