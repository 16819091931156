import React, { FC } from 'react'
import { FieldInputProps, FormikProps, useField } from 'formik'
import { Select, SelectChangeEvent } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { openHook } from '@hooks/openHook'
import { OptionIconArrInterface } from '@interfaces/interfaces'
import styles from '../FormField/FormField.module.scss'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  label: string
  optionArr: OptionIconArrInterface[]
}

const SelectArrField: FC<Props> = ({ field: { name, value }, form: { touched, errors }, label, optionArr }: Props) => {
  const { isOpen, openHandler, closeHandler } = openHook()
  const [, , { setValue }] = useField(name)
  const changeValueHandler = (event: SelectChangeEvent) => {
    setValue(event.target.value)
  }
  return (
    <div className='column-align_start-g'>
      <label htmlFor='menu' className={`${styles.title} label-title-g`} onClick={openHandler}>
        {label}
      </label>
      <Select
        className={`${styles.select} ${touched?.[name] && errors?.[name] && styles.input__error}`}
        open={isOpen}
        defaultValue={value || ''}
        value={value || ''}
        onChange={changeValueHandler}
        onClose={closeHandler}
        onOpen={openHandler}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{ MenuListProps: { style: { background: 'rgba(30, 14, 63)' } } }}
      >
        {optionArr?.map(({ label, value, icon }: OptionIconArrInterface) => (
          <MenuItem className='flex-g' key={value} value={String(value)}>
            <div className={styles.iconWrapper}>
              {icon && <img className={styles.icon} src={icon} alt='' />}
              {label}
            </div>
          </MenuItem>
        ))}
      </Select>
      <span className={styles.error}>{`${(touched?.[name] && errors?.[name]) || '\u00A0'}`}</span>
    </div>
  )
}

export default SelectArrField
