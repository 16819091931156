import React, { FC, useId, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import Popper from '@mui/material/Popper'
import Box from '@mui/material/Box'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { OptionArrInterface } from '@interfaces/interfaces'
import { useQueryHook } from '@hooks/useQueryHook'
import styles from './SearchUserRequestsButton.module.scss'

const rankRadioOptionArr: OptionArrInterface[] = [
  {
    label: 'Will see',
    value: 'WillWee',
  },
  {
    label: 'Best',
    value: 'Best',
  },
  {
    label: 'Not fit',
    value: 'NotFit',
  },
]

const statusRadioOptionArr: OptionArrInterface[] = [
  {
    label: 'Created',
    value: 'Created',
  },
  {
    label: 'Approved',
    value: 'Approved',
  },
  {
    label: 'Canceled',
    value: 'Canceled',
  },
  {
    label: 'Denied',
    value: 'Denied',
  },
]

interface Props {
  isRankButton?: boolean
}

const SearchUserRankButton: FC<Props> = ({ isRankButton }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [arrowRef, setArrowRef] = useState(null)
  const openHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const closeHandler = () => {
    setAnchorEl(null)
  }
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()

  const query: URLSearchParams = useQueryHook()
  const changeRadioHandler = (paramsName: string) => () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set(paramsName, query.get(paramsName) === 'false' ? 'true' : 'false')
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  const isOpen: boolean = Boolean(anchorEl)
  const popperId: string = useId()
  const id: string = useId()
  const radioOptionArr: OptionArrInterface[] = isRankButton ? rankRadioOptionArr : statusRadioOptionArr
  return (
    <>
      <Button
        className={`${styles.rankButton} ${(!isRankButton && styles.statusButton) || ''}`}
        type='button'
        onClick={openHandler}
        aria-describedby={popperId}
      >
        <span>{isRankButton ? 'Rank' : 'Status'}</span>
        <KeyboardArrowDownIcon className={`${styles.downIcon} ${isOpen && styles.downIcon_up}`} />
      </Button>
      <Popper
        id={popperId}
        open={isOpen}
        anchorEl={anchorEl}
        className={styles.popover}
        placement='bottom-start'
        modifiers={[{ name: 'arrow', enabled: true, options: { element: arrowRef } }]}
      >
        <Box component='span' ref={setArrowRef} className={styles.arrow} />
        <div className={styles.popoverContainer}>
          {radioOptionArr.map(({ value, label }: OptionArrInterface) => (
            <label htmlFor={`${id}${value}`} key={value} className={styles.radioWrapper}>
              <input
                id={`${id}${value}`}
                name={value}
                value={value}
                className={styles.radio}
                type='radio'
                checked={query.get(value) !== 'false'}
                onClick={changeRadioHandler(value)}
              />
              <div className={styles.radioButton} />
              {label}
            </label>
          ))}
        </div>
      </Popper>
      <div className={`${styles.fixed} ${(isOpen && styles.fixed_show) || ''}`} onClick={closeHandler} />
    </>
  )
}

export default SearchUserRankButton
