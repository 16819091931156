import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import StartsUserBox from '@components/userComponents/StartsUserBox/StartsUserBox'
import BluePopover, { PopoverInterface } from '@components/popovers/BluePopover/BluePopover'
import { MemberInterface } from '@state/profile/profileStateSlice'
import { createProfileFollowerAction, unsubscribeProfileFollowerAction } from '@state/follower/followerStateSlice'
import styles from './ProfileFollowerItem.module.scss'

interface Props {
  isFirstLoading: boolean
  follower: MemberInterface
  followerStatus: 'Follow' | 'Following'
  deleteMemberId?: number
}

const ProfileFollowerItem: FC<Props> = ({ isFirstLoading, follower, followerStatus, deleteMemberId }: Props) => {
  const dispatch = useDispatch()
  const handleCreateFollower = () => {
    if (followerStatus === 'Follow' && follower?.memberId) {
      dispatch(createProfileFollowerAction({ memberId: follower.memberId }))
    }
  }
  const clickUnsubscribeFollower = () => {
    if (deleteMemberId && followerStatus === 'Following') dispatch(unsubscribeProfileFollowerAction({ memberId: deleteMemberId }))
  }
  const popoverArr: PopoverInterface[] = [{ title: 'Unsubscribe from user', clickFunction: clickUnsubscribeFollower }]
  const buttonRender = (id: string, openHandler: (event: React.MouseEvent<HTMLButtonElement>) => void) => (
    <div className={styles.buttonWrapper}>
      <button
        className={`${styles.dotsButton} ${(followerStatus === 'Following' && styles.dotsButton_show) || ''}`}
        aria-describedby={id}
        type='button'
        onClick={openHandler}
      >
        <MoreHorizIcon className={styles.dots} />
      </button>
    </div>
  )
  return (
    <div className={styles.wrapper}>
      <StartsUserBox
        memberId={follower?.memberId}
        avatar={follower?.avatar}
        firstName={follower?.firstName}
        lastName={follower?.lastName}
        rating={Number((Math.random() * 5).toFixed(1))}
        reviews={Math.floor(Math.random() * 99)}
      />
      {isFirstLoading && (
        <Button className={styles[followerStatus.toLowerCase()]} onClick={handleCreateFollower} disabled={followerStatus === 'Following'}>
          <span className='z-index-5-g'>{followerStatus}</span>
        </Button>
      )}
      {isFirstLoading && <BluePopover buttonRender={buttonRender} popoverArr={popoverArr} />}
    </div>
  )
}

export default ProfileFollowerItem
