import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Popover from '@mui/material/Popover'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import SeeMoreButton from '@buttons/SeeMoreButton/SeeMoreButton'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { loadingTaskArrAction, TaskArrStateInterface } from '@state/taskArr/taskArrStateSlice'
import { TaskInterface } from '@state/task/taskStateSlice'
import styles from './RequestProjectTasksPopover.module.scss'
import linkStyles from '../RequestProjectTasksAside.module.scss'

const RequestProjectTasksPopover: React.FC = () => {
  const dispatch = useDispatch()
  const { taskArr, isTaskArrLoading }: TaskArrStateInterface = useSelector((state: AppStateInterface) => state.taskArrState)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    dispatch(loadingTaskArrAction({}))
  }
  const closeHandler = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  return (
    <>
      <SeeMoreButton onClick={clickHandler} isMarginBottom />
      <Popover
        className={styles.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={closeHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={styles.wrapper}>
          <LoadingBlockSpin isLoading={isTaskArrLoading} />
          <h3 className={linkStyles.title}>join our Tasks</h3>
          {!!taskArr.length &&
            taskArr?.map((task: TaskInterface) => (
              <div key={task?.id} className={linkStyles.linkWrapper}>
                <div className={linkStyles.linkBorder} />
                <Link className={linkStyles.link} to={`/task-request/${task?.id}`}>
                  {task?.name}
                </Link>
              </div>
            ))}
        </div>
      </Popover>
    </>
  )
}

export default RequestProjectTasksPopover
