export const faqs = [
  {
    question: 'What is NFT Token?',
    answer:
      'NFT is a utility token based on the ERC20 standard. All transactions on the NFT platform will be carried out in trading. The NFT token will be freely tradable on major exchanges and is fully compatible with Ethereum wallets.',
  },
  {
    question: 'What is NFT Token?',
    answer:
      'Below are some common frequently asked questionshendrerit justo quisque quis.Below are some common frequently asked questionshendrerit justo quisque quis.',
  },
  {
    question: 'What is NFT Token?',
    answer:
      'NFT is a utility token based on the ERC20 standard. All transactions on the NFT platform will be carried out in trading. The NFT token will be freely tradable on major exchanges and is fully compatible with Ethereum wallets.',
  },
  {
    question: 'What is NFT Token?',
    answer:
      'NFT is a utility token based on the ERC20 standard. All transactions on the NFT platform will be carried out in trading. The NFT token will be freely tradable on major exchanges and is fully compatible with Ethereum wallets.',
  },
  {
    question: 'What is NFT Token?',
    answer:
      'NFT is a utility token based on the ERC20 standard. All transactions on the NFT platform will be carried out in trading. The NFT token will be freely tradable on major exchanges and is fully compatible with Ethereum wallets.',
  },
  {
    question: 'What is NFT Token?',
    answer:
      'NFT is a utility token based on the ERC20 standard. All transactions on the NFT platform will be carried out in trading. The NFT token will be freely tradable on major exchanges and is fully compatible with Ethereum wallets.',
  },
]

export const nft = [
  {
    question: 'What is NFT?',
    answer:
      'NFT is a utility token based on the ERC20 standard. All transactions on the NFT platform will be carried out in trading. The NFT token will be freely tradable on major exchanges and is fully compatible with Ethereum wallets.',
  },
  {
    question: 'What is Token?',
    answer:
      'NFT is a utility token based on the ERC20 standard. All transactions on the NFT platform will be carried out in trading. The NFT token will be freely tradable on major exchanges and is fully compatible with Ethereum wallets.',
  },
  {
    question: 'What is NFT Token?',
    answer:
      'NFT is a utility token based on the ERC20 standard. All transactions on the NFT platform will be carried out in trading. The NFT token will be freely tradable on major exchanges and is fully compatible with Ethereum wallets.',
  },
  {
    question: 'What is NFT Token?',
    answer:
      'NFT is a utility token based on the ERC20 standard. All transactions on the NFT platform will be carried out in trading. The NFT token will be freely tradable on major exchanges and is fully compatible with Ethereum wallets.',
  },
  {
    question: 'What is NFT Token?',
    answer:
      'NFT is a utility token based on the ERC20 standard. All transactions on the NFT platform will be carried out in trading. The NFT token will be freely tradable on major exchanges and is fully compatible with Ethereum wallets.',
  },
  {
    question: 'What is NFT Token?',
    answer:
      'NFT is a utility token based on the ERC20 standard. All transactions on the NFT platform will be carried out in trading. The NFT token will be freely tradable on major exchanges and is fully compatible with Ethereum wallets.',
  },
]
