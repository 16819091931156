import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileFormValuesInterface } from '@pages/ProfileSettingsPage/ProfileSettingsForm/ProfileSettingsForm'
import { FormikErrors } from 'formik/dist/types'

// enum MemberStatus {
//   Active = 'Active',
//   Blocked = 'Blocked',
// }

export enum GlobalRoles {
  Administrator = 'Global Administrator',
  Moderator = 'Global Moderator',
  Member = 'Global Member',
}

export interface MemberInterface {
  id?: number
  memberId?: number
  firstName: string | null
  lastName: string | null
  bio: string | null
  facebookLink: string | null
  twitterLink: string | null
  linkedinLink: string | null
  websiteLink: string | null
  location: string | null
  skills: string[] | null
  avatar?: string | null
  email?: string
  createdAt?: Date
  updatedAt?: Date
}

export interface ProfileInterface extends MemberInterface {
  UID: string | null
  balance: number
}

export const initialProfile: ProfileInterface = {
  UID: null,
  balance: 0,
  firstName: '',
  lastName: '',
  bio: '',
  facebookLink: '',
  twitterLink: '',
  linkedinLink: '',
  websiteLink: '',
  location: '',
  skills: [],
}

export interface ProfileStateInterface {
  isProfileLoading: boolean
  isProfileFirstLoading: boolean
  profile: ProfileInterface
}

const initialProfileState: ProfileStateInterface = {
  isProfileLoading: true,
  isProfileFirstLoading: false,
  profile: initialProfile,
}

const profileState = createSlice({
  name: 'profileState',
  initialState: initialProfileState,
  reducers: {
    loadingProfileAction(state) {
      state.isProfileLoading = true
    },
    loadingProfileFailure(state) {
      state.isProfileLoading = false
      state.isProfileFirstLoading = true
    },
    loadingProfileSuccess(state, { payload: { profile } }: PayloadAction<{ profile: ProfileInterface }>) {
      state.profile = profile
      state.isProfileLoading = false
      state.isProfileFirstLoading = true
    },
    updateProfileAction(
      state,
      {}: PayloadAction<{ profile: ProfileInterface; setErrors: (errors: FormikErrors<ProfileFormValuesInterface>) => void }>,
    ) {
      state.isProfileLoading = true
    },
    updateProfileFailure(state) {
      state.isProfileLoading = false
    },
    updateProfileSuccess(state, { payload: { profile } }: PayloadAction<{ profile: ProfileInterface }>) {
      state.profile = profile
      state.isProfileLoading = false
    },
  },
})

export const { loadingProfileAction, loadingProfileFailure, loadingProfileSuccess, updateProfileSuccess, updateProfileFailure, updateProfileAction } =
  profileState.actions

export default profileState.reducer
