import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectInterface } from '@state/project/projectStateSlice'
import { MemberInterface } from '@state/profile/profileStateSlice'
import { TagInterface } from '@interfaces/interfaces'

export interface TaskParamsInterface {
  taskId: string
}

export enum TaskStatusInternalEnum {
  ToDo = 'To do',
  ForDevelop = 'For develop',
  InProgress = 'In Progress',
  Testing = 'Testing',
  Done = 'Done',
}

export interface FileByTaskInterface {
  id: number
  taskId: number
  name: string
  fileType: string
  fileSource: string
  createdBy: number
}

export interface TaskInterface {
  id: number
  projectId: number
  project: null | ProjectInterface
  memberId: number
  member: null | MemberInterface
  name: string
  description: string
  statusInternal: TaskStatusInternalEnum
  label: string
  skillCoins: number
  isStrict: boolean
  canPayByCoins: number
  canPayByTime: number
  skillCoinsTotal: number
  deadline: Date
  tags: TagInterface[]
  isAutomaticSendingNda: boolean
  isRequiredContract: boolean
  files: FileByTaskInterface[]
  createdAt: Date
}

export interface TaskStateInterface {
  isTaskLoading: boolean
  isTaskFirstLoading: boolean
  task: null | TaskInterface
}

const initialTaskState: TaskStateInterface = {
  isTaskLoading: true,
  isTaskFirstLoading: false,
  task: null,
}

const taskState = createSlice({
  name: 'taskState',
  initialState: initialTaskState,
  reducers: {
    loadingTaskAction(state, {}: PayloadAction<{ isMember?: boolean; isProjectMembers?: boolean }>) {
      state.isTaskLoading = true
    },
    loadingTaskFailure(state) {
      state.isTaskLoading = false
      state.isTaskFirstLoading = true
    },
    loadingTaskSuccess(state, { payload: { task } }: PayloadAction<{ task: TaskInterface }>) {
      state.task = task
      state.isTaskLoading = false
      state.isTaskFirstLoading = true
    },
  },
})

export const { loadingTaskAction, loadingTaskFailure, loadingTaskSuccess } = taskState.actions

export default taskState.reducer
