import React, { FC, useId } from 'react'
import { FieldInputProps, FormikProps } from 'formik'
import styles from '../FormField/FormField.module.scss'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  label: string
  placeholder?: string
}

const TextareaField: FC<Props> = ({ field: { name, value, onChange, onBlur }, form: { touched, errors }, label, placeholder }: Props) => {
  const id: string = useId()
  return (
    <div className='column-align_start-g'>
      <label htmlFor={id} className={`${styles.title} label-title-g`}>
        {label}
      </label>
      <textarea
        id={id}
        className={`${styles.textarea} ${touched?.[name] && errors?.[name] && styles.input__error}`}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder || label}
      />
      <span className={styles.error}>{`${(touched?.[name] && errors?.[name]) || '\u00A0'}`}</span>
    </div>
  )
}

export default TextareaField
