import { put, takeLatest, all, select, call } from 'redux-saga/effects'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { toast } from 'react-hot-toast'
import errorResponseReturn from '@utils/errorResponseReturn'
import { loadingDocumentArrAction, loadingDocumentArrFailure, loadingDocumentArrSuccess } from '@state/documentArr/documentArrStateSlice'

function* loadingDocumentArrSaga(): Generator {
  try {
    const { page, type, area, stage, date, searchUser }: any = yield select(state => state.router.location.query)
    let queryParams: string = ''
    if (type && type !== 'All') queryParams = `&type=${type}`
    if (searchUser) queryParams = `${queryParams}&searchUser=${searchUser}`
    if (area && area !== 'All') queryParams = `${queryParams}&area=${area}`
    if (stage && stage !== 'All') queryParams = `${queryParams}&stage=${stage}`
    queryParams = `${queryParams}&date=${date || 'down'}`
    const { data }: any = yield call(apiConnectAxios, `/request-documents/?page=${page || 1}&limit=10${queryParams}`)
    yield put(loadingDocumentArrSuccess({ documentArr: data?.documentArr, documentCount: data?.documentCount }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingDocumentArrFailure(errorResponseReturn(error)))
  }
}

export default function* documentArrRootSaga() {
  yield all([takeLatest(loadingDocumentArrAction.type, loadingDocumentArrSaga)])
}
