import { put, takeLatest, all, call } from 'redux-saga/effects'
import { toast } from 'react-hot-toast'
import errorResponseReturn from '@utils/errorResponseReturn'
import {
  loadingProjectDocumentTemplateAction,
  loadingProjectDocumentTemplateFailure,
  loadingProjectDocumentTemplateSuccess,
  loadingTaskDocumentTemplateAction,
  loadingTaskDocumentTemplateFailure,
  loadingTaskDocumentTemplateSuccess,
} from '@state/documentTemplate/documentTemplateStateSlice'
import { apiConnectAxios } from '@utils/apiConnectAxios'

export function* loadingTaskDocumentTemplateSaga(): Generator {
  try {
    const { data }: any = yield call(apiConnectAxios, '/document-default-task')
    yield put(loadingTaskDocumentTemplateSuccess({ documentTemplateTask: data }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingTaskDocumentTemplateFailure(errorResponseReturn(error)))
  }
}

export function* loadingProjectDocumentTemplateSaga(): Generator {
  try {
    const { data }: any = yield call(apiConnectAxios, '/document-default-project')
    yield put(loadingProjectDocumentTemplateSuccess({ documentTemplateProject: data }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingProjectDocumentTemplateFailure(errorResponseReturn(error)))
  }
}

export default function* documentTemplateRootSaga() {
  yield all([
    takeLatest(loadingTaskDocumentTemplateAction.type, loadingTaskDocumentTemplateSaga),
    takeLatest(loadingProjectDocumentTemplateAction.type, loadingProjectDocumentTemplateSaga),
  ])
}
