import { useState } from 'react'

export const authHeaderHooks = (): any => {
  const [isJoinUsOpen, setJoinUsOpen] = useState(false)

  const closePopups = (e: any) => {
    const target = e.target as HTMLDivElement

    if (target.classList.contains('PopupsOutside')) {
      isJoinUsOpen && setJoinUsOpen(false)
    }
  }
  const openJoinUs = () => {
    setJoinUsOpen(true)
  }
  const closeJoinUs = () => {
    setJoinUsOpen(false)
  }

  return {
    closePopups,
    openJoinUs,
    closeJoinUs,
    isJoinUsOpen,
  }
}
