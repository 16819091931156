import React, { ChangeEvent, FC, MouseEvent, useId, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { ClickAwayListener, MenuItem } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import Popper from '@mui/material/Popper'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import SeeMoreSmallButton from '@buttons/SeeMoreSmallButton/SeeMoreSmallButton'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { TaskInterface } from '@state/task/taskStateSlice'
import { ProjectInterface } from '@state/project/projectStateSlice'
import styles from './HeaderSearchInput.module.scss'

const HeaderSearchInput: FC = () => {
  const [searchTasksProjectsValue, setSearchTasksProjectsValue] = useState<string>('')
  const [taskProjectArr, setTaskProjectArr] = useState<TaskInterface[] | ProjectInterface[]>([])
  const [taskProjectCount, setTaskProjectCount] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(false)

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement> | any): void => {
    setSearchTasksProjectsValue(event?.target?.value)
    setAnchorEl(event?.currentTarget)
    if (event?.target?.value) {
      setIsLoading(true)
      apiConnectAxios(`/tasks/search?name=${event?.target?.value}&page=1&limit=6`, 'get')
        .then((data: any) => {
          setIsLoading(false)
          setIsFirstLoading(true)
          setTaskProjectArr(data?.data?.taskProjectArr)
          setTaskProjectCount(data?.data?.taskProjectCount)
          setPage(1)
        })
        .catch(error => {
          setIsLoading(false)
          toast.error(errorResponseReturn(error))
        })
    } else {
      setIsLoading(false)
      setTaskProjectArr([])
    }
  }

  const onClickLoadHandler = (): void => {
    if (searchTasksProjectsValue) {
      setIsLoading(true)
      apiConnectAxios(`/tasks/search?name=${searchTasksProjectsValue}&page=${page + 1}&limit=6`, 'get')
        .then((data: any) => {
          setIsLoading(false)
          setIsFirstLoading(true)
          if (data?.data?.taskProjectArr?.length) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            setTaskProjectArr([...taskProjectArr, ...data?.data?.taskProjectArr])
          }
          setTaskProjectCount(data?.data?.taskProjectCount)
          setPage(page + 1)
        })
        .catch(error => {
          setIsLoading(false)
          toast.error(errorResponseReturn(error))
        })
    } else {
      setIsLoading(false)
      setTaskProjectArr([])
    }
  }

  const history = useHistory<History>()
  const onClickItemHandler = (id: number, type: 'Task' | 'Project') => (): void => {
    setAnchorEl(null)
    history.push(type === 'Task' ? `/task/${id}` : `/project/${id}`)
  }

  const closeHandler = (): void => {
    setAnchorEl(null)
  }
  const isOpen: boolean = Boolean(anchorEl)
  const popperId: string = useId()
  return (
    <ClickAwayListener onClickAway={closeHandler}>
      <div className={styles.searchWrapper}>
        <input
          aria-describedby={popperId}
          className={styles.searchInput}
          value={searchTasksProjectsValue}
          name='search'
          type='search'
          placeholder='Search task or project'
          onChange={onChangeHandler}
          onClick={onChangeHandler}
        />
        <SearchIcon className={styles.searchIcon} />
        {searchTasksProjectsValue && (
          <Popper
            id={popperId}
            open={isOpen}
            anchorEl={anchorEl}
            className={styles.popper}
            placement='bottom'
            style={{ width: anchorEl?.clientWidth }}
          >
            {!!taskProjectArr?.length &&
              taskProjectArr?.map((item: TaskInterface | ProjectInterface | any) => (
                <MenuItem className={styles.item} onClick={onClickItemHandler(item?.id, item?.type)} key={`${item?.type}${item?.id}`}>
                  <span className={styles.text}>{`${item?.type}: ${item?.name}`}</span>
                </MenuItem>
              ))}
            {isFirstLoading && !taskProjectArr?.length && <span className={styles.text}>Not found</span>}
            <LoadingBlockSpin isLoading={isLoading} />
            {taskProjectCount > page * 6 && <SeeMoreSmallButton onClick={onClickLoadHandler} />}
          </Popper>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default HeaderSearchInput
