import React, { FC, memo } from 'react'
import { useDispatch } from 'react-redux'
import { useDrag } from 'react-dnd'
import dayjs from 'dayjs'
import AvatarUser from '@components/userComponents/StartsUserBox/AvatarUser/AvatarUser'
import { TaskStatusInternalEnum } from '@state/task/taskStateSlice'
import { changeStatusExecutorTaskArrAction, ExecutorProjectInterface, ExecutorTaskInterface } from '@state/executorTaskArr/executorTaskArrStateSlice'
import styles from './ProjectsTableTbodyCard.module.scss'

interface DropResult {
  name: string
}

interface Props {
  executorTask: ExecutorTaskInterface | ExecutorProjectInterface
}

const ProjectsTableTbodyCard: FC<Props> = ({ executorTask }: Props) => {
  const dispatch = useDispatch()
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'status',
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>()
      if (dropResult?.name && dropResult?.name !== executorTask?.statusInternal) {
        dispatch(changeStatusExecutorTaskArrAction({ requestId: executorTask?.requestId, statusInternal: dropResult?.name }))
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))
  const opacity: 0.5 | 1 = isDragging ? 0.5 : 1
  return (
    <div className={styles.wrapper} ref={drag} style={{ opacity }}>
      {'label' in executorTask ? <div className={styles.label}>{executorTask?.label}</div> : <div className={styles.label} />}
      <div className={styles.name}>{executorTask?.name}</div>
      <div className={styles.dateWrapper}>
        <div className={styles.date}>{dayjs(executorTask?.createdAt).format('DD.MM')}</div>
        {executorTask?.statusInternal === TaskStatusInternalEnum.ToDo && <div className={styles.status_todo}>0%</div>}
        {executorTask?.statusInternal === TaskStatusInternalEnum.ForDevelop && <div>20%</div>}
        {executorTask?.statusInternal === TaskStatusInternalEnum.InProgress && <div>50%</div>}
        {executorTask?.statusInternal === TaskStatusInternalEnum.Testing && <div>80%</div>}
        {executorTask?.statusInternal === TaskStatusInternalEnum.Done && <div className={styles.status_done}>100%</div>}
        <AvatarUser memberId={executorTask?.executor?.memberId} avatar={executorTask?.executor?.avatar} sizePx={32} />
      </div>
    </div>
  )
}

export default memo(ProjectsTableTbodyCard)
