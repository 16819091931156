import React, { ChangeEvent, FC, useRef } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { downloadFileHandler } from '@utils/downloadFileHandler'
import { toast } from 'react-hot-toast'
import CheckIcon from '@mui/icons-material/Check'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import {
  loadingDocumentRequestArrAction,
  loadingMessageRequestArrAction,
  MessageRequestInterface,
  RequestArrStateInterface,
  StageMessageByRequestEnum,
  StatusRequestEnum,
} from '@state/requestArr/requestArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import styles from './RequestsSystemMessage.module.scss'

interface Props {
  message: MessageRequestInterface
}

const RequestsSystemMessage: FC<Props> = ({ message }: Props) => {
  const { profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const { requestChat }: RequestArrStateInterface = useSelector((state: AppStateInterface) => state.requestArrState)
  const dispatch = useDispatch()
  const maxSize = 10000000 // 10mb
  const supportedFormats: string[] = ['application/pdf']
  const loadingFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    const reader: FileReader = new FileReader()
    const [file]: any = e.target.files
    if (file) {
      reader.readAsDataURL(file)
      reader.onloadend = async () => {
        if (!supportedFormats.includes(file?.type)) {
          return toast.error('Unsupported Format')
        } else if (file.size > maxSize) {
          return toast.error('Max file size is 10mb')
        } else {
          if (isClient && message?.systemStage === 'Start') {
            apiConnectAxios(`/request-documents/client`, 'post', { file, requestId: requestChat?.id, type: message?.systemType }, true)
              .then((): void => {
                toast.success('NDA created')
                dispatch(loadingDocumentRequestArrAction())
                dispatch(loadingMessageRequestArrAction())
              })
              .catch(error => {
                toast.error(errorResponseReturn(error))
              })
          } else if (!isClient && message?.systemStage === StageMessageByRequestEnum.OnCreatorSign) {
            apiConnectAxios(
              `/request-documents/performer/sign`,
              'patch',
              {
                file,
                requestId: requestChat?.id,
                type: message?.systemType,
              },
              true,
            )
              .then(() => {
                toast.success('NDA signed')
                dispatch(loadingMessageRequestArrAction())
              })
              .catch(error => {
                toast.error(errorResponseReturn(error))
              })
          }
        }
      }
      reader.onerror = error => toast.error(error?.toString())
    }
  }
  const hiddenFileInput = useRef(null)
  const handleClick = () => {
    // @ts-ignore
    hiddenFileInput.current.click()
  }
  const { url } = useRouteMatch()
  const isProjectPage: boolean = url?.includes('project')
  const isClient: boolean = requestChat?.clientId === profile?.memberId
  return (
    <div className={styles.wrapper}>
      <div className={styles.messageWrapper}>
        <div className={styles.message}>
          {message?.systemStatus === StatusRequestEnum.Created && <AccessTimeIcon className={styles.timeIcon} />}
          {message?.systemStatus === StatusRequestEnum.Approved && (
            <div className={`${styles.status} ${styles.status_created}`}>
              <CheckIcon className={styles.checkIcon} />
            </div>
          )}
          {(message?.systemStatus === StatusRequestEnum.Canceled || message?.systemStatus === StatusRequestEnum.Denied) && (
            <div className={`${styles.status} ${styles.status_canceled}`}>
              <PriorityHighIcon className={styles.priorityIcon} />
            </div>
          )}
          <div className={styles.text}>
            {isClient &&
              message?.systemStage === StageMessageByRequestEnum.StatusStage &&
              `This ${isProjectPage ? 'Project' : 'Task'} requires confirmation of status`}
            {!isClient && message?.systemStage === StageMessageByRequestEnum.StatusStage && `Wait for client to confirm the status`}
            {isClient &&
              message?.systemStage === StageMessageByRequestEnum.Start &&
              `This ${isProjectPage ? 'Project' : 'Task'} requires the creation of an ${message?.systemType}`}
            {!isClient && message?.systemStage === StageMessageByRequestEnum.Start && `Wait for client to create ${message?.systemType}`}
            {isClient &&
              message?.systemStage === StageMessageByRequestEnum.OnCreatorSign &&
              `Sended ${message?.systemType}, waiting for performer sign`}
            {!isClient &&
              message?.systemStage === StageMessageByRequestEnum.OnCreatorSign &&
              `This ${isProjectPage ? 'Project' : 'Task'} requires signing of ${message?.systemType}`}
            {isClient && message?.systemStage === StageMessageByRequestEnum.OnExecutorSign && `Performer sent a signed ${message?.systemType}`}
            {!isClient &&
              message?.systemStage === StageMessageByRequestEnum.OnExecutorSign &&
              `Sended ${message?.systemType}, waiting for client sign`}
            {isClient && message?.systemStage === StageMessageByRequestEnum.BothSigned && `${message?.systemType} a signed`}
            {!isClient && message?.systemStage === StageMessageByRequestEnum.BothSigned && `Client sent a signed ${message?.systemType}`}
          </div>
          {message?.file?.fileSource && (
            <button className={styles.fileButton} type='button' onClick={downloadFileHandler(message?.file?.fileSource, message?.systemType)}>
              <PictureAsPdfIcon className={styles.fileIcon} />
            </button>
          )}
          {message?.systemStatus === StatusRequestEnum.Created &&
            ((isClient && message?.systemStage === StageMessageByRequestEnum.Start) ||
              (!isClient && message?.systemStage === StageMessageByRequestEnum.OnCreatorSign)) && (
              <button className={styles.addFileButton} type='button' onClick={handleClick}>
                <AttachFileIcon className={styles.addFileIIcon} />
                <input type='file' accept={supportedFormats.join()} ref={hiddenFileInput} onChange={loadingFileHandler} />
              </button>
            )}
        </div>
        {message?.text && <div className={styles.comment}>{`Comment: ${message?.text}`}</div>}
      </div>
    </div>
  )
}

export default RequestsSystemMessage
