import { FC, memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { OptionArrInterface } from '@interfaces/interfaces'
import { DocumentTemplateStateInterface } from '@state/documentTemplate/documentTemplateStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'

const areaOptionArr: OptionArrInterface[] = [
  {
    label: 'Project',
    value: 'Project',
  },
  {
    label: 'Task',
    value: 'Task',
  },
]
const legalOptionArr: OptionArrInterface[] = [
  {
    label: 'C2B',
    value: 'C2B',
  },
  {
    label: 'B2B',
    value: 'B2B',
  },
  {
    label: 'C2C',
    value: 'C2C',
  },
]
const AddDocumentTemplateFormLogic: FC = (): null => {
  const { setValues, setErrors } = useFormikContext()

  const { documentTemplate }: DocumentTemplateStateInterface = useSelector((state: AppStateInterface) => state.documentTemplateState)
  useEffect((): void => {
    if (documentTemplate)
      setValues({
        name: documentTemplate?.name,
        area: documentTemplate?.area,
        legal: documentTemplate?.legal,
        fileSource: documentTemplate?.fileSource,
      })
    else
      setValues({
        name: '',
        area: areaOptionArr[0].value,
        legal: legalOptionArr[0].value,
        fileSource: '',
      })
    setErrors({})
  }, [documentTemplate, setValues])

  return null
}

export default memo(AddDocumentTemplateFormLogic)
