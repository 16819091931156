import { Typography, useTheme } from '@mui/material'
import { WrapFlexBox } from '../Boxes/WrapFlexBox'
import { Subtitle } from '../Text/Subtitle/Subtitle'
import { MembershipBlock } from './landing-components/MembershipBlock'

export const SectionMembership: React.FC = () => {
  const theme = useTheme()

  return (
    <WrapFlexBox sx={{ mb: theme.spacing(12.5) }}>
      <Typography component='div' sx={{ textAlign: 'center', mb: theme.spacing(7.5) }}>
        <Subtitle subtitle='Skill Rade' />
        <Typography variant='h2'>Warning! - ITGUILD members!</Typography>
      </Typography>

      <WrapFlexBox
        sx={{
          [theme.breakpoints.up(1024)]: {
            flexWrap: 'nowrap',
          },
        }}
      >
        <MembershipBlock
          imageSrc='images/members/1.png?v=1655211687859'
          titleText='20% Off on Platform Fees'
          mainText='As a valued ITGUILD member, enjoy a 20% discount on all platform fees when you use SkillRade. Whether you hiring developers, designers, or content creators, save on every transaction.'
        />
        <MembershipBlock
          imageSrc='images/members/2.png?v=1655211687859'
          titleText='Community Recognition'
          mainText=' Receive special badges and recognition within the SkillRade community, showcasing your ITGUILD membership and your commitment to quality work and collaboration.'
        />
      </WrapFlexBox>
    </WrapFlexBox>
  )
}
