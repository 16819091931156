import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TaskInterface } from '@state/task/taskStateSlice'

export interface TaskArrStateInterface {
  isTaskArrLoading: boolean
  isTaskArrFirstLoading: boolean
  taskArr: TaskInterface[]
  taskCount: number
}

const initialTaskArrState: TaskArrStateInterface = {
  isTaskArrLoading: true,
  isTaskArrFirstLoading: false,
  taskArr: [],
  taskCount: 0,
}

const taskArrState = createSlice({
  name: 'taskArrState',
  initialState: initialTaskArrState,
  reducers: {
    loadingTaskArrAction(state, {}: PayloadAction<{ page?: number; limit?: number }>) {
      state.isTaskArrLoading = true
    },
    loadingTaskArrFailure(state) {
      state.isTaskArrLoading = false
      state.isTaskArrFirstLoading = true
    },
    loadingTaskArrSuccess(state, { payload: { taskArr, taskCount } }: PayloadAction<{ taskArr: TaskInterface[]; taskCount: number }>) {
      state.taskArr = taskArr
      state.taskCount = taskCount
      state.isTaskArrLoading = false
      state.isTaskArrFirstLoading = true
    },
  },
})

export const { loadingTaskArrAction, loadingTaskArrFailure, loadingTaskArrSuccess } = taskArrState.actions

export default taskArrState.reducer
