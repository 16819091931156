import React, { FC } from 'react'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import AddDocumentTemplateForm from './DocumentTemplatePage/AddDocumentTemplateForm/AddDocumentTemplateForm'
import DocumentIndividualPage from '@pages/documentPages/DocumentIndividualPage/DocumentIndividualPage'
import DocumentTemplatePage from './DocumentTemplatePage/DocumentTemplatePage'
import DocumentCommercePage from './DocumentCommercePage/DocumentCommercePage'
import styles from './DocumentPages.module.scss'

interface linkArrInterface {
  link: string
  title: string
}

const linkArr: linkArrInterface[] = [
  { link: '/documents/templates', title: 'NDA Templates' },
  { link: '/documents/individual', title: 'Documents Individual' },
  { link: '/documents/commercial', title: 'Documents Commercial' },
]
const DocumentPages: FC = () => {
  const { url } = useRouteMatch()
  return (
    <div className='page-container-g'>
      <div className='flex-g width_max-g'>
        <h1 className={styles.title}>Documents</h1>
        <AddDocumentTemplateForm />
      </div>
      <div className={styles.linkWrapper}>
        {linkArr.map(({ link, title }: linkArrInterface) => (
          <Link key={link} className={`${styles.link} ${(link === url && styles.link_active) || ''}`} to={link}>
            <span className={styles.linkText}>{title}</span>
            <span className={styles.linkGradient}>{title}</span>
          </Link>
        ))}
      </div>
      <Switch>
        <Route exact path={'/documents/templates'} component={DocumentTemplatePage} />
        <Route exact path={'/documents/individual'} component={DocumentIndividualPage} />
        <Route exact path={'/documents/commercial'} component={DocumentCommercePage} />
      </Switch>
    </div>
  )
}

export default DocumentPages
