import React, { FC, memo } from 'react'
import AvatarUser from './AvatarUser/AvatarUser'
import StartsUser from './StartsUser/StartsUser'
import { getRandomInt } from '@utils/getRandomInt'
import styles from './StartsUserBox.module.scss'

interface Props {
  isBackground?: boolean
  memberId?: string | number | null
  avatar?: string | null
  firstName?: string | null
  lastName?: string | null
  rating?: number
  reviews?: number
  isStartsUserHide?: boolean
}

const StartsUserBox: FC<Props> = ({ isBackground, memberId, avatar, firstName, lastName, rating, reviews, isStartsUserHide }: Props) => (
  <div className={`${styles.wrapper} ${(isBackground && styles.wrapper_background) || ''}`}>
    <AvatarUser memberId={memberId} avatar={avatar} />
    <div className={styles.nameWrapper}>
      <h4 className={styles.name}>{`${firstName || ''}  ${lastName || ''}`}</h4>
      <div className={styles.professionWrapper}>
        <div className={styles.profession}>Graphic Design</div>
        {!isStartsUserHide && <StartsUser rating={rating || getRandomInt(0, 5)} reviews={reviews || getRandomInt(0, 100)} />}
      </div>
    </div>
  </div>
)

export default memo(StartsUserBox)
