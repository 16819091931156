import { FC } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Catalog } from '../pages/catalog'
import { Contact } from '../pages/contact'
import { FAQ } from '../pages/faq'
import { Home } from '../pages/home'
import NavigationMenu from './NavigationMenu/NavigationMenu'
import CreateNewsPage from '@pages/newsPages/CreateNewsPage/CreateNewsPage'
import CreateProjectPage from '@pages/projectPages/CreateProjectPage/CreateProjectPage'
import ProjectNewsPage from '@pages/newsPages/ProjectNewsPage/ProjectNewsPage'
import DocumentPages from '@pages/documentPages/DocumentPages'
import ProjectPage from '@pages/projectPages/ProjectPage/ProjectPage'
import CreateTaskPage from '@pages/taskPages/CreateTaskPage/CreateTaskPage'
import TransactionsPage from '@pages/TransactionsPage/TransactionsPage'

const Routes: FC = () => (
  <Switch>
    <Route exact path='/' component={Home} />
    <Route path='/contact' component={Contact} />
    <Route path='/faq' component={FAQ} />
    <Route path='/catalog' component={Catalog} />
    <Route
      exact
      path={[
        '/requests',
        '/tasks',
        '/projects',
        '/news',
        '/offers',
        '/tasks',
        '/settings',
        '/followers',
        '/following',
        '/user/:id',
        '/followers/:id',
        '/following/:id',
      ]}
      component={NavigationMenu}
    />
    <Route exact path={['/create-project', '/edit-project/:projectId']} component={CreateProjectPage} />
    <Route exact path={['/create-project-news', '/edit-project-news/:newsId']} component={CreateNewsPage} />
    <Route exact path='/project-news/:projectId' component={ProjectNewsPage} />
    <Route exact path={['/documents/templates', '/documents/individual', '/documents/commercial']} component={DocumentPages} />
    <Route exact path={['/project/:projectId', '/project/:projectId/donations']} component={ProjectPage} />
    <Route exact path={['/task/:taskId', '/task/:taskId/donations']} component={ProjectPage} />
    <Route path={['/create-task', '/edit-task/:taskId']} component={CreateTaskPage} />
    <Route path='/transactions' component={TransactionsPage} />
    <Redirect to='/' />
  </Switch>
)

export default Routes
