import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MemberInterface } from '@state/profile/profileStateSlice'
import { AreaDocumentEnum, LegalDocumentEnum } from '@state/documentArr/documentArrStateSlice'

export interface DocumentTemplateInterface {
  id: number
  authorId: number
  author?: MemberInterface
  area: AreaDocumentEnum
  legal: LegalDocumentEnum
  size: string
  name: string
  fileSource: string
  version: number
  createdAt?: Date
  updatedAt?: Date
}

export interface DocumentTemplateDefaultInterface {
  id: number
  authorId: number
  author?: MemberInterface
  documentTemplateId: number
  documentTemplate: DocumentTemplateInterface
}

export interface DocumentTemplateStateInterface {
  isDocumentTemplateLoading: boolean
  isDocumentTemplateFirstLoading: boolean
  isShowDocumentTemplateModal: boolean
  documentTemplate: DocumentTemplateInterface | null
  documentTemplateTask: DocumentTemplateDefaultInterface | null
  isDocumentTemplateTaskLoading: boolean
  isDocumentTemplateTaskFirstLoading: boolean
  documentTemplateProject: DocumentTemplateDefaultInterface | null
  isDocumentTemplateProjectLoading: boolean
  isDocumentTemplateProjectFirstLoading: boolean
}

const initialDocumentTemplateState: DocumentTemplateStateInterface = {
  isDocumentTemplateLoading: true,
  isDocumentTemplateFirstLoading: false,
  isShowDocumentTemplateModal: false,
  documentTemplate: null,
  documentTemplateTask: null,
  isDocumentTemplateTaskLoading: true,
  isDocumentTemplateTaskFirstLoading: false,
  documentTemplateProject: null,
  isDocumentTemplateProjectLoading: true,
  isDocumentTemplateProjectFirstLoading: false,
}

const documentTemplateState = createSlice({
  name: 'documentTemplateState',
  initialState: initialDocumentTemplateState,
  reducers: {
    changeModalDocumentTemplateSuccess(
      state,
      {
        payload: { isShowDocumentTemplateModal, documentTemplate },
      }: PayloadAction<{ isShowDocumentTemplateModal: boolean; documentTemplate?: DocumentTemplateInterface | null }>,
    ) {
      state.isShowDocumentTemplateModal = isShowDocumentTemplateModal
      if (documentTemplate) state.documentTemplate = documentTemplate
      else state.documentTemplate = null
    },
    loadingTaskDocumentTemplateAction(state) {
      state.isDocumentTemplateTaskLoading = true
    },
    loadingTaskDocumentTemplateFailure(state) {
      state.isDocumentTemplateTaskLoading = false
      state.isDocumentTemplateTaskFirstLoading = true
    },
    loadingTaskDocumentTemplateSuccess(
      state,
      { payload: { documentTemplateTask } }: PayloadAction<{ documentTemplateTask: DocumentTemplateDefaultInterface }>,
    ) {
      state.documentTemplateTask = documentTemplateTask
      state.isDocumentTemplateTaskLoading = false
      state.isDocumentTemplateTaskFirstLoading = true
    },
    loadingProjectDocumentTemplateAction(state) {
      state.isDocumentTemplateProjectLoading = true
    },
    loadingProjectDocumentTemplateFailure(state) {
      state.isDocumentTemplateProjectLoading = false
      state.isDocumentTemplateProjectFirstLoading = true
    },
    loadingProjectDocumentTemplateSuccess(
      state,
      { payload: { documentTemplateProject } }: PayloadAction<{ documentTemplateProject: DocumentTemplateDefaultInterface }>,
    ) {
      state.documentTemplateProject = documentTemplateProject
      state.isDocumentTemplateProjectLoading = false
      state.isDocumentTemplateProjectFirstLoading = true
    },
  },
})

export const {
  changeModalDocumentTemplateSuccess,
  loadingTaskDocumentTemplateAction,
  loadingTaskDocumentTemplateFailure,
  loadingTaskDocumentTemplateSuccess,
  loadingProjectDocumentTemplateAction,
  loadingProjectDocumentTemplateFailure,
  loadingProjectDocumentTemplateSuccess,
} = documentTemplateState.actions

export default documentTemplateState.reducer
