import React, { FC, memo, useEffect, useId, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryHook } from '@hooks/useQueryHook'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { ClickAwayListener } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import SeeMoreSmallButton from '@buttons/SeeMoreSmallButton/SeeMoreSmallButton'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ExecutorTaskArrStateInterface, loadingUsersExecutorTaskArrAction } from '@state/executorTaskArr/executorTaskArrStateSlice'
import { MemberInterface } from '@state/profile/profileStateSlice'
import styles from './ProjectsExecutorSelect.module.scss'

const ProjectsExecutorSelect: FC = () => {
  const dispatch = useDispatch()
  const {
    isTaskExecutorArrLoading,
    isTaskExecutorArrFirstLoading,
    taskExecutorArr,
    taskExecutorCount,
    taskExecutorPage,
  }: ExecutorTaskArrStateInterface = useSelector((state: AppStateInterface) => state.executorTaskArrState)
  const query: URLSearchParams = useQueryHook()
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()
  useEffect(() => {
    dispatch(loadingUsersExecutorTaskArrAction({ page: 1, limit: 6 }))
  }, [query.get('projectId'), dispatch])
  useEffect(() => {
    if (query.get('executorId') && !Number.isInteger(Number(query.get('executorId'))) && query.get('executorId') !== 'all') {
      const searchParams: URLSearchParams = new URLSearchParams(search)
      searchParams.set('executorId', '')
      push({
        pathname: pathname,
        search: searchParams.toString(),
      })
      toast.error('executorId must be number')
    }
  }, [query.get('executorId'), dispatch])
  const newTaskExecutorArr: MemberInterface[] = taskExecutorArr.slice(0, taskExecutorArr?.length > 3 ? 4 : taskExecutorArr?.length)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = Boolean(anchorEl)
  const popperId: string = useId()
  const [arrowRef, setArrowRef] = useState(null)
  const closeHandler = () => {
    setAnchorEl(null)
  }
  const openHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isOpen) setAnchorEl(null)
    else setAnchorEl(event.currentTarget)
  }
  const onClickFilterHandler = (executorId: string) => () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('executorId', executorId)
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
    setAnchorEl(null)
  }
  let foundExecutor: MemberInterface | undefined
  if (
    taskExecutorArr?.length &&
    query.get('executorId') &&
    query.get('executorId') !== 'all' &&
    !isTaskExecutorArrLoading &&
    isTaskExecutorArrFirstLoading
  ) {
    foundExecutor = taskExecutorArr?.find(({ memberId }: MemberInterface): boolean => memberId == Number(query.get('executorId')))
    if (!foundExecutor) {
      const searchParams: URLSearchParams = new URLSearchParams(search)
      searchParams.set('executorId', 'all')
      push({
        pathname: pathname,
        search: searchParams.toString(),
      })
    }
  }
  const onClickSeeMoreHandler = () => {
    dispatch(loadingUsersExecutorTaskArrAction({ page: taskExecutorPage + 1, limit: 6 }))
  }

  return (
    <>
      <ClickAwayListener onClickAway={closeHandler}>
        <div>
          <button className={styles.selectWrapper} type='button' onClick={openHandler} aria-describedby={popperId}>
            <div className={styles.filter}>Filter By:</div>
            {(!query.get('executorId') || query.get('executorId') === 'all') && <div className={styles.selectName}>All Executor</div>}
            {foundExecutor && (
              <div className={styles.selectNameWrapper}>
                <img className={styles.bigAvatar} src={foundExecutor?.avatar || ''} alt='' />
                <div className={styles.selectName}>{`${foundExecutor?.firstName?.slice(0, 8)}`}</div>
              </div>
            )}
            <KeyboardArrowDownIcon className={`${styles.downIcon} ${isOpen && styles.downIcon_up}`} />
          </button>
          <Popper
            id={popperId}
            open={isOpen}
            anchorEl={anchorEl}
            className={styles.popover}
            placement='bottom-end'
            modifiers={[{ name: 'arrow', enabled: true, options: { element: arrowRef } }]}
          >
            <Box component='span' ref={setArrowRef} className={styles.arrow} />
            <div className={styles.popoverContainer}>
              <MenuItem className={styles.menuItem} onClick={onClickFilterHandler('all')}>
                <div className={styles.avatarWrapper}>
                  {!!newTaskExecutorArr?.length &&
                    newTaskExecutorArr?.map((member: MemberInterface) => (
                      <div className={styles.avatarContainer} key={member?.memberId}>
                        <img className={styles.avatar} src={member?.avatar || ''} alt='' />
                      </div>
                    ))}
                </div>
                <div className={styles.name}>All Executor</div>
              </MenuItem>
              {!!taskExecutorArr?.length &&
                taskExecutorArr?.map((member: MemberInterface) => (
                  <MenuItem className={styles.menuItem} key={member?.memberId} onClick={onClickFilterHandler(String(member?.memberId))}>
                    <img className={styles.bigAvatar} src={member?.avatar || ''} alt='' />
                    <div className={styles.name}>{`${member?.firstName?.slice(0, 6)} ${member?.lastName?.slice(0, 5)}`}</div>
                  </MenuItem>
                ))}
              <LoadingBlockSpin isLoading={isTaskExecutorArrLoading} />
              {taskExecutorCount > taskExecutorPage * 6 && <SeeMoreSmallButton onClick={onClickSeeMoreHandler} />}
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  )
}
export default memo(ProjectsExecutorSelect)
