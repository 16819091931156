import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import ProjectUserPopoverBox from '@components/userComponents/ProjectUserPopoverBox/ProjectUserPopoverBox'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { loadingProjectAction, ProjectParamsInterface, ProjectStateInterface } from '@state/project/projectStateSlice'
import styles from './ProjectNewsDescription.module.scss'

const ProjectNewsDescription: FC = () => {
  const { projectId } = useParams<ProjectParamsInterface>()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadingProjectAction({ isProjectMembers: true }))
  }, [projectId, dispatch])
  const { project }: ProjectStateInterface = useSelector((state: AppStateInterface) => state.projectState)
  return (
    <>
      <div className={styles.projectWrapper}>
        <div className={styles.project}>Project</div>
        <ProjectUserPopoverBox />
      </div>
      <div className='align-self_start-g'>
        <div className={styles.nameWrapper}>
          <div className={styles.iconWrapper}>{project?.icon && <img className={styles.icon} src={project?.icon} alt='' />}</div>
          <div className={styles.name}>{project?.name || '\u00A0'}</div>
          <StarBorderIcon className={styles.star} />
        </div>
      </div>
      <div className={styles.title}>About project</div>
      <div className={styles.description}>{project?.description || '\u00A0'}</div>
      <div className={styles.news}>News</div>
    </>
  )
}

export default ProjectNewsDescription
