import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import LoadingTransparentSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import ProfileUserField from '@pages/ProfileSettingsPage/ProfileUser/ProfileUserField/ProfileUserField'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { UserStateInterface } from '@state/user/userStateSlice'
import st from '../ProfileSettingsForm/ProfileSettingsForm.module.scss'
import styles from './ProfileUser.module.scss'

export interface ArrInterface {
  label: string
  text: string
  isLink?: boolean
}

const ProfileUser: FC = () => {
  const { userProfile, isUserLoading }: UserStateInterface = useSelector((state: AppStateInterface) => state.userState)
  const initialValues: ArrInterface[] = [
    {
      label: 'Bio',
      text: userProfile?.firstName || '',
    },
    {
      label: 'Facebook',
      text: userProfile?.facebookLink || '',
      isLink: true,
    },
    {
      label: 'Your website/blog',
      text: userProfile?.websiteLink || '',
      isLink: true,
    },
    {
      label: 'Location',
      text: userProfile?.location || '',
      isLink: true,
    },
    {
      label: 'Skills',
      text: userProfile?.skills?.join(' ') || '',
      isLink: true,
    },
    {
      label: 'Email',
      text: userProfile?.email || '',
    },
  ]
  return (
    <>
      <HelmetTitle title='User' />
      <div className={st.wrapper}>
        <LoadingTransparentSpin isLoading={isUserLoading} />
        <h2 className={st.title}>Information</h2>
        {initialValues.map(({ label, text, isLink }: ArrInterface) => (
          <ProfileUserField key={label} label={label} text={text} isLink={isLink} />
        ))}
        <div className='flex-g'>
          <div className={styles.task}>{`${Math.floor(Math.random() * 99)} completed tasks`}</div>
          <div className={styles.progress}>{`${Math.floor(Math.random() * 99)} tasks in progress`}</div>
        </div>
      </div>
    </>
  )
}

export default memo(ProfileUser)
