import { FC, memo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ProfileUserSideBar from './ProfileUserSideBar/ProfileUserSideBar'
import ProfileFollowersSideBar from './ProfileFollowersSideBar/ProfileFollowersSideBar'
import { IdParamsInterface } from '@interfaces/interfaces'
import { MemberInterface } from '@state/profile/profileStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { FollowerStateInterface } from '@state/follower/followerStateSlice'
import styles from './ProfileSideBar.module.scss'

const ProfileSideBar: FC = () => {
  const { id } = useParams<IdParamsInterface>()
  let followerArr: MemberInterface[] | [] = []
  let followingArr: MemberInterface[] | [] = []
  let followerCount: number
  let followingCount: number
  const {
    profileFollowerArr,
    profileFollowerCount,
    profileFollowingArr,
    profileFollowingCount,
    userFollowerArr,
    userFollowerCount,
    userFollowingArr,
    userFollowingCount,
  }: FollowerStateInterface = useSelector((state: AppStateInterface) => state.followerState)
  if (id) {
    followerArr = userFollowerArr
    followingArr = userFollowingArr
    followerCount = userFollowerCount
    followingCount = userFollowingCount
  } else {
    followerArr = profileFollowerArr
    followingArr = profileFollowingArr
    followerCount = profileFollowerCount
    followingCount = profileFollowingCount
  }
  return (
    <div className={styles.wrapper}>
      <ProfileUserSideBar id={id} />
      {!!followerArr?.length && (
        <ProfileFollowersSideBar
          title='Followers'
          followersArr={followerArr}
          followerUrl={id ? `/followers/${id}` : '/followers'}
          followerCount={followerCount}
        />
      )}
      {!!followingArr?.length && (
        <ProfileFollowersSideBar
          title='Following'
          followersArr={followingArr}
          followerUrl={id ? `/following/${id}` : '/following'}
          followerCount={followingCount}
        />
      )}
    </div>
  )
}

export default memo(ProfileSideBar)
