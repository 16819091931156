import React, { FC, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-hot-toast'
import PageTitle from '@components/titles/PageTitle/PageTitle'
import PinkButton from '@buttons/PinkButton/PinkButton'
import SelectArrField from '@fields/SelectArrField/SelectArrField'
import FormField from '@fields/FormField/FormField'
import TextareaField from '@fields/TextareaField/TextareaField'
import LoadPhotoField from '@fields/LoadPhotoField/LoadPhotoField'
import LoadingTransparentSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import CreateNewsPageLogic from '@pages/newsPages/CreateNewsPage/CreateNewsPageLogic/CreateNewsPageLogic'
import { OptionIconArrInterface } from '@interfaces/interfaces'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { minTextareaLengthValidationVar, requiredValidationVar } from '@utils/fieldsValidationVariables'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { NewsInterface, NewsParamsInterface, NewsStateInterface } from '@state/news/newsStateSlice'
import { ProjectInterface } from '@state/project/projectStateSlice'
import { loadingProjectArrAction, ProjectArrStateInterface } from '@state/projectArr/projectArrStateSlice'
import errorResponseReturn from '@utils/errorResponseReturn'

const validationSchema = yup.object().shape({
  projectId: yup.string().required(requiredValidationVar),
  title: yup.string().required(requiredValidationVar),
  description: yup.string().required(requiredValidationVar).min(minTextareaLengthValidationVar.min, minTextareaLengthValidationVar.message),
  label: yup.string().required(requiredValidationVar),
  tags: yup.string().required(requiredValidationVar),
  image: yup.string().required(requiredValidationVar),
})

const labelOptionArr = [{ label: 'Design', value: 'Design' }]

type NewsFieldValuesInterface = Omit<NewsInterface, 'id' | 'createdBy' | 'tags' | 'projectId' | 'memberId' | 'commentByNews' | 'member'> & {
  projectId: string | number
  tags: string
}

const CreateNewsPage: FC = () => {
  const { isProjectArrLoading, isProjectArrFirstLoading, projectArr }: ProjectArrStateInterface = useSelector(
    (state: AppStateInterface) => state.projectArrState,
  )
  const history = useHistory<History>()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadingProjectArrAction())
  }, [dispatch])
  const isMounted = useRef<boolean>(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else if (isProjectArrFirstLoading && projectArr?.length === 0 && isProjectArrLoading === false) {
      toast.error('First create a project')
      history.push('/create-project')
    }
  }, [projectArr, isProjectArrFirstLoading, isProjectArrLoading, history])
  const optionArr: OptionIconArrInterface[] = projectArr?.map((project: ProjectInterface) => ({
    label: project?.name,
    value: project?.id,
    icon: project?.icon,
  }))

  const { newsId } = useParams<NewsParamsInterface>()
  const { news, isNewsLoading }: NewsStateInterface = useSelector((state: AppStateInterface) => state.newsState)
  const initialValues: NewsFieldValuesInterface = {
    projectId: news?.projectId || '',
    title: news?.title || '',
    description: news?.description || '',
    label: 'Design',
    tags: news?.tags?.join(',') || '',
    image: news?.image || '',
  }
  const submitHandler = async (fieldValues: NewsFieldValuesInterface, { setErrors }: FormikHelpers<NewsFieldValuesInterface>) => {
    apiConnectAxios(
      newsId ? `/project-news/${newsId}` : '/project-news',
      newsId ? 'patch' : 'post',
      { ...fieldValues, tags: fieldValues.tags?.split(', ') || [] },
      true,
    )
      .then(() => {
        toast.success(newsId ? 'News edited' : 'News created')
        history.push(`/project-news/${fieldValues?.projectId}?page=1`)
      })
      .catch(error => {
        setErrors(error?.response?.data?.errors || {})
        toast.error(errorResponseReturn(error))
      })
  }
  const title = newsId ? 'Edit News' : 'Create News'
  return (
    <div className='page-container-g'>
      <PageTitle title={title} />
      <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
        <Form className='position-relative-g direction_column-max-g' autoComplete='off'>
          {newsId && <LoadingTransparentSpin isLoading={isProjectArrLoading || isNewsLoading} />}
          <CreateNewsPageLogic />
          <Field name='projectId' label='Project' component={SelectArrField} optionArr={optionArr} isUpdateOption />
          <Field name='title' label='Title' component={FormField} />
          <Field name='description' label='Description' component={TextareaField} />
          <Field name='label' label='label' component={SelectArrField} optionArr={labelOptionArr} />
          <Field name='tags' label='Tags' placeholder='Add tags separated by commas' component={FormField} />
          <Field name='image' component={LoadPhotoField} />
          <PinkButton title={title} type='submit' sx={{ margin: '90px auto 30px auto' }} />
        </Form>
      </Formik>
    </div>
  )
}

export default CreateNewsPage
