import { FC, createContext, useContext, useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged, sendEmailVerification, User } from 'firebase/auth'
import { loadingProfileAction, ProfileInterface } from '@state/profile/profileStateSlice'
import { AuthService } from '../services/auth.service'
import ProfileService from '../services/profile.service'
import { toast } from 'react-hot-toast'
import { useDispatch } from 'react-redux'

interface IAuthProvider {
  handleGoogleCredentialAuth: (IdToken: string) => void
  handleGoogleAuth: () => void
  handleGitHubAuth: () => void
  handleAppleAuth: () => void
  handleMicrosoftAuth: () => void
  getUser: () => User | null
  getMemberProfile: () => ProfileInterface | null
  currentUser: User | null
}

// inititale state of the auth context, must be initialized
const AuthContext = createContext<IAuthProvider>({
  handleGoogleCredentialAuth: () => {
    return
  },
  handleGoogleAuth: () => {
    return
  },
  handleGitHubAuth: () => {
    return
  },
  handleAppleAuth: () => {
    return
  },
  handleMicrosoftAuth: () => {
    return
  },
  getUser: () => {
    return null
  },
  getMemberProfile: () => {
    return null
  },
  currentUser: null,
})

interface IAuthProviderProps {
  children?: any
}

const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
  const auth = getAuth()
  const authService = new AuthService()
  const profileService = new ProfileService()

  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [memberProfile, setMemberProfile] = useState<ProfileInterface | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      await profileService
        .getCurrentProfile()
        .then(data => {
          if (data) {
            setMemberProfile(data)
            dispatch(loadingProfileAction())
          }
        })
        .catch(error => {
          if (error?.response?.status === 401 && error?.response?.statusText === 'Unauthorized') {
            return
          }
        })
    }
    if (!memberProfile) {
      fetchData()
    }
  }, [memberProfile])

  const signInWithProviderCredential = (IdToken: string) => {
    // Sign in with credential from the Google user.
    authService
      .googleCredentialAuth(IdToken)
      .then(credential => {
        const user = credential.user
        profileService
          .createUserProfile(user)
          .then(() => {
            window.location.assign('/')
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log(`Error code: ${errorCode}.\nError message: ${errorMessage}`)
      })
  }
  const dispatch = useDispatch()
  const signInWithProvider = (provider: 'google' | 'github' | 'apple' | 'microsoft') => {
    authService
      .providerAuth(provider)
      .then(result => {
        // The signed-in user info.
        const user = result.user
        // Send verification email to the user
        if (!user.emailVerified) {
          sendEmailVerification(user)
        }
        profileService
          .createUserProfile(user)
          .then(data => {
            setMemberProfile(data)
            dispatch(loadingProfileAction())
          })
          .catch(error => {
            toast.error(error.response?.data?.message)
          })
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        console.log(`Error code: ${errorCode}.\nError message: ${errorMessage}`)
      })
  }

  const handleGoogleCredentialAuth = (idToken: string) => {
    signInWithProviderCredential(idToken)
  }

  const handleGoogleAuth = () => {
    signInWithProvider('google')
  }

  const handleGitHubAuth = () => {
    signInWithProvider('github')
  }

  const handleAppleAuth = () => {
    signInWithProvider('apple')
  }

  const handleMicrosoftAuth = () => {
    signInWithProvider('microsoft')
  }

  const getUser = () => {
    return auth.currentUser
  }

  const getMemberProfile = () => {
    return memberProfile
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user)
    })

    return unsubscribe
  }, [])

  const value = {
    handleGoogleCredentialAuth,
    handleGoogleAuth,
    handleGitHubAuth,
    handleAppleAuth,
    handleMicrosoftAuth,
    currentUser,
    getUser,
    getMemberProfile,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  return useContext(AuthContext)
}

export { useAuth, AuthProvider }
