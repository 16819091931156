import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import PagePagination from '@components/PagePagination/PagePagination'
import ProjectNewsItem from './ProjectNewsItem/ProjectNewsItem'
import { useQueryHook } from '@hooks/useQueryHook'
import { loadingNewsArrAction, NewsArrStateInterface } from '@state/newsArr/newsArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { NewsInterface } from '@state/news/newsStateSlice'

const ProjectNews: FC = () => {
  const { isNewsArrLoading, newsArr, newsCount }: NewsArrStateInterface = useSelector((state: AppStateInterface) => state.newsArrState)
  const dispatch = useDispatch()
  const query: URLSearchParams = useQueryHook()
  const { projectId } = useParams<{ projectId: string }>()
  useEffect(() => {
    dispatch(loadingNewsArrAction())
  }, [projectId, query.get('page'), dispatch])

  return (
    <div className='position-relative-g width_max-g'>
      <LoadingBlockSpin isLoading={isNewsArrLoading} />
      {!!newsArr.length && newsArr?.map((news: NewsInterface) => <ProjectNewsItem key={news?.id} news={news} />)}
      {newsCount > 10 && <PagePagination pageCount={Math.ceil(newsCount / 10)} />}
    </div>
  )
}

export default ProjectNews
