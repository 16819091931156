import { Typography, useTheme } from '@mui/material'
import { FlexBox } from '../../Boxes/FlexBox'
import { HowToText } from './styles'

interface BlcokProps {
  imageSrc?: string
  titleText?: string
  mainText?: string
}

export const HowToBlock: React.FC<BlcokProps> = ({ imageSrc, titleText, mainText }) => {
  const theme = useTheme()

  return (
    <FlexBox sx={{ margin: '15px', flexDirection: 'column' }}>
      <img src={imageSrc ?? '#'} alt='' />
      <Typography component='div' sx={{ margin: '30px 0 25px' }}>
        {titleText ?? 'Add title'}
      </Typography>
      <HowToText color={theme.palette.text.secondary}>{mainText ?? 'Add some text'}</HowToText>
    </FlexBox>
  )
}
