import FavoriteIcon from '@mui/icons-material/Favorite'
import StarIcon from '@mui/icons-material/Star'
import { Box, Card, CardActions, CardMedia, Rating, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { IProductCard } from '../../interfaces/product'
import { ProductsTitle } from './styles'

export const ProductCard: FC<IProductCard> = ({ title, image, href, cardType }) => {
  const theme = useTheme()

  return (
    <Card
      sx={{
        background: theme.palette.primary.dark,
        margin: '0px 10px 30px',
        height: '450px',
      }}
    >
      <CardMedia component={'a'} href={href} sx={{ height: 250 }} image={image} />
      <CardActions disableSpacing={true} sx={{ m: '25px 15px 0', p: '0', height: '175px', display: 'flex', flexWrap: 'wrap' }}>
        <ProductsTitle href={href}>{title}</ProductsTitle>
        <Box sx={{ width: '100%', display: 'flex', padding: '10px 0px', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <StarIcon sx={{ color: '#ffc50c' }} />
            <Typography sx={{ color: '#ffc50c' }}>5.0</Typography>
            <Typography>(52)</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>#{cardType}</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: 'solid 1px #ada2c3',
            width: '100%',
            height: '61px',
          }}
        >
          <Rating emptyIcon={<FavoriteIcon sx={{ color: '#ada2c3' }} />} icon={<FavoriteIcon />} max={1} sx={{ color: 'red' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#ada2c3' }}>STARTING AT</Typography>
            <Typography variant='h4' sx={{ color: '#ada2c3' }}>
              ₮120
            </Typography>
          </Box>
        </Box>
      </CardActions>
    </Card>
  )
}
