import React, { FC } from 'react'
import RequestProjectPage from './RequestProjectPage/RequestProjectPage'
import AddRequestProjectForm from './AddRequestProjectForm/AddRequestProjectForm'

const ProjectPage: FC = () => {
  return (
    <div className='page-container-g'>
      <RequestProjectPage />
      <AddRequestProjectForm />
    </div>
  )
}

export default ProjectPage
