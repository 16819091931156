import React, { ChangeEvent, FC, useRef } from 'react'
import { FieldInputProps, FormikProps, useField } from 'formik'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import { toast } from 'react-hot-toast'
import styles from './RequestsMessageFileField.module.scss'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
}

const RequestsMessageFileField: FC<Props> = ({ field: { name, value } }: Props) => {
  const [, , { setValue, setError, setTouched }] = useField(name)
  const maxSize = 10000000 // 10mb
  const supportedFormats: string[] = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']
  const loadingFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    const reader: FileReader = new FileReader()
    const [file]: any = e.target.files
    if (file) {
      reader.readAsDataURL(file)
      reader.onloadend = async () => {
        if (!supportedFormats.includes(file?.type)) {
          await setTouched(true)
          setError('Unsupported Format')
          return toast.error('Unsupported Format')
        } else if (file.size > maxSize) {
          await setTouched(true)
          setError('Max file size is 10mb')
          return toast.error('Max file size is 10mb')
        } else {
          await setTouched(true)
          setError('')
          setValue(file)
        }
      }
      reader.onerror = error => toast.error(error?.toString())
    }
  }
  const hiddenFileInput = useRef(null)
  const handleClick = () => {
    // @ts-ignore
    hiddenFileInput.current.click()
  }
  return (
    <>
      {!value && (
        <button className={styles.fileButton} type='button' onClick={handleClick}>
          <AttachFileIcon className={styles.fileIcon} />
          <input name={name} type='file' accept={supportedFormats.join()} ref={hiddenFileInput} onChange={loadingFileHandler} />
        </button>
      )}
    </>
  )
}
export default RequestsMessageFileField
