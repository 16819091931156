import { Typography, useTheme } from '@mui/material'

interface TitleProps {
  title: string
}

export const Title: React.FC<TitleProps> = ({ title }) => {
  const theme = useTheme()

  return (
    <Typography
      variant='h1'
      sx={{
        textTransform: 'uppercase',
        padding: '53px 0 60px',
        [theme.breakpoints.up(1200)]: { letterSpacing: '60px' },
        [theme.breakpoints.down(1200)]: { letterSpacing: '20px', fontSize: '60px' },
      }}
    >
      {title}
    </Typography>
  )
}
