import React, { FC } from 'react'
import { useDrop } from 'react-dnd'
import { TaskStatusInternalEnum } from '@state/task/taskStateSlice'
import { ExecutorProjectInterface, ExecutorTaskInterface } from '@state/executorTaskArr/executorTaskArrStateSlice'
import ProjectsTableTbodyCard from '@pages/ProjectsPage/ProjectsTableTbody/ProjectsTableTbodyColumn/ProjectsTableTbodyCard/ProjectsTableTbodyCard'
import styles from './ProjectsTableTbodyColumn.module.scss'

interface Props {
  status: TaskStatusInternalEnum
  executorTaskArr: ExecutorTaskInterface[] | ExecutorProjectInterface[]
}

const ProjectsTableTbodyColumn: FC<Props> = ({ status, executorTaskArr }: Props) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'status',
    drop: () => ({ name: status }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))
  const isActive = canDrop && isOver
  return (
    <div ref={drop} className={styles.wrapper} style={{ background: isActive ? 'rgba(59, 146, 253, 0.18)' : '' }} data-testid='dustbin'>
      {executorTaskArr?.map((executorTask: ExecutorTaskInterface | ExecutorProjectInterface) => (
        <ProjectsTableTbodyCard key={`${executorTask?.area}${executorTask?.id}${executorTask?.executor?.memberId}`} executorTask={executorTask} />
      ))}
    </div>
  )
}

export default ProjectsTableTbodyColumn
