import React, { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import { useQueryHook } from '@hooks/useQueryHook'
import styles from './RequestsSidebarButtons.module.scss'

const RequestsSidebarButtons: FC = () => {
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()

  const query: URLSearchParams = useQueryHook()
  const changeRadioHandler = (queryValue: string) => () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('relationship', queryValue)
    searchParams.set('chatRequestId', '')
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  return (
    <div className='flex-g'>
      <Button
        className={`${styles.button} ${query.get('relationship') != 'performer' && styles.button_active}`}
        onClick={changeRadioHandler('client')}
      >
        <span className='z-index-5-g'>Client</span>
      </Button>
      <Button
        className={`${styles.button} ${query.get('relationship') == 'performer' && styles.button_active}`}
        onClick={changeRadioHandler('performer')}
      >
        <span className='z-index-5-g'>Performer</span>
      </Button>
    </div>
  )
}

export default memo(RequestsSidebarButtons)
