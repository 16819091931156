import styled from '@emotion/styled'

export const CoinsCount = styled('div')`
  background-color: #dd2f6e;
  height: 20px;
  padding: 0 5px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-size: 12px;
`

export const UserName = styled('span')`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 2px;
  margin-bottom: 5px;
  text-transform: uppercase;
  height: 12px;
`

export const Count = styled('span')`
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  background-color: #dd2f6e;
  top: -6px;
  right: -10px;
  color: #fff;
`
