import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { toast } from 'react-hot-toast'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import RequestsMessageFileField from './RequestsMessageFileField/RequestsMessageFileField'
import RequestsChatSendFile from './RequestsChatSendFile/RequestsChatSendFile'
import RequestsMessageField from './RequestsMessageField/RequestsMessageField'
import { ReactComponent as TelegramSvgIcon } from '@icons/circleTelegramIcon.svg'
import { ReactComponent as SmileSvgIcon } from '@icons/smileIcon.svg'
import { minLengthValidationVar } from '@utils/fieldsValidationVariables'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { useQueryHook } from '@hooks/useQueryHook'
import { loadingMessageRequestArrAction } from '@state/requestArr/requestArrStateSlice'
import styles from './RequestsChatSendMessage.module.scss'

const validationSchema = yup.object().shape({
  message: yup.string().min(minLengthValidationVar.min, minLengthValidationVar.message),
  file: yup.string().notRequired(),
})

interface RequestsMessageValuesInterface {
  text: string
  file: string
}

const initialValues: RequestsMessageValuesInterface = {
  text: '',
  file: '',
}

const RequestsChatSendMessage: FC = () => {
  const dispatch = useDispatch()
  const query: URLSearchParams = useQueryHook()
  const submitHandler = async (
    fieldValues: RequestsMessageValuesInterface,
    { setErrors, setValues, setTouched }: FormikHelpers<RequestsMessageValuesInterface>,
  ) => {
    apiConnectAxios('/request-messages', 'post', { ...fieldValues, requestId: query.get('chatRequestId') }, true)
      .then(() => {
        dispatch(loadingMessageRequestArrAction())
        toast.success('Comment created')
        setValues({ text: '', file: '' })
        setTouched({})
      })
      .catch(error => {
        setErrors(error?.response?.data?.errors || {})
        toast.error(errorResponseReturn(error))
      })
  }
  return (
    <div className={styles.wrapper}>
      <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
        <Form className='align-items_start-g' autoComplete='off'>
          <Field name='file' component={RequestsMessageFileField} />
          <div className={styles.messageWrapper}>
            <RequestsChatSendFile />
            <Field name='text' component={RequestsMessageField} placeholder='Type your message...' />
          </div>
          <button className={styles.smileButton} type='button'>
            <SmileSvgIcon className={styles.smileIcon} />
          </button>
          <button className={styles.submitButton} type='submit'>
            <TelegramSvgIcon className={styles.telegramIcon} />
          </button>
        </Form>
      </Formik>
    </div>
  )
}

export default RequestsChatSendMessage
