import { FlexBox } from '../../Boxes/FlexBox'
import { LeftLine, RightLine, SubtitleWrap } from './styles'

interface SubtitleProps {
  subtitle: string
}

export const Subtitle: React.FC<SubtitleProps> = ({ subtitle }) => {
  return (
    <FlexBox sx={{ mb: '20px' }}>
      <RightLine />
      <SubtitleWrap variant='h4'>{subtitle}</SubtitleWrap>
      <LeftLine />
    </FlexBox>
  )
}
