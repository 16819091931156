import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { downloadFileHandler } from '@utils/downloadFileHandler'
import dayjs from 'dayjs'
import { Button } from '@mui/material'
import { Document, Page } from 'react-pdf'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ContractArrStateInterface, ContractInterface } from '@state/contractArr/contractArrStateSlice'
import { ReactComponent as DownloadIcon } from '@icons/downloadIcon.svg'
import { ReactComponent as CurrencyIcon } from '@icons/currencyIcon.svg'
import styles from './OffersTableTbody.module.scss'

const OffersTableTbody: FC = () => {
  const { contractArr }: ContractArrStateInterface = useSelector((state: AppStateInterface) => state.contractArrState)
  return (
    <tbody>
      {!!contractArr?.length &&
        contractArr?.map((contract: ContractInterface) => (
          <tr key={contract?.id}>
            <td className={styles.td}>
              <div className={styles.tdName}>{contract?.name}</div>
            </td>
            <td className={styles.td}>
              <div className={styles.tdDiv}>{contract?.status}</div>
            </td>
            <td className={styles.td}>
              <div className={styles.tdDiv}>{dayjs(contract?.createdAt).format('YYYY-DD-MM')}</div>
            </td>
            <td className={styles.td}>
              <div className={styles.tdDiv}>
                {contract?.fileSource && (
                  <div className={styles.documentWrapper}>
                    <Button className={styles.fileButton} type='button' onClick={downloadFileHandler(contract?.fileSource, contract?.name)}>
                      <Document file={contract?.fileSource}>
                        <Page pageNumber={1} />
                      </Document>
                      <div className={styles.fileBg}>
                        <DownloadIcon className={styles.downloadIcon} />
                      </div>
                    </Button>
                    <div className={styles.nameWrapper}>
                      <div className={styles.name}>{`${contract?.name}.pdf`}</div>
                      <div className={styles.documentSize}>{contract?.size}</div>
                    </div>
                  </div>
                )}
              </div>
            </td>
            <td className={styles.td}>
              <div className={styles.tdDiv}>
                {contract?.budget}
                <CurrencyIcon className={styles.currencyIcon} />
              </div>
            </td>
          </tr>
        ))}
    </tbody>
  )
}

export default memo(OffersTableTbody)
