import axios from 'axios'
import { User } from 'firebase/auth'
import Cookies from 'universal-cookie'
import { ProfileInterface } from '@state/profile/profileStateSlice'

class ProfileService {
  private readonly cookie: Cookies

  constructor() {
    this.cookie = new Cookies()
  }
  public async getCurrentProfile(): Promise<ProfileInterface> {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_APIDOMAIN}/members`, {
      headers: {
        'access-token': this.cookie.get('access-token'),
      },
    })

    return response.data
  }

  public async createUserProfile(user: User): Promise<ProfileInterface> {
    const idToken = await user.getIdToken(/* forceRefresh */ true)
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_APIDOMAIN}/members`, {}, { headers: { 'access-token': idToken } })

    return response.data
  }
}

export default ProfileService
