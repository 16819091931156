import React, { FC, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import BlueButton from '@buttons/BlueButton/BlueButton'
import ProfileSettingsFormLogic from './ProfileSettingsFormLogic/ProfileSettingsFormLogic'
import LoadingTransparentSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import LoadPhotoField from '@fields/LoadPhotoField/LoadPhotoField'
import FormField from '@fields/FormField/FormField'
import TextareaField from '@fields/TextareaField/TextareaField'
import { ProfileStateInterface, updateProfileAction, ProfileInterface } from '@state/profile/profileStateSlice'
import { minLengthValidationVar, minTextareaLengthValidationVar, requiredValidationVar, URLValidationVar } from '@utils/fieldsValidationVariables'
import { AppStateInterface } from '@state/_store/createRootReducer'
import styles from './ProfileSettingsForm.module.scss'

const validationSchema = yup.object().shape({
  firstName: yup.string().required(requiredValidationVar).min(minLengthValidationVar.min, minLengthValidationVar.message),
  lastName: yup.string().required(requiredValidationVar).min(minLengthValidationVar.min, minLengthValidationVar.message),
  bio: yup.string().min(minTextareaLengthValidationVar.min, minTextareaLengthValidationVar.message),
  facebookLink: yup.string().url(URLValidationVar),
  linkedinLink: yup.string().url(URLValidationVar),
  websiteLink: yup.string().url(URLValidationVar),
  location: yup.string().url(URLValidationVar),
  skills: yup.string(),
  avatar: yup.string().notRequired(),
})

export interface ProfileFormValuesInterface {
  firstName: string
  lastName: string
  bio: string
  facebookLink: string
  twitterLink: string
  linkedinLink: string
  websiteLink: string
  location: string
  skills: string
  avatar: string
}

const ProfileSettingsForm: FC = () => {
  const dispatch = useDispatch()
  const { profile, isProfileLoading }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const initialValues: ProfileFormValuesInterface = {
    firstName: profile?.firstName || '',
    lastName: profile?.lastName || '',
    bio: profile?.bio || '',
    facebookLink: profile?.facebookLink || '',
    twitterLink: profile?.twitterLink || '',
    linkedinLink: profile?.linkedinLink || '',
    websiteLink: profile?.websiteLink || '',
    location: profile?.location || '',
    skills: profile?.skills?.join(',') || '',
    avatar: profile?.avatar || '',
  }
  const submitHandler = async (fieldValues: ProfileFormValuesInterface, { setErrors }: FormikHelpers<ProfileFormValuesInterface>) => {
    const newProfile: ProfileInterface = {
      ...profile,
      firstName: fieldValues?.firstName,
      lastName: fieldValues?.lastName,
      bio: fieldValues?.bio || null,
      facebookLink: fieldValues?.facebookLink || null,
      twitterLink: fieldValues?.twitterLink || null,
      linkedinLink: fieldValues?.linkedinLink || null,
      websiteLink: fieldValues?.websiteLink || null,
      location: fieldValues?.location || null,
      skills: fieldValues.skills?.split(', ') || null,
      avatar: fieldValues?.avatar || null,
    }
    dispatch(updateProfileAction({ profile: newProfile, setErrors }))
  }
  return (
    <>
      <HelmetTitle title='Profile Settings' />
      <div className={styles.wrapper}>
        <LoadingTransparentSpin isLoading={isProfileLoading} />
        <h2 className={styles.title}>Settings</h2>
        <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
          <Form className={styles.form} autoComplete='off'>
            <ProfileSettingsFormLogic profile={profile} />
            <Field name='firstName' label='First Name' component={FormField} />
            <Field name='lastName' label='Last Name' component={FormField} />
            <Field name='avatar' component={LoadPhotoField} title='Profile photo' buttonTitle='Choose image' />
            <Field
              name='bio'
              placeholder='Bio'
              label='BIO (MUST BE AT LEAST OF 150 CHARACTERS IN ORDER TO GAIN 2 SKILLCOINS)'
              component={TextareaField}
            />
            <Field
              name='facebookLink'
              placeholder='URL...'
              label='FACEBOOK (CONNECT YOUR ACCOUNT IN ORDER TO GAIN 2 SKILLCOINS)'
              component={FormField}
            />
            <Field
              name='linkedinLink'
              placeholder='URL...'
              label='LINKEDIN (CONNECT YOUR ACCOUNT IN ORDER TO GAIN 2 SKILLCOINS)'
              component={FormField}
            />
            <Field name='websiteLink' placeholder='URL...' label='YOUR WEBSITE/BLOG' component={FormField} />
            <Field name='location' placeholder='URL...' label='LOCATION' component={FormField} />
            <Field
              name='skills'
              placeholder='Add skills separated by commas'
              label='SKILLS (ADD AS MANY AS YOU LIKE) - YOUD HAVE AT LEAST ONE SKILL'
              component={FormField}
            />
            <BlueButton title='Apply changes' type='submit' sx={{ marginTop: '30px' }} />
          </Form>
        </Formik>
      </div>
    </>
  )
}

export default memo(ProfileSettingsForm)
