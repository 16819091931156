import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MemberInterface } from '@state/profile/profileStateSlice'
import { TaskInterface } from '@state/task/taskStateSlice'
import { ProjectInterface } from '@state/project/projectStateSlice'
import { FileTypeEnum } from '@interfaces/interfaces'
import { DocumentRequestInterface, TypeDocumentEnum } from '@state/documentArr/documentArrStateSlice'

export enum AreaRequestEnum {
  Project = 'Project',
  Task = 'Task',
}
export enum StatusRequestEnum {
  Created = 'Created',
  Approved = 'Approved',
  Canceled = 'Canceled',
  Denied = 'Denied',
}

enum RankRequestEnum {
  WillWee = 'WillWee',
  Best = 'Best',
  NotFit = 'NotFit',
}

export enum StageMessageByRequestEnum {
  StatusStage = 'StatusStage',
  Start = 'Start',
  OnCreatorSign = 'OnCreatorSign',
  OnExecutorSign = 'OnExecutorSign',
  BothSigned = 'BothSigned',
}

export enum FileByMessageRequestTypeEnum {
  JPG = FileTypeEnum.JPG,
  JPEG = FileTypeEnum.JPEG,
  PNG = FileTypeEnum.PNG,
  PDF = FileTypeEnum.PDF,
  TXT = FileTypeEnum.TXT,
  DOC = FileTypeEnum.DOC,
}

export interface FileMessageRequestInterface {
  messageId: number
  name: string
  fileType: FileByMessageRequestTypeEnum
  fileSource: string
}

export interface MessageRequestInterface {
  id: number
  sendByMemberId: number
  requestId: number
  text: string
  systemStatus: StatusRequestEnum
  systemType: TypeDocumentEnum
  systemStage: StageMessageByRequestEnum
  file: FileMessageRequestInterface
  createdAt: Date
}

export interface RequestInterface {
  id: number | string
  document?: DocumentRequestInterface
  clientId: number
  client: MemberInterface
  performerRank: RankRequestEnum
  performerId: number
  performer: MemberInterface
  area: AreaRequestEnum
  taskId?: number
  task?: TaskInterface
  projectId?: number
  project?: ProjectInterface
  name: string
  status: StatusRequestEnum
  ownHours: number
  skillCoinsTotal: number
  isAltruistic?: boolean
  messageArr: MessageRequestInterface[]
  createdAt: Date
  isAutomaticSendingNda: boolean
  isRequiredNda: boolean
  isRequiredContract: boolean
}

export interface RequestArrStateInterface {
  isRequestArrLoading: boolean
  isRequestArrFirstLoading: boolean
  isMessageArrLoading: boolean
  isMessageArrFirstLoading: boolean
  isDocumentRequestLoading: boolean
  isDocumentRequestFirstLoading: boolean
  clientRequestArr: null | RequestInterface[]
  performerRequestArr: null | RequestInterface[]
  requestMessageArr: null | MessageRequestInterface[]
  unreadMessagesCount: number
  requestChat: null | RequestInterface
  documentRequest: null | DocumentRequestInterface
  contractRequest: null | DocumentRequestInterface
}

const initialRequestArrState: RequestArrStateInterface = {
  isRequestArrLoading: true,
  isRequestArrFirstLoading: false,
  isMessageArrLoading: true,
  isMessageArrFirstLoading: false,
  isDocumentRequestLoading: true,
  isDocumentRequestFirstLoading: false,
  clientRequestArr: null,
  performerRequestArr: null,
  requestMessageArr: null,
  unreadMessagesCount: 0,
  requestChat: null,
  documentRequest: null,
  contractRequest: null,
}

const requestArrState = createSlice({
  name: 'requestArrState',
  initialState: initialRequestArrState,
  reducers: {
    loadingRequestArrAction(state) {
      state.isRequestArrLoading = true
    },
    loadingRequestArrFailure(state) {
      state.isRequestArrLoading = false
      state.isRequestArrFirstLoading = true
    },
    loadingRequestArrSuccess(
      state,
      {
        payload: { clientRequestArr, performerRequestArr, unreadMessagesCount },
      }: PayloadAction<{
        clientRequestArr: RequestInterface[]
        performerRequestArr: RequestInterface[]
        unreadMessagesCount: number
      }>,
    ) {
      state.clientRequestArr = clientRequestArr
      state.performerRequestArr = performerRequestArr
      state.unreadMessagesCount = unreadMessagesCount
      state.isRequestArrLoading = false
      state.isRequestArrFirstLoading = true
    },
    loadingMessageRequestArrAction(state) {
      state.isMessageArrLoading = true
    },
    loadingMessageRequestArrFailure(state) {
      state.isMessageArrLoading = false
      state.isMessageArrFirstLoading = true
    },
    loadingMessageRequestArrSuccess(
      state,
      {
        payload: { requestMessageArr, requestChat },
      }: PayloadAction<{
        requestMessageArr: null | MessageRequestInterface[]
        requestChat: RequestInterface
      }>,
    ) {
      state.isMessageArrLoading = false
      state.isMessageArrFirstLoading = true
      state.requestMessageArr = requestMessageArr
      state.requestChat = requestChat
      if (requestChat?.document) {
        state.documentRequest = requestChat?.document
      }
    },
    loadingDocumentRequestArrAction(state) {
      state.isDocumentRequestLoading = true
    },
    loadingDocumentRequestArrFailure(state) {
      state.isDocumentRequestLoading = false
      state.isDocumentRequestFirstLoading = true
    },
    loadingDocumentRequestArrSuccess(
      state,
      {
        payload: { documentRequest, contractRequest },
      }: PayloadAction<{
        documentRequest: null | DocumentRequestInterface
        contractRequest: null | DocumentRequestInterface
      }>,
    ) {
      state.isDocumentRequestLoading = false
      state.isDocumentRequestFirstLoading = true
      state.documentRequest = documentRequest
      state.contractRequest = contractRequest
    },
  },
})

export const {
  loadingRequestArrAction,
  loadingRequestArrFailure,
  loadingRequestArrSuccess,
  loadingMessageRequestArrAction,
  loadingMessageRequestArrFailure,
  loadingMessageRequestArrSuccess,
  loadingDocumentRequestArrAction,
  loadingDocumentRequestArrFailure,
  loadingDocumentRequestArrSuccess,
} = requestArrState.actions

export default requestArrState.reducer
