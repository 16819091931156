import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialProfile, MemberInterface } from '@state/profile/profileStateSlice'

export interface UserStateInterface {
  isUserLoading: boolean
  isUserFirstLoading: boolean
  userProfile: MemberInterface
}

const initialUserState: UserStateInterface = {
  isUserLoading: true,
  isUserFirstLoading: false,
  userProfile: initialProfile,
}

const userState = createSlice({
  name: 'userState',
  initialState: initialUserState,
  reducers: {
    loadingUserAction(state) {
      state.isUserLoading = true
    },
    loadingUserFailure(state) {
      state.isUserLoading = false
      state.isUserFirstLoading = true
    },
    loadingUserSuccess(state, { payload: { userProfile } }: PayloadAction<{ userProfile: MemberInterface }>) {
      state.userProfile = userProfile
      state.isUserLoading = false
      state.isUserFirstLoading = true
    },
  },
})

export const { loadingUserAction, loadingUserFailure, loadingUserSuccess } = userState.actions

export default userState.reducer
