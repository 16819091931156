import React, { FC, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import StartsUserBox from '@components/userComponents/StartsUserBox/StartsUserBox'
import AddCommentNews from './AddCommentNews/AddCommentNews'
import CommentNews from './CommentNews/CommentNews'
import EditCommentNews from './EditCommentNews/EditCommentNews'
import CommentNewsPagination from './CommentNewsPagination/CommentNewsPagination'
import BluePopover, { PopoverInterface } from '@components/popovers/BluePopover/BluePopover'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import { deleteNewsArrAction, NewsArrStateInterface } from '@state/newsArr/newsArrStateSlice'
import { CommentByNewsInterface, NewsInterface } from '@state/news/newsStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import styles from './ProjectNewsItem.module.scss'

interface Props {
  news: NewsInterface
}

const ProjectNewsItem: FC<Props> = ({ news }: Props) => {
  const history = useHistory<History>()
  const { profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const { commentLoadingNewsId }: NewsArrStateInterface = useSelector((state: AppStateInterface) => state.newsArrState)
  const dispatch = useDispatch()
  const clickPushEditNews = () => {
    history.push(`/edit-project-news/${news?.id}`)
  }
  const clickDeleteNews = () => {
    if (news?.id) dispatch(deleteNewsArrAction({ newsId: news.id }))
  }
  const popoverArr: PopoverInterface[] = [
    {
      title: 'Edit',
      clickFunction: clickPushEditNews,
    },
    {
      title: 'Delete',
      clickFunction: clickDeleteNews,
    },
  ]
  const buttonRender = (id: string, openHandler: (event: React.MouseEvent<HTMLButtonElement>) => void) => (
    <button className={styles.dotsButton} onClick={openHandler} aria-describedby={id} type='button'>
      <MoreHorizIcon className={styles.dots} />
    </button>
  )
  return (
    <div className={styles.wrapper}>
      <div className='flex-g'>
        {news?.member && (
          <StartsUserBox
            memberId={news.member?.memberId}
            avatar={news.member?.avatar}
            firstName={news.member?.firstName}
            lastName={news.member?.lastName}
            rating={Number((Math.random() * 5).toFixed(1))}
            reviews={Math.floor(Math.random() * 99)}
          />
        )}
        {profile?.memberId == news?.member?.memberId && <BluePopover buttonRender={buttonRender} popoverArr={popoverArr} />}
      </div>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={news?.image} alt='' />
        <div className={styles.titleWrapper}>
          <h3 className={styles.title}>{news?.title}</h3>
          <div className={styles.description}>{news?.description}</div>
        </div>
      </div>
      <div className={styles.commentWrapper}>
        <AddCommentNews newsId={news?.id} />
        {!!news?.commentByNews?.commentByNewsArr?.length &&
          news.commentByNews.commentByNewsArr.map((comment: CommentByNewsInterface) => (
            <Fragment key={comment.id}>
              {profile?.memberId == comment.memberId && <EditCommentNews key={comment.id} comment={comment} page={news?.commentByNews?.page} />}
              {profile?.memberId != comment.memberId && <CommentNews key={comment.id} comment={comment} />}
            </Fragment>
          ))}
        {!!news?.commentByNews?.commentByNewsCount && news.id && news.commentByNews.commentByNewsCount > 10 && (
          <CommentNewsPagination page={news.commentByNews.page} pageCount={Math.ceil(news.commentByNews.commentByNewsCount / 10)} newsId={news.id} />
        )}
        <LoadingBlockSpin isLoading={commentLoadingNewsId == news?.id} />
      </div>
    </div>
  )
}

export default ProjectNewsItem
