import { FC, memo, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { toast } from 'react-hot-toast'
import { loadingNewsAction, NewsParamsInterface, NewsStateInterface } from '@state/news/newsStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { TagInterface } from '@interfaces/interfaces'

const CreateNewsPageLogic: FC = () => {
  const { setValues, setErrors, setTouched } = useFormikContext()
  const { newsId } = useParams<NewsParamsInterface>()
  const history = useHistory<History>()
  const { isNewsLoading, news }: NewsStateInterface = useSelector((state: AppStateInterface) => state.newsState)
  const { isProfileFirstLoading, profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const dispatch = useDispatch()
  useEffect(() => {
    if (newsId && !Number.isInteger(Number(newsId))) {
      toast.error('ID must be number')
      history.push('/create-project-news')
    } else if (isProfileFirstLoading && newsId && Number.isInteger(Number(newsId))) {
      dispatch(loadingNewsAction())
    }
  }, [newsId, isProfileFirstLoading, dispatch])
  const isMounted = useRef<boolean>(false)
  useEffect(() => {
    if (!isMounted.current || history?.location?.pathname === '/create-project-news') {
      isMounted.current = true
      setValues({
        projectId: '',
        title: '',
        description: '',
        label: '',
        tags: '',
        image: '',
      })
      setTouched({})
      setErrors({})
    } else if (profile?.memberId && newsId && isProfileFirstLoading && news?.createdBy && !isNewsLoading) {
      if (news?.createdBy == profile?.memberId) {
        setValues({
          projectId: news?.projectId || '',
          title: news?.title || '',
          description: news?.description || '',
          label: news?.label || '',
          tags: news?.tags?.map((tag: TagInterface) => tag?.name)?.join(', ') || '',
          image: news?.image || '',
        })
      } else {
        toast.error('No access')
        history.push('/create-project-news')
      }
    }
  }, [newsId, isProfileFirstLoading, news?.createdBy, isNewsLoading, history?.location?.pathname])
  return null
}

export default memo(CreateNewsPageLogic)
