import React, { FC, memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import TasksUserPopover from './TasksUserPopover/TasksUserPopover'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ExecutorTaskArrStateInterface, loadingUsersExecutorTaskArrAction } from '@state/executorTaskArr/executorTaskArrStateSlice'
import { MemberInterface } from '@state/profile/profileStateSlice'
import styles from '@components/userComponents/ProjectUserPopoverBox/ProjectUserPopoverBox.module.scss'

const TasksUserPopoverBox: FC = () => {
  const { taskExecutorArr, taskExecutorCount }: ExecutorTaskArrStateInterface = useSelector((state: AppStateInterface) => state.executorTaskArrState)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!taskExecutorArr?.length) {
      dispatch(loadingUsersExecutorTaskArrAction({ page: 1, limit: 6 }))
    }
  }, [dispatch])
  const newTaskExecutorArr: MemberInterface[] = taskExecutorArr.slice(0, taskExecutorArr?.length > 3 ? 4 : taskExecutorArr?.length)
  return (
    <div className={styles.avatarWrapper}>
      {!!newTaskExecutorArr?.length &&
        newTaskExecutorArr?.map((member: MemberInterface) => (
          <div className={styles.linkWrapper} key={member?.memberId}>
            {member?.avatar && (
              <Link className={styles.avatarLink} to={`/user/${member?.memberId}`}>
                <img className={styles.avatar} src={member?.avatar} alt='' />
              </Link>
            )}
          </div>
        ))}
      {taskExecutorArr && taskExecutorCount > 4 && <TasksUserPopover />}
    </div>
  )
}

export default memo(TasksUserPopoverBox)
