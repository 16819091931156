import { FC, Fragment, memo } from 'react'
import StarIcon from '@mui/icons-material/Star'
import StarHalfIcon from '@mui/icons-material/StarHalf'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import styles from './TasksStarts.module.scss'
interface StartIconArrInterface {
  id: number
}
interface Props {
  rating: number
}
const startIconArr: StartIconArrInterface[] = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]
const TasksStarts: FC<Props> = ({ rating }: Props) => {
  return (
    <div className={styles.wrapper}>
      {startIconArr.map(({ id }: StartIconArrInterface) => (
        <Fragment key={id}>
          {rating >= id && <StarIcon className={styles.starIcon} />}
          {rating < id && rating + 1 > id && <StarHalfIcon className={styles.starIcon} />}
          {rating + 1 <= id && <StarBorderIcon className={styles.starIcon} />}
        </Fragment>
      ))}
    </div>
  )
}

export default memo(TasksStarts)
