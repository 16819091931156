import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import defaultAvatarPng from '@images/avatar.png'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import styles from './AvatarUser.module.scss'

interface Props {
  memberId: string | number | null | undefined
  avatar: string | null | undefined
  sizePx?: number
}

const AvatarUser: FC<Props> = ({ memberId, avatar, sizePx }: Props) => {
  const { profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const link: string = memberId && profile?.memberId && memberId == profile?.memberId ? '/settings' : (memberId && `/user/${memberId}`) || ''
  const style = sizePx ? { minWidth: `${sizePx}px`, width: `${sizePx}px`, height: `${sizePx}px` } : {}
  return (
    <Link className={styles.avatarLink} to={link} style={style}>
      <img className={styles.avatar} src={avatar || defaultAvatarPng} alt='' />
    </Link>
  )
}

export default memo(AvatarUser)
