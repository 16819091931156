import React, { ChangeEvent, FC } from 'react'
import { FieldInputProps, FormikProps, useField } from 'formik'
import { ReactComponent as TelegramSvgIcon } from '@icons/circleTelegramIcon.svg'
import styles from './AddDocumentRequestsFileField.module.scss'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
}

const AddDocumentRequestsFileField: FC<Props> = ({ field: { name, value, onBlur } }: Props) => {
  const [, , { setValue }] = useField(name)
  const changeValueHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }
  return (
    <div className={styles.wrapper}>
      <input
        className={styles.input}
        name={name}
        value={value}
        onChange={changeValueHandler}
        onBlur={onBlur}
        type='text'
        placeholder='Type your message...'
      />
      <button className={styles.submitButton} type='submit'>
        <TelegramSvgIcon className={styles.telegramIcon} />
      </button>
    </div>
  )
}

export default AddDocumentRequestsFileField
