import { FC, memo, useEffect } from 'react'
import { useQueryHook } from '@hooks/useQueryHook'
import { useDispatch } from 'react-redux'
import { loadingExecutorTaskArrAction } from '@state/executorTaskArr/executorTaskArrStateSlice'

interface Props {
  selectedMinDate: Date
  selectedMaxDate: Date
}
const TransactionsTaskCalendarLogic: FC<Props> = ({ selectedMinDate, selectedMaxDate }: Props): null => {
  const query: URLSearchParams = useQueryHook()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadingExecutorTaskArrAction({ minDate: selectedMinDate, maxDate: selectedMaxDate }))
  }, [query.get('sort'), selectedMinDate, selectedMaxDate, dispatch])

  return null
}

export default memo(TransactionsTaskCalendarLogic)
