import React, { FC, useId } from 'react'
import { FieldInputProps, FormikProps, useField } from 'formik'
import fieldStyles from './CheckboxField.module.scss'
import { FormControlLabel, Switch } from '@mui/material'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  label: string
  anotherName?: string
}

const CheckboxField: FC<Props> = ({ field: { name, value }, label }: Props) => {
  const id: string = useId()
  const [, , { setValue }] = useField(name)
  const onChangeHandler = (_event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    setValue(isChecked)
  }
  return (
    <div className='column-align_start-g'>
      <label htmlFor={id} className={fieldStyles.titleStrict}>
        {label}
      </label>
      <FormControlLabel id={id} control={<Switch checked={value} onChange={onChangeHandler} />} label={value ? 'Yes' : 'No'} />
    </div>
  )
}

export default CheckboxField
