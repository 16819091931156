import { FC, memo } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { toast, ToastBar, Toaster } from 'react-hot-toast'
import { toastOptions } from './styles'

const Toast: FC = () => {
  return (
    <Toaster toastOptions={toastOptions} position='bottom-right'>
      {t => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && <CloseIcon onClick={() => toast.dismiss(t.id)} cursor='pointer' />}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default memo(Toast)
