import React, { FC, useId } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { FieldInputProps, FormikProps, useField } from 'formik'
import { FormControlLabel, Switch, Tooltip } from '@mui/material'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import fieldStyles from './SendingNDACheckboxField.module.scss'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  label: string
  anotherName?: string
}

const SendingNDACheckboxField: FC<Props> = ({ field: { name, value }, label }: Props) => {
  const [, , { setValue }] = useField(name)
  const [, , { setValue: setContractValue }] = useField('isRequiredContract')
  const { isProfileLoading, isProfileFirstLoading, profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const { url } = useRouteMatch()
  const isProjectPage: boolean = url?.includes('project')
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    if (!isProfileLoading && isProfileFirstLoading && profile) {
      if (isChecked) {
        apiConnectAxios(isProjectPage ? '/document-default-project' : '/document-default-task')
          .then((data: any) => {
            if (data?.data) {
              setValue(true)
              setContractValue(true)
            } else if (!data?.data) {
              toast.error(`Fist create ${isProjectPage ? 'project' : 'task'} NDA drafts and select file`)
            }
          })
          .catch(error => {
            toast.error(errorResponseReturn(error))
          })
      } else {
        setValue(false)
      }
    } else {
      toast.error('Log in first')
    }
  }
  const id: string = useId()
  return (
    <div className='column-align_start-g'>
      <Tooltip title={<div className={fieldStyles.tooltipTitle}>Enables automatic sending default NDA and will remind about Contract</div>}>
        <label htmlFor={id} className={fieldStyles.title}>
          {label}
        </label>
      </Tooltip>
      <FormControlLabel id={id} name={name} control={<Switch checked={value || false} onChange={onChangeHandler} />} label={value ? 'Yes' : 'No'} />
    </div>
  )
}

export default SendingNDACheckboxField
