import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MemberInterface } from '@state/profile/profileStateSlice'
import { TagInterface } from '@interfaces/interfaces'

export interface NewsParamsInterface {
  newsId: string
}
export interface CommentByNewsInterface {
  id: number
  newsId: number
  memberId: number
  member: MemberInterface
  text: string
  image: string
}

export interface CommentByNewsPageInterface {
  commentByNewsArr: CommentByNewsInterface[]
  page: number
  commentByNewsCount: number
}
export interface NewsInterface {
  createdBy: number | null
  id: number | null
  projectId: number | string | null
  title: string
  description: string
  label: string
  tags: TagInterface[]
  image: string
  member: MemberInterface | null
  memberId: string | number | null
  commentByNews: CommentByNewsPageInterface | null
}

const initialNews: NewsInterface = {
  createdBy: null,
  id: null,
  projectId: null,
  title: '',
  description: '',
  label: '',
  tags: [],
  image: '',
  member: null,
  memberId: null,
  commentByNews: null,
}

export interface NewsStateInterface {
  isNewsLoading: boolean
  isNewsFirstLoading: boolean
  news: NewsInterface
}

const initialNewsState: NewsStateInterface = {
  isNewsLoading: true,
  isNewsFirstLoading: false,
  news: initialNews,
}

const newsState = createSlice({
  name: 'newsState',
  initialState: initialNewsState,
  reducers: {
    loadingNewsAction(state) {
      state.isNewsLoading = true
    },
    loadingNewsFailure(state) {
      state.isNewsLoading = false
      state.isNewsFirstLoading = true
    },
    loadingNewsSuccess(state, { payload: { news } }: PayloadAction<{ news: NewsInterface }>) {
      state.news = news
      state.isNewsLoading = false
      state.isNewsFirstLoading = true
    },
  },
})

export const { loadingNewsAction, loadingNewsFailure, loadingNewsSuccess } = newsState.actions

export default newsState.reducer
