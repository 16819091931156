import React from 'react'
import { Container } from '@mui/material'
import { ContactPage } from '../components/screens/ContactPageScreen/ContactPage'
import HelmetTitle from '../components/titles/HelmetTitle/HelmetTitle'

export const Contact: React.FC = () => {
  return (
    <>
      <HelmetTitle title='Contact' />
      <Container>
        <ContactPage />
      </Container>
    </>
  )
}
