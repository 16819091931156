import React, { FC, memo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import PDFFileField from '@fields/PDFFileField/PDFFileField'
import AddDocumentRequestsFileField from '@pages/RequestsPage/SearchUserRequests/AddDocumentRequestsClientForm/AddDocumentRequestsFileField/AddDocumentRequestsFileField'
import AddNDARequestsFormLogic from '@pages/RequestsPage/SearchUserRequests/AddDocumentRequestsClientForm/AddDocumentRequestsFormLogic/AddDocumentRequestsFormLogic'
import { requiredValidationVar } from '@utils/fieldsValidationVariables'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { openHook } from '@hooks/openHook'
import { loadingDocumentRequestArrAction, loadingMessageRequestArrAction, RequestArrStateInterface } from '@state/requestArr/requestArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { LegalDocumentEnum, TypeDocumentEnum } from '@state/documentArr/documentArrStateSlice'
import styles from './AddDocumentRequestsClientForm.module.scss'

interface DocumentValuesInterface {
  text: string
  file: string
  name: string
  size: string
  legal: string
  type: string
}

const validationSchema = yup.object().shape({
  text: yup.string(),
  file: yup.string().required(requiredValidationVar),
  name: yup.string(),
})

interface Props {
  documentType: TypeDocumentEnum
}

const AddDocumentRequestsClientForm: FC<Props> = ({ documentType }: Props) => {
  const { requestChat }: RequestArrStateInterface = useSelector((state: AppStateInterface) => state.requestArrState)
  const dispatch = useDispatch()
  const { isOpen, setOpen, openHandler, closeHandler } = openHook()
  const initialValues: DocumentValuesInterface = {
    text: '',
    file: '',
    name: '',
    size: '',
    legal: LegalDocumentEnum?.B2B,
    type: documentType,
  }
  const { url } = useRouteMatch()
  const isProjectRequestPage: boolean = url?.includes('project')
  const submitHandler = async (fieldValues: DocumentValuesInterface, { setErrors }: FormikHelpers<DocumentValuesInterface>) => {
    console.log(111, fieldValues)
    apiConnectAxios('/request-documents/client', 'post', { ...fieldValues, requestId: requestChat?.id }, true)
      .then((): void => {
        toast.success(`${documentType} created`)
        dispatch(loadingDocumentRequestArrAction())
        dispatch(loadingMessageRequestArrAction())
        setOpen(false)
      })
      .catch(error => {
        setErrors(error?.response?.data?.errors || {})
        toast.error(errorResponseReturn(error))
      })
  }
  return (
    <>
      <Button className={styles.NDAButton} type='button' onClick={openHandler}>
        {`Add ${documentType}`}
      </Button>
      <Dialog className={styles.modalWrapper} open={isOpen} onClose={closeHandler} scroll='body'>
        <div>
          <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
            <Form className={styles.form} autoComplete='off'>
              {documentType === TypeDocumentEnum.NDA && <AddNDARequestsFormLogic />}
              <h4 className={styles.title}>{`This ${isProjectRequestPage ? 'project' : 'task'} requires an ${documentType} to be added`}</h4>
              <div className={styles.fileWrapper}>
                <Field name='file' component={PDFFileField} size='small' />
              </div>
              <Field name='text' component={AddDocumentRequestsFileField} />
            </Form>
          </Formik>
        </div>
      </Dialog>
    </>
  )
}

export default memo(AddDocumentRequestsClientForm)
