import React, { FC, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { Select, SelectChangeEvent } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuItem from '@mui/material/MenuItem'
import { useQueryHook } from '@hooks/useQueryHook'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProjectInterface } from '@state/project/projectStateSlice'
import { loadingProjectArrAction, ProjectArrStateInterface } from '@state/projectArr/projectArrStateSlice'
import styles from './ProjectNewsSelect.module.scss'
import fieldStyles from '@fields/FormField/FormField.module.scss'
import { openHook } from '@hooks/openHook'

const ProjectNewsSelect: FC = () => {
  const { isProjectArrFirstLoading, isProjectArrLoading, projectArr }: ProjectArrStateInterface = useSelector(
    (state: AppStateInterface) => state.projectArrState,
  )
  const { isOpen, openHandler, closeHandler } = openHook()
  const [value, setValue] = useState<string>('')
  const query: URLSearchParams = useQueryHook()
  const history = useHistory<History>()
  const changeValueHandler = (event: SelectChangeEvent) => {
    history.push(`/project-news/${event.target.value}?page=${Number(query.get('page')) || 1}`)
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadingProjectArrAction())
  }, [dispatch])
  const { projectId } = useParams<{ projectId: string }>()
  useEffect(() => {
    if (projectId && projectArr) {
      const projectFound: ProjectInterface | undefined = projectArr?.find(({ id }: ProjectInterface): boolean => id == Number(projectId))
      if (projectFound) setValue(String(projectFound?.id))
    }
  }, [projectId, projectArr])
  const isMounted = useRef<boolean>(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else if (isProjectArrFirstLoading && projectArr?.length === 0 && isProjectArrLoading === false) {
      toast.error('First create a project')
      history.push('/create-project')
    }
  }, [isProjectArrFirstLoading, isProjectArrLoading, projectArr])
  return (
    <div className={styles.wrapper}>
      <Select
        className={fieldStyles.select}
        open={isOpen}
        value={value}
        onChange={changeValueHandler}
        onClose={closeHandler}
        onOpen={openHandler}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{ MenuListProps: { style: { background: 'rgba(30, 14, 63)' } } }}
      >
        {projectArr?.map(({ id, name, icon }: ProjectInterface) => (
          <MenuItem className='flex-g' key={value} value={id}>
            <div className={fieldStyles.iconWrapper}>
              {icon && <img className={fieldStyles.icon} src={icon} alt='' />}
              {name}
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}
export default ProjectNewsSelect
