import { FC } from 'react'
import { ColorsButton } from '@buttons/ColorsButton/ColorsButton'
import { Box, useTheme } from '@mui/material'
import GoogleOneTapLogin from 'react-google-one-tap-login'
import { authHeaderHooks } from '../../hooks/authHeaderHooks'
import { useAuth } from '../../layouts/AuthContext'
import { JoinUs } from '../popups/JoinUsScreen/JoinUs'

export const LoggedOutSection: FC = () => {
  const { closePopups, openJoinUs, closeJoinUs, isJoinUsOpen } = authHeaderHooks()
  const { handleGoogleCredentialAuth } = useAuth()
  const theme = useTheme()
  return (
    <>
      <Box component='div' sx={{ display: 'flex', padding: theme.spacing(1.5), fontSize: '14px' }}>
        <ColorsButton onClick={openJoinUs} title='Join Us' buttonSize='medium' backgroundColor={theme.palette.secondary.main} />
      </Box>

      <JoinUs isJoinUsOpen={isJoinUsOpen} closeJoinUs={closeJoinUs} closePopups={closePopups} />
      <GoogleOneTapLogin
        onSuccess={(response: unknown) => {
          console.log(response)
        }}
        onError={(err: unknown) => {
          console.log(err)
        }}
        googleAccountConfigs={{
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
          callback(response) {
            handleGoogleCredentialAuth(response.credential)
          },
        }}
      />
    </>
  )
}
