import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from '@mui/material'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import App from './App'
import { configureStore, history } from '@state/_store/configureStore'
import reportWebVitals from './reportWebVitals'
import { basicThemeStyle } from './theme/BasicThemeStyle'
import { StyledEngineProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import '@assets/css/reset.scss'
import '@assets/css/globalStyles.scss'

const store = configureStore()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <HelmetProvider>
        <ThemeProvider theme={basicThemeStyle}>
          <StyledEngineProvider injectFirst>
            {/* Drag and drop provider */}
            <DndProvider debugMode={true} backend={HTML5Backend}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <App />
              </LocalizationProvider>
            </DndProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </HelmetProvider>
    </ConnectedRouter>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
