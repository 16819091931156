import React, { FC, Fragment, memo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryHook } from '@hooks/useQueryHook'
import StarIcon from '@mui/icons-material/Star'
import StarHalfIcon from '@mui/icons-material/StarHalf'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import UserBalance from '@components/userComponents/UserBalance/UserBalance'
import { loadingProfileAction, MemberInterface, ProfileInterface, ProfileStateInterface } from '@state/profile/profileStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { loadingUserAction, UserStateInterface } from '@state/user/userStateSlice'
import {
  createProfileFollowerAction,
  loadingProfileFollowerAction,
  loadingUserFollowerAction,
  FollowerStateInterface,
} from '@state/follower/followerStateSlice'
import { ReactComponent as MessageIcon } from '@svg/message.svg'
import defaultAvatarPng from '@images/avatar.png'
import styles from './ProfileUserSideBar.module.scss'

interface StartIconArrInterface {
  id: number
}

interface Props {
  id?: string
}

const ProfileUserSideBar: FC<Props> = ({ id }: Props) => {
  const dispatch = useDispatch()
  const { profile, isProfileFirstLoading, isProfileLoading }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const { userProfile, isUserFirstLoading, isUserLoading }: UserStateInterface = useSelector((state: AppStateInterface) => state.userState)
  const { profileFollowingIdArr, isProfileFollowerFirstLoading, isUserFollowerFirstLoading }: FollowerStateInterface = useSelector(
    (state: AppStateInterface) => state.followerState,
  )
  const history = useHistory<History>()
  const query: URLSearchParams = useQueryHook()
  useEffect(() => {
    dispatch(loadingProfileAction())
    dispatch(loadingProfileFollowerAction())
    if (id) {
      if (Number.isInteger(Number(id))) {
        dispatch(loadingUserAction())
        dispatch(loadingUserFollowerAction())
      } else history.push('/settings')
    }
  }, [dispatch, id, query.get('page')])

  const crateFollowerHandler = () => {
    if (id && Number.isInteger(Number(id))) dispatch(createProfileFollowerAction({ memberId: Number(id) }))
  }
  const profileNow: ProfileInterface | MemberInterface = id ? userProfile : profile
  const followingButton = id ? profileFollowingIdArr?.find((followId: number): boolean => followId == userProfile?.memberId) : false
  const startIconArr: StartIconArrInterface[] = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]

  const rating = Number((Math.random() * 5).toFixed(1))
  const isFirstLoading = id
    ? isUserFirstLoading && isProfileFirstLoading && isProfileFollowerFirstLoading && isUserFollowerFirstLoading
    : isProfileFirstLoading
  const isLoading = id ? isUserLoading : isProfileLoading
  return (
    <div className={styles.container}>
      <LoadingBlockSpin isLoading={isLoading} />
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{id ? 'Profile' : 'My Profile'}</span>
        <span className={styles.online}>online</span>
      </div>
      {isFirstLoading && <img className={styles.avatar} src={profileNow?.avatar || defaultAvatarPng} alt='' />}
      {!isFirstLoading && <div className={styles.avatar} />}
      <h3 className={styles.name}>{`${profileNow?.firstName || '\u00A0'} ${profileNow?.lastName || ''}`}</h3>
      <span className={styles.profession}>Graphic Design &nbsp;&nbsp;|&nbsp;&nbsp; Web-Design</span>
      {!id && <UserBalance />}
      {id && (
        <div className={styles.starsWrapper}>
          {startIconArr.map(({ id }: StartIconArrInterface) => (
            <Fragment key={id}>
              {rating >= id && <StarIcon sx={{ fontSize: 24 }} />}
              {rating < id && rating + 1 > id && <StarHalfIcon sx={{ fontSize: 24 }} />}
              {rating + 1 <= id && <StarBorderIcon sx={{ fontSize: 24 }} />}
            </Fragment>
          ))}
        </div>
      )}
      {id && !followingButton && isFirstLoading && (
        <Button className={styles.follow} onClick={crateFollowerHandler}>
          <span className='z-index-5-g'> Follow</span>
        </Button>
      )}
      {id && followingButton && isFirstLoading && (
        <Button className={styles.following} disabled>
          Following
        </Button>
      )}
      {id && isFirstLoading && (
        <button className={styles.messageWrapper} type='button'>
          <MessageIcon />
          <span>MESSAGE</span>
        </button>
      )}
      <div className={styles.date}>{`${profileNow?.createdAt ? `Join on ${dayjs(profileNow?.createdAt).format('D MMM YYYY')}` : '\u00A0'}`}</div>
    </div>
  )
}

export default memo(ProfileUserSideBar)
