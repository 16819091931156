import { PayloadAction } from '@reduxjs/toolkit'
import {
  loadingTransactionArrAction,
  loadingTransactionArrFailure,
  loadingTransactionArrSuccess,
} from '@state/transactionArr/transactionArrStateSlice'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import dayjs from 'dayjs'
import { toast } from 'react-hot-toast'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

function* loadingTransactionArrSaga({ payload: { minDate, maxDate } }: PayloadAction<{ minDate: Date; maxDate: Date }>): Generator {
  try {
    const { page, date, direction }: any = yield select(state => state.router.location.query)
    let queryParams: string = ''
    if (direction && direction !== 'All') queryParams = `&direction=${direction}`
    queryParams = `${queryParams}&date=${date || 'down'}`
    const { data }: any = yield call(
      apiConnectAxios,
      `/transactions/?page=${page || 1}&limit=10${queryParams}&minDate=${dayjs(minDate).format('YYYY-MM-DD')}&maxDate=${dayjs(maxDate).format('YYYY-MM-DD')}`,
    )
    yield put(loadingTransactionArrSuccess({ transactionArr: data?.transactionArr, transactionCount: data?.transactionCount }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingTransactionArrFailure(errorResponseReturn(error)))
  }
}

export default function* transactionArrRootSaga() {
  yield all([takeLatest(loadingTransactionArrAction.type, loadingTransactionArrSaga)])
}
