import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TransactionInterface } from '@state/transaction/transactionStateSlice'

export interface TransactionArrStateInterface {
  isTransactionArrLoading: boolean
  isTransactionArrFirstLoading: boolean
  transactionArr: TransactionInterface[]
  transactionCount: number
}

const initialTransactionArrState: TransactionArrStateInterface = {
  isTransactionArrLoading: true,
  isTransactionArrFirstLoading: false,
  transactionArr: [],
  transactionCount: 0,
}

const transactionArrState = createSlice({
  name: 'transactionArrState',
  initialState: initialTransactionArrState,
  reducers: {
    loadingTransactionArrAction(state, {}: PayloadAction<{ minDate: Date; maxDate: Date }>) {
      state.isTransactionArrLoading = true
    },
    loadingTransactionArrFailure(state) {
      state.isTransactionArrLoading = false
      state.isTransactionArrFirstLoading = true
    },
    loadingTransactionArrSuccess(
      state,
      { payload: { transactionArr, transactionCount } }: PayloadAction<{ transactionArr: TransactionInterface[]; transactionCount: number }>,
    ) {
      state.transactionArr = transactionArr
      state.transactionCount = transactionCount
      state.isTransactionArrLoading = false
      state.isTransactionArrFirstLoading = true
    },
  },
})

export const { loadingTransactionArrAction, loadingTransactionArrFailure, loadingTransactionArrSuccess } = transactionArrState.actions

export default transactionArrState.reducer
