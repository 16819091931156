import { Box, IconButton, MenuItem, Popover, Tooltip, useTheme } from '@mui/material'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'

type Props = {
  activePopup: string | null
  setActivePopup: (active: any) => void
  dropdownKey: string
  anchorRef: any
}

const DropdownAddButton: FC<Props> = ({ activePopup, setActivePopup, dropdownKey, anchorRef }: Props) => {
  const theme = useTheme()
  const history = useHistory<History>()
  const buttons = [
    { title: 'Create Project', link: '/create-project' },
    { title: 'Create Project News', link: '/create-project-news' },
    { title: 'Create Task', link: '/create-task' },
  ]

  const open = activePopup === dropdownKey
  const id = open ? 'add-btn-dropdown' : undefined

  const changePageHandler = (path: string) => () => {
    history.push(path)
  }

  return (
    <Box>
      <Tooltip title='Create new' placement='bottom'>
        <IconButton
          ref={anchorRef}
          sx={{ margin: '0 8px' }}
          onClick={() => {
            setActivePopup((prevPopup: string | null) => (prevPopup === dropdownKey ? null : dropdownKey))
          }}
          aria-expanded={activePopup ? 'true' : undefined}
          aria-controls={id}
        >
          <img src='../images/icons/add.png' alt='Add' />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setActivePopup(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.dark,
          }}
        >
          {buttons.map(btn => (
            <MenuItem
              key={btn.link}
              onClick={changePageHandler(btn.link)}
              sx={{
                color: theme.palette.text.primary,
              }}
            >
              {btn.title}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </Box>
  )
}

export default DropdownAddButton
