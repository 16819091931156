import React, { FC, ChangeEvent, useState, useRef, useEffect } from 'react'
import { FieldInputProps, FormikProps, useField } from 'formik'
import { Tooltip } from '@mui/material'
import { toast } from 'react-hot-toast'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as LandscapeIconSvg } from '@svg/landscape.svg'
import styles from './LandscapeNewsField.module.scss'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  isEdit?: boolean
}

const LandscapeNewsField: FC<Props> = ({ field: { name, value }, isEdit }: Props) => {
  const [, , { setValue, setError, setTouched }] = useField(name)
  const maxSize = 10000000 // 10mb
  const supportedFormats: string[] = ['image/jpg', 'image/jpeg', 'image/png']
  const inputRef = useRef(null)
  const clickHandler = () => {
    // @ts-ignore
    inputRef.current.click()
  }
  const [file, setFile] = useState<null | string>(null)
  useEffect(() => {
    if (!value) {
      setFile(null)
      return
    } else if (typeof value === 'object') {
      const reader: FileReader = new FileReader()
      reader.readAsDataURL(value)
      reader.onloadend = async () => {
        // @ts-ignore
        setFile(reader.result)
      }
      reader.onerror = error => toast.error(error?.toString())
    } else {
      setFile(value)
    }
  }, [value])
  const loadingFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    const reader: FileReader = new FileReader()
    const [file]: any = e.target.files
    if (file) {
      reader.readAsDataURL(file)
      reader.onloadend = async () => {
        if (!supportedFormats.includes(file?.type)) {
          await setTouched(true)
          setError('Unsupported Format')
          return toast.error('Unsupported Format')
        }
        if (file.size > maxSize) {
          await setTouched(true)
          setError('Max file size is 10mb')
          return toast.error('Max file size is 10mb')
        }
        setError('')
        setValue(file)
      }
      reader.onerror = error => toast.error(error?.toString())
    }
  }
  const clickDeleteValueHandler = () => {
    setValue('')
    setError('')
  }
  const isEditField = isEdit || typeof isEdit === 'undefined'
  return (
    <>
      {isEditField && !file && (
        <div className='flex-center-g'>
          <button className={styles.button} onClick={clickHandler} type='button'>
            <LandscapeIconSvg />
            <input name={name} type='file' ref={inputRef} accept={supportedFormats.join()} onChange={loadingFileHandler} />
          </button>
        </div>
      )}
      {file && (
        <div className={styles.imgWrapper}>
          <img className={styles.img} src={file} alt='' />
          {isEditField && (
            <Tooltip title='Delete image' placement='bottom'>
              <CloseIcon className={styles.icon} onClick={clickDeleteValueHandler} />
            </Tooltip>
          )}
        </div>
      )}
    </>
  )
}

export default LandscapeNewsField
