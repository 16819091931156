import { put, takeLatest, all, call, select, fork } from 'redux-saga/effects'
import { createMatchSelector } from 'connected-react-router'
import { toast } from 'react-hot-toast'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  loadingNewsArrAction,
  loadingNewsArrFailure,
  loadingNewsArrSuccess,
  deleteNewsArrAction,
  deleteNewsArrFailure,
  loadingPageCommentNewsArrAction,
  loadingPageCommentNewsArrFailure,
  loadingPageCommentNewsArrSuccess,
} from '@state/newsArr/newsArrStateSlice'
import { NewsInterface } from '@state/news/newsStateSlice'
import errorResponseReturn from '@utils/errorResponseReturn'

function* loadingNewsArrSaga(): Generator {
  try {
    const matchSelector = createMatchSelector({ path: ['/project-news/:projectId'] })
    // @ts-ignore
    const match: any = yield select(matchSelector)
    const { page }: any = yield select(state => state.router.location.query)
    const { data }: any = yield call(apiConnectAxios, `/project-news/project/${match?.params?.projectId}?page=${page || 1}`)
    yield put(loadingNewsArrSuccess({ newsArr: data?.newsArr, newsCount: data?.newsCount }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingNewsArrFailure(errorResponseReturn(error)))
  }
}

function* deleteNewsArrSaga({ payload: { newsId } }: PayloadAction<{ newsId: number }>): Generator {
  try {
    yield call(apiConnectAxios, `/project-news/${newsId}`, 'delete')
    yield fork(loadingNewsArrSaga)
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(deleteNewsArrFailure(errorResponseReturn(error)))
  }
}

function* loadingPageCommentNewsArrActionSaga({
  payload: { newsId, commentPage },
}: PayloadAction<{ newsId: number; commentPage: number }>): Generator {
  try {
    const { data }: any = yield call(apiConnectAxios, `/project-news-comments/news/${newsId}?page=${commentPage}`)
    const { newsArr }: any = yield select(state => state.newsArrState)
    const newNewsArr: NewsInterface[] = newsArr.map((news: NewsInterface) => {
      if (news.id == newsId) return { ...news, commentByNews: data }
      return news
    })
    yield put(loadingPageCommentNewsArrSuccess({ newsArr: newNewsArr }))
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingPageCommentNewsArrFailure(errorResponseReturn(error)))
  }
}

export default function* newsArrRootSaga() {
  yield all([
    takeLatest(loadingNewsArrAction.type, loadingNewsArrSaga),
    takeLatest(deleteNewsArrAction.type, deleteNewsArrSaga),
    takeLatest(loadingPageCommentNewsArrAction.type, loadingPageCommentNewsArrActionSaga),
  ])
}
