import React, { FC, memo } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import styles from './LoadingBlockSpin.module.scss'

interface Props {
  isLoading: boolean
  marginTop?: number
}

const LoadingBlockSpin: FC<Props> = ({ isLoading }: Props) => (
  <div className={`${styles.wrapper} ${(isLoading && styles.show) || ''}`}>
    <CircularProgress />
  </div>
)

export default memo(LoadingBlockSpin)
