import React, { FC } from 'react'
import AddIcon from '@mui/icons-material/Add'
import SeeMoreButton from '@buttons/SeeMoreButton/SeeMoreButton'
import StartsUserBox from '@components/userComponents/StartsUserBox/StartsUserBox'
import { ReactComponent as CurrencyIcon } from '@icons/currencyIcon.svg'
import styles from './RequestProjectDonations.module.scss'

const userArr = [
  {
    id: 1,
    memberId: 1,
    firstName: 'Dominick Donnelly',
    lastName: 'Graphic Design',
    avatar: '',
    coins: 120,
  },
  {
    id: 2,
    memberId: 2,
    firstName: 'Jamie Greenholt',
    lastName: 'Graphic Design',
    avatar: '',
    coins: 200,
  },
  {
    id: 3,
    memberId: 3,
    firstName: 'Dominick Donnelly',
    lastName: 'Graphic Design',
    avatar: '',
    coins: 180,
  },
  {
    id: 4,
    memberId: 4,
    firstName: 'Jamie Greenholt',
    lastName: 'Graphic Design',
    avatar: '',
    coins: 250,
  },
]

const RequestProjectDonations: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.buttonWrapper}>
      <div className={styles.title}>TOP 10 donations</div>
      <button className={styles.addButton} type='button'>
        <AddIcon className={styles.addIcon} />
      </button>
    </div>
    {userArr.map(user => (
      <div key={user?.id} className={styles.userWrapper}>
        <StartsUserBox memberId={user?.memberId} avatar={user?.avatar} firstName={user?.firstName} isStartsUserHide />
        <div className={styles.coinsWrapper}>
          <span className={styles.coins}>{`+ ${user?.coins}`}</span>
          <CurrencyIcon className={styles.currencyIcon} />
        </div>
      </div>
    ))}
    <SeeMoreButton onClick={() => null} />
  </div>
)

export default RequestProjectDonations
