import React, { FC, memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { toast } from 'react-hot-toast'
import LoadingTransparentSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { TagInterface } from '@interfaces/interfaces'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { ProjectParamsInterface } from '@state/project/projectStateSlice'

const CreateProjectPageLogic: FC = () => {
  const { setValues, setErrors, setTouched } = useFormikContext()
  const { projectId } = useParams<ProjectParamsInterface>()
  const history = useHistory<History>()
  const { isProfileFirstLoading, isProfileLoading, profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  useEffect(() => {
    if (history?.location?.pathname === '/create-project') {
      setValues({
        name: '',
        description: '',
        tags: '',
        isAutomaticSendingNda: false,
        image: '',
        icon: '',
        isRequiredContract: false,
      })
      setTouched({})
      setErrors({})
    } else if (projectId && isProfileFirstLoading) {
      apiConnectAxios(`/projects/${projectId}`, 'get')
        .then(({ data }) => {
          if (!data) {
            toast.error('This project does not exist with this Id')
            history.push('/')
            return
          } else if (profile?.memberId && profile?.memberId == data?.memberId) {
            setValues({
              name: data?.name || '',
              description: data?.description || '',
              tags: data?.tags?.map((tag: TagInterface) => tag?.name)?.join(', ') || '',
              isAutomaticSendingNda: data?.isAutomaticSendingNda || false,
              image: data?.image || '',
              icon: data?.icon || '',
              isRequiredContract: data?.isRequiredContract || false,
            })
          } else {
            toast.error('No access')
            history.push('/create-project')
          }
        })
        .catch(error => {
          toast.error(errorResponseReturn(error))
          history.push('/create-project')
        })
    }
  }, [projectId, isProfileFirstLoading, profile?.memberId, history?.location?.pathname])
  if (!projectId) return null
  return <LoadingTransparentSpin isLoading={isProfileLoading} />
}

export default memo(CreateProjectPageLogic)
