import { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import styles from './BackLink.module.scss'

interface Props {
  title?: string
  link?: string
}

const BackLink: FC<Props> = ({ title = 'Back', link }: Props) => {
  const history = useHistory<History>()
  const clickHandler = () => {
    if (link) history.push(link)
    else if (history.length > 1) history.goBack()
    else history.push('/')
  }

  return (
    <button className={styles.button} onClick={clickHandler} type='button'>
      <div className={styles.iconWrapper}>
        <KeyboardDoubleArrowLeftIcon className={styles.icon} />
      </div>
      <span className={styles.text}>{title}</span>
    </button>
  )
}

export default memo(BackLink)
