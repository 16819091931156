import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import RequestsSidebarButtons from './RequestsSidebarButtons/RequestsSidebarButtons'
import RequestsSidebarClient from './RequestsSidebarClient/RequestsSidebarClient'
import RequestsSidebarPerformer from './RequestsSidebarPerformer/RequestsSidebarPerformer'
import { RequestArrStateInterface } from '@state/requestArr/requestArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import styles from './RequestsSidebar.module.scss'

const RequestsSidebar: FC = () => {
  const { isRequestArrLoading }: RequestArrStateInterface = useSelector((state: AppStateInterface) => state.requestArrState)

  return (
    <div className={styles.wrapper}>
      <LoadingBlockSpin isLoading={isRequestArrLoading} />
      <RequestsSidebarButtons />
      <RequestsSidebarClient />
      <RequestsSidebarPerformer />
    </div>
  )
}

export default RequestsSidebar
