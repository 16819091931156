import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-hot-toast'
import PageTitle from '@components/titles/PageTitle/PageTitle'
import PinkButton from '@buttons/PinkButton/PinkButton'
import FormField from '@fields/FormField/FormField'
import TextareaField from '@fields/TextareaField/TextareaField'
import LoadPhotoField from '@fields/LoadPhotoField/LoadPhotoField'
import SendingNDACheckboxField from '@pages/projectPages/CreateProjectPage/SendingNDACheckboxField/SendingNDACheckboxField'
import CheckboxField from '@fields/CheckboxField/CheckboxField'
import CreateProjectPageLogic from './CreateProjectPageLogic/CreateProjectPageLogic'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { minTextareaLengthValidationVar, requiredValidationVar } from '@utils/fieldsValidationVariables'
import { ProjectParamsInterface } from '@state/project/projectStateSlice'
import errorResponseReturn from '@utils/errorResponseReturn'

const validationSchema = yup.object().shape({
  name: yup.string().required(requiredValidationVar),
  description: yup.string().required(requiredValidationVar).min(minTextareaLengthValidationVar.min, minTextareaLengthValidationVar.message),
  tags: yup.string(),
  image: yup.string().required(requiredValidationVar),
})

interface ProjectFormValuesInterface {
  name: string
  description: string
  tags: string
  isAutomaticSendingNda: boolean
  isRequiredContract: boolean
  image: string
  icon: string
}

const CreateProjectPage: FC = () => {
  const history = useHistory<History>()
  const { projectId } = useParams<ProjectParamsInterface>()
  const initialValues: ProjectFormValuesInterface = {
    name: '',
    description: '',
    tags: '',
    isAutomaticSendingNda: false,
    isRequiredContract: false,
    image: '',
    icon: '',
  }

  const submitHandler = async (fieldValues: ProjectFormValuesInterface, { setErrors }: FormikHelpers<ProjectFormValuesInterface>) => {
    apiConnectAxios(
      projectId ? `/projects/${projectId}` : '/projects',
      projectId ? 'patch' : 'post',
      { ...fieldValues, tags: fieldValues.tags?.split(', ') || [] },
      true,
    )
      .then(() => {
        toast.success(projectId ? 'Project edited' : 'Project created')
        history.push('/projects')
      })
      .catch(error => {
        setErrors(error?.response?.data?.errors || {})
        toast.error(errorResponseReturn(error))
      })
  }

  const title = projectId ? 'Edit Project' : 'Create Project'
  return (
    <div className='page-container-g'>
      <PageTitle title={title} />
      <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
        <Form autoComplete='off' className='direction_column-max-g'>
          <CreateProjectPageLogic />
          <Field name='name' label='name' component={FormField} />
          <Field name='description' label='Description' component={TextareaField} />
          <Field name='tags' label='Tags' placeholder='Add tags separated by commas' component={FormField} />
          <Field name='isAutomaticSendingNda' label='Enable document workflow' component={SendingNDACheckboxField} />
          <Field name='isRequiredContract' label='Contract Required' component={CheckboxField} />
          <Field name='image' component={LoadPhotoField} />
          <Field name='icon' component={LoadPhotoField} isIcon />
          <PinkButton title={title} type='submit' sx={{ margin: '90px auto 30px auto' }} />
        </Form>
      </Formik>
    </div>
  )
}

export default CreateProjectPage
