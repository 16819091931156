import React, { ChangeEvent, FC, useId } from 'react'
import { FieldInputProps, FormikProps, useField } from 'formik'
import styles from './FormField.module.scss'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  label: string
  placeholder?: string
  type?: 'number'
  isTransparentBackground?: boolean
}

const FormField: FC<Props> = ({
  field: { name, value, onBlur },
  form: { touched, errors },
  label,
  placeholder,
  type,
  isTransparentBackground,
}: Props) => {
  const [, , { setValue }] = useField(name)
  const changeValueHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      if (/^[0-9]*[.]?[0-9]+$/.test(e.target.value) || e.target.value == '') setValue(e.target.value)
    } else setValue(e.target.value)
  }
  const id: string = useId()
  return (
    <div className='column-align_start-g'>
      <label htmlFor={id} className={`${styles.title} label-title-g`}>
        {label}
      </label>
      <input
        id={id}
        className={`${styles.input} ${(touched?.[name] && errors?.[name] && styles.input_error) || ''} ${(isTransparentBackground && styles.input_transparent) || ''} 'field-input-g'`}
        name={name}
        value={value}
        onChange={changeValueHandler}
        onBlur={onBlur}
        type='text'
        placeholder={placeholder || label}
      />
      <div className={styles.error}>{`${(touched?.[name] && errors?.[name]) || `\u00A0`}`}</div>
    </div>
  )
}

export default FormField
