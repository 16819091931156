import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ReplayIcon from '@mui/icons-material/Replay'
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Container,
  darken,
  FormControlLabel,
  Grow,
  IconButton,
  MenuList,
  Pagination,
  Paper,
  Popper,
  Typography,
  useTheme,
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import React, { ChangeEvent, FC, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { ColorsButton } from '@buttons/ColorsButton/ColorsButton'
import { ProductCard } from '../components/Cards/ProductCard'
import { DateTypes, SearchLanguage, SearchTypes } from '../interfaces/catalog'
import { catalogQueryService } from '../services/catalog.service'
import HelmetTitle from '../components/titles/HelmetTitle/HelmetTitle'

const initTypes = {
  Community: false,
  Project: false,
  Task: false,
}

const initLanguage = {
  All: false,
  English: false,
  Ukranian: false,
  Italian: false,
  Hebrew: false,
}

const initialPeriod = {
  Week: false,
  Month: false,
  Year: false,
}

export const Catalog: FC = () => {
  const theme = useTheme()

  const [page, setPage] = useState<number>(1)
  const [maxPages, setMaxPage] = useState<number>(0)
  const [catalogCards, setCatalogCards] = useState<any>([])

  //menu language and period
  const languageRef = useRef<HTMLButtonElement>(null)
  const [openLanguage, setOpenLanguage] = useState(false)
  const handleCloseLanguage = (event: Event | SyntheticEvent) => {
    if (languageRef.current && languageRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpenLanguage(false)
  }

  const periodRef = useRef<HTMLButtonElement>(null)
  const [openPeriod, setOpenPeriod] = useState(false)
  const handleClosePeriod = (event: Event | SyntheticEvent) => {
    if (periodRef.current && periodRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpenPeriod(false)
  }

  useEffect(() => {
    const search = new URLSearchParams(window.location.search)
    handlePageChange(parseInt(search.get('page') || '1', 10))

    const language = search.get('language')
    if (language) {
      const languageArr = language.split(',')
      const newState = {}
      languageArr.forEach(language => {
        if (language === SearchLanguage.All) {
          return setLanguageFilter({ ...initLanguage, All: true })
        }
        Object(newState)[language] = true
      })
      setLanguageFilter({ ...initLanguage, ...newState })
    }

    const time = search.get('time')
    switch (time) {
      case null:
        break
      case DateTypes.Month:
        handleChangePeriod(time)
        break
      case DateTypes.Year:
        handleChangePeriod(time)
        break
      case DateTypes.Week:
        handleChangePeriod(time)
        break
    }

    const type = search.get('type')
    switch (type) {
      case SearchTypes.Community:
        handleTypeChange(type)
        break
      case SearchTypes.Project:
        handleTypeChange(type)
        break
      case SearchTypes.Task:
        handleTypeChange(type)
        break
      default:
        handleTypeChange(SearchTypes.Project)
        break
    }

    fetchData()
  }, [])

  const languages = [SearchLanguage.All, SearchLanguage.English, SearchLanguage.Ukranian, SearchLanguage.Italian, SearchLanguage.Hebrew]
  const [languageFilter, setLanguageFilter] = useState(initLanguage)
  const handleLanguageChange = (language: SearchLanguage) => {
    switch (language) {
      case SearchLanguage.All:
        setLanguageFilter({ ...initLanguage, All: !languageFilter[language] })
        if (languageFilter[language]) {
          catalogQueryService.deleteUrlQuery('language')
          return
        }
        catalogQueryService.setUrlQuery('language', `${language}`)

        return
      default:
        // eslint-disable-next-line no-case-declarations
        const newState = { ...languageFilter, All: false, [language]: !languageFilter[language] }
        setLanguageFilter(newState)
        // eslint-disable-next-line no-case-declarations
        const arrLanguage = []
        for (const language in newState) {
          if (Object(newState)[language]) {
            arrLanguage.push(language)
          }
        }

        if (arrLanguage.length === 0) {
          catalogQueryService.deleteUrlQuery('language')
          return
        }
        catalogQueryService.setUrlQuery('language', `${arrLanguage}`)

        return
    }
  }

  const periods = [DateTypes.Week, DateTypes.Month, DateTypes.Year]
  const [periodFilter, setPeriodFilter] = useState(initialPeriod)
  const handleChangePeriod = (period: DateTypes) => {
    setPeriodFilter({ ...initialPeriod, [period]: !periodFilter[period] })
    if (periodFilter[period]) {
      catalogQueryService.deleteUrlQuery('time')
      return
    }
    catalogQueryService.setUrlQuery('time', period)
  }

  const resetFilter = () => {
    catalogQueryService.resetSearchParams()
    setCategoryList({ ...initTypes, Project: true })
    setLanguageFilter(initLanguage)
    setPeriodFilter(initialPeriod)
    catalogQueryService.setUrlQuery('type', SearchTypes.Project)
    catalogQueryService.setUrlQuery('page', '1')
    handlePageChange(1)
    fetchData()
  }

  const [categoryList, setCategoryList] = useState(initTypes)
  const handleTypeChange = (type: SearchTypes): void => {
    setCategoryList({ ...initTypes, [type]: true })

    const currentType = catalogQueryService.getQueryByKey('type')
    if (currentType === type) return

    catalogQueryService.setUrlQuery('type', type)
  }

  const [, setLoading] = useState(true)
  const fetchData = () => {
    setLoading(true)
    catalogQueryService
      .getCardsListByQuery()
      .then(data => {
        setMaxPage(data.maxPages || 0)
        if (data.communities) {
          setCatalogCards(data.communities)
        }
        if (data.projects) {
          setCatalogCards(data.projects)
        }
        if (data.tasks) {
          setCatalogCards(data.tasks)
        }
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  const handlePageChange = async (page: number): Promise<void> => {
    catalogQueryService.setUrlQuery('page', `${page}`)
    setPage(page)
  }

  return (
    <Container disableGutters={true}>
      <HelmetTitle title='Catalog' />
      <Box sx={{ textAlign: 'left', margin: '60px 10px 40px' }}>
        <Typography sx={{ fontWeight: 500, color: '#ffc50c', fontSize: 'calc(24px + 16 * ((100vw - 320px) / 890))' }}>HELP SOMEONE</Typography>
      </Box>
      <Typography variant='h4' m='0 10px 50px'>
        Thousands of Community members need your help. Search through the requests and find the ones that pique your interest. Every hour you earn is
        an hour you can spend to get the help you need.
      </Typography>
      <Box sx={{ margin: '0px 10px 80px', height: '45px', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <ColorsButton
            onClick={() => {
              handleTypeChange(SearchTypes.Project)
              fetchData()
            }}
            title='Projects'
            backgroundColor={theme.palette.primary.dark}
            textColor={categoryList.Project ? '#ffc50c' : '#c4c4c4'}
          />
          <ColorsButton
            onClick={() => {
              handleTypeChange(SearchTypes.Task)
              fetchData()
            }}
            title='Tasks'
            backgroundColor={theme.palette.primary.dark}
            textColor={categoryList.Task ? '#ffc50c' : '#c4c4c4'}
          />
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <div>
            <Button
              ref={languageRef}
              onClick={() => {
                setOpenLanguage(!openLanguage)
              }}
              variant='contained'
              sx={{
                mr: '16px',
                background: theme.palette.primary.dark,
                '&:hover': {
                  backgroundColor: darken(theme.palette.primary.dark, 0.3),
                },
              }}
            >
              Language <KeyboardArrowDownIcon />
            </Button>
            <Popper open={openLanguage} anchorEl={languageRef.current} role={undefined} placement='bottom-start' transition>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper elevation={0} sx={{ background: 'rgba(0, 0, 0, 0)' }}>
                    <ClickAwayListener onClickAway={handleCloseLanguage}>
                      <MenuList autoFocusItem={openLanguage} sx={{ pt: '15px' }}>
                        {languages.map(language => {
                          return (
                            <MenuItem
                              disableRipple
                              key={language}
                              sx={{ overflow: 'hidden', background: 'rgba(40, 28, 102, 0.8)', ':hover': { background: 'rgba(40, 28, 102, 0.8)' } }}
                            >
                              <FormControlLabel
                                sx={{ pr: '8px', m: '0', width: '100%' }}
                                control={<Checkbox checked={languageFilter[language]} />}
                                label={language}
                                onChange={() => {
                                  handleLanguageChange(language)
                                  fetchData()
                                }}
                              />
                            </MenuItem>
                          )
                        })}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
          <div>
            <Button
              ref={periodRef}
              onClick={() => {
                setOpenPeriod(!openPeriod)
              }}
              variant='contained'
              sx={{
                mr: '16px',
                background: theme.palette.primary.dark,
                '&:hover': {
                  backgroundColor: darken(theme.palette.primary.dark, 0.3),
                },
              }}
            >
              Newest <KeyboardArrowDownIcon />
            </Button>
            <Popper open={openPeriod} anchorEl={periodRef.current} role={undefined} placement='bottom-start' transition>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper elevation={0} sx={{ background: 'rgba(0, 0, 0, 0)' }}>
                    <ClickAwayListener onClickAway={handleClosePeriod}>
                      <MenuList autoFocusItem={openPeriod} sx={{ pt: '15px' }}>
                        {periods.map(period => {
                          return (
                            <MenuItem
                              disableRipple
                              key={period}
                              sx={{ overflow: 'hidden', background: 'rgba(40, 28, 102, 0.8)', ':hover': { background: 'rgba(40, 28, 102, 0.8)' } }}
                            >
                              <FormControlLabel
                                sx={{ pr: '8px', m: '0', width: '100%' }}
                                control={<Checkbox checked={periodFilter[period]} />}
                                label={period}
                                onChange={() => {
                                  handleChangePeriod(period)
                                  fetchData()
                                }}
                              />
                            </MenuItem>
                          )
                        })}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
          <IconButton sx={{ p: '0' }} onClick={resetFilter}>
            <ReplayIcon color='action' sx={{ color: '#fff', width: '40px', height: '40px', transform: 'rotate(-30deg)' }} />
          </IconButton>
        </Box>
      </Box>

      {/* Render cards on each page */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: '50px' }}>
        {catalogCards.length === 0 ? (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Typography variant='h3'>No offers were found for you</Typography>
          </Box>
        ) : (
          catalogCards.map((card: any) => (
            <Box
              key={card.id}
              sx={{ width: '33.3333%', [theme.breakpoints.down(992)]: { width: '50%' }, [theme.breakpoints.down(576)]: { width: '100%' } }}
            >
              <ProductCard
                title={card.name}
                image={card.image || 'https://picsum.photos/620/420?v=1655211687859'}
                href={`/task/${card.id}`}
                cardType={'Task'}
              />
            </Box>
          ))
        )}
      </Box>

      <Box sx={{ display: `${maxPages < 1 ? 'none' : 'flex'}`, justifyContent: 'center', mb: '1rem' }}>
        <Pagination
          onChange={(event: ChangeEvent<unknown>, page: number) => {
            window.scrollTo(0, 0)
            event.preventDefault()
            handlePageChange(page)
            fetchData()
          }}
          size='large'
          page={page}
          count={maxPages}
          defaultPage={page}
        />
      </Box>
    </Container>
  )
}
