import { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import NavigationTabs from './NavigationTabs/NavigationTabs'
import RequestsPage from '@pages/RequestsPage/RequestsPage'
import ProfileSettingsPage from '@pages/ProfileSettingsPage/ProfileSettingsPage'
import OffersPage from '@pages/OffersPage/OffersPage'
import TasksPage from '@pages/TasksPage/TasksPage'
import ProjectsPage from '@pages/ProjectsPage/ProjectsPage'

const NavigationMenu: FC = () => {
  return (
    <div className='page-container-g'>
      <NavigationTabs />
      <Switch>
        <Route path='/requests' component={RequestsPage} />
        <Route path='/tasks' component={TasksPage} />
        <Route path={['/projects', '/news']} component={ProjectsPage} />
        <Route path='/offers' component={OffersPage} />
        <Route
          exact
          path={['/settings', '/followers', '/following', '/user/:id', '/followers/:id', '/following/:id']}
          component={ProfileSettingsPage}
        />
      </Switch>
    </div>
  )
}

export default NavigationMenu
