import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const FooterWrapper = styled(Box)({
  position: 'relative',
  color: '#c7c7c7',
  fontSize: '14px',
  lineHeight: '150%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  padding: '85px 0 0',
})

export const FooterItemWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '15px',
  fontSize: '14px',
})

export const FooterBoxItem = styled(Box)({
  padding: '0 10px 15px',
})

export const BottomSection = styled(Box)({
  borderTop: '1px solid #000',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '13px',
  height: '60px',
  color: '#01A7CD',
})
