import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { toast } from 'react-hot-toast'
import { Button } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import CheckIcon from '@mui/icons-material/Check'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AvatarUser from '@components/userComponents/StartsUserBox/AvatarUser/AvatarUser'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StartsUser from '@components/userComponents/StartsUserBox/StartsUser/StartsUser'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { loadingRequestArrAction, RequestInterface } from '@state/requestArr/requestArrStateSlice'
import styles from './RequestsSidebarUser.module.scss'
interface Props {
  relationship: 'client' | 'performer'
  request: RequestInterface
}

const RequestsSidebarUser: FC<Props> = ({ relationship, request }: Props) => {
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()

  const clickHandler = () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('chatRequestId', String(request?.id))
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  const dispatch = useDispatch()
  const changeClientRankHandler = async () => {
    apiConnectAxios(`/requests/performerRank/${request?.id}`, 'patch', { performerRank: request?.performerRank !== 'Best' ? 'Best' : 'NotFit' })
      .then(() => {
        toast.success('Rank changed')
        dispatch(loadingRequestArrAction())
      })
      .catch(error => {
        toast.error(errorResponseReturn(error))
      })
  }
  return (
    <>
      {relationship === 'client' && (
        <div className={styles.nameWrapper}>
          {`${request?.area}: ${request?.name?.slice(0, 24)}${request?.name?.length > 24 && '...'}`}
          {(request?.status === 'Approved' || request?.status === 'Canceled') && (
            <div className={styles.status}>
              {request?.status === 'Approved' && <CheckIcon className={styles.checkIcon} />}
              {request?.status === 'Canceled' && <PriorityHighIcon className={styles.priorityIcon} />}
            </div>
          )}
          {request?.status === 'Created' && <AccessTimeIcon className={styles.timeIcon} />}
        </div>
      )}
      <div className={styles.wrapper}>
        {relationship === 'performer' && (
          <button className={styles.starButton} type='button' onClick={changeClientRankHandler}>
            {request?.performerRank === 'Best' && <StarIcon className={styles.starIcon} />}
            {request?.performerRank !== 'Best' && <StarBorderIcon className={styles.starIcon} />}
          </button>
        )}
        <Button className={styles.wrapperButton} type='button' onClick={clickHandler}>
          <div className={styles.avatarWrapper}>
            <AvatarUser memberId={request?.[relationship]?.memberId} avatar={request?.[relationship]?.avatar} sizePx={96} />
            <div className={styles.circleOnline} />
          </div>
          <div className={styles.userContainer}>
            <div className={`${styles.messageWrapper} ${styles.messageWrapper_active}`}>
              <EmailIcon className={styles.emailIcon} />
              <span className={styles.message}>You have new messages</span>
            </div>
            <div className={styles.personWrapper}>
              <PersonIcon className={styles.personIcon} />
              <span className={styles.userName}>{request?.[relationship]?.firstName}</span>
            </div>
            {relationship === 'client' ? (
              <div>
                {/*<div className={styles.lastMessage}>{`${message?.slice(0, 34)}${message?.length > 34 && '...'}`}</div>*/}
                {request?.createdAt && <div className={styles.date}>{dayjs(request?.createdAt).format('HH:mm A')}</div>}
              </div>
            ) : (
              <div className={styles.startsUser}>
                <StartsUser rating={1} reviews={2} />
                <div className={styles.coins}>
                  Coins: {request?.skillCoinsTotal || 0} Selft
                  <div>Time: {request?.ownHours || 0} H</div>
                </div>
              </div>
            )}
          </div>
          {relationship === 'performer' && (
            <div className={styles.statusWrapper}>
              {(request?.status === 'Approved' || request?.status === 'Canceled') && (
                <div className={styles.status}>
                  {request?.status === 'Approved' && <CheckIcon className={styles.checkIcon} />}
                  {request?.status === 'Canceled' && <PriorityHighIcon className={styles.priorityIcon} />}
                </div>
              )}
              {request?.status === 'Created' && <AccessTimeIcon className={styles.timeIcon} />}
            </div>
          )}
        </Button>
      </div>
    </>
  )
}

export default RequestsSidebarUser
