import React, { FC, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-hot-toast'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Dialog from '@mui/material/Dialog'
import BlueButton from '@buttons/BlueButton/BlueButton'
import FormField from '@fields/FormField/FormField'
import SelectArrField from '@fields/SelectArrField/SelectArrField'
import PDFFileField from '@fields/PDFFileField/PDFFileField'
import { minLengthValidationVar, requiredValidationVar } from '@utils/fieldsValidationVariables'
import { OptionArrInterface } from '@interfaces/interfaces'
import AddDocumentTemplateFormLogic from '@pages/documentPages/DocumentTemplatePage/AddDocumentTemplateForm/AddDocumentTemplateFormLogic/AddDocumentTemplateFormLogic'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { loadingDocumentTemplateArrAction } from '@state/documentTemplateArr/documentTemplateArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { AreaDocumentEnum, LegalDocumentEnum } from '@state/documentArr/documentArrStateSlice'
import { changeModalDocumentTemplateSuccess, DocumentTemplateStateInterface } from '@state/documentTemplate/documentTemplateStateSlice'
import { ReactComponent as CircleCloseSvgIcon } from '@icons/circleCloseIcon.svg'
import styles from './AddDocumentTemplateForm.module.scss'
import { useRouteMatch } from 'react-router-dom'
export interface DocumentTemplateValuesInterface {
  area: AreaDocumentEnum | string
  legal: LegalDocumentEnum | string
  name: string
  fileSource: string
}

const validationSchema = yup.object().shape({
  area: yup.string().required(requiredValidationVar),
  legal: yup.string().required(requiredValidationVar),
  name: yup.string().required(requiredValidationVar).min(minLengthValidationVar.min, minLengthValidationVar.message),
  fileSource: yup.string().required(requiredValidationVar),
})

const areaOptionArr: OptionArrInterface[] = [
  {
    label: 'Project',
    value: 'Project',
  },
  {
    label: 'Task',
    value: 'Task',
  },
]
const legalOptionArr: OptionArrInterface[] = [
  {
    label: 'C2B',
    value: 'C2B',
  },
  {
    label: 'B2B',
    value: 'B2B',
  },
  {
    label: 'C2C',
    value: 'C2C',
  },
]
const AddDocumentTemplateForm: FC = () => {
  const { isShowDocumentTemplateModal, documentTemplate }: DocumentTemplateStateInterface = useSelector(
    (state: AppStateInterface) => state.documentTemplateState,
  )
  const openModalHandler = (): void => {
    dispatch(changeModalDocumentTemplateSuccess({ isShowDocumentTemplateModal: true }))
  }
  const closeModalHandler = (): void => {
    dispatch(changeModalDocumentTemplateSuccess({ isShowDocumentTemplateModal: false }))
  }
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const isTemplatesPage: boolean = url?.includes('templates')
  const submitHandler = async (
    fieldValues: DocumentTemplateValuesInterface,
    { setErrors, setTouched }: FormikHelpers<DocumentTemplateValuesInterface>,
  ) => {
    apiConnectAxios(
      `/document-templates${(documentTemplate && `/${documentTemplate?.id}`) || ''}`,
      !documentTemplate ? 'post' : 'patch',
      fieldValues,
      true,
    )
      .then((): void => {
        toast.success(!documentTemplate ? 'Document Template created' : 'Document Template updated')
        setTouched({})
        setErrors({})
        if (isTemplatesPage) dispatch(loadingDocumentTemplateArrAction())
        dispatch(changeModalDocumentTemplateSuccess({ isShowDocumentTemplateModal: false }))
      })
      .catch(error => {
        setErrors(error?.response?.data?.errors || {})
        toast.error(errorResponseReturn(error))
      })
  }

  const initialValues: DocumentTemplateValuesInterface = {
    name: '',
    area: areaOptionArr[0].value,
    legal: legalOptionArr[0].value,
    fileSource: '',
  }
  return (
    <>
      <div className={styles.addButton}>
        <BlueButton title='Add' onClick={openModalHandler} icon={<AddIcon className={styles.addIcon} />} />
      </div>
      <Dialog className={styles.modal} open={isShowDocumentTemplateModal} onClose={closeModalHandler} scroll='body'>
        <div className={styles.form}>
          <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
            <Form className={styles.container} autoComplete='off'>
              <AddDocumentTemplateFormLogic />
              <button className={styles.closeButton} onClick={closeModalHandler} type='button'>
                <CircleCloseSvgIcon className={styles.closeIcon} />
              </button>
              <h3 className={styles.title}>{`${!documentTemplate ? 'Add a new document Template' : 'Edit document Template'}`}</h3>
              <Field name='area' component={SelectArrField} optionArr={areaOptionArr} label='Area type' />
              <Field name='legal' component={SelectArrField} optionArr={legalOptionArr} label='Legal type' />
              <Field name='name' component={FormField} label='name' />
              <Field name='fileSource' component={PDFFileField} />
              <Button className={styles.button} type='submit'>
                Save
              </Button>
            </Form>
          </Formik>
        </div>
      </Dialog>
    </>
  )
}

export default memo(AddDocumentTemplateForm)
