import React, { FC, memo, useId, useState } from 'react'
import { differenceInDays, endOfMonth, startOfMonth } from 'date-fns'
import dayjs from 'dayjs'
import { ClickAwayListener, IconButton, Popper } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import TransactionsTaskCalendarLogic from './TransactionsTaskCalendarLogic/TransactionsTaskCalendarLogic'
import TransactionsCalendarLogic from './TransactionsCalendarLogic/TransactionsCalendarLogic'
import { ReactComponent as CircleLeftArrowSVGIcon } from '@icons/circleLeftArrowIcon.svg'
import { ReactComponent as CircleRightArrowSVGIcon } from '@icons/circleRightArrowIcon.svg'
import styles from './TransactionsCalendar.module.scss'

const weeksArr: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

interface Props {
  isTask?: boolean
}

const TransactionsCalendar: FC<Props> = ({ isTask }: Props) => {
  const [selectedMinDate, setSelectedMinDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 7)))
  const [selectedMaxDate, setSelectedMaxDate] = useState<Date>(new Date())
  const [calendarDate, setCalendarDate] = useState<Date>(new Date())
  const [isSelectedMaxDate, setIsSelectedMaxDate] = useState<boolean>(true)
  const onClickChangeMinDateHandler = () => {
    setSelectedMinDate(new Date(selectedMinDate.setDate(selectedMinDate.getDate() - 7)))
  }
  const onClickChangeMaxDateHandler = () => {
    const differenceDays: number = Math.floor((Date.now() - selectedMaxDate.valueOf()) / 86400000)
    if (differenceDays > 1) {
      const newSelectedMaxDate: any = new Date(selectedMaxDate.getTime())
      if (differenceDays > 7) {
        setSelectedMaxDate(new Date(newSelectedMaxDate.setDate(newSelectedMaxDate.getDate() + 7)))
      } else {
        setSelectedMaxDate(new Date(newSelectedMaxDate.setDate(newSelectedMaxDate.getDate() + differenceDays)))
      }
    }
  }
  const yearOptionArr: number[] = [2022]
  const getYear = () => {
    yearOptionArr.push(yearOptionArr[yearOptionArr.length - 1] + 1)
    if (new Date().getFullYear() >= yearOptionArr[yearOptionArr.length - 1] + 1) {
      getYear()
    }
  }
  if (new Date().getFullYear() >= yearOptionArr[yearOptionArr.length - 1]) {
    getYear()
  }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen: boolean = Boolean(anchorEl)
  const closeCalendarHandler = () => {
    setAnchorEl(null)
  }
  const openCalendarHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isOpen) setAnchorEl(null)
    else setAnchorEl(event.currentTarget)
  }
  const popperId: string = useId()
  const [isOpenYear, setIsOpenYear] = useState<boolean>(false)
  const openYearModalHandler = () => {
    if (isOpenYear) setIsOpenYear(false)
    else setIsOpenYear(true)
  }
  const closeYearModalHandler = () => {
    setIsOpenYear(false)
  }
  const changeYearHandler = (year: number) => () => {
    setIsOpenYear(false)
    const newCalendarDate: any = new Date(calendarDate.getTime())
    newCalendarDate.setYear(year)
    setCalendarDate(newCalendarDate)
  }
  const clickBackMonthHandler = () => {
    const newCalendarDate: any = new Date(calendarDate.getTime())
    newCalendarDate.setMonth(newCalendarDate.getMonth() - 1)
    setCalendarDate(newCalendarDate)
  }
  const clickForwardMonthHandler = () => {
    const newCalendarDate: any = new Date(calendarDate.getTime())
    if (differenceMonths) {
      newCalendarDate.setMonth(newCalendarDate.getMonth() + 1)
      setCalendarDate(newCalendarDate)
    }
  }
  const onClickDayHandler = (day: number) => () => {
    let newCalendarDate: any = new Date(calendarDate.getTime())
    newCalendarDate = new Date(newCalendarDate.setDate(day))
    if (Math.floor((selectedMinDate.getTime() - new Date(newCalendarDate.setDate(day)).getTime()) / 86400000) === 0) {
      setIsSelectedMaxDate(!isSelectedMaxDate)
    } else if (Math.floor((selectedMaxDate.getTime() - new Date(newCalendarDate.setDate(day)).getTime()) / 86400000) === 0) {
      setIsSelectedMaxDate(!isSelectedMaxDate)
    } else if (isSelectedMaxDate) {
      setSelectedMaxDate(newCalendarDate)
      if (Math.floor((selectedMinDate.getTime() - new Date(newCalendarDate.setDate(day)).getTime()) / 86400000) > 0) {
        setSelectedMinDate(newCalendarDate)
      }
    } else {
      setSelectedMinDate(newCalendarDate)
      if (Math.floor((selectedMaxDate.getTime() - new Date(newCalendarDate.setDate(day)).getTime()) / 86400000) < 0) {
        setSelectedMaxDate(newCalendarDate)
      }
    }
  }
  const newCalendarDate: any = new Date(calendarDate.getTime())
  const differenceMonths: number =
    new Date().getMonth() - newCalendarDate.getMonth() + 12 * (new Date().getFullYear() - newCalendarDate.getFullYear())
  const prefixCalendarDate: any = new Date(calendarDate.getTime())
  const prefixDate = prefixCalendarDate.setMonth(prefixCalendarDate.getMonth() - 1)
  const prefixStartDate: Date = startOfMonth(prefixDate)
  const prefixEndDate: Date = endOfMonth(prefixDate)
  const prefixDays: number = differenceInDays(prefixEndDate, prefixStartDate) + 1
  const startDate: Date = startOfMonth(prefixCalendarDate)
  const endDate: Date = endOfMonth(prefixCalendarDate)
  const numDays: number = differenceInDays(endDate, startDate) + 1
  const prefixDaysLength: number = startDate.getDay()
  const suffixDays: number = 6 - endDate.getDay()
  return (
    <>
      {isTask ? (
        <TransactionsTaskCalendarLogic selectedMinDate={selectedMinDate} selectedMaxDate={selectedMaxDate} />
      ) : (
        <TransactionsCalendarLogic selectedMinDate={selectedMinDate} selectedMaxDate={selectedMaxDate} />
      )}
      <div className={styles.wrapper}>
        <IconButton type='button' onClick={onClickChangeMinDateHandler}>
          <ArrowBackIosIcon className={styles.arrowIcon} />
        </IconButton>
        <ClickAwayListener onClickAway={closeCalendarHandler}>
          <div className={styles.dateWrapper}>
            <button className={styles.dateButton} aria-describedby={popperId} type='button' onClick={openCalendarHandler}>
              {dayjs(selectedMinDate).format('DD.MM')}-{dayjs(selectedMaxDate).format('DD.MM')}
            </button>
            <Popper id={popperId} open={isOpen} anchorEl={anchorEl} className={styles.popover} placement='bottom'>
              <div className={styles.yearBlock}>
                <ClickAwayListener onClickAway={closeYearModalHandler}>
                  <div className={styles.yearWrapper}>
                    <button className={styles.yearButton} onClick={openYearModalHandler}>
                      {calendarDate?.getFullYear()}
                    </button>
                    <div className={styles.yearList}>
                      {isOpenYear &&
                        yearOptionArr?.map((year: number) => (
                          <div className={styles.yearItem} key={year} onClick={changeYearHandler(year)}>
                            {year}
                          </div>
                        ))}
                    </div>
                  </div>
                </ClickAwayListener>
              </div>
              <div className={styles.monthWrapper}>
                <button className={styles.monthButton} onClick={clickBackMonthHandler}>
                  <CircleLeftArrowSVGIcon />
                </button>
                <div className={styles.month}>{dayjs(calendarDate).format('MMMM')}</div>
                <button
                  className={`${styles.monthButton} ${(Boolean(!differenceMonths) && styles.buttonDisable) || ''}`}
                  disabled={Boolean(!differenceMonths)}
                  onClick={clickForwardMonthHandler}
                >
                  <CircleRightArrowSVGIcon />
                </button>
              </div>
              <div className={styles.weekWrapper}>
                <div className={styles.weekContainer}>
                  {weeksArr?.map((week: string) => (
                    <div className={styles.week} key={week}>
                      {week}
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.dayWrapper}>
                {!!prefixDaysLength &&
                  Array.from({ length: prefixDaysLength }).map((_, index: number) => (
                    <div className={`${styles.dayContainer} ${styles.dayDisabled}`} key={index}>
                      <div className={styles.dayButton}>{prefixDays - prefixDaysLength + index + 1}</div>
                    </div>
                  ))}
                {Array.from({ length: numDays - 1 }).map((_, index: number) => (
                  <div className={`${styles.dayContainer}`} key={index}>
                    <div
                      className={`${styles.dayButtonWrapper}
                      ${(Math.floor((new Date(newCalendarDate.setDate(index + 1)).valueOf() - selectedMinDate.valueOf()) / 86400000) >= 0 && Math.floor((new Date(newCalendarDate.setDate(index + 1)).valueOf() - selectedMaxDate.valueOf()) / 86400000) <= 0 && styles.dayButtonWrapper_selected) || ''}
                      ${(Math.floor((selectedMaxDate.valueOf() - new Date(newCalendarDate.setDate(index + 1)).valueOf()) / 86400000) === 0 && styles.dayButtonWrapper_maxSelected) || ''}
                      ${(Math.floor((selectedMinDate.valueOf() - new Date(newCalendarDate.setDate(index + 1)).valueOf()) / 86400000) === 0 && styles.dayButtonWrapper_minSelected) || ''}`}
                    >
                      <button
                        className={`${styles.dayButton} 
                        ${(Math.floor((selectedMinDate.valueOf() - new Date(newCalendarDate.setDate(index + 1)).valueOf()) / 86400000) === 0 && (isSelectedMaxDate ? styles.day_active : styles.day_nowActive)) || ''}
                        ${(Math.floor((selectedMaxDate.valueOf() - new Date(newCalendarDate.setDate(index + 1)).valueOf()) / 86400000) === 0 && (isSelectedMaxDate ? styles.day_nowActive : styles.day_active)) || ''}
                        ${(Math.floor((Date.now() - new Date(newCalendarDate.setDate(index + 1)).valueOf()) / 86400000) < 0 && styles.buttonDisable) || ''}`}
                        type='button'
                        onClick={onClickDayHandler(index + 1)}
                        disabled={Math.floor((Date.now() - new Date(newCalendarDate.setDate(index + 1)).valueOf()) / 86400000) < 0}
                      >
                        {index + 1}
                      </button>
                    </div>
                  </div>
                ))}
                {!!suffixDays &&
                  Array.from({ length: suffixDays + 1 }).map((_, index: number) => (
                    <div className={styles.dayContainer} key={index}>
                      <div className={`${styles.dayButton} ${styles.dayDisabled}`}>{index + 1}</div>
                    </div>
                  ))}
              </div>
            </Popper>
          </div>
        </ClickAwayListener>
        <IconButton type='button' onClick={onClickChangeMaxDateHandler}>
          <ArrowForwardIosIcon className={styles.arrowIcon} />
        </IconButton>
      </div>
    </>
  )
}

export default memo(TransactionsCalendar)
