import { Typography } from '@mui/material'
import { WrapFlexBox } from '../Boxes/WrapFlexBox'

export const SectionPromo: React.FC = () => {
  return (
    <WrapFlexBox>
      <Typography variant='h2' sx={{ letterSpacing: '0px', mb: '25px', textTransform: 'initial' }}>
        Welcome to SkillRade, the ultimate platform for collaborative software development and service creation.
        <br />
      </Typography>

      <Typography variant='body2' sx={{ letterSpacing: '0px', maxWidth: '1030px', margin: '0 auto 50px' }}>
        Whether you're a developer, designer, or content creator, SkillRade allows you to contribute your skills, earn SkillCoins™, and use them to
        complete your own projects — all for free!
      </Typography>

      <Typography
        component='iframe'
        src='https://www.youtube.com/embed/XrMqbyXPXKA'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        sx={{ height: '396px', maxWidth: '1000px', width: '100%', margin: '0 auto', mb: '140px' }}
      ></Typography>
    </WrapFlexBox>
  )
}
