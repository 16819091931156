import { getDefaultMiddleware } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { createRootReducer } from './createRootReducer'
import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

export const history: History = createBrowserHistory()

const devMode = process.env.NODE_ENV === 'development' && typeof window === 'object'

export function configureStore() {
  const middleware = [routerMiddleware(history), ...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware]
  if (devMode) {
    // @ts-ignore
    middleware.push(logger)
  }
  const store = createStore(createRootReducer(history), applyMiddleware(...middleware))
  sagaMiddleware.run(rootSaga)

  return store
}
