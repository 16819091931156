import React, { FC } from 'react'
import { Select, SelectChangeEvent } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { openHook } from '@hooks/openHook'
import { OptionIconArrInterface } from '@interfaces/interfaces'
import styles from './SelectArr.module.scss'

interface Props {
  label: string
  optionArr: OptionIconArrInterface[]
  setValue: any
  value: string
}

const SelectArr: FC<Props> = ({ label, optionArr, setValue, value }: Props) => {
  const { isOpen, openHandler, closeHandler } = openHook()
  const changeValueHandler = (event: SelectChangeEvent) => {
    setValue(event.target.value)
  }
  return (
    <div className={styles.wrapper}>
      <label className={styles.title} onClick={openHandler}>
        {label}
      </label>
      <Select
        className={styles.select}
        open={isOpen}
        defaultValue={value || ''}
        value={value || ''}
        onChange={changeValueHandler}
        onClose={closeHandler}
        onOpen={openHandler}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{ MenuListProps: { style: { background: 'rgba(30, 14, 63)' } } }}
      >
        {optionArr?.map(({ label, value }: OptionIconArrInterface) => (
          <MenuItem className='flex-g' key={value} value={String(value)}>
            <div className={styles.itemLabel}>{label}</div>
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default SelectArr
