import React, { FC, memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import TelegramIcon from '@mui/icons-material/Telegram'
import AvatarUser from '@components/userComponents/StartsUserBox/AvatarUser/AvatarUser'
import AddCommentNewsField from '../AddCommentNews/AddCommentNewsField/AddCommentNewsField'
import LandscapeNewsField from '../AddCommentNews/LandscapeNewsField/LandscapeNewsField'
import { PopoverInterface } from '@components/popovers/BluePopover/BluePopover'
import HorizonDotsPopover from '@components/popovers/HorizonDotsPopover/HorizonDotsPopover'
import { minLengthValidationVar, requiredValidationVar } from '@utils/fieldsValidationVariables'
import { CommentNewsFieldValuesInterface } from '../AddCommentNews/AddCommentNews'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { loadingPageCommentNewsArrAction } from '@state/newsArr/newsArrStateSlice'
import { CommentByNewsInterface } from '@state/news/newsStateSlice'
import styles from '../AddCommentNews/AddCommentNews.module.scss'
import errorResponseReturn from '@utils/errorResponseReturn'

const validationSchema = yup.object().shape({
  text: yup.string().required(requiredValidationVar).min(minLengthValidationVar.min, minLengthValidationVar.message),
  image: yup.string().notRequired(),
})

interface Props {
  comment: CommentByNewsInterface
  page?: number
}
const EditCommentNews: FC<Props> = ({ comment, page }: Props) => {
  const { profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const [isEdit, setEdit] = useState<boolean>(false)
  const initialValues: CommentNewsFieldValuesInterface = {
    text: comment?.text || '',
    image: comment?.image || '',
  }
  const dispatch = useDispatch()
  const submitHandler = async (fieldValues: CommentNewsFieldValuesInterface, { setErrors }: FormikHelpers<CommentNewsFieldValuesInterface>) => {
    if (comment?.newsId) {
      apiConnectAxios(`/project-news-comments/${comment?.id}`, 'patch', { ...fieldValues, newsId: comment?.newsId }, true)
        .then(() => {
          toast.success('Comment edit')
          setEdit(false)
          dispatch(loadingPageCommentNewsArrAction({ newsId: comment?.newsId, commentPage: page || 1 }))
        })
        .catch(error => {
          setErrors(error?.response?.data?.errors || {})
          toast.error(errorResponseReturn(error))
        })
    }
  }
  const clickEditHandler = () => {
    setEdit(true)
  }
  const clickDeleteHandler = () => {
    apiConnectAxios(`/project-news-comments/${comment?.id}`, 'delete')
      .then(() => {
        toast.success('Comment deleted')
        dispatch(loadingPageCommentNewsArrAction({ newsId: comment?.newsId, commentPage: page || 1 }))
      })
      .catch(error => {
        toast.error(errorResponseReturn(error))
      })
  }
  const popoverArr: PopoverInterface[] = [
    { title: 'Edit', clickFunction: clickEditHandler },
    { title: 'Delete', clickFunction: clickDeleteHandler },
  ]
  return (
    <div className={styles.wrapper}>
      <AvatarUser memberId={profile?.memberId} avatar={profile?.avatar} />
      <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
        <Form className={styles.form} autoComplete='off'>
          <Field name='text' component={AddCommentNewsField} placeholder='Add a comment ........' isDisabled={!isEdit} />
          <Field name='image' component={LandscapeNewsField} isEdit={isEdit} />
          {isEdit && (
            <div className='flex-center-g'>
              <button type='submit'>
                <TelegramIcon />
              </button>
            </div>
          )}
        </Form>
      </Formik>
      {!isEdit && <HorizonDotsPopover popoverArr={popoverArr} />}
    </div>
  )
}

export default memo(EditCommentNews)
