import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQueryHook } from '@hooks/useQueryHook'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton } from '@mui/material'
import { loadingStatusExecutorTaskArrAction } from '@state/executorTaskArr/executorTaskArrStateSlice'
import styles from './ProjectsSearchInput.module.scss'

const ProjectsSearchInput: FC = () => {
  const [searchTask, setSearchTask] = useState<string>('')
  const dispatch = useDispatch()
  const query: URLSearchParams = useQueryHook()
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()
  useEffect(() => {
    dispatch(loadingStatusExecutorTaskArrAction())
  }, [query.get('searchName'), query.get('page'), query.get('projectId'), query.get('executorId'), dispatch])

  const changeSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTask(event?.target?.value)
  }
  const clickSearchUserHandler = () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('searchName', searchTask)
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }

  return (
    <div className={styles.searchWrapper}>
      <input className={styles.search} name='search' type='search' value={searchTask} placeholder='Search name' onChange={changeSearchHandler} />
      <IconButton className={styles.searchButton} type='button' onClick={clickSearchUserHandler}>
        <SearchIcon className={styles.searchIcon} />
      </IconButton>
    </div>
  )
}

export default memo(ProjectsSearchInput)
