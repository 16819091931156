import React, { FC, memo } from 'react'
import { useDispatch } from 'react-redux'
import { Pagination } from '@mui/material'
import { loadingPageCommentNewsArrAction } from '@state/newsArr/newsArrStateSlice'
import styles from './CommentNewsPagination.module.scss'

interface Props {
  page: number
  pageCount: number
  newsId: number
}

const CommentNewsPagination: FC<Props> = ({ page, pageCount, newsId }: Props) => {
  const dispatch = useDispatch()
  const changePageHandler = (_event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(loadingPageCommentNewsArrAction({ newsId, commentPage: page }))
  }

  return (
    <div>
      <Pagination
        className={styles.pagination}
        onChange={changePageHandler}
        count={pageCount}
        page={page}
        variant='outlined'
        shape='rounded'
        showFirstButton
        showLastButton
      />
    </div>
  )
}
export default memo(CommentNewsPagination)
