import React, { FC, ReactNode, useId, useState } from 'react'
import Popper from '@mui/material/Popper'
import { MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import styles from './BluePopover.module.scss'
export interface PopoverInterface {
  title: string
  title2?: string
  iconComponent?: ReactNode
  clickFunction: (id?: number, argument?: string) => void
}

interface Props {
  buttonRender: (id: string, openHandler: (event: React.MouseEvent<HTMLButtonElement>) => void) => void
  popoverArr: PopoverInterface[]
  id?: number
  argument?: string | any
  title2Index?: number | null
}
const BluePopover: FC<Props> = ({ buttonRender, popoverArr, id, argument, title2Index }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const closeHandler = () => {
    setAnchorEl(null)
  }
  const openHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const clickHandler = (clickFunction: (id?: number, argument?: string) => void) => () => {
    setAnchorEl(null)
    if (id && argument) clickFunction(id, argument)
    else if (id) clickFunction(id)
    else clickFunction()
  }
  const isOpen = Boolean(anchorEl)
  const popperId: string = useId()
  const [arrowRef, setArrowRef] = useState(null)
  return (
    <>
      {buttonRender(popperId, openHandler)}
      <Popper
        id={popperId}
        open={isOpen}
        anchorEl={anchorEl}
        className={styles.popover}
        placement='bottom-end'
        modifiers={[{ name: 'arrow', enabled: true, options: { element: arrowRef } }]}
      >
        <Box component='span' ref={setArrowRef} className={styles.arrow} />
        <div className={styles.container}>
          {popoverArr.map(({ title, title2, iconComponent, clickFunction }: PopoverInterface, index: number) => (
            <MenuItem className={styles.item} onClick={clickHandler(clickFunction)} key={title}>
              {iconComponent && iconComponent}
              <span className={styles.text}>{(index == title2Index && title2) || title}</span>
            </MenuItem>
          ))}
        </div>
      </Popper>
      <div className={`${styles.fixed} ${(isOpen && styles.fixed_show) || ''}`} onClick={closeHandler} />
    </>
  )
}

export default BluePopover
