import axios from 'axios'
import fileDownload from 'js-file-download'

export const downloadFileHandler = (link: string, name: string) => () => {
  axios
    .get(link, {
      responseType: 'blob',
    })
    .then(res => {
      fileDownload(res?.data, `${name}.pdf`)
    })
}
