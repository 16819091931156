import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectInterface } from '@state/project/projectStateSlice'

export interface ProjectArrStateInterface {
  isProjectArrLoading: boolean
  isProjectArrFirstLoading: boolean
  projectArr: ProjectInterface[]
}

const initialProjectArrState: ProjectArrStateInterface = {
  isProjectArrLoading: true,
  isProjectArrFirstLoading: false,
  projectArr: [],
}

const projectArrState = createSlice({
  name: 'projectArrState',
  initialState: initialProjectArrState,
  reducers: {
    loadingProjectArrAction(state) {
      state.isProjectArrLoading = true
    },
    loadingProjectArrFailure(state) {
      state.isProjectArrLoading = false
      state.isProjectArrFirstLoading = true
    },
    loadingProjectArrSuccess(state, { payload: { projectArr } }: PayloadAction<{ projectArr: ProjectInterface[] }>) {
      state.projectArr = projectArr
      state.isProjectArrLoading = false
      state.isProjectArrFirstLoading = true
    },
  },
})

export const { loadingProjectArrAction, loadingProjectArrFailure, loadingProjectArrSuccess } = projectArrState.actions

export default projectArrState.reducer
