import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DocumentTemplateInterface } from '@state/documentTemplate/documentTemplateStateSlice'

export interface DocumentTemplateArrStateInterface {
  isDocumentTemplateArrLoading: boolean
  isDocumentTemplateArrFirstLoading: boolean
  documentTemplateTaskArr: DocumentTemplateInterface[] | null
  documentTemplateTaskCount: number
  documentTemplateProjectArr: DocumentTemplateInterface[] | null
  documentTemplateProjectCount: number
}

const initialDocumentTemplateArrState: DocumentTemplateArrStateInterface = {
  isDocumentTemplateArrLoading: true,
  isDocumentTemplateArrFirstLoading: false,
  documentTemplateTaskArr: null,
  documentTemplateTaskCount: 0,
  documentTemplateProjectArr: null,
  documentTemplateProjectCount: 0,
}

const documentTemplateArrState = createSlice({
  name: 'documentTemplateArrState',
  initialState: initialDocumentTemplateArrState,
  reducers: {
    loadingDocumentTemplateArrAction(state) {
      state.isDocumentTemplateArrLoading = true
    },
    loadingDocumentTemplateArrFailure(state) {
      state.isDocumentTemplateArrLoading = false
      state.isDocumentTemplateArrFirstLoading = true
    },
    loadingDocumentTemplateArrSuccess(
      state,
      {
        payload: { documentTemplateTaskArr, documentTemplateTaskCount, documentTemplateProjectArr, documentTemplateProjectCount },
      }: PayloadAction<{
        documentTemplateTaskArr: DocumentTemplateInterface[]
        documentTemplateTaskCount: number
        documentTemplateProjectArr: DocumentTemplateInterface[]
        documentTemplateProjectCount: number
      }>,
    ) {
      state.documentTemplateTaskArr = documentTemplateTaskArr
      state.documentTemplateTaskCount = documentTemplateTaskCount
      state.documentTemplateProjectArr = documentTemplateProjectArr
      state.documentTemplateProjectCount = documentTemplateProjectCount
      state.isDocumentTemplateArrLoading = false
      state.isDocumentTemplateArrFirstLoading = true
    },
  },
})

export const { loadingDocumentTemplateArrAction, loadingDocumentTemplateArrFailure, loadingDocumentTemplateArrSuccess } =
  documentTemplateArrState.actions

export default documentTemplateArrState.reducer
