import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from '@fields/FormField/FormField.module.scss'

interface Props {
  label: string
  text: string
  isLink?: boolean
}

const ProfileUserField: FC<Props> = ({ label, text, isLink }: Props) => (
  <div className={styles.container}>
    <h4 className={`${styles.title} label-title-g`}>{label}</h4>
    {!isLink && <div className={`${styles.input} align-items_center-g`}>{text}</div>}
    {isLink && (
      <div className={styles.input}>
        <Link className={styles.link} to={text}>
          {text}
        </Link>
      </div>
    )}
  </div>
)

export default ProfileUserField
