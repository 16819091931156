import React, { FC, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { IconButton } from '@mui/material'
import TelegramIcon from '@mui/icons-material/Telegram'
import AvatarUser from '@components/userComponents/StartsUserBox/AvatarUser/AvatarUser'
import AddCommentNewsField from './AddCommentNewsField/AddCommentNewsField'
import LandscapeNewsField from './LandscapeNewsField/LandscapeNewsField'
import { minLengthValidationVar, requiredValidationVar } from '@utils/fieldsValidationVariables'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { loadingNewsArrAction } from '@state/newsArr/newsArrStateSlice'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { CommentByNewsInterface } from '@state/news/newsStateSlice'
import styles from './AddCommentNews.module.scss'
import errorResponseReturn from '@utils/errorResponseReturn'

const validationSchema = yup.object().shape({
  text: yup.string().required(requiredValidationVar).min(minLengthValidationVar.min, minLengthValidationVar.message),
  image: yup.string().notRequired(),
})

export type CommentNewsFieldValuesInterface = Omit<CommentByNewsInterface, 'id' | 'newsId' | 'memberId' | 'member'>

interface Props {
  newsId: number | null
}
const initialValues: CommentNewsFieldValuesInterface = {
  text: '',
  image: '',
}
const AddCommentNews: FC<Props> = ({ newsId }: Props) => {
  const { profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const dispatch = useDispatch()
  const submitHandler = async (
    fieldValues: CommentNewsFieldValuesInterface,
    { setErrors, setValues, setTouched }: FormikHelpers<CommentNewsFieldValuesInterface>,
  ) => {
    if (newsId) {
      apiConnectAxios('/project-news-comments', 'post', { ...fieldValues, newsId }, true)
        .then(() => {
          dispatch(loadingNewsArrAction())
          toast.success('Comment created')
          setValues({ text: '', image: '' })
          setTouched({ text: false })
        })
        .catch(error => {
          setErrors(error?.response?.data?.errors || {})
          toast.error(errorResponseReturn(error))
        })
    }
  }

  return (
    <div className={styles.wrapper}>
      <AvatarUser memberId={profile?.memberId} avatar={profile?.avatar} />
      <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
        <Form className={styles.form} autoComplete='off'>
          <Field name='text' component={AddCommentNewsField} placeholder='Add a comment ........' />
          <Field name='image' component={LandscapeNewsField} />
          <div className='flex-center-g'>
            <IconButton type='submit'>
              <TelegramIcon />
            </IconButton>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default memo(AddCommentNews)
