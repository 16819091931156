import styled from '@emotion/styled'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import { Box, Button, Typography } from '@mui/material'
import { DefaultToastOptions } from 'react-hot-toast'

export const PopupsBox = styled(Box)({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '35px 35px 30px',
  borderRadius: '20px',
  background: '#311768',
  maxWidth: '480px',
  flexWrap: 'wrap',
})

export const PopupsInside = styled(Box)({
  alignItems: 'center',
  backgroundColor: 'rgba(18, 0, 31, .85)',
  bottom: '0',
  justifyContent: 'center',
  left: '0',
  position: 'fixed',
  right: '0',
  top: '0',
  zIndex: '999',
})

export const PopupsButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  fontSize: '18px',
  fontWidth: 600,
  color: 'black',
  background: '#fff',
  margin: '10px 0',
  height: '50px',
  width: '90%',
  '&:hover': {
    background: '#fff',
  },
})

export const PopupsClose = styled(Typography)({
  alignItems: 'left',
  position: 'absolute',
  right: '25px',
  top: '20px',
})

export const toastOptions: DefaultToastOptions = {
  id: 'toast',
  className: 'toaster',
  duration: 5000,
  icon: <PriorityHighRoundedIcon />,
  style: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  success: {
    style: {
      background: 'green',
      color: 'white',
    },
  },
  error: {
    style: {
      background: 'red',
      color: 'white',
    },
  },
}
