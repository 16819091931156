import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const WrapFlexBox = styled(Box)({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
})
