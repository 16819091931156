import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MemberInterface } from '@state/profile/profileStateSlice'
import { RequestInterface } from '@state/requestArr/requestArrStateSlice'

export enum StatusContractEnum {
  Approve = 'Approve',
  Revoke = 'Revoke',
}

export interface ContractInterface {
  id: number
  requestId: number
  request?: RequestInterface
  clientId: number
  client: MemberInterface // who created the task or project
  performerId: number
  performer: MemberInterface // who created this request
  status: StatusContractEnum
  name: string
  fileSource: string
  fileName: string
  size: string
  budget: number
  createdAt: Date
}

export interface ContractArrStateInterface {
  isContractArrLoading: boolean
  isContractArrFirstLoading: boolean
  contractArr: ContractInterface[]
  contractCount: number
}

const initialContractArrState: ContractArrStateInterface = {
  isContractArrLoading: true,
  isContractArrFirstLoading: false,
  contractArr: [],
  contractCount: 0,
}

const contractState = createSlice({
  name: 'contractArrState',
  initialState: initialContractArrState,
  reducers: {
    loadingContractArrAction(state) {
      state.isContractArrLoading = true
    },
    loadingContractArrFailure(state) {
      state.isContractArrLoading = false
      state.isContractArrFirstLoading = true
    },
    loadingContractArrSuccess(
      state,
      { payload: { contractArr, contractCount } }: PayloadAction<{ contractArr: ContractInterface[]; contractCount: number }>,
    ) {
      state.contractArr = contractArr
      state.contractCount = contractCount
      state.isContractArrLoading = false
      state.isContractArrFirstLoading = true
    },
  },
})

export const { loadingContractArrAction, loadingContractArrFailure, loadingContractArrSuccess } = contractState.actions

export default contractState.reducer
