import React, { FC, useId } from 'react'
import { FieldInputProps, FormikProps } from 'formik'
import { TextField } from '@mui/material'
import styles from '../FormField/FormField.module.scss'
import dayjs from 'dayjs'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  label: string
}

export const DateFormField: FC<Props> = ({ field: { name, value, onChange, onBlur }, form: { touched, errors }, label }: Props) => {
  const id: string = useId()
  return (
    <div className='column-align_start-g'>
      <label htmlFor={id} className={`${styles.title} label-title-g`}>
        {label}
      </label>
      <TextField
        id={id}
        type='date'
        style={{ padding: 0 }}
        className={`${styles.input} ${(touched?.[name] && errors?.[name] && styles.input__error) || ''} 'field-input-g'`}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder='dd.mm.yyyy'
        InputProps={{ inputProps: { min: dayjs(new Date(Date.now() + 86400000)).format('YYYY-MM-DD'), max: '2030-01-01' } }}
      />
      <div className={styles.error}>{`${(touched?.[name] && errors?.[name]) || `\u00A0`}`}</div>
    </div>
  )
}

export default DateFormField
