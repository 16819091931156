import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  OAuthProvider,
  Auth,
  signInWithCredential,
  UserCredential,
} from 'firebase/auth'

class AuthService {
  private readonly auth: Auth

  constructor() {
    this.auth = getAuth()
  }

  public async googleCredentialAuth(IdToken: string): Promise<UserCredential> {
    const credential = GoogleAuthProvider.credential(IdToken)

    // Sign in with credential from the Google user.
    const response = await signInWithCredential(this.auth, credential)

    return response
  }

  public async providerAuth(providerName: 'google' | 'github' | 'apple' | 'microsoft'): Promise<UserCredential> {
    let provider: GoogleAuthProvider | GithubAuthProvider | OAuthProvider

    switch (providerName) {
      case 'google':
        provider = new GoogleAuthProvider()
        return await signInWithPopup(this.auth, provider)

      case 'github':
        provider = new GithubAuthProvider()
        return await signInWithPopup(this.auth, provider)

      case 'apple':
        provider = new OAuthProvider('apple.com')
        return await signInWithPopup(this.auth, provider)

      case 'microsoft':
        provider = new OAuthProvider('microsoft.com')
        return await signInWithPopup(this.auth, provider)
    }
  }

  public async signOut() {
    await this.auth.signOut()
  }
}
export { AuthService }
