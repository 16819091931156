import React, { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { Pagination } from '@mui/material'
import { useQueryHook } from '@hooks/useQueryHook'
import styles from './PagePagination.module.scss'

interface Props {
  pageCount: number
  pageName?: string
}

const PagePagination: FC<Props> = ({ pageCount, pageName = 'page' }: Props) => {
  const query: URLSearchParams = useQueryHook()
  const history = useHistory<History>()
  const changePageHandler = (_event: React.ChangeEvent<unknown>, page: number) => {
    history.push(`${history.location.pathname}?${pageName}=${page}`)
  }

  return (
    <div>
      <Pagination
        className={styles.pagination}
        onChange={changePageHandler}
        count={pageCount}
        page={Number(query.get(pageName)) || 1}
        variant='outlined'
        shape='rounded'
        showFirstButton
        showLastButton
      />
    </div>
  )
}
export default memo(PagePagination)
