import React, { FC, useEffect, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import * as yup from 'yup'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { toast } from 'react-hot-toast'
import TextareaField from '@fields/TextareaField/TextareaField'
import PinkButton from '@buttons/PinkButton/PinkButton'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { minLengthValidationVar, requiredValidationVar } from '@utils/fieldsValidationVariables'
import errorResponseReturn from '@utils/errorResponseReturn'
import { ProjectParamsInterface, ProjectStateInterface } from '@state/project/projectStateSlice'
import { TaskParamsInterface, TaskStateInterface } from '@state/task/taskStateSlice'
import styles from './AddRequestProjectForm.module.scss'
import RequestMemberProject from '@pages/projectPages/ProjectPage/RequestMemberProject/RequestMemberProject'
import { useSelector } from 'react-redux'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'

const validationSchema = yup.object().shape({
  text: yup.string().required(requiredValidationVar).min(minLengthValidationVar.min, minLengthValidationVar.message),
})
interface FormValuesInterface {
  text: string
}

const initialValues = {
  text: '',
}
const AddRequestProjectForm: FC = () => {
  const { projectId } = useParams<ProjectParamsInterface>()
  const { taskId } = useParams<TaskParamsInterface>()
  const { url } = useRouteMatch()
  const isProjectPage: boolean = url?.includes('project')
  const submitHandler = async (fieldValues: FormValuesInterface, { setErrors, resetForm }: FormikHelpers<FormValuesInterface>) => {
    const values: any = { ...fieldValues }
    values[projectId ? 'projectId' : 'taskId'] = isProjectPage ? projectId : taskId
    apiConnectAxios('/requests', 'post', { ...values, area: isProjectPage ? 'Project' : 'Task' })
      .then((data: any) => {
        if (data?.data?.id) {
          setChatRequestId(data?.data?.id)
        }
        resetForm()
        toast.success('Request created')
      })
      .catch(error => {
        setErrors(error?.response?.data?.errors || {})
        toast.error(errorResponseReturn(error))
      })
  }

  const { project }: ProjectStateInterface = useSelector((state: AppStateInterface) => state.projectState)
  const { task }: TaskStateInterface = useSelector((state: AppStateInterface) => state.taskState)
  const { profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const [chatRequestId, setChatRequestId] = useState<null | number>(null)
  useEffect(() => {
    if (profile && isProjectPage ? project?.memberId : task?.memberId || !isProjectPage ? task?.memberId : project?.memberId) {
      apiConnectAxios(
        `/requests/client?client=${project?.memberId}&${isProjectPage ? `area=Project&projectId=${projectId}` : `area=Task&taskId=${taskId}`}`,
      )
        .then((data: any) => {
          if (data?.data?.id) setChatRequestId(data?.data?.id)
        })
        .catch(() => {})
    }
  }, [profile, isProjectPage, project, task])
  return (
    <>
      <RequestMemberProject chatRequestId={chatRequestId} />
      <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
        <Form className={styles.form} autoComplete='off'>
          <h4 className={styles.title}>add request</h4>
          <Field name='text' component={TextareaField} placeholder='Add reguest' />
          <div className={styles.buttonWrapper}>
            <PinkButton title='ADD REQUEST' type='submit' />
          </div>
        </Form>
      </Formik>
    </>
  )
}

export default AddRequestProjectForm
