import { createTheme } from '@mui/material'

//* Basic Font styles *//
export const basicThemeStyle = createTheme({
  typography: {
    h1: { fontWeight: 300, fontSize: '72px' },
    h2: { fontWeight: 400, fontSize: '48px' },
    h3: { fontWeight: 400, fontSize: '36px' },
    h4: { fontWeight: 500, fontSize: '18px' },
    h5: { fontWeight: 600, fontSize: '16px' },
    h6: { fontWeight: 600, fontSize: '14px' },
    body1: { fontWeight: 400, fontSize: '16px', color: '#fff' },
    body2: { fontWeight: 400, fontSize: '16px', color: '#bfbcc5' },
  },
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          ul: {
            button: {
              height: '50px',
              width: '50px',
              borderRadius: '50%',
              background: '#2a1761',
              color: '#ada2c3',
              ':hover': {
                background: '#fff',
                color: '#271156',
              },
            },
            '& .Mui-selected': {
              color: '#271156',
              background: '#fff',
              ':hover': {
                background: '#fff',
                color: '#271156',
              },
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#12a2f1',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          letterSpacing: '2px',
          ':hover': {
            backgroundColor: '#251b61',
          },
          '& .MuiButtonBase-root:not(:last-child)': {
            marginRight: '10px',
          },
        },
        sizeSmall: {},
        sizeMedium: {
          minHeight: '40px',
          minWidth: '100px',
        },
        sizeLarge: {
          minHeight: '55px',
          minWidth: '250px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#12a2f1',
      dark: '#2a125c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#de00ac',
      contrastText: '#fff',
    },
    text: {
      primary: '#fff',
      secondary: '#ada2c3',
    },
  },
})
