import { put, takeLatest, all, call, select } from 'redux-saga/effects'
import { createMatchSelector, push } from 'connected-react-router'
import { toast } from 'react-hot-toast'
import { loadingProjectAction, loadingProjectFailure, loadingProjectSuccess } from '@state/project/projectStateSlice'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { PayloadAction } from '@reduxjs/toolkit'

function* loadingProjectSaga({
  payload: { isMember, isProjectMembers },
}: PayloadAction<{ isMember?: boolean; isProjectMembers?: boolean }>): Generator {
  try {
    const matchSelector = createMatchSelector({ path: ['/project-news/:projectId', '/project/:projectId', '/project/:projectId/donations'] })
    const match: any = yield select(matchSelector)
    let queryParams: string = ''
    if (isProjectMembers) queryParams = 'isProjectMembers=true'
    if (isMember) queryParams = `${queryParams}&isMember=true`
    const { data }: any = yield call(apiConnectAxios, `/projects/${match?.params?.projectId}?${queryParams}`)
    if (!data) {
      yield put(push('/'))
      toast.error('Project does not exist')
    }
    yield put(loadingProjectSuccess({ project: data }))
  } catch (error: any) {
    yield put(push('/create-project'))
    toast.error(errorResponseReturn(error))
    yield put(loadingProjectFailure(errorResponseReturn(error)))
  }
}

export default function* projectRootSaga() {
  yield all([takeLatest(loadingProjectAction.type, loadingProjectSaga)])
}
