import React, { FC } from 'react'
import { useQueryHook } from '@hooks/useQueryHook'
import { useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'
import TransactionsBalanceAside from './TransactionsBalanceAside/TransactionsBalanceAside'
import TransactionsDetailsAside from './TransactionsDetailsAside/TransactionsDetailsAside'
import BlueButton from '@buttons/BlueButton/BlueButton'
import EmptyText from '@components/Text/EmptyText/EmptyText'
import PagePagination from '@components/PagePagination/PagePagination'
import TransactionsTableThead from './TransactionsTableThead/TransactionsTableThead'
import TransactionsTableTbody from './TransactionsTableTbody/TransactionsTableTbody'
import LoadingTransparentSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import TransactionsCalendar from './TransactionsCalendar/TransactionsCalendar'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { DirectionTransactionEnum, MethodTransactionEnum, PaymentTransactionEnum } from '@state/transaction/transactionStateSlice'
import { TransactionArrStateInterface } from '@state/transactionArr/transactionArrStateSlice'
import styles from './TransactionsPage.module.scss'

const TransactionsPage: FC = () => {
  const query: URLSearchParams = useQueryHook()
  const { transactionCount, isTransactionArrLoading, isTransactionArrFirstLoading }: TransactionArrStateInterface = useSelector(
    (state: AppStateInterface) => state.transactionArrState,
  )
  const onClickCreateTransactionHandler = () => {
    apiConnectAxios('/transactions', 'post', {
      payment: PaymentTransactionEnum.TopUp,
      direction: DirectionTransactionEnum.Incoming,
      method: MethodTransactionEnum?.Card,
      amount: Number((Math.random() * 100).toFixed(1)),
    })
      .then(() => {})
      .catch(error => {
        toast.error(errorResponseReturn(error))
      })
  }
  return (
    <>
      <HelmetTitle title='Transactions' />
      <div className='page-container-g direction_row-g'>
        <div className='direction_column-g'>
          <h1 className={styles.title}>All transactions</h1>
          <div className={styles.buttonWrapper}>
            <TransactionsCalendar />
            <BlueButton
              type='button'
              title='Create transaction'
              sx={{
                margin: '0 15px 0 auto',
                width: '220px !important',
                height: '40px !important',
                fontSize: '14px !important',
                fontWeight: '400 !important',
                letterSpacing: '2px !important',
              }}
              onClick={onClickCreateTransactionHandler}
            />
            <BlueButton
              type='button'
              title='Add transaction'
              sx={{
                width: '220px !important',
                height: '40px !important',
                fontSize: '14px !important',
                fontWeight: '400 !important',
                letterSpacing: '2px !important',
              }}
            />
          </div>
          <div className={styles.tableWrapper}>
            <LoadingTransparentSpin isLoading={isTransactionArrLoading} />
            <table className='width_max-g'>
              <TransactionsTableThead />
              <TransactionsTableTbody />
            </table>
            {isTransactionArrFirstLoading && !transactionCount && <EmptyText text='transaction' />}
            {transactionCount > 10 && <PagePagination pageCount={Math.ceil(transactionCount / 10)} />}
          </div>
        </div>
        {!query.get('transactionId') && <TransactionsBalanceAside />}
        {query.get('transactionId') && <TransactionsDetailsAside />}
      </div>
    </>
  )
}

export default TransactionsPage
