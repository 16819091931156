import React, { FC } from 'react'
import HelmetTitle from '@components/titles/HelmetTitle/HelmetTitle'

const DocumentCommercePage: FC = () => {
  return (
    <>
      <HelmetTitle title='Document Commerce' />
    </>
  )
}

export default DocumentCommercePage
