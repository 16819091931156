import React, { FC } from 'react'
import { FieldInputProps, FormikProps } from 'formik'
import styles from './TasksTableTbodyField.module.scss'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  isDisabled?: boolean
}
const TasksTableTbodyField: FC<Props> = ({ field: { name, onChange, value, onBlur }, form: { touched, errors }, isDisabled }: Props) => (
  <>
    <input
      className={styles.input}
      name={name}
      value={value}
      disabled={isDisabled}
      type='text'
      placeholder='Name'
      onBlur={onBlur}
      onChange={onChange}
    />
    <div className={styles.error}>{`${(touched?.[name] && errors?.[name]) || errors?.name || `\u00A0`}`}</div>
  </>
)

export default TasksTableTbodyField
