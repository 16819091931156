import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NewsInterface } from '@state/news/newsStateSlice'

export interface NewsArrStateInterface {
  isNewsArrLoading: boolean
  isNewsArrFirstLoading: boolean
  commentLoadingNewsId: number
  newsArr: NewsInterface[]
  newsCount: number
}

const initialNewsArrState: NewsArrStateInterface = {
  isNewsArrLoading: true,
  isNewsArrFirstLoading: false,
  commentLoadingNewsId: 0,
  newsArr: [],
  newsCount: 0,
}

const newsArrState = createSlice({
  name: 'newsArrState',
  initialState: initialNewsArrState,
  reducers: {
    loadingNewsArrAction(state) {
      state.isNewsArrLoading = true
    },
    loadingNewsArrFailure(state) {
      state.isNewsArrLoading = false
      state.isNewsArrFirstLoading = true
    },
    loadingNewsArrSuccess(state, { payload: { newsArr, newsCount } }: PayloadAction<{ newsArr: NewsInterface[]; newsCount: number }>) {
      state.newsArr = newsArr
      state.newsCount = newsCount
      state.isNewsArrLoading = false
      state.isNewsArrFirstLoading = true
    },
    deleteNewsArrAction(state, {}: PayloadAction<{ newsId: number }>) {
      state.isNewsArrLoading = true
    },
    deleteNewsArrFailure(state) {
      state.isNewsArrLoading = false
      state.isNewsArrFirstLoading = true
    },
    loadingPageCommentNewsArrAction(state, { payload: { newsId } }: PayloadAction<{ newsId: number; commentPage: number }>) {
      state.commentLoadingNewsId = newsId
    },
    loadingPageCommentNewsArrFailure(state) {
      state.commentLoadingNewsId = 0
    },
    loadingPageCommentNewsArrSuccess(state, { payload: { newsArr } }: PayloadAction<{ newsArr: NewsInterface[] }>) {
      state.newsArr = newsArr
      state.commentLoadingNewsId = 0
    },
  },
})

export const {
  loadingNewsArrAction,
  loadingNewsArrFailure,
  loadingNewsArrSuccess,
  deleteNewsArrAction,
  deleteNewsArrFailure,
  loadingPageCommentNewsArrAction,
  loadingPageCommentNewsArrFailure,
  loadingPageCommentNewsArrSuccess,
} = newsArrState.actions

export default newsArrState.reducer
