import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import AvatarUser from '@components/userComponents/StartsUserBox/AvatarUser/AvatarUser'
import { MessageRequestInterface, RequestArrStateInterface } from '@state/requestArr/requestArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import chatRobot from '@images/avatars/chatRobot.png'
import styles from './RequestsChatAvatar.module.scss'

interface Props {
  message: MessageRequestInterface
}

const RequestsChatAvatar: FC<Props> = ({ message }: Props) => {
  const { requestChat }: RequestArrStateInterface = useSelector((state: AppStateInterface) => state.requestArrState)
  return (
    <div className={styles.avatarWrapper}>
      {message?.systemStage || message?.systemType || message?.systemStatus ? (
        <img className={styles.img} src={chatRobot} alt='' />
      ) : (
        <AvatarUser
          memberId={message?.sendByMemberId == requestChat?.client?.memberId ? requestChat?.client?.memberId : requestChat?.performer?.memberId}
          avatar={message?.sendByMemberId == requestChat?.client?.memberId ? requestChat?.client?.avatar : requestChat?.performer?.avatar}
          sizePx={40}
        />
      )}
      <div className={styles.date}>{dayjs(message?.createdAt).format('HH:mm')}</div>
    </div>
  )
}

export default RequestsChatAvatar
