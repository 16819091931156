import React from 'react'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { IconButton } from '@mui/material'
import styles from './SeeMoreSmallButton.module.scss'

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  isMarginBottom?: boolean
}
const SeeMoreSmallButton: React.FC<Props> = ({ onClick }: Props) => (
  <IconButton className={styles.arrowButton} type='button' onClick={onClick}>
    <KeyboardDoubleArrowDownIcon className={styles.arrowIcon} />
  </IconButton>
)

export default SeeMoreSmallButton
