import { CSSProperties, FC, MouseEvent, ReactNode } from 'react'
import { Button } from '@mui/material'
import styles from './BlueButton.module.scss'

interface Props {
  title: string
  icon?: ReactNode
  type?: 'button' | 'submit' | 'reset'
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  isLowerStyle?: boolean
  sx?: CSSProperties
}

const BlueButton: FC<Props> = ({ title, icon, type = 'button', onClick, isLowerStyle = false, sx }: Props) => {
  const onClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(e)
  }
  return (
    <Button className={`${styles.button} ${isLowerStyle ? styles.button_small : ''}`} sx={sx} type={type} onClick={onClickHandler}>
      <span className='z-index-5-g'>
        {title}
        {icon || ''}
      </span>
    </Button>
  )
}

export default BlueButton
