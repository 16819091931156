import React, { FC, memo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { downloadFileHandler } from '@utils/downloadFileHandler'
import { Document, Page } from 'react-pdf'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import PDFFileField from '@fields/PDFFileField/PDFFileField'
import AddDocumentRequestsFileField from '@pages/RequestsPage/SearchUserRequests/AddDocumentRequestsClientForm/AddDocumentRequestsFileField/AddDocumentRequestsFileField'
import { requiredValidationVar } from '@utils/fieldsValidationVariables'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import errorResponseReturn from '@utils/errorResponseReturn'
import { openHook } from '@hooks/openHook'
import { loadingMessageRequestArrAction, loadingRequestArrAction, RequestArrStateInterface } from '@state/requestArr/requestArrStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ProfileStateInterface } from '@state/profile/profileStateSlice'
import { DocumentRequestInterface, TypeDocumentEnum } from '@state/documentArr/documentArrStateSlice'
import { ReactComponent as DownloadIcon } from '@icons/downloadIcon.svg'
import styles from '../AddDocumentRequestsClientForm/AddDocumentRequestsClientForm.module.scss'

interface DocumentValuesInterface {
  text: string
  file: string
}

const validationSchema = yup.object().shape({
  text: yup.string(),
  file: yup.string().required(requiredValidationVar),
})

interface Props {
  documentType: TypeDocumentEnum
  document: DocumentRequestInterface
}

const SignDocumentRequestsPerformerForm: FC<Props> = ({ documentType, document }: Props) => {
  const { profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const { requestChat }: RequestArrStateInterface = useSelector((state: AppStateInterface) => state.requestArrState)
  const dispatch = useDispatch()
  const { isOpen, setOpen, openHandler, closeHandler } = openHook()
  const initialValues: DocumentValuesInterface = {
    text: '',
    file: '',
  }

  const { url } = useRouteMatch()
  const submitHandler = async (fieldValues: DocumentValuesInterface, { setErrors }: FormikHelpers<DocumentValuesInterface>) => {
    if (requestChat?.performerId === profile?.memberId) {
      apiConnectAxios(
        `/request-documents/performer/sign`,
        'patch',
        { file: fieldValues?.file, text: fieldValues?.text, requestId: requestChat?.id, type: documentType },
        true,
      )
        .then(() => {
          toast.success(`${documentType} signed`)
          dispatch(loadingRequestArrAction())
          dispatch(loadingMessageRequestArrAction())
          setOpen(false)
        })
        .catch(error => {
          setErrors(error?.response?.data?.errors || {})
          toast.error(errorResponseReturn(error))
        })
    } else {
      toast.success('Access denied')
    }
  }
  return (
    <>
      <Button className={styles.NDAButton} type='button' onClick={openHandler}>
        {`ACCEPT ${documentType}`}
      </Button>
      <Dialog className={styles.modalWrapper} open={isOpen} onClose={closeHandler} scroll='body'>
        <div>
          <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
            <Form className={styles.form} autoComplete='off'>
              <h4 className={styles.title}>{`This ${url?.includes('project') ? 'project' : 'task'} requires signing of ${documentType}`}</h4>
              <div className={styles.fileWrapper}>
                <Button className={styles.fileButton} type='button' onClick={downloadFileHandler(document?.firstFileSource, document?.type)}>
                  <Document file={document?.firstFileSource}>
                    <Page pageNumber={1} />
                  </Document>
                  <div className={styles.fileBg}>
                    <DownloadIcon className={styles.downloadIcon} />
                  </div>
                </Button>
                <Field name='file' component={PDFFileField} size='small' />
              </div>
              <Field name='text' component={AddDocumentRequestsFileField} />
            </Form>
          </Formik>
        </div>
      </Dialog>
    </>
  )
}

export default memo(SignDocumentRequestsPerformerForm)
