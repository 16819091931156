import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popover from '@mui/material/Popover'
import Button from '@mui/material/Button'
import StartsUserBox from '@components/userComponents/StartsUserBox/StartsUserBox'
import LoadingBlockSpin from '@components/spins/LoadingBlockSpin/LoadingBlockSpin'
import SeeMoreButton from '@buttons/SeeMoreButton/SeeMoreButton'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ExecutorTaskArrStateInterface, loadingUsersExecutorTaskArrAction } from '@state/executorTaskArr/executorTaskArrStateSlice'
import { MemberInterface } from '@state/profile/profileStateSlice'
import styles from '@components/userComponents/ProjectUserPopoverBox/ProjectUserPopover/ProjectUserPopover.module.scss'

const TasksUserPopover: React.FC = () => {
  const dispatch = useDispatch()
  const { taskExecutorArr, taskExecutorPage, taskExecutorCount, isTaskExecutorArrLoading }: ExecutorTaskArrStateInterface = useSelector(
    (state: AppStateInterface) => state.executorTaskArrState,
  )
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const closeHandler = () => {
    setAnchorEl(null)
  }
  const clickSeeMoreHandler = () => {
    dispatch(loadingUsersExecutorTaskArrAction({ page: taskExecutorPage + 1, limit: 6 }))
  }
  const open = Boolean(anchorEl)
  return (
    <>
      {taskExecutorArr && taskExecutorCount > 4 && (
        <div className={styles.buttonWrapper}>
          <Button className={styles.avatarButton} variant='contained' onClick={clickHandler}>
            +{taskExecutorCount - 4}
          </Button>
        </div>
      )}
      <Popover
        className={styles.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={closeHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={styles.wrapper}>
          <LoadingBlockSpin isLoading={isTaskExecutorArrLoading} />
          {!!taskExecutorArr?.length &&
            taskExecutorArr?.map((member: MemberInterface) => (
              <Fragment key={member?.id}>
                <div className={styles.margin} />
                <StartsUserBox
                  memberId={member?.memberId}
                  avatar={member?.avatar}
                  firstName={member?.firstName}
                  lastName={member?.lastName}
                  rating={Number((Math.random() * 5).toFixed(1))}
                  reviews={Math.floor(Math.random() * 99)}
                />
                <hr className={styles.line} />
              </Fragment>
            ))}
          {taskExecutorCount > taskExecutorPage * 6 && <SeeMoreButton onClick={clickSeeMoreHandler} />}
        </div>
      </Popover>
    </>
  )
}

export default TasksUserPopover
