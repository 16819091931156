import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import TasksTableTbodyForm from './TasksTableTbodyForm/TasksTableTbodyForm'
import AvatarUser from '@components/userComponents/StartsUserBox/AvatarUser/AvatarUser'
import TasksStarts from './TasksStarts/TasksStarts'
import { getRandomInt } from '@utils/getRandomInt'
import { TaskStatusInternalEnum } from '@state/task/taskStateSlice'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { ExecutorProjectInterface, ExecutorTaskArrStateInterface, ExecutorTaskInterface } from '@state/executorTaskArr/executorTaskArrStateSlice'
import styles from './TasksTableTbody.module.scss'

const TasksTableTbody: FC = () => {
  const { executorTaskArr }: ExecutorTaskArrStateInterface = useSelector((state: AppStateInterface) => state.executorTaskArrState)
  return (
    <tbody>
      {!!executorTaskArr?.length &&
        executorTaskArr?.map((executorTask: ExecutorTaskInterface | ExecutorProjectInterface) => (
          <tr key={`${executorTask?.area}${executorTask?.id}${executorTask?.executor?.memberId}`}>
            <td className={styles.formTd}>
              <TasksTableTbodyForm executorTask={executorTask} />
            </td>
            <td className={styles.td}>
              <div className={styles.tdDiv}>
                <AvatarUser memberId={executorTask?.executor?.memberId} avatar={executorTask?.executor?.avatar} sizePx={40} />
              </div>
            </td>
            <td className={styles.td}>
              <div className={styles.tdDiv}>
                {executorTask?.statusInternal === TaskStatusInternalEnum.ToDo && <div className={styles.status_todo}>0%</div>}
                {executorTask?.statusInternal === TaskStatusInternalEnum.ForDevelop && <div>20%</div>}
                {executorTask?.statusInternal === TaskStatusInternalEnum.InProgress && <div>50%</div>}
                {executorTask?.statusInternal === TaskStatusInternalEnum.Testing && <div>80%</div>}
                {executorTask?.statusInternal === TaskStatusInternalEnum.Done && <div className={styles.status_done}>100%</div>}
              </div>
            </td>
            <td className={styles.td}>
              <div className={styles.tdDiv}>{dayjs(executorTask?.createdAt).format('DD-MM')}</div>
            </td>
            <td className={styles.startsTd}>
              <TasksStarts rating={getRandomInt(0, 5)} />
            </td>
          </tr>
        ))}
    </tbody>
  )
}

export default memo(TasksTableTbody)
