import { FC, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Box, IconButton, useTheme } from '@mui/material'
import { ProfileInterface, ProfileStateInterface } from '@state/profile/profileStateSlice'
import { getAuth } from 'firebase/auth'
import { FlexBox } from '../Boxes/FlexBox'
import DropdownAddButton from '@components/Header/DropdownAddButton/DropdownAddButton'
import DropdownProfileOptions from './DropdownProfile/DropdownProfile'
import { ReactComponent as ChatSvgIcon } from '@icons/chatIcon.svg'
import { Count } from './styles'
import { loadingRequestArrAction, RequestArrStateInterface } from '@state/requestArr/requestArrStateSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateInterface } from '@state/_store/createRootReducer'

export enum DropDownsConsts {
  PROFILE = 'PROFILE',
  CREATESPAGES = 'CREATESPAGES',
}

export const LoggedInSection: FC<{ memberProfile: ProfileInterface | null }> = ({ memberProfile }) => {
  const theme = useTheme()
  const auth = getAuth()
  const history = useHistory()
  const [activePopup, setActivePopup] = useState<string | null>(null)
  const anchorRefProfile = useRef<HTMLButtonElement>(null)
  const anchorRefAddBtn = useRef<HTMLButtonElement>(null)
  const dropdownsContainerRef = useRef<HTMLDivElement>(null)
  const { unreadMessagesCount }: RequestArrStateInterface = useSelector((state: AppStateInterface) => state.requestArrState)
  const { isProfileLoading, isProfileFirstLoading, profile }: ProfileStateInterface = useSelector((state: AppStateInterface) => state.profileState)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!isProfileLoading && isProfileFirstLoading && profile) {
      dispatch(loadingRequestArrAction())
    }
  }, [isProfileLoading, isProfileFirstLoading, profile, dispatch])
  useEffect(() => {
    return history.listen(() => {
      setActivePopup(null)
    })
  }, [history])

  async function onLogout() {
    auth.signOut()
    window.location.href = '/'
  }

  return (
    <FlexBox ref={dropdownsContainerRef}>
      <Box
        sx={{
          display: 'flex',
          pr: '10px',
          [theme.breakpoints.down(576)]: {
            display: 'none',
          },
        }}
      >
        <DropdownAddButton
          activePopup={activePopup}
          setActivePopup={setActivePopup}
          dropdownKey={DropDownsConsts.CREATESPAGES}
          anchorRef={anchorRefAddBtn}
        />
        <IconButton href='#' sx={{ margin: '0 8px' }}>
          <img src='../images/icons/notification.png' alt='' />
          <Count>2</Count>
        </IconButton>
        <Link to='/requests' style={{ display: 'inline-flex' }}>
          <IconButton sx={{ margin: '0 8px' }}>
            <ChatSvgIcon />
            {!!unreadMessagesCount && <Count>{unreadMessagesCount}</Count>}
          </IconButton>
        </Link>
      </Box>
      <DropdownProfileOptions
        activePopup={activePopup}
        setActivePopup={setActivePopup}
        memberProfile={memberProfile}
        onLogout={onLogout}
        anchorRef={anchorRefProfile}
        dropdownKey={DropDownsConsts.PROFILE}
      />
    </FlexBox>
  )
}
