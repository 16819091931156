import { styled } from '@mui/material'

export const FormField = styled('input')`
  height: 55px;
  padding-left: 30px;
  width: 100%;
  background-color: #271156ab;
  border: none;
  border-radius: 5px;
  color: #ada2c3;
  font-size: 16px;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`
