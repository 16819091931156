import React, { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { AppStateInterface } from '@state/_store/createRootReducer'
import { TransactionArrStateInterface } from '@state/transactionArr/transactionArrStateSlice'
import { ReactComponent as CircleGreenArrowSvgIcon } from '@icons/circleGreenArrowIcon.svg'
import { ReactComponent as CircleRedArrowSvgIcon } from '@icons/circleRedArrowIcon.svg'
import { ReactComponent as CurrencySvgIcon } from '@icons/currencyIcon.svg'
import { ReactComponent as CircleCheckMarkSvgIcon } from '@icons/circleCheckMarkIcon.svg'
import { ReactComponent as ExclamationMarkSvgIcon } from '@icons/circleRedExclamationMarkIcon.svg'
import styles from './TransactionsTableTbody.module.scss'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { DirectionTransactionEnum, StatusTransactionEnum, TransactionInterface } from '@state/transaction/transactionStateSlice'

const TransactionsTableTbody: FC = () => {
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()
  const openTransactionHandler = (transactionId: number) => (): void => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('transactionId', String(transactionId))
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  const { transactionArr }: TransactionArrStateInterface = useSelector((state: AppStateInterface) => state.transactionArrState)
  return (
    <tbody>
      {!!transactionArr?.length &&
        transactionArr?.map((transaction: TransactionInterface) => (
          <tr className={styles.bodyTr} key={transaction?.id} onClick={openTransactionHandler(transaction?.id)}>
            <td>
              <div className={styles.tdDiv}>
                {transaction?.direction === DirectionTransactionEnum.Incoming ? (
                  <CircleGreenArrowSvgIcon className={styles.arrowIcon} />
                ) : (
                  <CircleRedArrowSvgIcon className={styles.arrowIcon} />
                )}
                {dayjs(transaction?.createdAt).format('MMMM D, YYYY')}
              </div>
            </td>
            <td>
              <div className={styles.tdDiv}>
                {transaction?.donationMember?.firstName || ''}
                {transaction?.donationMember?.lastName || ''}
              </div>
            </td>
            <td>
              <div className={styles.tdDiv}>{transaction?.direction}</div>
            </td>
            <td>
              <div className={styles.tdDiv}>
                {transaction?.amount}
                <CurrencySvgIcon className={styles.currencyIcon} />
              </div>
            </td>
            <td>
              <div className={styles.tdDiv}>
                {transaction?.status === StatusTransactionEnum.Success && <CircleCheckMarkSvgIcon />}
                {transaction?.status === StatusTransactionEnum.Hold && <AccessTimeIcon className={styles.timeIcon} />}
                {transaction?.status === StatusTransactionEnum.Error && <ExclamationMarkSvgIcon />}
              </div>
            </td>
          </tr>
        ))}
    </tbody>
  )
}

export default memo(TransactionsTableTbody)
