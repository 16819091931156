import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GlobalRoles, MemberInterface, ProfileInterface } from '@state/profile/profileStateSlice'
import { TagInterface } from '@interfaces/interfaces'

export interface ProjectParamsInterface {
  projectId: string
}
export interface ProjectByMemberInterface {
  id: number
  memberId: number
  member: MemberInterface
  projectId: number
  project: ProjectInterface
  memberLevel: GlobalRoles
}
export interface ProjectByMemberPageInterface {
  page: number
  projectByMemberArr: ProjectByMemberInterface[]
  projectByMemberCount: number
}

export interface ProjectInterface {
  id: number
  memberId: number
  member: MemberInterface
  createdBy: number
  name: string
  description: string
  tags: TagInterface[]
  isAutomaticSendingNda: boolean
  isRequiredContract: boolean
  image: string | null
  icon: string | null
  createdAt: Date
  projectByMembers: null | ProjectByMemberPageInterface
}

export interface ProjectStateInterface {
  isProjectLoading: boolean
  isProjectFirstLoading: boolean
  project: ProjectInterface | null
  projectUserArr: ProfileInterface[]
  projectUserCount: null | number
}

const initialProjectArrState: ProjectStateInterface = {
  isProjectLoading: true,
  isProjectFirstLoading: false,
  project: null,
  projectUserArr: [],
  projectUserCount: null,
}

const projectState = createSlice({
  name: 'projectState',
  initialState: initialProjectArrState,
  reducers: {
    loadingProjectAction(state, {}: PayloadAction<{ isMember?: boolean; isProjectMembers?: boolean }>) {
      state.isProjectLoading = true
    },
    loadingProjectFailure(state) {
      state.isProjectLoading = false
      state.isProjectFirstLoading = true
    },
    loadingProjectSuccess(state, { payload: { project } }: PayloadAction<{ project: ProjectInterface }>) {
      state.project = project
      state.isProjectLoading = false
      state.isProjectFirstLoading = true
    },
    loadingMembersProjectSuccess(state, { payload: { projectByMembers } }: PayloadAction<{ projectByMembers: ProjectByMemberPageInterface | any }>) {
      if (state.project) state.project.projectByMembers = projectByMembers
    },
  },
})

export const { loadingProjectAction, loadingProjectFailure, loadingProjectSuccess, loadingMembersProjectSuccess } = projectState.actions

export default projectState.reducer
