import { FC } from 'react'
import Toast from './components/popups/Toaster'
import Footer from './components/Footer'
import Routes from './Routes/Routes'
import Header from './components/Header/Header'
import { initializeApp } from '@firebase/app'
import { firebaseConfig } from './firebase-app-config'
import { AuthProvider } from './layouts/AuthContext'

initializeApp(firebaseConfig)

const App: FC = () => (
  <AuthProvider>
    <Toast />
    <Header />
    <Routes />
    <Footer />
  </AuthProvider>
)

export default App
