import React, { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryHook } from '@hooks/useQueryHook'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SelectArr from '@components/selects/SelectArr/SelectArr'
import { OptionIconArrInterface } from '@interfaces/interfaces'
import styles from './TransactionsTableThead.module.scss'
import { DirectionTransactionEnum } from '@state/transaction/transactionStateSlice'

const directionOptionArr: OptionIconArrInterface[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: DirectionTransactionEnum.Incoming,
    value: DirectionTransactionEnum.Incoming,
  },
  {
    label: DirectionTransactionEnum.Outgoing,
    value: DirectionTransactionEnum.Outgoing,
  },
]

const TransactionsTableThead: FC = () => {
  const query: URLSearchParams = useQueryHook()
  const {
    push,
    location: { search, pathname },
  } = useHistory<History>()
  const onChangeDateHandler = () => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('date', query.get('date') === 'up' ? 'down' : 'up')
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  const changeQueryDirectionHandler = (queryValue: string) => {
    const searchParams: URLSearchParams = new URLSearchParams(search)
    searchParams.set('direction', queryValue)
    push({
      pathname: pathname,
      search: searchParams.toString(),
    })
  }
  return (
    <thead>
      <tr className={styles.tr}>
        <th>
          <div className={styles.thDiv}>
            <button className={styles.thButton} onClick={onChangeDateHandler} type='button'>
              Date
              <ArrowDownwardIcon className={`${styles.arrow} ${(query.get('date') === 'up' && styles.arrow_up) || ''}`} />
            </button>
          </div>
        </th>
        <th>
          <div className={styles.thDiv}>Person</div>
        </th>
        <th>
          <div className={styles.thDiv}>
            <SelectArr
              label='direction'
              value={query.get('direction') || 'All'}
              setValue={changeQueryDirectionHandler}
              optionArr={directionOptionArr}
            />
          </div>
        </th>
        <th>
          <div className={styles.thDiv}>Amount</div>
        </th>
        <th></th>
      </tr>
    </thead>
  )
}

export default memo(TransactionsTableThead)
