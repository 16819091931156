import { styled } from '@mui/material'
import { AccordionItem, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'

export const Ul = styled('ul')`
  align-items: center;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  column-gap: 30px;
  margin-bottom: 30px;
`
export const StyledAccordionItem = styled(AccordionItem)`
  border: 1px solid #2a125c;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: rgba(42, 18, 92, 0.8);
  padding: 1rem;

  &:hover {
    background-color: #2a125c;
  }
`

export const StyledAccordionItemButton = styled(AccordionItemButton)`
  /* background-color: #f2f2f2; */
  border: none;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`

export const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  padding: 10px;
`
