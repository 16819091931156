import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Button, Tooltip } from '@mui/material'
import { toast } from 'react-hot-toast'
import { FieldInputProps, FormikProps, useField } from 'formik'
import { pdfjs } from 'react-pdf'
import { Document, Page } from 'react-pdf'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as FileSvgIcon } from '@icons/fileIcon.svg'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import styles from './PDFFileField.module.scss'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  isSmall?: boolean
}

const PDFFileField: FC<Props> = ({ field: { name, value }, form: { touched, errors }, isSmall }: Props) => {
  const [, { value: nameValue }, { setValue: setNameValue }] = useField('name')
  const [, , { setValue, setError, setTouched }] = useField(name)
  const maxSize = 10000000 // 10mb
  const supportedFormats: string[] = ['application/pdf']
  const [file, setFile] = useState<null | string>(null)
  useEffect(() => {
    if (!value) {
      setFile(null)
      return
    } else if (typeof value === 'object') {
      const reader: FileReader = new FileReader()
      reader.readAsDataURL(value)
      reader.onloadend = async () => {
        // @ts-ignore
        setFile(reader.result)
      }
      reader.onerror = error => toast.error(error?.toString())
    } else {
      setFile(value)
    }
  }, [value])
  const loadingFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      return
    }
    const reader: FileReader = new FileReader()
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [file]: any = e?.target?.files
    if (file) {
      reader.readAsDataURL(file)
      reader.onloadend = async () => {
        if (!supportedFormats.includes(file?.type)) {
          await setTouched(true)
          setError('Unsupported Format')
          return toast.error('Unsupported Format')
        }
        if (file.size > maxSize) {
          await setTouched(true)
          setError('Max file size is 10mb')
          return toast.error('Max file size is 10mb')
        }
        setError('')
        setValue(file)
        if (!nameValue) setNameValue(file?.name)
      }
      reader.onerror = error => toast.error(error?.toString())
    }
  }
  const clickDeleteValueHandler = () => {
    setValue('')
    setError('')
  }
  return (
    <div className={`${styles.wrapper} pdf-file-g`}>
      {!file && (
        <Button className={styles.fileButton} component='label'>
          <div className={styles.fileBorder}>
            <FileSvgIcon className={styles.fileIcon} />
            <span className={styles.fileText}>Drop your files here</span>
            <input name={name} type='file' accept={supportedFormats.join()} onChange={loadingFileHandler} />
          </div>
        </Button>
      )}
      {file && (
        <div className={styles.documentWrapper}>
          <div className={`${styles.document} ${isSmall || styles.document_small || ''}`}>
            <Document file={file}>
              <Page pageNumber={1} />
            </Document>
          </div>
          <Tooltip title='Delete file' placement='bottom'>
            <button className={styles.button} onClick={clickDeleteValueHandler} type='button'>
              <CloseIcon className={styles.icon} />
            </button>
          </Tooltip>
        </div>
      )}
      <span className={styles.error}>{`${(touched?.[name] && errors?.[name]) || '\u00A0'}`}</span>
    </div>
  )
}
export default PDFFileField
