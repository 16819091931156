import { put, takeLatest, all, select, call } from 'redux-saga/effects'
import { apiConnectAxios } from '@utils/apiConnectAxios'
import { toast } from 'react-hot-toast'
import errorResponseReturn from '@utils/errorResponseReturn'
import {
  loadingDocumentTemplateArrAction,
  loadingDocumentTemplateArrFailure,
  loadingDocumentTemplateArrSuccess,
} from '@state/documentTemplateArr/documentTemplateArrStateSlice'
import { loadingProjectDocumentTemplateSaga, loadingTaskDocumentTemplateSaga } from '@state/documentTemplate/documentTemplateSaga'

function* loadingDocumentTemplateArrSaga(): Generator {
  try {
    const { documentTaskPage, documentProjectPage }: any = yield select(state => state.router.location.query)
    const { data }: any = yield call(
      apiConnectAxios,
      `/document-templates?documentTaskPage=${documentTaskPage || 1}&documentTaskLimit=6&documentProjectPage=${documentProjectPage || 1}&documentProjectLimit=6`,
    )
    yield put(
      loadingDocumentTemplateArrSuccess({
        documentTemplateTaskArr: data?.documentTemplateTaskArr,
        documentTemplateTaskCount: data?.documentTemplateTaskCount,
        documentTemplateProjectArr: data?.documentTemplateProjectArr,
        documentTemplateProjectCount: data?.documentTemplateProjectCount,
      }),
    )
    yield call(loadingTaskDocumentTemplateSaga)
    yield call(loadingProjectDocumentTemplateSaga)
  } catch (error: any) {
    toast.error(errorResponseReturn(error))
    yield put(loadingDocumentTemplateArrFailure(errorResponseReturn(error)))
  }
}

export default function* documentTemplateArrRootSaga() {
  yield all([takeLatest(loadingDocumentTemplateArrAction.type, loadingDocumentTemplateArrSaga)])
}
