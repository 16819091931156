import axios from 'axios'

interface DefaultResponse {
  projects?: any[]
  communities?: any[]
  tasks?: any[]
  page: number
  maxPages: number
}

class CatalogQueryService {
  private readonly urlService: URL

  constructor(url: string) {
    this.urlService = new URL(url)
  }

  public get getUrl(): string {
    return this.urlService.href
  }

  public get getSearch(): string {
    return this.urlService.search
  }

  public async getCardsListByQuery(): Promise<DefaultResponse> {
    const result = await axios.get(this.getUrl)

    if (result.status !== 200) {
      console.log('Data coud not be fetched!')
    }

    return result.data
  }

  public getQueryByKey(key: string): string | null {
    return this.urlService.searchParams.get(key)
  }

  public setUrlQuery(key: string, value: string): void {
    this.urlService.searchParams.set(key, value)
    const currentURL = window.location.protocol + '//' + window.location.host + window.location.pathname + this.getSearch
    window.history.pushState({ path: currentURL }, '', currentURL)
  }

  public deleteUrlQuery(key: string): void {
    this.urlService.searchParams.delete(key)
    const currentURL = window.location.protocol + '//' + window.location.host + window.location.pathname + this.getSearch
    window.history.pushState({ path: currentURL }, '', currentURL)
  }

  public resetSearchParams(): void {
    const currentURL = window.location.protocol + '//' + window.location.host + window.location.pathname
    window.history.pushState({ path: currentURL }, '', currentURL)
    this.urlService.search = ''
  }
}
export const catalogQueryService = new CatalogQueryService(`${process.env.REACT_APP_BACKEND_APIDOMAIN}/common/search` + window.location.search)
