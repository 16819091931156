import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const RightLine = styled(Typography)`
  width: ${props => props.theme.spacing(8.5)};
  height: ${props => props.theme.spacing(0.2)};
  background-color: #099ff1;
`
export const SubtitleWrap = styled(Typography)`
  padding: 0 15px;
  color: #099ff1;
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.spacing(0.25)};
`

export const LeftLine = styled(Typography)`
  width: ${props => props.theme.spacing(8.5)};
  height: ${props => props.theme.spacing(0.2)};
  background-color: #099ff1;
`
