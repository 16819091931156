import { ColorsButton } from '@buttons/ColorsButton/ColorsButton'
import { Typography, useTheme } from '@mui/material'
import React from 'react'
import { authHeaderHooks } from '../../hooks/authHeaderHooks'
import { WrapFlexBox } from '../Boxes/WrapFlexBox'
import { Title } from '../Text/Title'

export const SectionIntro: React.FC = () => {
  const theme = useTheme()
  const { isJoinUsOpen } = authHeaderHooks()

  return (
    <WrapFlexBox sx={{ height: '100vh' }}>
      <div>
        <Typography sx={{ textTransform: 'uppercase', letterSpacing: '8px', fontSize: '20px', fontWeight: '400' }}>Join the</Typography>
        <Title title='Skill Rade' />
        <Typography variant='body2' sx={{ margin: '0 auto 85px', maxWidth: '620px', letterSpacing: '2px', lineHeight: '150%' }}>
          Find help and help others by trading time, not money. help others by trading time, not money.
        </Typography>

        <div>
          <ColorsButton title={'Why Time?'} buttonSize='large' backgroundColor={theme.palette.primary.main} />
          {isJoinUsOpen && <ColorsButton title={'Join Us'} buttonSize='large' backgroundColor={theme.palette.secondary.main} />}
        </div>
      </div>
    </WrapFlexBox>
  )
}
