import { combineReducers } from 'redux'
import { History } from 'history'
import { connectRouter } from 'connected-react-router'
import profileState, { ProfileStateInterface } from '@state/profile/profileStateSlice'
import followerState, { FollowerStateInterface } from '@state/follower/followerStateSlice'
import userState, { UserStateInterface } from '@state/user/userStateSlice'
import newsState, { NewsStateInterface } from '@state/news/newsStateSlice'
import newsArrState, { NewsArrStateInterface } from '@state/newsArr/newsArrStateSlice'
import projectState, { ProjectStateInterface } from '@state/project/projectStateSlice'
import projectArrState, { ProjectArrStateInterface } from '@state/projectArr/projectArrStateSlice'
import documentTemplateState, { DocumentTemplateStateInterface } from '@state/documentTemplate/documentTemplateStateSlice'
import documentTemplateArrState, { DocumentTemplateArrStateInterface } from '@state/documentTemplateArr/documentTemplateArrStateSlice'
import documentArrState, { DocumentArrStateInterface } from '@state/documentArr/documentArrStateSlice'
import taskState, { TaskStateInterface } from '@state/task/taskStateSlice'
import taskArrState, { TaskArrStateInterface } from '@state/taskArr/taskArrStateSlice'
import executorTaskArrState, { ExecutorTaskArrStateInterface } from '@state/executorTaskArr/executorTaskArrStateSlice'
import requestArrState, { RequestArrStateInterface } from '@state/requestArr/requestArrStateSlice'
import contractArrState, { ContractArrStateInterface } from '@state/contractArr/contractArrStateSlice'
import transactionState, { TransactionStateInterface } from '@state/transaction/transactionStateSlice'
import transactionArrState, { TransactionArrStateInterface } from '@state/transactionArr/transactionArrStateSlice'

export interface AppStateInterface {
  profileState: ProfileStateInterface
  followerState: FollowerStateInterface
  userState: UserStateInterface
  newsState: NewsStateInterface
  newsArrState: NewsArrStateInterface
  projectState: ProjectStateInterface
  projectArrState: ProjectArrStateInterface
  documentTemplateState: DocumentTemplateStateInterface
  documentTemplateArrState: DocumentTemplateArrStateInterface
  documentArrState: DocumentArrStateInterface
  taskState: TaskStateInterface
  taskArrState: TaskArrStateInterface
  executorTaskArrState: ExecutorTaskArrStateInterface
  requestArrState: RequestArrStateInterface
  contractArrState: ContractArrStateInterface
  transactionState: TransactionStateInterface
  transactionArrState: TransactionArrStateInterface
}

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    profileState,
    followerState,
    userState,
    newsState,
    newsArrState,
    projectState,
    projectArrState,
    documentTemplateState,
    documentTemplateArrState,
    documentArrState,
    taskState,
    taskArrState,
    executorTaskArrState,
    requestArrState,
    contractArrState,
    transactionState,
    transactionArrState,
  })
