import React, { FC } from 'react'
import { useQueryHook } from '@hooks/useQueryHook'
import RequestsChat from './RequestsChat/RequestsChat'
import RequestsChatSendMessage from './RequestsChatSendMessage/RequestsChatSendMessage'
import styles from './RequestsChatAside.module.scss'
const RequestsChatAside: FC = () => {
  const query: URLSearchParams = useQueryHook()
  return (
    <div className={styles.wrapper}>
      {query.get('chatRequestId') && (
        <>
          <RequestsChat />
          <RequestsChatSendMessage />
        </>
      )}
      {!query.get('chatRequestId') && (
        <div className={styles.chat}>
          <hr className={styles.line} />
          <div className={styles.conversation}>No conversation selected</div>
          <hr className={styles.line} />
          <div className={styles.text}>Please select conversation on the left side to start a chat</div>
        </div>
      )}
    </div>
  )
}

export default RequestsChatAside
